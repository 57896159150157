.center_block{
	padding-left: 78px;
}

.blckk-ht{
	height: 450px;
    margin-bottom: 10px;
}
.blckk-ht1{
    height: 680px;
    margin-bottom: 10px;
}

.resource_blck{
    border-radius: 4px;
    padding-top: 60px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    margin-top: 30px;
    text-align: center;
    padding: 8px;
}

.resource_txt{
    margin-top: 40px;
    font-size: 20px;
}

.resource_para{
	padding-top: 10px;
    font-size: 18px;
}
.resource_privacy{
  margin-left: 60px;
}
@media only screen and (device-width : 414px) and (device-height : 736px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 401px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 400px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 401px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 400px)  { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 375px) and (device-height : 667px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  .videocard1{}

}
@media only screen and (device-width : 320px) and (device-height : 568px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 411px) and (device-height : 823px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 411px) and (device-height : 731px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 360px) and (device-height : 640px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 600px) and (device-height : 960px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 412px) and (device-height : 732px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 384px) and (device-height : 640px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 400px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 393px) and (device-height : 851px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 768px) and (device-height : 1024px) { 
  .resource_blck{
   height: 400px;
  }
  

}
@media only screen and (device-width : 1024px) and (device-height : 768px) { 
  .resource_privacy{
    margin-left: 40px;
  }
}
.sbbannerWall{
	/*height: 535px;*/
  margin-top: 127px;
	/*background-color:  #444;*/
	padding: 0px !important;
	position: relative;
  text-align: center;
  color: white;
  	/*background-image: url("/images/bannerback.jpeg");*/
  background-size: 100% 100%;

}
/*.sbbannerWall:hover {
    -webkit-transform: scale(1.1) ! important;
    transform: scale(1.1) ! important;
    cursor: pointer;
    -webkit-transition-duration: .5s ! important;
    transition-duration: .5s ! important;
  }*/
.nopadding{
	padding: 0px;
}
.blogTitle_Single{
  font-size: 35px ! important;
  color: #fff ! important;
  margin-top: -30px ! important;
  margin-bottom: 20px;

}
@media screen and (max-width: 600px) {
  .blogTitle_Single{
    font-size: 24px ! important;
  }
  .fs24{
    font-size: 18px ! important;
  }

}
.sbcentered {
  background-color: #191818ba;
  /*background-image: url("/images/bannerback1.png");*/
  padding: 0px !important;
  /*background-repeat: no-repeat;*/
  height: 490px;
  background-size: 100% 100%;
  padding:0;
  background-color: rgba(25,24,24,.73);


}
.sbbtextcentered{
  top: 25%;
}
.fs40{
  font-size: 40px;
  margin-top: 40px;

}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.newblogTxt{
  font-size: 40px ! important;
}
.fs24{
  font-size: 20px;
  color:#fff ! important;
}
.ht100{
  height: 100px;
}
.takeleft{
	padding-left: 45px;
	background-color: transparent !important;
}
.breadcrumb1{
	background-color: transparent;
	width: 306px;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
}
.breadcrumb1responsive{
    background-color: transparent;
    width: 280px;
    height: 34px;
    background-color: #f5f5f5;
    border-radius: 100px;
}
.triangleright{

      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-left: 36px solid red;
      border-bottom: 19px solid transparent;
 }/* Base
------------------------------------------------------------------------- */
            
    /*body {
        font: 14px/1.5 Helvetica, Arial, "Lucida Grande", "Lucida Sans", Tahoma, Verdana, sans-serif;
        }  
    */
/* Step Navigation
------------------------------------------------------------------------- */
    
    .stepNav {
      /*margin: 30px 20px;*/
        height: 43px;
        padding-right: 20px;
        position: relative;
        z-index: 0;
        }
    
    /* z-index to make sure the buttons stack from left to right */
    
    .stepNav li {
        float: left;
        position: relative;
        z-index: 3;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.12);
           -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.12);
                    box-shadow: 0 1px 1px rgba(0,0,0,0.12);
        }
        
    .stepNav li:first-child {
        -webkit-border-radius: 5px 0 0 5px;
           -moz-border-radius: 5px 0 0 5px;
              border-radius: 5px 0 0 5px;
        }
        
    .stepNav li:nth-child(2) { z-index: 1; }
    .stepNav li:nth-child(3) { z-index: 0; }
    
    /* different widths */
    
    .stepNav.twoWide li { width: 50%; }
    .stepNav.threeWide li { width: 33.33%; }
    
       /* step links */
    
    .stepNav a, .stepNav a:visited {
        width: 100%;
        height: 34px;
        padding: 0 0 0 25px;
        color: #717171;
        text-align: center;
        text-shadow: 0 1px 0 #fff;
        line-height: 43px;
        white-space: nowrap;
        border: 1px solid #cbcbcb;
        text-decoration: none;
        border-top-color: #dddddd;
        border-right: 0;
        background-color: #fbfbfb;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(251, 251, 251)), to(rgb(233, 233, 233)));
        background-image: -webkit-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -moz-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -o-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -ms-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        float: left;
        position: relative;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                  box-sizing: border-box;
        }
        
    .stepNav li:first-child a {
        padding-left: 12px;
        -webkit-border-radius: 5px 0 0 5px;
           -moz-border-radius: 5px 0 0 5px;
              border-radius: 5px 0 0 5px;
        }
    
    .stepNav a:before {
        content: "";
        width: 27px;
        height: 22px;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #cbcbcb;
        background-image: -webkit-gradient(linear, right top, left bottom, from(rgb(251, 251, 251)), to(rgb(233, 233, 233)));
        background-image: -webkit-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -moz-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -o-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -ms-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        display: block;
        position: absolute;
        top: 6px;
        right: -13px;
        z-index: -1;
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }
        
    .stepNav a:hover {
        color: #2e2e2e;
        background-color: #f5f5f5;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(242, 242, 242)), to(rgb(217, 217, 217)));
        background-image: -webkit-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -moz-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -o-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -ms-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
        
    .stepNav a:hover:before {
        background-image: -webkit-gradient(linear, right top, left bottom, from(rgb(242, 242, 242)), to(rgb(217, 217, 217)));
        background-image: -webkit-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -moz-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -o-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -ms-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
    
    /* selected */
        
    .stepNav li.selected {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                    box-shadow: none;
        }
                                
    .stepNav li.selected a, .stepNav li.selected a:before {
        background: #ebebeb;
        }
        
    .stepNav li.selected a {
        border-top-color: #bebebe;
        -webkit-box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
           -moz-box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
                    box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
        }
        
    .stepNav li.selected a:before {
        border-right: 1px solid #bebebe;
        border-bottom: 1px solid #cbcbcb;
        -webkit-box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
           -moz-box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
                    box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
        }

 /* Base
------------------------------------------------------------------------- */
            
   /* body {
        font: 14px/1.5 Helvetica, Arial, "Lucida Grande", "Lucida Sans", Tahoma, Verdana, sans-serif;
        }*/  
    
/* Step Navigation
------------------------------------------------------------------------- */
    
    .stepNav {
      margin: 30px 20px;
        height: 43px;
        padding-right: 20px;
        position: relative;
        z-index: 0;
        }
    
    /* z-index to make sure the buttons stack from left to right */
    
    .stepNav li {
        float: left;
        position: relative;
        z-index: 3;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.12);
           -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.12);
                    box-shadow: 0 1px 1px rgba(0,0,0,0.12);
        }
        
    .stepNav li:first-child {
        -webkit-border-radius: 5px 0 0 5px;
           -moz-border-radius: 5px 0 0 5px;
              border-radius: 5px 0 0 5px;
        }
        
    .stepNav li:nth-child(2) { z-index: 1; }
    .stepNav li:nth-child(3) { z-index: 0; }
    
    /* different widths */
    
    .stepNav.twoWide li { width: 50%; }
    .stepNav.threeWide li { width: 33.33%; }
    
       /* step links */
    
    .stepNav a, .stepNav a:visited {
        width: 100%;
        height: 34px;
        padding: 0 0 0 25px;
        color: #717171;
        text-align: center;
        text-shadow: 0 1px 0 #fff;
        line-height: 43px;
        white-space: nowrap;
        border: 1px solid #cbcbcb;
        text-decoration: none;
        border-top-color: #dddddd;
        border-right: 0;
        background-color: #fbfbfb;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(251, 251, 251)), to(rgb(233, 233, 233)));
        background-image: -webkit-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -moz-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -o-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -ms-linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        float: left;
        position: relative;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                  box-sizing: border-box;
        }
        
    .stepNav li:first-child a {
    	/*width:110px;*/
        padding-left: 12px;
        -webkit-border-radius: 5px 0 0 5px;
        -moz-border-radius: 5px 0 0 5px;
        border-radius: 5px 0 0 5px;
        }
    
    .stepNav a:before {
        content: "";
        width: 27px;
        height: 22px;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #cbcbcb;
        background-image: -webkit-gradient(linear, right top, left bottom, from(rgb(251, 251, 251)), to(rgb(233, 233, 233)));
        background-image: -webkit-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -moz-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -o-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: -ms-linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        background-image: linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        display: block;
        position: absolute;
        top: 6px;
        right: -13px;
        z-index: -1;
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }
        
    .stepNav a:hover {
        color: #2e2e2e;
        background-color: #f5f5f5;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(242, 242, 242)), to(rgb(217, 217, 217)));
        background-image: -webkit-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -moz-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -o-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -ms-linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
        
    .stepNav a:hover:before {
        background-image: -webkit-gradient(linear, right top, left bottom, from(rgb(242, 242, 242)), to(rgb(217, 217, 217)));
        background-image: -webkit-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -moz-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -o-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: -ms-linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        background-image: linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
    
    /* selected */
        
    .stepNav li.selected {
        -webkit-box-shadow: none;
           -moz-box-shadow: none;
                    box-shadow: none;
        }
                                
    .stepNav li.selected a, .stepNav li.selected a:before {
        background: #ebebeb;
        }
        
    .stepNav li.selected a {
        border-top-color: #bebebe;
        -webkit-box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
           -moz-box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
                    box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
        }
        
    .stepNav li.selected a:before {
        border-right: 1px solid #bebebe;
        border-bottom: 1px solid #cbcbcb;
        -webkit-box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
        -moz-box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
         box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
        }
    .thirdbox{

    }    
  
.banner-wrapper{
	margin-top: 0px;
  /*margin-bottom: 18px;*/
  overflow: hidden;
}
.full-height {
  height: 100%;
  }
.banner{
}
.bannerContainer1{
  opacity: 1;
  background-size: 100% 100% ;
  width: 100%;
  height: 48em;
  margin-bottom: 50px ! important;
  background-image: url(/images/Banner3_.png);

}
.bannerContainer {
    background-size: 100% 100% ;
    width: 100%;
    height: 48em;
    background-image: url(/images/banner.png);
}
.resbannerimg{
  background-size: 100% 100%;
  height: 500px ! important;
  background-repeat: no-repeat;
  background-image: url("/images/newbanner_1.png");
}
.lead {
    font-size: 18px;
    line-height: 28px;
}
.title-h1{
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
    font-weight: 500;
    letter-spacing: -.4px;
}
.Home_Buttons {
    margin-top: 40px;
}
.Home_BlueButton {
    margin-right: 20px;
}
.btn--fill {
	border-radius: 28px;
    background-color: #37cd8f;
    color: #fff;
}
.btn--shadow-blue {
    box-shadow: 0 10px 25px 0 rgba(65,54,181,.45);
}
.btn-style{
	display: inline-block;
    border: 2px solid transparent;
    border-radius: 28px;
    cursor: pointer;
    font: inherit;
    font-weight: 500;
    line-height: 44px;
    outline: none;
    text-align: center;
    text-decoration: none;
    padding: 0 30px;
    transition: background-color .2s,color .2s,box-shadow .2s;
}
.btn-style:hover{
	color: #fff !important;
}
.btn-style:focus{
	color: #fff !important;
}
.btn--stroke-white {
	background: transparent;
    border-color: #fff;
    color: #fff;
}

.banner-wrapper:before {
    left: -50px;
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    opacity: .3;
}

.banner-wrapper:after {
    left: 50px;
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    opacity: .1;
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, 15px, 0);
  }
}

.banner-wrapper:after, .banner-wrapper:before {
    z-index: 0;
    bottom: -5px;
    left: 0;
    background-color: #eee;
    content: "";
}

.cloud1{
    position: absolute;
    left: -250px;
    animation:  cloud1 70s linear  infinite;
    z-index: 100;
    top: 3px;

}
.cloud1 img{
height: 170px;
}
.cloud2 img{
height: 140px;
}
.cloud3 img{
height: 120px;
}
.cloud4 img{
height: 150px;
}
.car{
    position: absolute;
    right: 5em;
    top: 42em;
    animation:  car 10s linear  infinite;
}
.car img{
}
.contanim{
    position: absolute;
    right: 5em;
    top: 26em;
    animation:  contanim 10s linear  infinite;
}
.contanim img{
}
.wheel1{
  position: absolute;
  right: 22px;
  top: 21px;
  animation:  wheel 0.4s linear  infinite;
}
.wheel2{
  position: absolute;
  right: 89px;
  top: 21px;
  animation:  wheel 0.4s linear  infinite;
}
.truckanim1{
    position: relative;
    left: 721px;
    top: 331px;
    overflow: hidden;
    /*background-color: red;*/
    height: 57px;
    width: 240px;
}
.truckanim{
    position: absolute;
    right: 0px;
    top: 0px;
    animation:  truckanim 10s linear  infinite;
}
.truckanim img{
  margin-right:127px;
}
.truckanim2{
    position: relative;
    left: 959px;
    top: 387px;
    overflow: hidden;
    /* background-color: red; */
    height: 57px;
    width: 418px;
    z-index: 2;
}
.truckanimtion{
    position: absolute;
    right: 0px;
    top: 0px;
    animation:  truckanimtion 10s linear  infinite;
}
.truckanimtion img{
  margin-right:645px;
}
.twheel4{
  position: absolute;
  right: 18px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}
.twheel5{
  position: absolute;
  right: 72px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}
.twheel6{
  position: absolute;
  right: 91px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}

.truckfront{
  position: absolute;
   right: 325px;
    top: 351px;
    overflow: hidden;
    /*background-color: red;*/
    z-index: 1;
    height: 123px;
}
.truckfront img{
  margin-top:0px;
  animation: truckfront 10s 7s linear  infinite;
  opacity: 0;
}
.twheel1{
  position: absolute;
  right: 18px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}
.twheel2{
  position: absolute;
  right: 72px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}
.twheel3{
  position: absolute;
  right: 91px;
  top: 34px;
  animation:  wheel 0.4s linear  infinite;
}
.cloud2{
    position: absolute;
    left: -330px;
    /*top: 0px;*/
    animation:  cloud2 110s linear  infinite;
    z-index: 101;
    top: 3px;
}
.cloud3{
    position: absolute;
    left: -750px;
    animation:  cloud3 140s linear  infinite;
    z-index: 102;
    top: 3px;
}
.cloud4{
    position: absolute;
    left: -950px;
    width: 100px;
    animation:  cloud4 165s linear  infinite;
    z-index: 103;
    top: 3px;

}
.bannersun{
    position: absolute;
    left: 419px;
    z-index: 10;
    bottom: 0px;
    right: 20px;
    top: 120px;
}
.tree1{
    position: absolute;
    right: 269px;
    top: 367px;
    z-index: 10;
}
.tree2{
    position: absolute;
    right: 101px;
    top: 375px;
    z-index: 10;
}
.tree3{
    position: absolute;
    right: 208px;
    top: 587px;
    z-index: 10;
}
.tree4{
    position: absolute;
    right: 0px;
    top: 421px;
    z-index: 10;
}
.bannersun img{
height: 90px;
}

.tank{
    position: absolute;
    left: 49em;
    top: 36em;
    z-index: 11;
}

.boat{
    position: absolute;
    left: -250px;
    top: 300px;
    animation:  boat 101s linear  infinite;

}
.up{
    position: absolute;
    left: 64px;
    top: 300px;

}
.up img{
  height: 160px;
}
.up1{
    position: absolute;
    left: 62.5px;
    top: 345px;
    overflow: hidden;

}
.up1 img{
  margin-top: -10px;
  animation:  up1 2s linear  infinite;

}
.animnewimg{
  top: 38.5em;
  left: 8em;
  position: absolute;
}
.animnewimg img{
  animation:  anim 8s linear  infinite;

}
.anim{
    position: absolute;
    left: 109px;
    top: 377px;
}
.anim img{
  animation:  anim 8s linear  infinite;

}
.oilsign{
    position: absolute;
    left: 163px;
    top: 405px;
    z-index: 1;
    animation:   8s linear  infinite;

}
.oilsign2{
    position: absolute;
    left: 31.7em;
    top: 32em;
    animation:   8s linear  infinite;
}
.oilsign3{
    position: absolute;
    left: 11.6em;
    top: 39.4em;
    animation: 8s linear infinite;
}
.oilsign4{
    position: absolute;
    right: 37em;
    top: 34.5em;
    animation:   8s linear  infinite;
}
.oilsign5{
    position: absolute;
    left: 9.6em;
    top: 20.3em;
    animation:   8s linear  infinite;
}
.oilsign6{
    position: absolute;
    right: 16em;
    top: 35em;
    animation:   8s linear  infinite;
}
.oilsign7{
    position: absolute;
    right: 16.5em;
    top: 15em;
    animation:  8s linear  infinite;
}
.oilsign8 {
    position: absolute;
    right: 40.9em;
    top: 16.7em;
    animation:  8s linear infinite;
    /*z-index: 1;*/
}
.oilsign8 img:hover .dataonHover1 {
      display: block !important;
      cursor: pointer;
}
.oilsign8 img:hover .triangle-down1  {
  display: block ! important;
  cursor: pointer;
}
.oilsign8 img:hover .triangle-down211  {
  display: block ! important;
  cursor: pointer;
}
.other{
    position: absolute;
    left: 720px;
    top: 94px;
}


@keyframes truckfront {
  
  0% {
    margin-top:-20px ;
    opacity: 1;
  }
  100% {
   margin-top:120px ;
    opacity: 1;
  }
  
}
@keyframes up1 {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}
@keyframes car {
  
  0% {
    right: 260px
  }
  100% {
  right: -150px;
  }
  
}
@keyframes contanim {
  
  0% {
    right: 189px
  }
  100% {
  right: -250px;
  }
  
}
@keyframes truckanim {
  
  0% {
    margin-right:127px;
  }
  100% {
    margin-right: -243px;
    opacity: 1;
    
  }
  
}@keyframes truckanimtion {
  
  0% {
    margin-right:645px;
  }
  100% {
    margin-right: -793px;
  }
  
}
@keyframes anim {
  
  0% {
  transform: rotate(0deg);
  }
  50% {
  transform: rotate(8deg);
  }
  
}
@keyframes oilsign {
  
  0% {
  transform: rotateY(0deg);
  }
  50% {
  transform: rotateY(360deg);
  }
  
}

@keyframes wheel {
  
  0% {
  transform: rotate(0deg);
  }
  100% {
  transform: rotate(360deg);
  }
  
}
@keyframes cloud1 {
  
  0% {
    left:-250px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud2 {
  
  0% {
    left:-330px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud3 {
  
  0% {
    left:-750px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud4 {
  
  0% {
    left:-950px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes boat {
  
  0% {
    left:-250px ;
  }
  50% {
   left:300px ;
  }
  
}



/*.hoveroilsign1:hover .dataonHover1  {
  display: block ! important;
  cursor: pointer;
}*/
/*try*/
.hoveroilsign1:hover{
  cursor: pointer;
}
/*.oilsign9:hover .dataonHover1{
  display: block;
  cursor: pointer;
}*/
.hoveroilsign8{
    /*display: none;*/
    height: 50px;
    position: absolute;
    width: 50px;
    top: 213px;
    left: 709px;
    background-color: #333;
    z-index: 999;
}
.oilsign7:hover .triangle-down1_oil7 {
  display: block;
  cursor: pointer;
}
.oilsign7:hover .dataonHover  {
  display: block;
  cursor: pointer;
}
.dataonHover{
    display: none;
    height: 113px;
    width: 233px;
    border-radius: 5px;
    background-color: #ffffff;
    left: 58.4em;
    top: 11.4em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 200;
}
.dataonHover1{
  display: none;
  height: 117px;
  width: 241px;
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 44.2em;
  top: 7.2em;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
}
.dataonHover2 {
    display: none;
    height: 105px;
    width: 233px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: -101px;
    margin-top: -147px;
    position: absolute;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
}
 .triangle-down {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #ffffff;
      margin-top: -43px
    }
.triangle-down1 {
    position: absolute;
     display: none; 
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.1);
    top: 219px;
    left: 721px;
    }
    .triangle-down1_oil7 {
      position: absolute;
     display: none; 
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.1);
    top: 142px;
    left: 73px;
    }
    .triangle-down21 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: -17px;
      right: -15px;
    }
    .triangle-down211 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: 114px;
    right: 112px;
    left: -14px;
    bottom: 2px;
    }
    .triangle-down211_oil7 {
      position: absolute;
      /*display: none;*/
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: 114px;
      right: 112px;
    }
    .triangle-down2 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      z-index: 1000;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid rgba(0, 0, 0, 0.1);
      margin-top: -42px
    }
    .triangle-left {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #ffffff;
      border-bottom: 15px solid transparent;
      left:31px;
      top:-2px;
    }

.hoveroilsign4:hover .dataonHover  {
      display: block ! important;
      cursor: pointer;
}
.hoveroilsign4:hover .arrow-right  {
  display: block ! important;
  cursor: pointer;
}

.hoveroilsign2:hover .dataonHover2  {
      display: block ! important;
      cursor: pointer;
}
.hoveroilsign2:hover .triangle-down2  {
  display: block ! important;
  cursor: pointer;
}
.hoveroilsign2:hover .triangle-down21  {
  display: block ! important;
  cursor: pointer;
}

.New_hoverdiv{
   /* height: 50px;
    width: 50px;
    background-color: #333;
    position: absolute;
    left: 51em;
    top: 16em;
    z-index: 2;*/

}
/*.New_hoverdiv:hover .dataonHover2{
  cursor: pointer;
  display: block;
}
*/


.pinhoverdata{
  color: #333;
  font-size: 12px;
  padding: 10px 6px 6px 3px;
}

.Hoverreadmore{
    font-size: 12px;
    color: #009de2;
}
.Hoverreadmore:hover{
  border-bottom: 1px solid #009de2;
}
.movingimg{
  display: none;
}
.movingimg1:hover .movingimg{
 display:block;
}
.result {
    height: 72px;
    width: 100%;
    border: 1px solid #000;
  }
  .result_hover {
    border: 1px solid #fff;
  }

@media screen and (max-width: 600px) {
   .resbanner{
        /*height: 670px ! important;*/
       }    
}  
  @media only screen 
    and (device-width : 375px) 
    and (device-width : 667px){
      .resbanner{
        height: 660px ! important;

      }
 }
  @media only screen 
    and (device-width : 401px) 
    and (device-width : 768px){
      .resbanner{
        height: 660px ! important;

      }
 }

  @media only screen 
    and (device-width : 390px) 
    and (device-height : 844px){
      .resbanner{
        margin-top: 100px;
        height: 800px ! important;

      }
 }
 
  @media only screen 
    and (device-width : 414px) 
    and (device-height : 736px){
      .resbanner{
        height: 730px ! important;

      }
 }
  @media only screen 
    and (device-width : 360px) 
    and (device-height : 640px){
      .resbanner{
        /*background-repeat: no-repeat;*/
        height: 690px ! important;

      }
 }
  @media only screen and (device-width : 360px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
   @media only screen and (device-width : 360px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
@media only screen and (device-width : 375px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
  @media only screen and (device-width : 375px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
  @media only screen and (device-width : 375px) and (device-height : 490px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
   @media only screen and (device-width : 384px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 700px ! important;

      }
  }
   @media only screen and (device-width : 384px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 700px ! important;

      }
  }
   @media only screen and (device-width : 240px) and (device-height : 320px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 420px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 600px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 812px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 823px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 240px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 420px ! important;

      }
  }
   @media only screen and (device-width : 412px) {
     .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }

   }
   @media only screen and (device-width : 412px) and (device-height : 732px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
  @media only screen and (device-width : 412px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 600px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
  @media only screen and (device-width : 320px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
  @media only screen and (device-width : 320px) and (device-height : 823px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 412px) and (device-height : 500px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 480px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 545px ! important;

      }
  }
   @media only screen and (device-width : 480px) and (device-height : 854px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 855px ! important;

      }
  }
   @media only screen and (device-width : 480px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 820px ! important;

      }
  }
  @media only screen and (device-width : 480px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 820px ! important;

      }
  }
   @media only screen and (device-width : 375px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
 @media only screen 
    and (device-width : 411px) 
    and (device-height : 731px){
      .resbanner{
        /*margin-top: 130px ! important;*/
        height: 765px ! important;

      }
 }
  @media only screen 
    and (device-width : 411px) 
    and (device-height : 823px){
      .resbanner{
        /*margin-top: 120px ! important;*/
        height: 860px ! important;

      }
 }
  @media only screen 
    and (min-device-width : 320px) 
    and (device-height : 568px){
      .resbanner{
        height: 570px ! important;
        background:50%;

      }
    }
  @media only screen 
    and (min-device-width : 393px) 
    and (device-height : 851px){
      .resbanner{
       /*background-image: url(/images/Mobile.png);*/
        /*background-size: cover;*/
        height: 850px ! important;
        /*object-fit: contain;*/
         background: 50%; 
        /*background-repeat: no-repeat;*/

      }
      .webclientbackimg{
        width: 310px ! important;
        margin-left: 49px ! important;
      }
 }
 @media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .resbanner{
        height: 812px ! important;
        background: 50%,100%;
        /*margin-top: 100px ! important;*/

      }
 }

  .resbanner{
    /*margin-top: 105px;*/
    background-image: url("/images/Mobile.png");
    background-size: cover;
    /*height: 730px ! important;*/
    object-fit: contain;
    /*background:50%;*/
    background-repeat: no-repeat;
  } 
  .arrow-right {
  display: none; 
  width: 0; 
  height: 0; 
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  right: 255px;
  top: 201px;
  position: absolute;
  border-left: 15px solid #ffffff;
} 
.oilimage{
  position: absolute;
  height: 124px;
  /*left: 968px;*/
  bottom: 0px;
  right: 248px;
  top: 160px;
  height: 101px;
  animation: slide-left 4s ;
  /*z-index: 110;*/
  /*opacity: 0.9;*/
}
.petrolimage1{
  position: absolute;
  right: 277px;
  top: 424px;
  height: 221px;
  animation: slide-top 4s;
   /*opacity: 0.7;*/

}
.boat_img2_img{
  width: 240px;
}
.waterimg2Div_img{
  width:135px; 
}
.petrolimage1_img{
  width: 255px;
}
.oilimage_img{
  width: 255px;

}
.plantimage11_img{
  width: 255px;
}
@media screen and (min-width: 1900px) {
  .bannerContainer1{
    height: 55em ! important;
  }
  #blackbannerDiv{
    height: 55em ! important;
  }
  .boat_img2{
    left:545px ! important;
    top:331px ! important;
  }
  .boat_img2_img{
    width: 330px ! important;
  }
  .waterimg2Div_img{
    width:230px ! important; 
  }
  .waterimg2{
    top:62% ! important;
    left:16% ! important;
  }
  .nexttogasimg_size{
    width: 330px ! important;
  }
  .nexttogasimage{
    right: 541px ! important;
    top:350px ! important;
  }
  .petrolimage1_img{
    width: 330px ! important;
  }
  .petrolimage1{
    top:500px ! important;
  }
  .oilimage_img{
    width: 330px ! important;
    
  }
  .plantimage11_img{
    width: 330px ! important;
  }
  .plantimage11{
    right: 26px ! important;
    top:341px ! important;
  }
  .boat_img{
    width: 500px ! important;
    height:270px ! important;
  }
  .boat_img_size{
    width: 230px ! important;
  }
  .waterimg_size{
    width: 190px ! important;
  }
  .topnav a{
    font-size: 18px ! important;
    padding: 15px 15px;
  }
  .expertiesdropdown{
    width:1316px ! important;
  }
  .dropdown .dropbtn{
    font-size: 18px ! important;
    padding: 15px 15px;
  }
}
@media only screen (min-width: 1600px) {
  .bannerContainer1{
    height: 55em ! important;
  }
  .tabimage{
    width: 350px ! important;
  }
  .tabimage_1{
    width: 300px ! important;
  }
  .plantimage1{
    right: 320px ! important;
    top: 245px ! important;
  }
   .nexttogasimage{
    left: 865px ! important;
    top: 578px ! important;
    width: 300px ! important;
  }
  .petrolimage1{
    right: 516px ! important;
    top: 392px ! important;
  }
  .gasimage{
    right: 264px ! important;
    top: 538px ! important;
  }
  .plantimage11 {
    right: 509px ! important;
    top: 159px ! important;
  }
  .petrolimage {
    right: 225px ! important;
    top: 296px ! important;
  }
  .oilimage {
    right: 196px ! important;
    top: 78px ! important;
  }
  .boat_img2 {
    left: 447px ! important;
    top: 377px ! important;
  }
}
.waterimg_size{
  width: 130px;
}
.boat_img_size{
  width: 155px;
  position: absolute;
  left: 151px;
  top: 90px;
}
.nexttogasimg_size{
  width: 240px;

}

@media only screen and (max-width: 768px) {
.oilimage{
  right: 108px;
  top: 159px;
}
.boat_img2_img{
  width: 173px ! important;
}
.plantimage11_img{
  width: 173px ! important;
}

.petrolimage1_img{
  width: 173px ! important;

}
.oilimage_img{
   width: 173px ! important;
}
.waterimg2_img {
  width:110px ! important;
}

.waterimg_size{
  width: 100px;
}
.nexttogasimg_size{
  width: 150px;
}
.boat_img_size{
  width: 125px;
  left: 116px;
  top: 65px;
}
.bannerContainer1{
  height: 38em ! important;
}
.tabimage{
  width: 180px ! important;
}
.plantimage11{
  right: -7px ! important;
  top: 278px ! important;
 }
 .petrolimage{
   right: 45px ! important;
   top: 229px ! important;
 }
 .boat_img{
    left: 38px ! important;
    top: 68px ! important;
 }
 .plantimage1{
    right: 285px !important;
    top: 211px ! important;
 }
 .petrolimage1{
  right: 140px ! important;
  top: 364px ! important;
 }
 .boat_img2{
  left: 158px ! important;
  top: 308px ! important;
 }
 .gasimage{
  right: 34px !important;
  top: 355px ! important;
 }
 .nexttogasimage_img{
  width: 173px ! important;
 }
 .nexttogasimage{
   right: 243px ! important;
   top: 268px ! important;
 }
 .waterimg{
  left: 41px ! important;
  top: 193px ! important;
 }
 .new_HoverDivforboat{
    margin-left: -46px;
    margin-top: -40px;
 }
 
 .waterimg2{
  top: 77% ! important;
  left: 10% ! important;
 }
 .new_HoverDiv1{
    margin-left: -157px ! important;
    margin-top: -164px !important;
 }
 .new_HoverDiv4{
    margin-left: -124px ! important;
    margin-top: -238px ! important;
 }
 .new_HoverDiv5{
  margin-left: -129px ! important;
  margin-top: -230px ! important;
 }
 .new_HoverDiv7{
    margin-left: -70px ! important;
    margin-top: -197px ! important;
 }
 .new_HoverDiv2{
    margin-left: -84px ! important;
    margin-top: -231px ! important;
 }
}
.gasimage{
  position: absolute;
  right: 88px;
  top: 282px;
  height: 168px;
  animation: slide-tl 4s;

}
.nexttogasimage{
   position: absolute;
   animation: slide-top 4s;
   bottom: 0px;
   right: 470px;
   top: 305px;
   height: 129px;
}
.petrolimage{
  position: absolute;
  bottom: 0px;
  right: 126px;
  top: 267px;
  height: 221px;
  animation: slide-tl 4s;


}

.plantimage1{
  position: absolute;
  bottom: 0px;
  right: 262px;
  top: 153px;
  height: 221px;
  animation: slide-bottom 4s ;

  /*z-index: 110;*/

}
.plantimage11{
  position: absolute;
  right: 65px;
  top: 299px;
  height: 100px;
  animation: slide-tl 4s;
  /*opacity: 1;*/

}

.fade-in {
animation: fadeIn ease 20s;
-webkit-animation: fadeIn ease 10s;
-moz-animation: fadeIn ease 10s;
-o-animation: fadeIn ease 10s;
-ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.waterimg{
  position: absolute;
  bottom: 0px;
  left: 67px;
  top: 261px;
  height: 221px;
  animation: slide-right 4s ease-out;
  
  /*opacity:1;*/
  /*z-index: 110;*/
}
/*.waterimg:hover{
  z-index: 120;
  
}*/
.waterimg:hover #blackbannerDiv{
  display: block;
  /*text-transform: */

}
/*.waterimg2:hover #blackbannerDiv{
  display: block;

}
*/
/*.scaleimage:hover{
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1); 
  transition: all 2s ease-in-out;

}*/
.boat_img2:hover{
  z-index: 120;
}
.boat_img2 :hover{
/*  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1); 
  transition: all 2s ease-in-out;*/

}
.boat_img2 :hover .waterimg2{
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1) ! important; 
  transition: all 2s ease-in-out;

}

.boat_img{
  position: absolute;
  bottom: 0px;
  left: 97px;
  top: 104px;
  width: 304px;
  height: 257px;
  animation: slide-br 4s
  /*z-index: 110;*/
  /*opacity:1;*/

}
.boat_img2{
  position: absolute;
  bottom: 0px;
  left: 361px;
  top: 336px;
  height: 221px;
  animation: slide-br 4s ;
  /*z-index: 110;*/
  /*opacity: 1;*/

}
.waterimg2{
  position: absolute;
  top: 72%;
  left: 16%;
  height: 221px;
  animation: slide-tr 4s;
  /*z-index: 110;*/
  /*opacity: 0.9;*/

}
.new_HoverDivforboat{
   height: 275px;
    width: 355px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -38px;
    margin-top: -41px;
    position: absolute;
    background-size: 100% 100%;
    animation: fadeIn ease 2s;
    z-index: 110;
    display: none;

}
.new_HoverDiv {
    display: none;
    height: 275px;
    width: 275px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -1px;
    margin-top: -167px;
    position: absolute;
    background-size: 100% 100%;
    animation: fadeIn ease 2s;
    z-index: 110;
  }

.new_HoverDiv img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv1 img {
       
       width: 400px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv2  img {

       width: 395px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDivforboat  img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv3  img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv4  img {
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv5  img {
    
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv6 img {
    
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv7 img {
    
        width: 390px;
       -webkit-animation: rotation 45s infinite linear;
}
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(150deg);
  }
}

  #blackbackground{
    display: none;
    background-color:#21252999;
    width: 1353px;
    height: 810px;
  }
    

.petrolimage1:hover{
  z-index: 110 ! important;
  /*opacity: 1;*/
}
/*.waterimg:hover .new_HoverDiv {
      display: block ! important;
      cursor: pointer;
      z-index: 140;
}
.waterimg:hover .hidepoweredby{
  display: none ! important;

}*/

.boat_img2:hover .  {
      z-index: 110;
}

.plantimage1fv:hover  {
     z-index: 110;
}
.nexttogasimage:hover{

  /*z-index: 110;*/

}
.oilimage:hover{
  z-index: 110 ! important;

}

.hiddendiv {
  visibility: hidden ! important;
}

.plantimage11:hover  {
     z-index: 110 ! important;

}
.new_HoverDiv1 {
    display: none;
    height: 275px;
    width: 400px ! important;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -220px;
    margin-top: -143px;
    position: absolute;
    animation: fadeIn ease 1.5s;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv5 {
    display: none;
    height: 260px;
    width: 410px ! important;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -84px;
    margin-top: -227px;
    animation: fadeIn ease 1.5s;
    position: absolute;
    background-size: 100% 100%;
    
}
.new_HoverDiv5 img{
  width: 400px ! important;
}

.new_HoverDiv3 {
    display: none;
    height: 275px;
    width: 355px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -26px;
    margin-top: -130px;
    position: absolute;
    animation: fadeIn ease 1.5s;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv4 {
    display: none;
    height: 275px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -33px;
    animation: fadeIn ease 1.5s;
    margin-top: -235px;
    position: absolute;
    background-size: 100% 100%;
    opacity: 1;
    z-index: 1000000;
    
}
.new_HoverDiv4 img{
  width: 409px ! important;
}
.new_HoverDiv6 {
    display: none;
    height: 275px;
    width: 355px;
    border-radius: 5px;
    animation: fadeIn ease 1.5s;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -98px;
    margin-top: -175px;
    position: absolute;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv7 {
    display: none;
    height: 274px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -37px;
    margin-top: -192px;
    animation: fadeIn ease 1.5s;
    position: absolute;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv2 {
    display: none;
    height: 275px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -12px;
    animation: fadeIn ease 1.5s;
    margin-top: -241px;
    position: absolute;
    background-size: 100% 100%;
    z-index:110;
    
}
/*.oilimage:hover .new_HoverDiv7  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;

}*/

.boat_img2:hover .new_HoverDiv1  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}

/*.boat_img:hover .new_HoverDiv  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}*/
/*.plantimage1:hover .new_HoverDiv3  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}*/
.nexttogasimage:hover .new_HoverDiv5 {
      display: block ! important;
      cursor: pointer;
      z-index: 120;
      /*opacity: 1;*/
}
.nexttogasimage:hover{
  z-index: 120;

  
}

.plantimage11:hover .new_HoverDiv4  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}
/*.petrolimage1:hover .new_HoverDiv2{
   display: block ! important;
   cursor: pointer;
  opacity: 1;

}*/
.petrolimage1:hover .new_HoverDiv2{
  display: block ! important;
  cursor: pointer;
}
.oilimage:hover .new_HoverDiv7{
  display: block ! important;
  cursor: pointer;
}

.onhoverimages{
  opacity: 0.7;

}
.onhoverBackgImg{
  opacity: 0.3;
}
.newhovertxt{
    margin-top: -252px;
    margin-left: 84px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    text-align: justify;
    position: absolute;
    width: 177px;
}
.newhovertxt_again{
    top: 132px;
    position: absolute;
    width: 160px;
    font-weight: 500;
    left: 92px;
    text-align: justify;
    font-size: 13px;
}
.newhovertxt_6{
  margin-top: -176px;
  margin-left: 68px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  text-align: center;
  position: absolute;
  width: 113px;
}
.newhovertxt_8{
    margin-top: -260px;
    margin-left: 95px;
    font-size: 13px;
    padding-left: 10px;
    text-align: justify;
    font-weight: 500;
    width: 169px;
    position: absolute;
}
.newhovertxt_1{
  margin-top: -181px;
  margin-left: 62px;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 500;
  text-align: center;
  position: fixed;
  width: 122px;
}
.newhovertxt_4{
    margin-top: -260px;
    margin-left: 102px;
    font-size: 13px;
    width: 164px;
    font-weight: 500;
    /* padding-left: 10px; */
    text-align: justify;
    position: absolute;
}
.newhovertxt_5{
  margin-top: 78px;
  margin-left: 62px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  text-align: center;
}
.newhovertxt_e_p{
    margin-top: -260px;
    margin-left: 85px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    text-align: justify;
    position: absolute;
    width: 183px;

}
.newhovertxt_e_p1{
    margin-top: -226px;
    margin-left: 81px;
    font-size: 13px;
    padding-left: 10px;
    text-align: justify;
    font-weight: 500;
    position: absolute;
    width: 170px;
    height: 100px;
    /*overflow: scroll;*/

}
#style-14::-webkit-scrollbar-thumb
{
  /*background-color: #7a7676;*/
  /*border-radius: 30px;*/

  /*background: red;*/
}
#style-3::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

#style-14::-webkit-scrollbar-track
{
  width: 6px;
  /*background-color: #F5F5F5;*/
 }

#style-3::-webkit-scrollbar-thumb
{
  background-color: #000000;
}
#style-2::-webkit-scrollbar-track
{
  -webkit-box-shadow: 0px 11px 15px 1px #ccc;;
  border-radius: 10px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar {
    width: 7px;
    background-color: #eee;
}
::-webkit-scrollbar-button {
    background: #e9e8e8;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    /*border-radius: 30px;*/
}
::-webkit-scrollbar-track-piece {
    background:#e9e8e8;
}
::-webkit-scrollbar-thumb {
    background: #009de2;
}



.scrollbar
{
 margin-left: 87px;
 float: left;
 height: 85px;
 width: 165px;
 padding-right: 11px;
 overflow-y: scroll;
 margin-bottom: 25px;
}
.scrollbar1 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 195px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 22px;
    padding-right: 12px;
}
.scrollbar2 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 195px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 15px;
}
.scrollbar3 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 186px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 19px;
    padding-right: 12px;
}
.scrollbar4{
    /*margin-left: 87px;*/
    float: left;
    height: 103px;
    width: 186px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 14px;
    padding-right: 12px;
}
.scrollbar5{
    /*margin-left: 87px;*/
    float: left;
    height: 103px;
    width: 180px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 18px;
}



.force-overflow
{
  min-height: 102px;
  /*background-color: red;*/
}
.new_HoverDivforboat img{
  width: 365px;
}
.newhovertxt_ptrchemical{
    margin-top: -184px;
    margin-left: 72px;
    font-size: 12px;
    padding-left: 10px;
    text-align: center;
    position: absolute;
    width: 111px;
}
.servicekbtnarrow1{
   margin-left: 177px;
    color: #000;
    margin-top: -142px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow2{
    margin-left: 124px;
    color: #000;
    margin-top: -106px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow3 {
    margin-left: 178px;
    color: #000;
    margin-top: -139px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow4 {
    margin-left: 173px;
    color: #000;
    margin-top: -139px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow4_1 {
    margin-left: 154px;
    color: #000;
    margin-top: -121px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow5 {
    left: 124px;
    color: #000;
    top:164px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow7{
    margin-left: 172px;
    color: #000;
    margin-top: -146px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow8 {
    left: 177px;
    color: #000;
    top: 257px;
    font-size: 13px;
    position: absolute;
}
@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(0,0,0,0.2);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include rings(3s, 0s);
  }
  
  &::before {
    @include rings(3s, 0.5s);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
}
.ripplebox:hover{
  cursor: pointer;
}
.ripplebox1:hover{
  cursor: pointer;
}
.ripplebox2:hover{
  cursor: pointer;
}
.ripplebox3:hover{
  cursor: pointer;
}
.ripplebox4:hover{
  cursor: pointer;
}
.ripplebox5:hover{
  cursor: pointer;
}

.ripplebox{
  width:40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 51px;
  top: -24px;
  /*z-index: 10000000;*/

}
.ripplebox1 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 115px;
    top: 24px;
  }
  .ripplebox2 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 95px;
    top: -18px;
  }
   .ripplebox3 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 109px;
    top: -9px;
  }
   .ripplebox4 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 42px;
    top: -20px;
  }
   .ripplebox5 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 71px;
    top: -10px;
  }
.ripplebox span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}
.ripplebox1 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}
.ripplebox2 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}
.ripplebox3 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}
.ripplebox4 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}
.ripplebox5 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  animation: animate 6s linear infinite;
  animation-delay: calc(-1.5s * var(--i));
}

@keyframes animate{
  0%{
    width: 0px;
    height: 0px;
  }
  50%{
    opacity: 1;
  }
  100%{
    width:40px;
    height: 40px;
  }
}
.hover_close
{
   position: absolute;
    top: 15px;
    left: 222px;
    color: #fff;
    font-size: 33px;
    z-index: 110;
  }
  .hover_close:hover{
    cursor: pointer;
  }
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 20s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}

svg {
  width: 80vw;
}
.squiggle {
  stroke-dasharray: 10;
  stroke-dashoffset: 200;
  animation: draw 8s linear forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.svgBox{
    margin-top: 172px;
    margin-left: 100px;

}
#blackbannerDiv{

  background:#0f0f0f9e;
  position: absolute;
  /*animation: dripple1 1s ease-out 1s;*/
  animation: fadeIn ease 3s;
  height: 53em;
  z-index: 50;
}
#hidepoweredbyblack{
     height: 50px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 1348px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack2{
     height: 100px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 48px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack3{
     height: 100px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 210px;
  left: 70px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack1{
  height: 95px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 1348px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
 
}
/*.waterimg:hover .hidepoweredby{
  background-color:#0f0f0fbf ! important;
}
*/

.circle_wrapper {
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  width: 500px; height: 250px;
  overflow: hidden;
  border: 3px dashed gold;
  border-bottom: none;
}
.circle_wrapper:after {
  content: '';
  display: block;
  width: 100px; height: 100%;
  background: gold;
  -webkit-animation: fill 7s linear infinite;
  animation: fill 7s linear infinite;
}
@-webkit-keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}

.mini_circle {
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  width: 500px; height: 250px;
  overflow: hidden;
  /*border: 3px dashed gold;*/
  border-bottom: none;
}
.mini_circle:after {
  content: '';
  display: block;
  width: 100px; height: 100%;
  background: #fff;
  -webkit-animation: fill 7s linear infinite;
  animation: fill 7s linear infinite;
}
@-webkit-keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
 .page-wrap {
   position: absolute;
   top: 187px;
   left: 123px;
}
 .gauge-wrapper {
   position: relative;
   -webkit-transform: translate3d(0, 0, 0);
   transform: translateZ(0);
   -webkit-backface-visibility: hidden;
   -webkit-perspective: 1000;
   backface-visibility: hidden;
   perspective: 1000;
}
 .gauge2, .gauge1 {
   position: absolute;
   top: 33px;
   left: 25px;
   width: 400px;
   height: 400px;
}
 .box-wrap {
   height: 113px;
   width: 226px;
   overflow: hidden;
   position: relative;
}
 .box {
   transition: 1s all;
   top: 0px;
   left: 0px;
   position: absolute;
   width: 200px;
   height: 200px;
   border: 13px solid #2f87c3;
   border-radius: 50%;
   clip: rect(0, 300px, 113px, 0px);
   -webkit-transform: rotate(-180deg);
}
 .gauge2 {
   top: 23px;
   left: 23px;
}
 .gauge2 .box {
   border: 13px solid #2ecc71;
   width: 154px;
   height: 154px;
   clip: rect(0, 231px, 90px, 0px);
}
 .gauge2 .box-wrap {
   height: 90px;
   width: 200px;
}

.chart-skills {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills *,
.chart-skills::before {
  box-sizing: border-box;
}


/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills {
  position: relative;
  width: 311px;
  height: 202px;
  overflow: hidden;
  left: 44px;
  top: 38px;
}

.chart-skills::before,
.chart-skills::after {
  position: absolute;
}

.chart-skills::before {
  content: '';
  width: inherit;
  height: inherit;
  /*border: 45px solid rgba(211, 211, 211, .3);*/
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.chart-skills::after {
  content: '';
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
}

.chart-skills li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 4px dashed;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 6s;
  animation-timing-function: linear;
}


.chart-skills li:nth-child(4) {
  z-index: 1;
  border-color: orange;
  animation-name: rotate-four;
  -webkit-animation: rotation 8s infinite linear;

}

.chart-skills span {
  position: absolute;
  font-size: .85rem;
  backface-visibility: hidden;
  animation: fade-in .4s linear forwards ;
}

.chart-skills li:nth-child(1) span {
  top: 5px;
  left: 10px;
  transform: rotate(-21.6deg);
}

.chart-skills li:nth-child(2) span {
  top: 20px;
  left: 10px;
  transform: rotate(-79.2deg);
  animation-delay: .4s;
}

.chart-skills li:nth-child(3) span {
  top: 18px;
  left: 10px;
  transform: rotate(-140.4deg);
  animation-delay: .8s;
}

.chart-skills li:nth-child(4) span {
  top: 10px;
  left: 10px;
  /*animation:  truckanim 10s linear  infinite;*/
  transform: rotate(360deg);
  -webkit-animation: rotation1 2s infinite linear;
}

/*2nd acr css*/
 .chart-skills1 {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills1 *,
.chart-skills1::before {
  box-sizing: border-box;
}


/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills1 {
    position: relative;
    width: 311px;
    height: 198px;
    overflow: hidden;
    left: 17px;
    top: 237px;
}

.chart-skills1::before,
.chart-skills1::after {
  position: absolute;
}

.chart-skills1::before {
  content: '';
  width: inherit;
  height: inherit;
  /*border: 45px solid rgba(211, 211, 211, .3);*/
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.chart-skills1::after {
  content: '';
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
}

.chart-skills1 li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 4px dashed;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: 6s;
  animation-timing-function: linear;
}


.chart-skills1 li:nth-child(4) {
  z-index: 1;
  border-color: orange;
  animation-name: rotate-four;
  -webkit-animation: rotation 8s infinite linear;
  animation-delay: 8s;

}

.chart-skills1 span {
  position: absolute;
  font-size: .85rem;
  backface-visibility: hidden;
  animation: fade-in .4s linear forwards ;
}

.chart-skills1 li:nth-child(1) span {
  top: 5px;
  left: 10px;
  transform: rotate(-21.6deg);
}

.chart-skills1 li:nth-child(2) span {
  top: 20px;
  left: 10px;
  transform: rotate(-79.2deg);
  animation-delay: .4s;
}

.chart-skills1 li:nth-child(3) span {
  top: 18px;
  left: 10px;
  transform: rotate(-140.4deg);
  animation-delay: .8s;
}

.chart-skills1 li:nth-child(4) span {
  top: 10px;
  left: 10px;
  animation-delay:10s;
  /*animation:  truckanim 10s linear  infinite;*/
  transform: rotate(360deg);
  -webkit-animation: rotation1 2s infinite linear;
}


@-webkit-keyframes rotation1 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}


/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */



@keyframes rotate-four {
  from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(180deg);
    }
}
@-webkit-keyframes blackbannerDiv {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes blackbannerDiv {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
div.drop-container {
  position: absolute;
  top: 718px;
  right: 0;
  bottom: 0;
  left: 14px;
  margin: auto;
  height: 200px;
  width: 200px;
}


div.drop {
  position: absolute;
  top: -25%;
  width: 20%;
  height: 20%;
  border-radius: 100% 5% 100% 100%;
  transform: rotate(-45deg);
  margin: 0px;
  background: transparent;
  animation: drip 4s linear;
}

h1 {
  color: white;
  position: absolute;
  font-size: 2.5em;
  height: 1em;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 2;
  margin: auto;
  text-align: center;
  opacity: 0;
  animation: appear 2s 2.5s forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    z-index: 10;
  }
}

div.drop-container:before,
div.drop-container:after {
  content: '';
  position: absolute;
  z-index: 221;
  top: 40%;
  right: 103%;
  transform: translate(50%) rotateX(100deg);
  border-radius: 100%;
  opacity: 0;
  width: 130%;
  height: 43%;
  border: 5px solid skyblue;
  animation: dripple 5s linear infinite;
}

div.drop-container:after {
  animation: dripple 2s ease-out 1.7s;
}

@keyframes drip {
  45% {
    top: 0;
    border-radius: 100% 5% 100% 100%;
    transform: rotate(-45deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
    border-radius: 100%;
  }
}

@keyframes dripple {
  0% {
    width: 150px;
    height: 150px;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 500px;
    height: 500px;
    top: -20%;
    /*opacity: 0;*/
  }
}
@keyframes dripple1 {
  0% {
    width: 5%;
    height: 5%;
  }
  
  100% {
    width: 100%;
    height: 100%;
    /*top: -20%;*/
    /*opacity: 0;*/
  }
}

/*slide objects*/
@-webkit-keyframes slide-right {

  0% {

    position: absolute;
    bottom: 0px;
    right: 493px;
    top: 232px;
    -webkit-transform: translateX(150px);
            transform: translateX(150px);
  }
  100% {
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX();
            transform: translateX();
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX();
            transform: translateX();
  }
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY();
            transform: translateY();
  }
}
@-webkit-keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
            transform: translateY(-100px) translateX(-100px);
  }
}
@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(50px);
            transform: translateY(0) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX();
            transform: translateY(-100px) translateX();
  }
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY();
            transform: translateY();
  }
}
@-webkit-keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
            transform: translateY(-100px) translateX(100px);
  }
}
@keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(-100px);
            transform: translateY(0) translateX(-100px);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX();
            transform: translateY(-100px) translateX();
  }
}
@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(100px);
            transform: translateY(100px) translateX(100px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(-40px);
            transform: translateY(0) translateX(-40px);
  }
  100% {
    -webkit-transform: translateY(100px) translateX();
            transform: translateY(100px) translateX();
  }
}



.slide-right {

  -webkit-animation: slide-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
   animation: slide-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  -webkit-animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
  -webkit-animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-tl {
  -webkit-animation: slide-tl 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-tl 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-top {
  -webkit-animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-tr {
  -webkit-animation: slide-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-br {
  -webkit-animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.translatediv{
  z-index: 2000;
  position: absolute;
  margin-top: 9px;
  /*position: absolute;*/
  right: 30px;
}
.translatediv span{
  display: none;
}
.translatediv {
  color: transparent;
}
.navbar-form .moduletable:first-child {
    float: right;
}
.flagdiv{
  padding:2px 2px 2px 2px;
  width: 20px;
  height: 20px;
}
/*.goog-te-banner-frame{
  position: absolute ! important;
  z-index: 0 ! important;

}*/
/*.noselection{
  margin-top: -40px ! important;
}
.goog-te-banner-content{
  display: none;
}
.goog-te-banner-frame{
  width: 0px ! important;
  height:0px ! important;
}*/
.goog-te-banner-frame{
  position: absolute;
  width: 0px ! important;
  height:0px ! important;
}
.goog-te-combo{
  border: none;
  top: -20px;
  right: 0px;
  position: absolute;
  background-color: #eee;
}
.goog-te-gadget .goog-te-combo{
  margin: 0px;
}



@media only screen and (device-width: 1024px)  {

  .oilimage{
        right: 170px ! important;
        top: 124px ! important;
    }
  .plantimage11{
    right: -7px ! important;
    top: 263px ! important;
  }  
  .nexttogasimage{
    right: 358px ! important;
    top: 284px ! important;
  } 
  .boat_img2{
    left: 187px ! important;
    top: 388px ! important;
  } 
  .waterimg2{
    top: 76% ! important;
    left: 9% ! important;
  } 
  .petrolimage1{
    right: 167px ! important;
    top: 421px ! important;
  }
  .topnav a{
    padding-left:  5px ! important;
  } 
  
  .dropdown .dropbtn{
    padding-left: 5px ! important;
  }

}

@media screen 
  and (device-width: 411px) 
  and (device-height: 823px) 
  and (-webkit-device-pixel-ratio: 3.5) 
  and (orientation: portrait) {
    .resbanner{
      height: 820px ! important;
    }

}
@media screen 
  and (device-width: 411px) 
  and (device-height: 731px) 
  and (-webkit-device-pixel-ratio: 3.5) 
  and (orientation: portrait) {
    .resbanner{
      height: 730px ! important;
    }

}

@media only screen and (device-width : 1024px) and (device-height : 1366px) { 

   #consultdown_data3{
    top:62em ! important;
    width: 450px;
   }
   #consultdown_img0{
    /*left:6em ! important;*/
   }
   #consultdown_data0{
    right: 0% ! important;
    width: 450px;
   }
   #consultdown_data2{
    right: 0% ! important;
   }
    #consultdown_img2{
    /*left:6em ! important;*/
   }
    #consultdown_img4{
    /*left:6em ! important;*/
   }
   #consultdown_data1{
        left: -3%;
       width: 450px;
   }
   #consultdown_img3{
    top:62em ! important;
    left:33em;
   }
   #consultdown_img1{
    left:33em;
   }
   #consultdown_data4{
    right: 0%;
    width: 450px;
   }
   #consultdown_data5{
    width: 450px;
   }
   #consultdown_img5{
    left:40em;
   }
   .oilimage{
    right: 160px;
    top: 108px;
   }
   .oilimage_img{
    width: 220px;
   }
   .plantimage11_img {
    width: 220px;
    } 
    .plantimage11{
      right: 19px;
      top: 247px;
    }
    .nexttogasimg_size {
    width: 220px;
    }
    .nexttogasimage{
      right: 335px;
       top: 216px;
    }
    .petrolimage1_img {
    width: 221px;
    }
    .petrolimage1{
      right: 192px;
     top: 350px;
    }
    .waterimg2{
          top: 78%;
         left: 8%;
    }
    .boat_img2_img {
    width: 220px;
    }
    .boat_img2{
      left: 194px;
       top: 404px;
    }
    .new_HoverDiv4{
      margin-left: -85px;
    }
} 
@media only screen and (device-width : 1024px){ 

   #consultdown_data3{
    top:62em ! important;
    width: 450px;
   }
   #consultdown_img0{
    /*left:6em ! important;*/
   }
   #consultdown_data0{
    right: 0% ! important;
    width: 450px;
   }
   #consultdown_data2{
    right: 0% ! important;
   }
    #consultdown_img2{
    /*left:6em ! important;*/
   }
    #consultdown_img4{
    /*left:6em ! important;*/
   }
   #consultdown_data1{
        left: -3%;
       width: 450px;
   }
   #consultdown_img3{
    top:62em ! important;
    left:33em;
   }
   #consultdown_img1{
    left:33em;
   }
   #consultdown_data4{
    right: 0%;
    width: 450px;
   }
   #consultdown_data5{
    width: 450px;
   }
   #consultdown_img5{
    left:40em;
   }
   .oilimage{
    right: 160px;
    top: 108px;
   }
   .oilimage_img{
    width: 220px;
   }
   .plantimage11_img {
    width: 220px;
    } 
    .plantimage11{
      right: 19px;
      top: 247px;
    }
    .nexttogasimg_size {
    width: 220px;
    }
    .nexttogasimage{
      right: 335px;
       top: 216px;
    }
    .petrolimage1_img {
    width: 221px;
    }
    .petrolimage1{
      right: 192px;
     top: 350px;
    }
    .waterimg2{
          top: 78%;
         left: 8%;
    }
    .boat_img2_img {
    width: 220px;
    }
    .boat_img2{
      left: 194px;
       top: 404px;
    }
    .new_HoverDiv4{
      margin-left: -85px;
    }
} 

@media only screen and (device-width : 1170px) and (device-height : 2532px) { 
  .waterimg2{
        left: 9%;
  }
  .boat_img2{
    left: 197px;
    top: 390px;
  }
}

@media only screen and (device-width : 1440px) and (device-height : 900px) { 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  

}
@media only screen and (device-width : 1440px){ 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  
  .new_HoverDiv1 {
    margin-left: -232px ! important;
    margin-top: -131px ! important;
  }

}
@media only screen and (device-width : 1600px) { 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  

}
@media only screen and (device-width : 1680px) and (device-height : 1050px) { 
  .waterimg2 {
    top: 69% ! important;
    left: 11% ! important;
 }
 .waterimg2Div_img{
  width: 200px ! important
 }
 .Type2Img img{
  width: 85% ! important;

 }
}
@media only screen and (device-width : 1680px){ 
  .waterimg2 {
    top: 69% ! important;
    left: 11% ! important;
 }
 .waterimg2Div_img{
  width: 200px ! important
 }
 .Type2Img img{
  width: 85% ! important;

 }
}
@media only screen and (device-width : 2560px) and (device-height : 1600px) {
 .boat_img{
  left:167px ! important;
 } 
 .boat_img2 {
    left: 691px ! important;
    top: 361px ! important;
  }
  .nexttogasimage {
    right: 771px ! important;
    top: 398px ! important;
  }
  .oilimage{
    right: 429px ! important;
    top: 218px ! important;
  }
  .nexttogasimage {
    right: 771px ! important;
    top: 366px ! important;
  }
  .petrolimage1 {
    top: 525px ! important;
    right: 414px;
  }
  .plantimage11 {
    right: 118px ! important;
    top: 415px ! important;
  }
}
@media only screen and (device-width : 2560px) and (device-height : 1600px) {
  .nexttogasimg_size {
    width: 370px ! important;
  }
    .oilimage_img {
      width: 370px ! important;
  }
  .plantimage11_img{
     width: 370px ! important;
  }
  .petrolimage1_img{
    width: 370px ! important;
  }
  .boat_img2_img{
    width: 370px ! important;
  }
  .waterimg2Div_img{
    width: 300px ! important;
  }
  .boat_img_size{
    width: 370px ! important;
  }
  .waterimg_size{
    width: 230px ! important;
  }
}
@media only screen and (device-width : 2560px){
  .nexttogasimg_size {
    width: 370px ! important;
  }
    .oilimage_img {
      width: 370px ! important;
  }
  .plantimage11_img{
     width: 370px ! important;
  }
  .petrolimage1_img{
    width: 370px ! important;
  }
  .boat_img2_img{
    width: 370px ! important;
  }
  .waterimg2Div_img{
    width: 300px ! important;
  }
  .boat_img_size{
    width: 370px ! important;
  }
  .waterimg_size{
    width: 230px ! important;
  }
}
@media only screen and (device-width : 1280px) and (device-height : 800px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 264px;
    top: 366px;
  }

}
@media only screen and (device-width : 1280px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 249px ! important;
    top: 388px ! important;
  }
  .nexttogasimage {
    right: 476px ! important;
    top: 295px ! important;
  }
  .petrolimage1 {
    right: 250px ! important;
    top: 405px ! important;
  }
  .oilimage {
    right: 232px ! important;
    top: 141px ! important;
  }
  .plantimage11 {
    right: 31px ! important;
    top: 272px ! important;
  }
  .boat_img2_img{
    width: 255px;
  }
  .waterimg2Div_img{
    width: 175px ! important;
  }
  .waterimg_size{
    width: 150px ! important;
  }

}
@media only screen and (device-width : 1152px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 249px ! important;
    top: 388px ! important;
  }
  .nexttogasimage {
    right: 441px ! important;
    top: 277px ! important;
  }
  .petrolimage1 {
    right: 250px ! important;
    top: 398px ! important;
  }
  .oilimage {
    right: 232px ! important;
    top: 141px ! important;
  }
  .plantimage11 {
    right: 45px ! important;
    top: 288px ! important;
  }
  .boat_img2_img{
    width: 200px;
  }
  .waterimg2Div_img{
    width: 175px ! important;
  }
  .waterimg_size{
    width: 150px ! important;
  }
  .plantimage11_img{
    width: 225px ! important;

  }
  .oilimage_img {
    width: 225px;
  }
  .nexttogasimg_size{
    width: 225px ! important;
  }
  .petrolimage1_img{
    width: 225px ! important;
  }

}
@media screen and (device-width: 1920px){
  .nexttogasimage {
    right: 597px ! important;
    top: 338px ! important;
  }
  .petrolimage1 {
    right: 308px ! important;
    top: 456px ! important;
   }
   .plantimage11 {
    right: 66px ! important;
    top: 291px ! important;
  }
  .oilimage {
    right: 335px ! important;
    top: 159px ! important;
  }
  .boat_img2 {
    left: 467px ! important;
    top: 363px ! important;
  }
  .waterimg2 {
    top: 66% ! important;
    left: 10% ! important;
  }
}
@media screen and (device-width: 1856px){
  .nexttogasimage {
    right: 597px ! important;
    top: 338px ! important;
  }
  .petrolimage1 {
   right: 374px ! important;
    top: 475px ! important;
   }
   .plantimage11 {
    right: 125px ! important;
    top: 331px ! important;
  }
  .oilimage {
    right: 335px ! important;
    top: 159px ! important;
  }
  .boat_img2 {
    left: 467px ! important;
    top: 363px ! important;
  }
  .waterimg2 {
    top: 69% ! important;
    left: 15% ! important;
  }
  .waterimg2Div_img{
    width: 200px ! important;
  }
  .nexttogasimg_size {
    width: 285px;
  }
  .oilimage_img {
    width: 290px;
  }
  .plantimage11_img {
    width: 290px;
  }
  .petrolimage1_img {
    width: 290px;
  }
}
@media screen and (device-width: 1792px){
  .nexttogasimage {
    right: 619px ! important;
    top: 318px ! important;
  }
  .petrolimage1 {
   right: 374px ! important;
    top: 455px ! important;
   }
   .plantimage11 {
    right: 125px ! important;
    top: 294px ! important;
  }
  .oilimage {
    right: 351px ! important;
    top: 130px ! important;
  }
  .boat_img2 {
    left: 441px ! important;
    top: 320px ! important;
  }
  .waterimg2 {
    top: 69% ! important;
    left: 15% ! important;
  }
  .waterimg2Div_img{
    width: 200px ! important;
  }
  .nexttogasimg_size {
    width: 285px;
  }
  .oilimage_img {
    width: 290px;
  }
  .plantimage11_img {
    width: 290px;
  }
  .petrolimage1_img {
    width: 290px;
  }
  .boat_img2_img{
    width: 290px ! important;
  }
}
@media screen and (device-width: 2560px){
  .boat_img_size {
    width: 333px ! important;
    height: 187px;
  }
  .boat_img2 {
    left: 713px ! important;
  }
  .waterimg2 {
    top: 57% ! important;
    left: 16% ! important;
  }
  .nexttogasimage {
    right: 671px ! important;
  }
  .petrolimage1{
    right: 360px ! important;
  }
  .oilimage{
    right: 365px ! important;
  }
  .plantimage11 {
    right: 91px ! important;
  }
}

@media screen and (min-device-width: 2880px){
  .boat_img_size {
    width: 333px ! important;
    height: 187px;
  }
  .boat_img2 {
    left: 713px ! important;
  }
  .waterimg2 {
    top: 57% ! important;
    left: 16% ! important;
  }
  .nexttogasimage {
    right: 858px ! important;
  }
  .petrolimage1{
    right: 437px ! important;
  }
  .oilimage{
    right: 490px ! important;
  }
  .plantimage11 {
    right: 91px ! important;
  }
  .waterimg2Div_img {
    width: 270px ! important;
  }
  .boat_img2_img {
    width: 370px ! important;
  }
  .nexttogasimg_size {
    width: 428px ! important;
  }
  .petrolimage1_img {
    width: 421px ! important;
  }
  .oilimage_img {
    width: 420px ! important;
  }
  .plantimage11_img {
    width: 421px ! important;
  }
}

@media only screen and (device-width : 800px) {
  .oilimage_img {
    width: 200px;
  }
  .oilimage {
    right: 161px;
    top: 159px;

  }

  .plantimage11_img {
    width: 200px;
  }
  .plantimage11 {
    right: 38px;
    top: 304px;
  }
  .boat_img_size {
    left: 45px;
    top: 32px;
  }
  .waterimg {
    left: 31px;
    top: 205px;
  }
  .nexttogasimg_size {
    width: 200px;
  }
  .nexttogasimage {
    right: 295px;
    top: 294px;
  }
  .boat_img2_img {
    width: 200px;
  }
  .petrolimage1_img {
    width: 200px;
  }
  .boat_img2 {
    left: 117px;
    top: 381px;
  }
  .waterimg2 {
    top: 72%;
    left: 4%;
  }
  .petrolimage1 {
    right: 175px;
    top: 424px;
  }
  .new_HoverDiv4{
    margin-left: -93px ! important;
  }
}
@media only screen and (device-width : 851px) {
  .oilimage_img {
    width: 200px;
  }
  .oilimage {
    right: 161px;
    top: 159px;

  }

  .plantimage11_img {
    width: 200px;
  }
  .plantimage11 {
    right: 38px;
    top: 304px;
  }
  .boat_img_size {
    left: 45px;
    top: 32px;
  }
  .waterimg {
    left: 31px;
    top: 205px;
  }
  .nexttogasimg_size {
    width: 200px;
  }
  .nexttogasimage {
    right: 295px;
    top: 294px;
  }
  .boat_img2_img {
    width: 200px;
  }
  .petrolimage1_img {
    width: 200px;
  }
  .boat_img2 {
    left: 117px;
    top: 381px;
  }
  .waterimg2 {
    top: 72%;
    left: 4%;
  }
  .petrolimage1 {
    right: 175px;
    top: 424px;
  }
  .new_HoverDiv4{
    margin-left: -93px ! important;
  }
}


.plan_line_paraaaa{
    line-height: 30px;
    font-size: 19px;
}
.plan_line_subheadd{
    /*font-weight: 600;*/
    font-size: 33px;
    color: #3a3737;
    font-weight: bold;
  }

 .plan_line_subsubheadd{
 	font-weight: 700;
    font-size: 23px;
    color: #3a3737;
 }

.plans_imgg{
  /*width: 751px;*/
  /*margin-left: 200px;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) , 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
.plan_line_paraaaa3{
    line-height: 30px;
    font-size: 19px;
    margin-top: 41px;
}
.mt_plans{
	width: 98%;
}
.p1{
        margin-left: 28px;
}

.p2{
        margin-left: 28px;
}

.p3{
        margin-left: 28px;
}
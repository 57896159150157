.innerBlocktepmlate4{
	height: auto;
}
.B3T_NewsDiv {
    /*top: 130px;*/
    height: 190px;
    /*width: 303px;*/
    box-shadow: 3px 6px 4px 2px #eee;
    margin-right: 20px;
    margin-bottom: 40px;
}
.news_img{
	/*margin-left: 77px;*/
	height: 80px;
}
.B4T_txt{
	margin-top: 25px;
	margin-left: 20px;
}
.B4T_line{
    background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 54px;
    left: 15px;
  	position: absolute;
  	z-index: 999	
}
.B2T_challengesTitle{
	/*top: 53px;
    left: 563px;
    position: absolute;*/
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
}
.yearbox{
    box-shadow: -3px 1px 6px 5px #f3f3f3;
    width: 70px;
    height: 47px;
    margin-right: 15px;

    color:#009de2;
}
.maindivNews{
  margin-top: 25px;
  min-height: 400px;
}
.newsheading{
  font-weight: 700;
  color:#009de2;
}
.Newsbannerimg{
  height: 180px;
}

@media screen and (min-width: 1900px) {

  .eventdesc{
    padding: 0px ! important;
  }
}  
.blogPage-wrap{
	margin-top: 100px;
    margin-bottom: 100px;
    color: #fff;
    background-size: 100% 100%;
    height: auto;
    background-color: #fff;
}
.blankblock{
	height: 100px;
}
.latestblog1{
	margin-bottom: 0px ! important;
}

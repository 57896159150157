.upstreamcontentheader h1{
  font-size: 40px;
}

.upstreamcontentheader hr{
  height: 2px;
  width: 100px;
  border: 1px solid #f19000;
  background-color: #f19000;
}
.owl-carousel .owl-wrapper-outer{
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1; 
    filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}

.carousel, .carousel-inner {
   height: 100%;
   backface-visibility:visible;
   filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}
.carouselInner{
    height: 100% !important;
    position:relative;
    backface-visibility:visible;
    filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}
.owl-carousel owl-theme owl-loaded owl-drag{
   filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;

}
.owl-theme div:hover {

    /*filter: Alpha(Opacity=100);*/
    opacity: 1;
    text-decoration: none;
}
.summary_blogs_HP{
  height: 150px;
  margin-bottom: 20px;
}
.carousel-inner{
  backface-visibility:visible;
}


.blogsimg img{
  height: 200px;
  border-radius: 8px 8px 0px 0px;
}

.blogtext p{
  text-align: justify;
  padding: 0px 20px;
  font-size: 11px;
  height: 135px;
  color:#000;
  /*margin-top: 10px;*/

}
.newfooterjoin{

}
.blogdate{
  text-align: justify;
  padding: 10px 20px;
  font-size: 13px;
  margin-top: -16px;
  font-weight: 600;

}
.blogtext h4{
  /*text-align: justify;*/
  padding: 0px 20px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 7px;

}
.blogtext {
	height: 550px;
	border-radius: 8px;
	margin-top: 5px
}

.latestblog{
  margin-bottom: 50px;
}

.blogblock{
  height: 495px ! important;
	box-shadow: 0px -2px 12px 0px #d6d0d0;
	border-radius: 8px;
  padding:0px;

}
.blogtext p{
  overflow: hidden;
}

.blogbtn1{
  	margin-top: -15px;
    /*border-radius: 20px;*/
    color: #fff;
    font-weight: 600;
}
.blogbtn1:focus{
  outline: 0px auto -webkit-focus-ring-color!important;
}

.blogleftarrow{
    /*background-color: #009de2;*/
    box-shadow: 0px 0px 5px 4px #eee;
    color: #333;
    border-radius: 50%;
    padding: 9px 8px;
    font-size: 25px;
    left: -80px;
    height: 45px;
    width: 45px;
    top: 204px;
    position: absolute;

}
.blogrightarrow{
    /*background-color: #009de2;*/
    box-shadow: 0px 0px 5px 4px #eee;
    color: #333;
    border-radius: 50%;
    padding: 9px 8px;
    font-size: 25px;
    right: -80px;
    height: 45px;
    width: 45px;
    top: 204px;
    position: absolute;

}
.gt__2YKbG{
  margin-right: 59% ! important;
}
.sxSelectListsWrapper__2321j{
  width: 42% ! important;
}

@media screen and (max-width: 600px) {
  .blogblock{
    height: 600px ! important;
  }
  .price-footer{
    margin-top: 38px ! important;
  }
  .blogrightarrow{
    right: -42px ! important;
  }
  .blogleftarrow{
    left:-42px ! important;
  }
  .blogtext h4{
    font-size: 15px ! important;
    height: 74px ! important;
  }
  .blogtext p{
    margin-bottom: 60px ! important;
  }
  .blogdate{
    margin-bottom: 15px ! important;
    padding: 7px 11px;
  }
  .New_Study_shadow{
    width: 250px ! important;
  }
  .iconCircle{
    margin-left: 29% ! important;
  }
  .padding_Mob{
    padding-left: 11px ! important;
  }
  .blogbtn1{
      padding: 7px 0px;
      width: 80px;
      float: right;

    }
}
@media only screen and (device-width: 1024px) {
  .blogblock{
    height: 515px ! important;

    }
  }
@media only screen and (device-width: 1024px) {
    .blogbtn1{
      padding: 7px 0px;
      width: 205px;
    }
    .blogblock{
      height: 555px ! important;
    }
    .price-footertab{
      margin-top: 35px ! important;
      padding-left: 20px! important;
    }  
  }
@media only screen and (device-width: 728px)  {
    .blogbtn1{
      padding: 7px 0px;
      width: 220px;
      float: right;
    }
    .blogdate{
      padding-top: 113px ! important;
    }


   
  }
@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .blogbtn1{
      padding: 7px 0px;
      width: 180px;
      float: right;

    }
    .blogdate{
      padding-top: 0px ! important;
    }
   
  }
  @media only screen and (device-width : 412px) and (device-height : 712px) { 
    .All1blog1{
      width: 305px ! important;
    }

  }
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
    
  .blogblock {
        height: 661px ! important;
    }
  .blogdate{
      margin-top: 40px;
     
  }
  .blogtext h4{
    padding: 0px 3px;
  } 
  .blogtext{
    height: 726px;
  }

}
  @media only screen and (device-width : 320px) and (device-height : 533px) { 
    .blogtext {
        height: 693px ! important;
    }
    .blogblock {
        height: 689px ! important;
    }
    .price-footerBlog {
        margin-top: 100px ! important;
    }
    .blogtext h4{
          margin-bottom: 20px;
          line-height: 1;
    }
  }
  @media only screen and (device-width : 320px){ 
    .blogtext {
        height: 693px ! important;
    }
    .blogblock {
        height: 689px ! important;
    }
    .price-footerBlog {
        margin-top: 100px ! important;
    }
    .blogtext h4{
          margin-bottom: 20px;
          line-height: 1;
    }
   /*  .index0{
      width: 182px ! important;
    }
    .index2{
      width: 182px ! important;
    }
    .index1{
      width: 182px ! important;
    }
    .index3{
      width: 182px ! important;
    }
    .index4{
      width: 182px ! important;
    }*/
  }
  @media only screen and (device-width : 375px) {
  .blogdate{
    font-size: 12px ! important;
  }
}
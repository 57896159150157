.challenges_line_paraaaa{
    line-height: 30px;
    font-size: 19px;
    color: #7b6f6f;
}
.challenges_line_paraaaa1{
    line-height: 30px;
    font-size: 19px;
    color: #7b6f6f;
}
.challenges_line_subheadd{
    /*font-weight: 600;*/
    font-size: 25px;
    color: #3a3737;
    font-weight: bold;
  }

   .challenges_list{
  	font-size: 19px;
    color: #7b6f6f;
    background-repeat: no-repeat;
  }

  .challenges_line_paraaaa2{
    line-height: 30px;
    font-size: 19px;
    /*color: #7b6f6f;*/
    /*margin-left: 15px;*/
}
.Policy_Challenges{
  padding: 30px;
}
@media only screen and (device-width : 393px) and (device-height : 851px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 360px) and (device-height : 640px) {
   .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }
}
@media only screen and (device-width : 411px) and (device-height : 731px) {
   .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }
}
@media only screen and (device-width : 411px) and (device-height : 823px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 320px) and (device-height : 568px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 375px) and (device-height : 667px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 414px) and (device-height : 736px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 375px) and (device-height : 812px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 384px) and (device-height : 640px) {

 .Policy_Challenges {
    padding: 0px;
}
  .contactcontent{
    padding-left: 20px;
    padding-top: 20px;
  }
}




body{
    overflow-x: hidden;
    margin-top: 0px ! important;
    font-family: 'Raleway', sans-serif ! important;
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
    font-family: 'Raleway', sans-serif ! important;
    /*color:#000;*/
}
.input-group .form-control{
    z-index: 0 ! important;
  }
.dropZindex{
    z-index: 0 ! important;
}
.marginTopMobile{
  margin-bottom: 35px ! important;
}
.TypecomponentBtnImg{
  width: 465px ! important;
  height: 380px ! important;
  padding-top: 50px;
  /*float:right;*/
}
.TypecomponentBtnImg img{
  height: 400px;
  object-fit: contain;
}
.contentheaderNewPage h2{
  letter-spacing: 0.5px;
}
.sharefacebook{
    padding-top: 150px;
}
.bw{
    background-color: #abc;
}
.bw1{
    background-color: #bcd;
}
.alternateblock{
    background-color: #f1f1f1c7;
}
.blogBox p > img {
  /*width: 300px ! important;*/
  object-fit: contain;

}
.blogBoxSmall p > img{
  width: 300px ! important;
  object-fit: contain;
}

.ApplicationImg{
  padding-left: 20px  !important;
}
.ApplicationImg img{
  object-fit: contain;
}
.fs12{
    font-size: 12px;
}
.downstreamtxt p{
    color: #fff ! important;
}
.NOpadding{
    padding-left: 0px;
    padding-right: 0px;
}
.minHeight{
    min-height: 600px;
}

.contactBg{
    
    /*background: linear-gradient(-45deg, #7474BF, #348AC7);*/
} 
.upstreamBg{
    
    /*background-color: #367588;*/
    height: 570px;
}   
.color-light {
    /*color: #555 !important;*/
    font-size: 20px;
}
.color-white{
  /*color: #fff;*/
}
.font-size-normal {
    font-weight: 300 !important;
}
.contact.contact-us-one {
    /*background-color: rgba(255,255,255,.9);*/
    /*border-bottom: 10px solid #ddd;*/
}

.contact.contact-us-one, .contact.contact-us-two {
    display: block;
    float: left;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    /*background-color: #ffc60b;*/
}

.contactext{
    /*color: #333;*/
}
.input-md {
    height: 40px;
    padding: 4px 10px;
    font-size: 11px;
    /*font-family: 'Montserrat', sans-serif;*/
    text-transform: uppercase;
}
.input-rounded {
    border-radius: 4px;
}
.contactbutton-grad-stellar {
    /*background: linear-gradient(-45deg, #7474BF, #348AC7) !important;*/
    /*background-color: #367588;*/
}
.contactbutton-block {
    display: block;
    width: 100% !important;
    margin-top: 5px !important;
    border:none !important;
    border-radius: 7px !important;
    /*color: #ffffff !important;*/
   


}
.contactbutton-md {
     /*font-family: 'Montserrat', sans-serif !important;*/
    text-transform: uppercase !important;
    font-size: 13px !important;
    padding: 12px 25px !important;
}

.unih1{
    font-size: 30px;
}

.energypagedata h2{
    color:#333 ! important;
}













.pt75 {
    padding-top: 75px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 150px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}




.pricesmall{
     font-size: 50%

}


.price.price-three {
    /*transform: perspective(100px) rotateY(-5deg);
    -webkit-transform: perspective(100px) rotateY(-5deg);*/
    padding: 10px 0px;
    text-align: center;
    background-color: #fff;
    /*padding: 30px 0;*/
    height: 407px;
}
.price {
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    border-radius: 4px;
}
 .price1 {
    font-size: 80px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
    font-weight: ;
    line-height: 70px;
}


.footerlogo img{
    height: 80px;
    margin-top: 30px;
}

.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.bb-solid-1 {
    /*border-bottom: 1px solid #e9eaec;*/
}

.bg-gray {
    /*background-color: #f5f7f9 !important;*/
}
.pt100{
    padding-top: 100px;
}
.pb100{
    padding-bottom: 100px;
}
.pt20{
    padding-top: 20px;
}
.pt25{
    padding-top: 25px;
}
.pt30{
    padding-top: 30px;
}

.pt50{
    padding-top: 50px;
}
.pt75{
    padding-top: 75px;

}
.pb10{
    padding-bottom: 10px;
}
.pb20{
    padding-bottom: 20px;
}
.pb35{
    padding-bottom: 35px; 
}
.pb50{
    padding-bottom: 50px;
}
.pb35{
    padding-bottom: 35px;

}
.pb25{
    padding-bottom: 25px;
}
.mr20{
    margin-right: 20px;
}
.mr10{
    margin-right: 10px;
}
.mr50{
    margin-right: 50px;
}
.mb50{
    margin-bottom: 50px;
}
.mb20{
    margin-bottom: 20px;
}

.mt10{
    margin-top: 10px;
}

.color-blue{
    /*color: #3dace1;*/
}
.color-red{
/*color: #fe4530;*/
}
.color-primary{
    /*color: #337ab7;*/
}
.color-dark{
    /*color: #b0b2b4;*/
}
.color-green{
   /*color:  #b2cc71;*/
}
.color-purple{
/*color:#a85ad4;*/
}

.blog-one .blog-one-attrib .blog-author-name {
    font-size: 12px;
    font-weight: 600;
    margin-right: 20px;
    display: block;
    float: left;
    margin-top: 5px;
        /*color: #ff4530!important;*/
}

.blog-one .blog-one-attrib .blog-date {
    /*color: #bbb;*/
    font-size: 11px;
    font-weight: 600;
    float: right;
    display: block;
    margin-top: 5px;
}

.blog-one .blog-one-body .blog-title {
    font-size: 15px;
    /*color: #aaa !important;*/
}
.B2T_p ul {
    padding-left: 15px ! important;
}
.TextRightImg{
    width: 500px;
    height: 400px;
}
.TextRightImg img{
    width: 500px;
    /*height: 220px;*/
    object-fit: contain;
}
.blog-title {
    
    font-size: 18px;
    line-height: 1.45;
    /*colo'r: #aaa !important;*/
}


.blog-one .blog-one-body .blog-title a {
    color: #555!important;
}

.blog-one .blog-one-body p {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.blog-one .blog-one-body {
    padding: 30px 25px 0 25px;
    background: #fff;
    /*background-color:#fff;*/
    overflow: hidden;
    margin-top: -25px;
    float: left;
    width: 100%;
    padding-bottom: 0;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -webkit-border-bottom-right-radius: 2px;
    -moz-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.imh2{
     color: #ff4530;
}


.imgIcon{
  height:40px;
}


.adjust{
  margin-top: 50px;
}

.bg-gray-rs{
  /*background-color: #f5f7f9;*/
  height:400px;
}
.el-icon2x img{
  height: 40px;
}

.contactp{
    /*font-family: 'Open Sans', sans-serif ;*/
    margin: 0 0 25px 0;
    font-size: 13px;
    line-height: 25px;
    color: #747474;
}

.pricepb30 {
    padding-bottom: 30px;
}
.pricepb50 {
    padding-bottom: 50px;
}
.pricept75 {
    padding-top: 75px;
}
.pricemb50{
    margin-bottom: 25px;
}
.pricemt50
{
    margin-top: 50px;
}
.priceh1{
    line-height: 45px !important;
    padding-top: 13px;
}
.priceh4{
    line-height: 30px !important;
}

.pricecolor-light {
    color: #ffffff !important;
    
}
.pricefont-size-normal {
    font-weight: 300 !important;   
}


.pricesmall {
    font-size: 50%;
    opacity: .7;
    /*font-family: 'Open Sans', sans-serif;*/
}
.priceicon{
    font-size: 15px;
}

.pricehover-float {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    transition-duration: 0.3s;
    transition-property: transform;
    transition-timing-function: ease-out;
}
.pricehover-float:hover, .pricehover-float:focus, .pricehover-float:active {
        transform: translateY(-8px);
}

.price.price-three {
    text-align: center;
    /*background-color: #fff;*/
    padding: 0px 0px 30px 0px;
}
.price {
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    margin-left: 2px;
    transition: all .3s ease-in-out;
    border-radius: 4px;
}
.modal-backdrop{
    z-index: 0 ! important;
}


.price-three span {
    font-size: 80px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
    line-height: 70px;
}
.pricecolor-green {
    /*color: #b2cc71 !important;*/
}


.price-three sup {
    font-size: 30px;

}
.price-three sup {
    top: -.5em;
}
.price-three sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.price-three .price-body {
    padding: 5px 0;
}

.price-body ul {
    font-size: 13px;
    /*color: #333;*/
    margin: 0;
    padding: 0;
    list-style: none;
}

.price-body ul li {
    
    line-height: 25px;
}
.price-footer_upstream{
    background-color: #eee;
    /*margin-top: 10px;*/
    /*position: absolute;*/
    /*cursor: pointer !important;*/

}
.pricebtn1,.pricebtn2,.pricebtn3,.pricebtn4{
    /*background-color: #367588;*/
    border-radius: 4px;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 700;
    padding: 8px 20px;
    /*color: #fff !important;*/
    margin-bottom: 5px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    /*font-family: 'Montserrat', sans-serif;*/
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    z-index: 1000;
    color: #fff;

}
.pricebtn2{
    /*background-color*/: #367588;
        margin-bottom: -4px;

}
.pricebtn3{
    /*background-color: #367588;*/

}
.pricebtn4{
    /*background-color: #367588;*/

}
 .price-badge1,.price-badge2 {
    /*margin: -30px auto 15px auto;*/
    width: 50%;
    font-size: 11px;
    text-transform: uppercase;
    /*color: #fff;*/
    padding: 5px 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.price-badge2{
    margin: -30px auto 5px auto;

}
.bg-grad-blood-mary {
    /*background: linear-gradient(-45deg, #FF512F, #DD2476);*/
    /*background-color: #ffc60b;*/
    height: 40px;
    /*color: #333;*/
}

.pricecolor-success {
    /*color: #5cb85c !important;*/
}

.pricecolor-cyan {
    /*color: #1abc9c !important;*/
}
.pricecolor-blue {
    /*color: #3dace1 !important;*/
}

.iconsize{
    font-size: 22px!important;
}


.bgiogblue{
    background-color: #005b99;
}

.textcolorblue{
    color:#005b99;
}
.bgiogyellow{
    background-color: #f08f00;
}

.textcoloryellow{
    color:#f08f00;
}

.lightgray{
    background-color: #eeee;
}
.darkgray{
    background-color: #333333;
}

.buttonhover:hover{
    color: #fff!important;
    background-color: #f19000;
    transition-duration:0.7s;
    cursor: pointer;

}

.lightbluebg{
    background-color: #009de2;
}
.lightbluetext{
    color: #009de2;
}
.darkbluebg{
    background-color: #004266;
}
.darkbluetext{
    color: #004266;
}

.textcolorwhite{
    color: #fff;
}

.margin-top{
  margin-top: 150px;
}
.margin-bottom{
  margin-bottom:100px;
}

.errorMsg{
    color: #f00;
    font-family: 'Raleway', sans-serif;
    font-size:12px;
}
.nameSpaceUpper{
    text-transform: capitalize;
}

.mt50{
    margin-top: 50px;
}
.modalZindex{
    z-index: 10000;
}
.fs15{
    font-size: 14px;
    letter-spacing: 1px ! important; 
}
.fs15 p{
    font-size: 14px;
    letter-spacing: 1px ! important; 
}
.mar10{
    margin: 10px;
}
.sxSelectHeader__Xlz5r{
    height: 40px;
}
.ServiceTxt{
    font-size: 16px ! important;
    text-align: justify;
}

.fa{
    font-family: FontAwesome !important;
   }

.NOPadding{
    padding: 0;
} 
.error{
    font-size: 10px;
    color: red;
}
.astrick{
    /*font-size: 10px;*/
    color: red;
}
.blogtext p{
    font-size: 14px ! important;
}
.carousel-control.left , .carousel-control.right
{
  background-image: none !important;
}

.gt__2YKbG{
  margin-right: 59% ! important;
}
.sxSelectListsWrapper__2321j{
  width: 42% ! important;
}
@media screen and (min-width: 320px) {
.Typecomponent1Img_vision {
        width: 350px;
        height: 218px;
    }
}

@media screen and (min-width: 411px) {
.pt20{
        padding-top: 29px ! important;
    }

}
@media screen and (device-width:1024px){
.new_round-tabfor4{
        margin-left:219% ! important;
     }
.new_wizardNotefor4
       {
         right: -88px ! important;
       }
.new_wizardNotefor4{
      left:196px ! important;
     }
.blogdate{
      padding-top: 0px ! important;
     }
.setlogo{
        padding-left: 22px ! important;
     }

}
@media screen and (max-width:768px)  and (max-height: 1024px) {
.new_round-tabfor6{
      margin-left: 59% ! important;
     }
}
@media screen and (max-width:768px){
.ContactmodalContent_brochure{
      height: 460px ! important;
     }
.serflip-card-back p{
      font-size: 13.5px ! important;
     }
.dwstmTitle{
      font-size: 17px ! important;
     }

.new_round-tabfor6{
      margin-left: 59% ! important;
     }
.newcard_landscape{
      width: 270px ! important;
     }
.landscape_btn{
      float: right ! important;
     }
.ContactmodalContent{
      height: 500px ! important;
     }
.ContactmodalContent_brochure{
      margin-top: 46% ! important;
     }.new_round-tabfor4{
        margin-left:127% ! important;
     }
.new_wizardNotefor5{
      right: -12px ! important;
     }
.new_wizardNotefor4{
      left:113px ! important;
     }
.BT4_txt{
        font-size: 13px ! important;
        margin-left: 0px ! important;
     }
.iconCircle{
        margin-left: 30% ! important;
     }
.type1btnDiv{
        height: 630px ! important;
     }
.BtnImageDown{
        padding-left: 0px ! important;
     }
.serflip-card{
        width: 260px ! important;
     }
.righttextaboutus ul{
        padding-left: 12px ! important;
        font-size:15px ! important;
     }
.About5block{
        padding-top: 0px ! important;
     }
.About5block{

     }
.new_round-tab{
      margin-left: 55% ! important;
     }
.NwesBoxShadow{
        height: 380px ! important;
     }
.jobformcontent{
        margin-left: 200px ! important;
     }
.challengedivPadd{
        padding-left: 0px ! important;
     }
.TypecomponentBtnImg img{
        /*margin-top: 165px ! important;*/
     }.new_round-tabforTwo{
        margin-left: 98% ! important;
      }
.new_wizardNotefortwo{
        right: -130px ! important;
      }
.subdata{
        padding-left: 0px ! important;
      }
.Aboutuslink{
        margin-left: 0px ! important;
      }
.navigationTitle_for6{
        left:275px ! important;
      }
.new_round-tabfor3{
        margin-left: 99% ! important;
      }
.new_wizardNotefor3{
        right: -99px ! important;
      }
.new_round-tabfor9 {
       margin-left: 5% ! important;
     }
.new_round-tabfor5{
      margin-left: 56% ! important;
     }
.new_wizardNote_for6{
      right: -18px ! important;
     }
.new_wizardNotefor9{
        font-size: 10px ! important;
      }
     
  }
 @media only screen and (device-width : 320px) and (device-height : 568px) { 
.videocard1 {
    width: 265px ! important;
  }
.new_BT3_innerBlock{
    width: 310 ! important;
  }
.maindivWidth_mob {
    width: 300px ! important;
  }
 } 
  @media only screen and (device-width : 411px) and (device-height : 823px) { 
.new_BT3_innerBlock {
    width: 413px ! important;
  }
 } 
 
@media screen and (max-width:480px){
.new_round-tabfor4{
        margin-left:27% ! important;
     }
.uptitle h3{
      font-size: 22px ! important;
     }
.serflip-card{
        width: 220px ! important;
     }
.mobile_width{
        
     }
.contentheader1 h2{
      font-size: 30px ! important;
     }
.para-top{
      font-size: 20px ! important;
     }
.Typecomponent1Img_vision{
      width: 350px ! important;
      height: 185px ! important;
     }
.webclientbackimg{
      margin-left: 10px ! important;
     }
.MobileDivHeight{
      height: 600px ! important;
     }
.type1btnDiv{
        margin-bottom: 0px ! important;

     }
.navigationTitle_for6{
        left:74px ! important;
     }
.new_wizardNote_for6{
        right: -12px ! important;
     }
.All1blog1{
      width: 275px ! important;
      margin-left: 25px ! important;
     }
.videocard1{
      width: 315px ! important;
     }

.overviewPadding{
        padding-left: 0px ! important;
        padding-right: 0px ! important;
      }
.challengedivPadd{
        padding-left: 0px ! important;
     }
.new_wizardNotefor4{
      left:-12px ! important;
      font-size: 11px ! important;
     }
.Typecomp2Btn_img{
        padding-left: 0px ! important;
     }
.BtnImageDown{
        margin-top: 0px ! important;
     }
.new_round-tabforTwo{
        margin-left: 40% ! important;
      }
.new_wizardNotefortwo{
        right: 0px ! important;
      }
.Type1_comp_padd{
        padding-left: 0px ! important;
      }
.new_wizardNotefor5{
        font-size: 9px ! important;
        right: -14px ! important;
      }
.new_wizardNote{
        right: -75px ! important;
      }
.new_round-tabfor3{
        margin-left: 29% ! important;
      }
.new_wizardNotefor3{
        right: 7px ! important;
      }
.new_wizardNotefor9{
        font-size: 10px ! important;
      }    
} 

@media only screen (max-width:1024px) and(orientation: portrait) {
.price.price-three{
      margin-left: 165px ! important;
      margin-top: 35px ! important;
    }
.upstream_tab{
        margin-left:175px ! important;
    }
.oilimage{
        right: 136px ! important;
        top:120px ! important;
    }
}
@media only screen and (device-width: 1024px) {
.navigationTitle_for6{
      left:430px ! important;
     }
.servicekbtnarrow{
      margin-left: 2px ! important;
     }
.navigation_tab{
      margin-left: 27px ! important;
     }
  }
@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {  
.navigation_tab{
      margin-left: 20px ! important;
     }
  }
@media only screen and (device-width: 1024px){
.jobformcontent{
        margin-left: 250px ! important;
    }
.TypebtnText{
      margin-left: 0px ! important;
    }
.blogtext h4{
      height: 75px ! important;
      overflow: hidden;
    }
.blogtext p{
      height: 100px ! important;
    }
.blogdate{
      padding-left: 0px ! important;
    }
.new_wizardNotefor4forJ{
      right: -234px ! important;
    }
.jobpageform{
        margin-top: 80px ! important;
    }
.subdata{
        padding-left: 0px ! important;
    }
.TypecomponentBtnImg img{
        margin-top: 0px ! important;
     }
.B4T_ServicesDiv{
        margin-left: 55px ! important;
     }
.new_wizardNotefor3{
        right: -218px ! important;
     }
.new_round-tabfor3{
        margin-left: 164% ! important;
     }
.new_wizardNotefor5{
        right: 10px ! important;
     }
.innerBlocktepmlate6{
        margin-left: 145px ! important;
     }
.NwesBoxShadow{
      height: 350px ! important;
     }
.journytextblock{
      width: 76% ! important;
     }
.type1btnDiv{
      height: 575px ! important;
     }
.new_wizardNote_for6{
      right: 35px ! important;
     }
.navigationTitle_for6{
      left:430px ! important;
     }
.servicekbtnarrow{
      margin-left: 5px ! important;
     }
.ContactmodalContent_brochure{
      height: 460px ! important;
     }
.landscape_btn{
      float: right ! important;
     }
.ContactmodalContent{
      height: 500px ! important;
     }
.ContactmodalContent{
      margin-top: 20% ! important;
     }
.new_round-tab{
      left:-33px ! important;
     }
.new_wizardNotefor9{
      right: 0px ! important;
     }
.serflip-card-back p{
      font-size: 13.5px ! important;
     }
.dwstmTitle{
      font-size: 17px ! important;
     }
.B4T_ServicesDiv{
      min-height: 215px ! important;
     }
.img1 img{
      width: 100% ! important;
     }
  } 
@media only screen and (device-width : 1920px){
.ApplicationImg{
    padding-left: 90px ! important;
  }

.ApplicationImg img{
    object-fit: contain;
   }

} 
@media only screen and (device-width : 320px) and (device-height : 568px) {
.B3T_challengesDiv {

          width : 238px ! important;
          margin-left: 27px ! important;
          /*border: 1px solid #dedbdb;*/
      }
.padding_640{
        padding-left: 0px;
      }

  }  
@media only screen and (device-width : 360px) and (device-height : 640px) { 
.B3T_challengesDiv {
          width : 260px ! important;
          margin-left: 27px ! important;
      }
.BT3_text11{
        padding-right: 29px ! important;
      }
.padding_640{
        padding-left: 0px;
      }
  }  
@media only screen and (device-width : 411px) and (device-height : 823px) { 
.B3T_challengesDiv {
          width : 325px ! important;
      }
.serflip-card {
        width: 265px ! important;
    }  
.BT3_innerBlockImage{
      height: 370px ! important;
    }
.blck{
          padding: 32px 25px 56px;
          margin-top: 58px;
          margin: 20px;
        }
  }   
@media only screen and (device-width : 480px) and (device-height : 854px) { 
.serflip-card {
          width: 350px ! important;
      }
.serflip-card img{
        width: 295px ! important;
      }
  }   
@media only screen and (device-width : 600px) and (device-height : 960px) { 
.serflip-card {
          width: 350px ! important;
          margin-left: 45px; 
    }
.serflip-card img{
        width: 295px ! important;
      }
.ct-topbar{
        left:515px ! important;
      }
.B4T_ServicesDiv{
        width: 530px ! important;
      }
.topnav.responsive{
            width: 600px ! important;
            height: 529px ! important;
            left: -158px ! important;
      }
.newcard_landscape {
          width: 202px ! important;
          margin-left: 35px;
      }
#consultdown_img1{
        left:21em ! important;
      }
#consultdown_img3{
        left:21em ! important;
      }
 #consultdown_img5{
        left:21em ! important;
      }
#consultdown_img0{
        height: 106px ! important;
      }
#consultdown_data1 {
        left: -8% ! important;
        width: 282px ! important;
    }
#consultdown_img1 {
        height: 106px ! important;
        left: 18em ! important;
    }
 #consultdown_img2 {
      left: 1em ! important;
      height: 100px ! important;
     }
#consultdown_data3 {
        left: -7% ! important;
        width: 300px ! important;
    }
#consultdown_img3 {
        left: 17em ! important;
        height: 100px ! important;
    }
#consultdown_img4{
      left: 2em ! important;
       height: 100px ! important;
    }
#consultdown_data5 {
        left: -1% ! important;
        width: 278px ! important;
    }
#consultdown_img5 {
        left: 20em ! important;
    }

  }   
@media only screen and (device-width : 411px) and (device-height : 731px) { 
.serflip-card img{
      margin-left: 20px ! important;
    }   
  }   
@media only screen and (device-width : 414px) and (device-height : 736px) { 
.B3T_challengesDiv {
          width : 261px ! important;
          margin-left: 60px ! important
          /*border: 1px solid #dedbdb;*/
      }
.B4T_img{
        margin-left: 60px ! important;
      }
 .serflip-cardservices img{
      margin-left: 20px ! important;
    } 
.pt20{
      padding-top: 20px ! important;
    }

  } 
@media only screen and (device-width : 800px) and (device-height : 1220px) { 
.serflip-cardservices img{
     width: 220px ! important;
    } 
  } 
@media only screen and (device-width : 1024px) and (device-height : 768px) { 
.serflip-cardservices img{
     width: 170px ! important;
    } 
  } 
 @media only screen and (device-width : 768px) and (device-height : 1024px) { 
.serflip-cardservices img{
     width: 220px ! important;
    } 
  } 
 @media only screen and (device-width : 412px) and (device-height : 732px) {   
.serflip-cardservices img{
     width: 227px ! important;
    } 
  } 
@media only screen and (device-width : 412px) {  
.serflip-cardservices img{
     width: 227px ! important;
    } 
.serflip-card {
        width: 275px ! important;
    }
.serflip-card img{
      width: 225px ! important;
    }
  } 
@media only screen and (device-width : 384px) {   
    .serflip-card{
      width: 255px ! important;
    }    
}
@media only screen and (device-width : 390px) {  
.serflip-card{
      width: 275px ! important;
    }
  } 
@media only screen and (device-width : 411px) {  
.serflip-card{
      width: 270px ! important;
    }
.serflip-card img{
      width: 210px ! important;
    }
  } 
@media only screen and (device-width : 375px) { 
.serflip-card{
      width: 260px ! important;
    }
.serflip-card img{
      width: 210px ! important;
    }   
  } 
@media only screen and (device-width : 414px) {   
.serflip-card{
      width: 260px ! important;
    }
  } 
@media only screen and (device-width : 600px) and (device-height : 960px) {   
.serflip-cardservices img{
     width: 340px ! important;
    } 
.newcard_landscape {
      width: 380px ! important;
    }
    

  } 
@media only screen and (device-width : 480px) and (device-height : 854px) {    
.serflip-cardservices img{
     width: 340px ! important;
    } 
.newcard_landscape {
      width: 350px ! important;
    }
  } 
@media only screen and (device-width : 375px) and (device-height : 667px) { 
.B3T_challengesDiv {

          width : 261px ! important;
          margin-left: 43px ! important
          /*border: 1px solid #dedbdb;*/
      }
.B4T_img{
        margin-left: 60px ! important;
      }
  }
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
.righttextaboutus{
      width: 245px ! important;
    }
.serflip-card img{
      /*margin-left: 10px ! important;*/
    }
.serflip-card {
        width: 237px ! important;
    }
.Typecomponent1Img_vision {
        width: 300px ! important;
    }
.blck1heightnew{
      height: 284px ! important;
    }
.blck1heightnew{
      width:239px ! important;
    }
  } 
@media only screen and (device-width : 393px) and (device-height : 851px) { 
.innerBlocktConsult{
      height: 1721px ! important;

    } 
#consultdown_img3{
      top:94em ! important;
    }
.BT3_innerBlockImage{
      height: 450px ! important;
    }
 #consultdown_data3{
      top:100em ! important;
    }   
.resfooterend{
      height: 65px ! important;
    } 
.responsivefooter{
      height: 591px ! important;
    }
.contactcontent {
      margin-bottom: 35px;
      margin-left: 23px;
      margin-top: 26px;
   
    }
.serflip-card img{
      width: 220px ! important;
    }
.videocard{
      widows: 270px ! important;
    }
.BT3_text11{
      padding-right: 29px ! important;
    }
.serflip-card img{
      /*margin-left: 15px ! important;*/
    }

.NewLoader{
      margin-left: 0px !important;
    }
.energypagedata p{
      letter-spacing: 0.5px ! important;
    }
.sxSelectHeader__Xlz5r{
        height: 50px ! important;
    }
  } 
 @media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:768px){
   /* Safari only override */ ::i-block-chrome,
.services_title { 
      letter-spacing:0.5px ! important; 
       }
.title_Description { 
      letter-spacing:0.5px ! important; 
       }
.contentheaderNewPage h2{
      letter-spacing: 0.5px;
      }   
    }
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
  { @media {

.services_title { 
      letter-spacing:0.5px ! important; 
       }
.title_Description { 
      letter-spacing:0.5px ! important; 
       }
.contentheaderNewPage h2{
      letter-spacing: 0.5px;
    }   

  }}  
@media only screen and (device-width: 1024px) {
.pt20{
      padding-top: 20px ! important;
    }
.blogtext p{
      height: 185px ! important;
    }
.journytextblock{
      height: 129px ! important;
    }
.Journycaro9{
      top:1145px ! important;
    }
.add10{
      left: 128px ! important;
      top: -67px ! important;
    }
.add9{
      left:172px ! important;
      top:157px ! important;
    }
.Journycaro10{
      left:285px ! important;
    }
.newcard_HD{
      width: 220px ! important;
    }
.newcard {
      width: 200px ! important;
    }
.serflip-card img{
      width: 150px ! important;
    }
.navigation_tab{
      margin-left: 25px ! important;
    }
.new_wizardNotefor9{
      right: 0px ! important;
    }
.secondblockk {
    padding-top: 100px;
    }
  } 
@media only screen and (device-width: 1024px) {
.righttextaboutus h3{
      font-size: 23px ! important;
    }
.landscape_css{
      margin-right: 20px;
    }
.contacticon21{
      /*margin-left: 20px ! important;*/
    }
.contacticon2{
      /*margin-left: 20px;*/
    }
.newcard_HD{
      width: 220px ! important;
    }
.iconCircle{
      margin-left: 28% ! important;
    }
  
  }

 @media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
.righttextaboutus h3{
      font-size: 23px ! important;
    }
.landscape_css{
      margin-right: 20px;
    }
.contacticon21{
      /*margin-left: 20px ! important;*/
    }
.contacticon2{
      /*margin-left: 20px;*/
    }
  
  }
@media only screen and (device-width : 401px) { 
.serflip-cardservices img{
    width: 220px ! important;
  }
}
  @media only screen and (device-width : 400px) and (device-height :640px) { 
.serflip-cardservices img{
    width: 245px ! important;
  }
.contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
.serflip-card {
      width: 270px ! important;
  }
}
@media only screen and (device-width : 393px) { 
.serflip-cardservices img{
    margin-left: 0px ! important;
  }  
}  
@media only screen and (device-width : 360px) { 
.newcard_landscape {
      width: 245px ! important;
  }
}
@media only screen and (device-width : 360px) { 
.serflip-card{
    width: 240px ! important;
   } 
}
@media only screen and (device-width : 600px) and (device-height :1024px) { 
.newcard_landscape {
    width: 337px ! important;
    margin-left: 71px;
  }
 .serflip-cardservices img{
  width: 285px ! important;
 } 
} 
@media only screen and (device-width : 360px) and (device-height :640px) { 
 .serflip-card{
  width: 270px ! important;
 } 
 .serflip-card img {
    width: 215px ! important;
  }
  .blck1heightnew{
    width: 278px ! important;
  }
} 

@media only screen and (device-width : 320px) and (device-height : 533px){ 
.newcard_landscape {
      width: 215px ! important;
  }
}  
@media only screen and (device-width : 320px) and (device-height : 480px){ 
.newcard_landscape {
      width: 210px ! important;
  }
}    

@media only screen and (device-width : 240px) and (device-height : 320px){ 
.newcard_landscape {
      width: 155px ! important;
  }
}  
@media only screen and (device-width: 2048px) and (device-height:1536px ) and (orientation: landscape){
.newcard_landscape {
      width: 370px ! important;
      margin-left: 25px ! important;
      }
.newcard {
        width: 410px ! important;
        margin-left: 10px;
    }
.Expertise_maindiv{
      margin-left: 0px ! important;
    }

  } 
@media only screen and (device-width: 1536px){
.TypecomponentBtnImg{
      margin-left: 230px ! important;
    }
.TypebtnText{
      margin-left: 175px ! important;
    }
.righttextaboutus p{
      /*text-align: left ! important;*/
    }
.All1blog1 {
        width: 320px ! important;
    }
.Typecomp_1_img img{
      width: 100%;

    }
.BtnImageDown{
      padding-left: 0px ! important;
    }
.downimgtext{
      left:29em ! important;
    }
.B3T_challengesDiv{
      margin-left: 125px ! important;
    }
.ApplicationImg{
      padding-left: 50px ! important;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
.B4T_ServicesDiv{
      width: 638px ! important;
      margin-left: 75px ! important;
     }
  } 
@media only screen and (device-width: 2560px){
.Typecomp_1_img img{
      width: 100%;

    }
  }
@media only screen and (device-width: 2048px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img{
      width: 80% ! important;
    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 120px ! important;
    }
.B3T_challengesDiv {
    top: 45px;
    box-shadow: 1px 2px 4px 3px #c4bfbf;
    margin-left: 79px;
    margin-bottom: 40px;
    min-height: 200px;
    width: 493px;
    height: 232px;
    padding-top: 35px;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
  }   
@media only screen and (device-width: 2048px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
.Type2Img{
      width: 80% ! important;
    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 120px ! important;
    }
.B3T_challengesDiv {
    top: 45px;
    box-shadow: 1px 2px 4px 3px #c4bfbf;
    margin-left: 79px;
    margin-bottom: 40px;
    min-height: 200px;
    width: 493px;
    height: 232px;
    padding-top: 35px;
    }
.newcard_landscape {
      width: 370px ! important;
      margin-left: 25px ! important;
      }
.newcard {
        width: 410px ! important;
        margin-left: 10px;
    }
.Expertise_maindiv{
      margin-left: 0px ! important;
    }
  }   
@media only screen and (min-device-width: 2560px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;
    }
.Type2Img img{
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 147px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 585px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 475px ! important;
    }
.new_round-tabfor9{
      margin-left: 67% ! important;
    }
.new_wizardNotefor9{
      right: -52px ! important;
    }
.new_wizardNote_for6{
      right: 102px ! important;
    }
.Allblog{
    margin-left: 80px  ! important;
    }
.newcard{
      width: 450px ! important;
      margin-left: 15px ! important
    }
.Mobile_leftPadd{
      /*margin-left: 290px !important;*/
    }
.MacProPdding{
      margin-left: 50px ! important;
    }
.TypebtnText_Padding{
          padding-top: 4% ! important;
      }
.MacProPddingfor3{
        margin-left: 290px ! important;
      }
.MacProPddingfor_{
        margin-left: 265px ! important;
      }
.newcard_HD {
        width: 500px ! important;
        margin-left: 30px ! important;
      }
.input-group .form-control{
        z-index: 0 ! important;
      } 
   }    
@media only screen and (device-width: 2560px){
.Allblog{
    width: 430px ! important;
   }
.iconCircle{
    margin-left: 41% ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img{
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 147px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 585px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 475px ! important;
    }
.new_round-tabfor9{
      margin-left: 67% ! important;
    }
.new_wizardNotefor9{
      right: -52px ! important;
    }
.new_wizardNote_for6{
      right: 102px ! important;
    }
.Allblog{
        margin-left: 80px  ! important;
      }
.newcard{
      width: 450px ! important;
      margin-left: 15px ! important
    }
.Mobile_leftPadd{
      /*margin-left: 290px !important;*/
    }
.MacProPdding{
      margin-left: 50px ! important;
    }
.TypebtnText_Padding{
          padding-top: 4% ! important;
    }
.MacProPddingfor3{
        margin-left: 290px ! important;
    }
.MacProPddingfor_{
        margin-left: 265px ! important;
      }
.input-group .form-control{
        z-index: 0 ! important;
      }  
   }    
 @media only screen and (device-width : 1680px) and (device-height : 1050px) { 
.newcard_HD {
    width: 330px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 }
 @media only screen and (device-width : 1680px){ 
.newcard_HD {
    width: 350px ! important;
  }
.newcard{
    width: 350px ! important;
  }
.dwstmTitle{
    font-size: 22px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.B3T_challengesDiv{
    width: 450px ! important;
  }
.B4T_img{
    margin-left: 150px ! important;
  }
.ApplicationImg{
    padding-left: 70px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        margin-left: 500px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
 }
 @media only screen and (device-width : 1280px) and (device-height : 800px) { 
.All1blog1{
    margin-left: 85px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.newcard{
    width: 250px ! important;
  }
 }
 @media only screen and (device-width : 1280px){ 
.All1blog1{
    margin-left: 85px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.DirectorText{
    padding-top: 10px ! important;
  }
.newcard_HD {
    width: 250px ! important;
  }
.newcard{
    width: 260px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 }
 @media screen and (device-width: 1920px){

.Type2Img{
    width: 85% ! important;

  }
.newcard{
    width: 370px ! important;
    margin-left: 25px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left:  ! important;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px ! important;
  }
.newcard_HD {
    width: 400px ! important;
  }
.roadjourny{
    z-index: 0 ! important;
  }
.Typecomp_1_img img{
    width: 100% ! important;
  }
.Type2Img{
    width: 85% ! important;
  }
.iconCircle{
      margin-left: 40% ! important;
     }
.jobformcontent{
        margin-left: 630px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }   

 }
@media screen and (device-width: 1856px){

.Type2Img{
    width: 85% ! important;
  }
.newcard{
    width: 385px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left: 85px;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px;
  }
.newcard_HD {
    width: 370px ! important;
  }
.All1blog1{
    margin-left: 80px ! important;
  }
.roadjourny{
    z-index: -1;
  }
.iconCircle{
    margin-left: 39% ! important;
 }
.input-group .form-control{
    z-index: 0 ! important;
  }
 .jobformcontent{
        margin-left: 600px ! important;
    }
 .jobpageform{
        margin-top: 50px ! important;
    }   
 }
  @media screen and (device-width: 1792px){

.Type2Img {
    width: 85% ! important;

  }
.newcard{
    width: 370px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left: 85px;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px;
  }
.newcard_HD {
    width: 370px ! important;
  }
.All1blog1{
    margin-left: 80px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        margin-left: 600px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
 }
@media only screen and (device-width : 1152px) {
.newcard_HD{
    width: 240px ! important;

   }
.newcard {
    width: 240px ! important;
  }
.All1blog1{
    margin-left: 71px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.journybg{
    display: none;
  }
.TypebtnText{
    margin-left: 0px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 } 

@media only screen and (device-width : 1600px) {
.B3T_challengesDiv{
  width: 430px ! important;
 } 
.newcard {
  margin-left: 20px ! important;
 }
.ApplicationImg {
    padding-left: 81px !important;
  }
.All1blog1{
    margin-left: 71px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        /*margin-left: 500px ! important;*/
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
}
@media only screen and (device-width : 800px) {
.serflip-cardservices img{
    width: 230px ! important;
  }
.TypebtnText{
    margin-left: 0px ! important;
  }
.B3T_challengesDiv{
    width: 175px ! important;
  }
.B4T_img{
    margin-left: 18px;
  }
.B4T_txt{
    margin:0px;
    font-size: 10px ! important;
  }
 } 
@media only screen and (device-width : 2880px) {
.newcard_HD {
      width: 420px ! important;
      margin-left: 100px ! important;
  }
.commonBlockpadd {
    padding-left: 150px ! important;
  }
.iconCircle{
    margin-left: 43% ! important;
  }
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img {
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 133px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 678px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 425px ! important;
        margin-left: 80px ! important;
    }
.Expertise_maindiv{
        margin-left: 0px ! important;
    }
.input-group .form-control{
    z-index: 0 ! important;
  }
 } 
 @media only screen and (device-width : 3200px) {
.newcard_HD{
    width: 460px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }

 }
.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
/*    padding-top: 282px;
*/ padding-left: 0px;
   padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}

.NOpadding{
  padding-left: 0px;
  padding-right: 0px;
}

.line_1{
  background-color: #f19000;
  width: 105px;
  height: 4px;
}

.career_line_subhead{
    font-size: 50px;
    margin-top: 15px;
    padding-bottom: 0px;
    padding-left: 12px;
  }

  .career_line_paraone{
    padding-bottom: 46px;
    padding-top: 32px;
    font-size: 20px;
    padding-left: 12px;
}

.career_intro_img{
    width: 73%;
    margin-top: 0px;
    margin-left: 64px;
}
  

.butt_1 {
    background-color: #003bb3;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
    padding-right: 22px;
    padding-bottom: 10px;
    padding-left: 14px;
    width: 136px;
    transition: all 0.5s;
    cursor: pointer;
    /*margin-left: 22px;*/
    height: -24px;
    border-radius: 40px;
}


.butt_1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.butt_1 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 105px;
  transition: 0.5s;
}

.butt_1:hover span {
  /*padding-right: 25px;*/

}

.butt_1:hover span:after {
  opacity: 1;
  right: 0;
}

/*

.career_para-top{
    font-size: 50px;
    font-weight: 100;
    color: #666666;
    padding-left: 22px;
    margin-top: 44px;
}

.line_subhead1{
  padding-left: 22px;
  font-size: 20px;
  color: #808080;
   margin-bottom: 41px;
   margin-top: 28px;
}
.iogimgblock1{
  width: 100%;
  padding-top: 142px;
}

.iogblock1text{
  padding-top: 100px;
  font-size: 15px;
  margin-top: 142px;
}

.iogblock1text span{
  font-size: 18px;
  font-weight: bold;
}*/

.colorblue_txt{
	color: #1378d4;
}

.career1_img{
width: 100%;
padding-right: 45px;
}


.line_headd hr {
    height: 2px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
}

.career_linee_1 {
    background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 165px;
    margin-left: 69px;
}

.rcorners1 {
    border-radius: 32px;
    background: #1378d4;
    padding: 20px;
    width: 235px;
    height: 60px;
    padding-left: 38px;
    color: white;
    font-size: 22px;
    padding-top: 13px;
}


.mt100{
	margin-top: 40px;
}

.mt70{
	margin-top: 70px;
}



.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -10px;
  color: white;
  font-size: 25px;
  transform: rotate(90deg);
}

.videocard {
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  height: 200px;
  text-decoration: none;
  z-index: 0;

  overflow: hidden;
  color: #000;
}
.videocard h3{

height: 100px;  
}
.videosDiv{
  margin-bottom: 50px;
}
.videocard:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -32px;
    right: 35px;
    background: #00838d;
    height: 32px;
    width: 36px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  .videocard:hover:before {
    transform: scale(21);
  }

.videocard:hover p{
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);  
}

.videocard:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
.videocard:hover{
	text-decoration: none;
  cursor: pointer;
}
.videocard:focus{
	text-decoration: none;
}
.BT1_img1{
    height: 200px;
    width: 100%;
    /*margin-top: 45px;*/

  }
  .reactplayerDiv{
     width:324px ! important;
     height:211px ! important;
  }

.videocard1{
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 15px 12px;
  margin: 12px;
  height: 242px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.BT1_bannerTitle_res{

    position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 27px;*/
    top: 130px;
    letter-spacing: 2px;
  }

.videocard1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 50px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  .videocard1:hover:before {
    transform: scale(21);
  }

.videocard1:hover p{
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);  
}

.videocard1:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
.videocard1:hover{
  text-decoration: none;
}
.videocard1:focus{
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .videocard1{
    width: 275px;
    margin: 0px;
  }
  .videocard{
    /*width: 230px;*/
    margin: 0px;
  }
  .Mobile_leftPadd{
    padding-left: 0px ! important;
  }
  .mobileright_padd{
    padding-right: 0px ! important;
  }
  
}
@media screen and (min-width: 1900px) {
  .reactplayerDiv{
    width: 510px ! important;
  }

}
@media screen and (min-width: 1680px) {
  .reactplayerDiv{
    width: 433px ! important;
  }

}

@media only screen and (device-width : 393px) and (device-height : 851px) { 
       
    .resfooterend{
      height: 61px ! important;
    } 
    .contactcontent {
      margin-bottom: 35px;
      margin-left: 23px;
      margin-top: 26px;
   
    }
    .videocard{
      widows: 270px ! important;
    }


  } 
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
  .videocard1 {
    width: 260px ! important;
  }
 } 
  @media only screen and (device-width : 411px) and (device-height : 731px) { 
  .videocard1 {
    width: 350px ! important;
  }
 } 
  @media only screen and (device-width : 360px) and (device-height : 640px) { 
  .videocard1 {
    width: 295px ! important;
  }
 } 
  @media only screen and (device-width : 600px) and (device-height : 960px) { 
  .videocard1 {
    width: 355px ! important;
    margin-left: 95px;
  }
 } 
 @media only screen and (device-width : 1792px) { 
  .reactplayerDiv {
    width: 463px ! important;
  }
 } 
 @media only screen and (device-width : 1856px) { 
  .reactplayerDiv {
    width: 430px ! important;
  }
 } 
 @media only screen and (device-width : 1600px) { 
  .reactplayerDiv {
    width: 405px ! important;
  }
 } 
 @media only screen and (device-width : 2880px) { 
  .reactplayerDiv {
    width: 828px ! important;
  }
 }
 @media only screen and (device-width : 2560px) { 
   .reactplayerDiv {
      width: 722px ! important;
  } 
 } 


.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	/*font-family: 'Open Sans', sans-serif ! important;*/
	color:#000;
}
p{
	/*font-size: 17px;*/
}

.AllBlogsbannerWall{
  height: 435px;
  /*background-color:  #444;*/
  padding: 0px !important;
  position: relative;
    text-align: center;
    color: white;
    background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/ceo.png");
    background-size: 100% 100%;
    margin-bottom: 100px;

}
.AllBlogsbannerWall img{
  margin-top: 30px;
}
.nopadding{
  padding: 0px;
}
.AllBlogscentered {
  background-color: #f3e8e8ba;
  padding: 0px !important;
  height: 435px;
  text-align:center !important;

}
.AllBlogstextcentered{
  position: absolute;
  top: 43%;
  text-align: center;
  left: 0%;
  
}

.fs72{
  font-weight: 600;
  font-size: 72px;
  color: #333;
}
.fs24{
  font-size: 22px;
}



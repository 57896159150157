.blogContentDiv p, .blogContentDiv li{
	/*font-family: 'Open Sans', sans-serif;*/
	font-size: 16px !important;
	line-height: 25px;
	color:#303133;
}
.imgContainerInner{
	height: auto;
}
.blogcontenttext{
    height: 460px;
    border-radius: 8px;
    margin-top: 50px;
}
.blogSubImg:hover{
	/*transform: scale(0.8, 0.8);
    overflow-wrap: hidden;
    transition-duration: 0.20s;*/
}
.blogSubImg{
    height: 270px;
    border-radius: 5px;
    width: 100%;
}
.pdfHeight{
  height: 800px;
}
.blogBox table{
  width: 200px ! important;
}
#pdfWrap{
  /*background-color:#f5f5f5;
  width: 210mm;
  min-height: 297mm;
  margin-left: auto;
  margin-right : auto;*/
}
.blogImgContainerInner{
  height: 450px;
  width: 100%;
  /*border-radius: 10px;*/
  margin-top: 70px;
  margin-bottom: 15px;
  padding: 0px;
  background-color: #121212; 

} 
.blogBox  img {
  width: 300px;
  height: auto;

}
.blogImgContainerInner:hover img{
  /*transform: scale(1);*/
}

.blogImgContainerInner img {
	position: relative;
	opacity: 0.3;
  /*transform: scale(2);*/
	/*opacity: 1;*/
    height: 450px;
    width: 100%;
}
.middle {
  transition: .5s ease;
  /*opacity: 0;*/
  /*position: absolute;*/
  margin-left: 15px;
}


.fbBtn{
  border: none;
  background-color: transparent;
}
.blogImgContainerInner:hover .blogimgtext {
  /*opacity: 1;*/
}

.middle .blogfb .fb:hover, .middle .blogtwit a:hover, .middle .blogLin a:hover{
  color: #009de2;
}

.middle .blogfb .fb, .middle .blogtwit a, .middle .blogLin a{
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  top: 410px;

}
.blogtwit{
}
.middle .blogtwit {
  position: absolute;
   left: 90%; 
  /*top: 10px;*/
  padding: 12px 0px 0px 0px;
  /*right: 60px;*/
  top: 400px;

}
.middle .blogfb .fb{
  position: absolute;
   /*left: 42%; */
  /*top: 5px;*/
  padding: 6px 0px 0px 0px;
  right: 35px;
}
.middle .blogLin a{
  position: absolute;
  padding: 10px 0px 0px 0px;
   /*left: 42%; */
  /*top: 8px;*/
  right: 0px;
}


.blogimgtext{
	color: #fff;
	font-weight: bold;
	font-size: 35px;
	padding: 16px 32px;
	position: absolute;
	 /*left: 42%; */
	top: 200px;
	text-align: center;
}
.blogdatetext{
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 16px 32px;
  position: absolute;
   /*left: 42%; */
  top: 260px;
  text-align: center;
}
.header-scrolldown i :hover{
	top: 490px;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
}
.header-scrolldown i {
	color: #fff;
	font-size : 30px;
}
.header-scrolldown {
  position: absolute;
  left: 50%;
  z-index: 100;
  top: 410px;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
}
.header-scrolldown:hover {
  transform: translateY(5px);
  opacity: .8 !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
}

.commentBox{
  height: 125px !important;
  margin-bottom: 10px;
}
.commentBoxText{
  height: 90px !important;
}
.commentBoxbtn {
  font-weight: 600;
  padding: 10px 20px;
  color: #fff;
  margin-top: 10px;
}

.miniDiv1Filter img {
  /*transform: scale(1);*/
  cursor: pointer;
  transition-duration: 0.5s;
   animation: scale 5s ;
   animation-iteration-count: 2;
   animation-timing-function: linear;
}
.miniDiv1Filter{
  overflow: hidden;
  margin-bottom: 20px;
}
.miniDiv1Filter:hover img{
  transform: scale(1.1);
  cursor: pointer;
  transition-duration: 0.5s;
}

@keyframes scale {
  from {transform: scale(1.5)}
  to {transform: scale(1)}
}


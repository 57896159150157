.dwstmTitle{
  font-size: 18px;
  font-weight: 700;
  margin-top: 2px;
}
.leafModalBody{
  height: 415px;
  padding: 0px;
}
.leafModalFooter{
  border: none !important;
}
.skipText{
  color: #555;
  font-size: 15px;    
  text-align: center;
  cursor: pointer;
  font-weight: 900
}
.contentWraper{
	height: auto;
	background-color: transparent;
	padding-top: 0px!important;
	/*background-color: #fff;*/
	/*padding-top: 35px;*/
  overflow: hidden;
}

.serflip-card {
    /*background-color: #fff;*/
    /*box-shadow: 0 10px 29px rgba(92,141,255,.22);*/
    margin-bottom: 70px;
    height: 300px;
    width:300px; 
     perspective: 1000px;
    border-radius: 15px;
}
.serflip-cardservices{
   margin-bottom: 70px;
    height: 300px;
    width:300px; 
     perspective: 1000px;
    border-radius: 15px;

}

.serflip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  border-radius: 15px;
}

.serflip-cardservices:hover .serflip-card-inner {
  transform: rotateY(180deg);
  transition-duration:1.5s;
}
.serflip-cardservices:hover .serflip-card-inner {
  transform: rotateY(180deg);
  transition-duration:1.5s;
}

.serflip-card-front, .serflip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
}

.serflip-card-front {
  background-color: #fff;
  color: black;
  border-radius: 15px;
  border-radius: 15px;
  padding: 60px 22px 47px;


}

.serflip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 15px;
  padding: 22px 22px 47px; /*PB*/
}

.serflip-card-back p{
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 600;
}
.downstreamservicesblock{
	  background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 87px 22px 47px;
    height: 300px;
}
.serflip-card img{
  height: 130px;
  margin-bottom: 15px;
}
.serflip-cardservices img{
  height: 130px;
  width: 190px;
  object-fit: contain;
  /*padding-left: 50px;*/
  margin-bottom: 15px;
}

.serflip-cardservices h2{
	/*margin-top: 70px;*/
	font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  text-align: center;
}
.serflip-card h2{
  /*margin-top: 70px;*/
  font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  text-align: center;
}
.block p{
  font-size: 14px;
  color:#000;
}

.contentheader{
	/*margin-bottom:120px;*/
}
.contentheader h1{
	  font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader h2{
  font-size: 42px;
  line-height: 55px;
  /*letter-spacing: 2px;*/
}
.contentheader hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}
.contentheadernew{
  /*margin-bottom:120px;*/
}
.contentheadernew h1{
    font-size: 30px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheadernew h2{
  font-size: 30px;
  line-height: 55px;
  letter-spacing: 2px;
}
.contentheadernew hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}

/*.block1height{
  height: 300px;
}
.block2height{
  height: 300px;
}
.block3height{
  height: 300px;
}
.block4height{
  height: 300px;
}
.block5height{
  height: 300px;
}
.block6height{
  height: 300px;
}
*/
.text-justify{
	text-align: justify;
}
@-webkit-keyframes rotation {
    
    to{
       
        -webkit-transform: perspective(100px) rotateY(10deg);
    }
}
.upstreamBg{
    padding: 150px 0px;
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/6.png");
    height: 785px;
}

.upstream-wrapper:before {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}
.upstream-wrapper:after {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}

.upstream-wrapper:before {
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    background-position: calc(50% - 100px) 50%;
    opacity: .2;
}

.upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}

.upstream-wrapper:after {
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    background-position: calc(50% + 100px) 50%;
    opacity: .1;
}
.upstream-wrapper:after, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, 15px, 0);
  }
}
.upstream-wrapperafter, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
.upstreamBg{
    position: relative;
    z-index: 1;
    background-position: 50%;
    background-repeat: repeat-x;
}
.svgAnimation{
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/data_image_svgs.svg");
    height: 540px;
}



.Main_Cards__Bubble:first-child {
    top: 270px;
    left: -75px;
}
.Main_Cards__Bubble:first-child {
    width: 493px;
    height: 493px;
    content: "";
}
.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color:#eeeeee4d;
    border-radius: 50%;
}
.Main_Cards__Bubble:nth-child(2) {
    right: 0px;
    bottom: 0px;
    width: 800px;
    height: 800px;
}
/*.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color: rgba(105,92,255,.05);
    border-radius: 50%;
}*/
.havh{
  
}




@keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; transform-origin: 350px -50px; transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; transform: scale(1);
  }
}
@keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; transform: scale(1) ; 
  }
  20% {
    transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  transform: scale(1);
  }
}
@keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; transform: scale(1) ; 
  }
  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    transform-origin: 550px -50px; transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    transform: scale(1);
  }
}
.bub1{
    animation:  bubblea1 30s linear infinite;


}

.bub2{
    animation: bubblea3 30s linear infinite;
}
.downstreamtxt{
  padding-top: 10px;
}
@media only screen and (max-width : 600px){
  .commonBlockpadd{
    padding: 0px ! important;
  }
  .B2T_p{
    padding: 0px ! important;
  }
  .responsivePadding{
    padding-left: 0px ! important;
  }
}

@media only screen and (device-width: 1024px) {
  .newcard_landscape{
    width: 220px ! important;

  }
  .serflip-cardservices img{
    padding-left: 0px ! important;
  }
}  
@media only screen and (device-width: 1366px) {
  .newcard_landscape{
    width: 300px ! important;

  }

} 
@media only screen and (device-width: 1024px) 
   {
  .newcard_landscape{
    width: 220px ! important;

  }
  .downimgtext{
    left:15em ! important;
  } 

}  
@media only screen 
  and (min-width: 1440px) 
  and (max-height: 900px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .newcard_landscape{
    width: 295px ! important;

  }
}

@media only screen and (device-width : 393px) and (device-height : 851px) {

   .serflip-cardservices img{
    margin-left: 20px ! important;
  } 
}
@media only screen and (device-width : 393px) and (device-height : 851px) {

  .serflip-card{
    width:260px ! important;
  } 
}
@media only screen and (device-width : 411px) and (device-height : 823px) {

   .serflip-cardservices img{
    margin-left: 15px ! important;
  } 
}
@media only screen and (device-width : 411px) {

   .serflip-cardservices img{
    margin-left: 15px ! important;
  } 
}
@media only screen and (device-width : 375px) and (device-height : 812px) { 
    .newcard_landscape {
      width: 248px ! important;
  }
}
@media only screen and (device-width : 320px) and (device-height : 568px) { 
    .newcard_landscape {
      width: 210px ! important;
  }
}
@media only screen and (device-width : 401px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
 }
  @media only screen and (device-width : 412px) and (device-height : 732px) {
   .serflip-card {
        width: 275px ! important;
    } 
  }  
 @media only screen and (device-width : 400px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
     .serflip-card img{
      width: 220px ! important;
     }
 }
 @media only screen and (device-width : 401px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
     .serflip-card img{
      width: 220px ! important;
     }
 }

.pageWrapper{
    height: 6000px;

}
.p1{
    height: 1px;
    width: 1px;
    border: 2px solid #000;
    position: absolute;
    left: 592px;
    top: 342px;
    border-radius: 100%;
    background-color: #000;
}
.p2{
    height: 15px;
    width: 15px;
    border: 1px solid #aaa;
    position: absolute;
    left: 587px;
    top: 336px;
    border-radius: 100%;
}
.p3{
    height: 50px;
    width: 50px;
    border: 1px solid #aaa;
    position: absolute;
    left: 569px;
    top: 317px;
    border-radius: 100%;
}
.p4{
    height: 100px;
    width: 100px;
    border: 1px solid #aaa;
    position: absolute;
    left: 572px;
    top: 293px;
    border-radius: 100%;
}
.p5{
    height: 125px;
    width: 125px;
    border: 1px solid #000;
    position: absolute;
    left: 560px;
    top: 279px;
    border-radius: 100%;
}
.p6{
    height: 145px;
    width: 145px;
    border: 1px solid #000;
    position: absolute;
    left: 550px;
    top: 269px;
    border-radius: 100%;
}
.p7{
    height: 200px;
    width: 200px;
    border: 1px solid #000;
    position: absolute;
    left: 522px;
    top: 238px;
    border-radius: 100%;
}
.p8{
     height: 225px;
    width: 225px;
    border: 1px solid #000;
    position: absolute;
    left: 510px;
    top: 225px;
    border-radius: 100%;
}
.p9{height: 95px;
    border: 1px solid #aaa;
    position: absolute;
    left: 621px;
    top: 297px;
    border-radius: 100%;
}
.p10{
     height: 95px;
    border: 1px solid #aaa;
    position: absolute;
    left: 623px;
    top: 296px;
    border-radius: 100%;
    transform: rotate(90deg);
}
.p11{
    height: 35px;
    width: 420px;
    border: 4px solid #000;
    position: absolute;
    left: 419px;
    top: 614px;
  }
.p12{
    height: 35px;
    width: 370px;
    border: 4px solid #000;
    position: absolute;
    left: 444px;
    top: 572px;
}
.p13{
    height: 35px;
    width: 362px;
    border: 4px solid #000;
    position: absolute;
    left: 444px;
    top: 150px;
}
.p14{
    height: 21px;
    width: 45px;
    border: 4px solid #000;
    position: absolute;
    left: 470px;
    top: 189px;
}
.p15{
    height: 21px;
    width: 45px;
    border: 4px solid #000;
    position: absolute;
    left: 720px;
    top: 189px;
}
.p16{
    height: 14px;
    width: 71px;
    border: 4px solid #000;
    position: absolute;
    left: 463px;
    top: 554px;
}
.p17{
    height: 14px;
    width: 71px;
    border: 4px solid #000;
    position: absolute;
    left: 709px;
    top: 554px;
}
.p18{
    width: 195px;
    border: 2px solid #000;
    position: absolute;
    left: 523px;
    top: 200px;
}
.p19{
    height: 336px;
    width: 30px;
    border: 4px solid #000;
    position: absolute;
    left: 733px;
    top: 214px;
}
.p20{
    height: 336px;
    width: 30px;
    border: 4px solid #000;
    position: absolute;
    left: 476px;
    top: 214px;
}
.p21{
        height: 35px;
    width: 267px;
    border: 4px solid #000;
    position: absolute;
    left: 484px;
    top: 111px;
}
.p22{
    height: 35px;
    width: 190px;
    border: 4px solid #000;
    position: absolute;
    left: 522px;
    top: 72px;
}
.p23{
    height: 35px;
    width: 124px;
    border: 4px solid #000;
    position: absolute;
    left: 556px;
    top: 32px;

}
.p24{height: 29px;
    border: 1px solid #000;
    position: absolute;
    left: 631px;
    top: 318px;
    border-radius: 100%;
    transform: rotate(45deg);

}
.p25{height: 37px;
    border: 1px solid #000;
    position: absolute;
    left: 611px;
    top: 310px;
    border-radius: 100%;
    transform: rotate(145deg);
}
.p26{height: 45px;
    border: 1px solid #000;
    position: absolute;
    left: 630px;
    top: 339px;
    border-radius: 100%;
    transform: rotate(155deg);
}



.para a {
  text-decoration: none;
  font-family: Helvetica;
  font-weight: bold;
  color: #222;
  border: 1px solid #ccc9c9;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 1px 0px #e8e7e7;
  font-size: 15px;
  cursor: default;
}

.para a:hover {
  background-color: #eaeaea;
}

.para a:active {
  box-shadow: inset -0px 4px 2px -3px #ccc9c9;
}

.para h1 {
margin-left: 505px;
    margin-top: -841px;
    color: #111;
    font-family: 'Open Sans Condensed', sans-serif;
    line-height: 3900px;
}

.nav a {
  text-decoration: none;
  font-family: Helvetica;
  font-weight: bold;
  color: #222;
  border: 1px solid #ccc9c9;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 1px 0px #e8e7e7;
  font-size: 15px;
  cursor: default;
}

.nav a:hover {
  background-color: #eaeaea;
}

.para a:active {
  box-shadow: inset -0px 4px 2px -3px #ccc9c9;
}

.para h1 {
margin-left: 505px;
    margin-top: -841px;
    color: #111;
    font-family: 'Open Sans Condensed', sans-serif;
    line-height: 3900px;
}
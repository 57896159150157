.contentWraper{
	height: auto;
	background-color: transparent;
	padding-top: 0px!important;
	/*background-color: #fff;*/
	/*padding-top: 35px;*/
  overflow: hidden;
}

.block{

	  background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 30px;
    padding:13px 12px 10px 12px;
    height: 225px;
    padding-top: 30px;
    /*width: 287px;*/
}
.block img{
  height: 55%;
}
.block img:hover
{
  transform: scale(1.1);
  overflow-wrap: hidden;
  /*transition-delay: 0.10s;*/
  transition-duration: 0.20s;
}
.block h2{
	/*margin-top: 70px;*/
	font-size: 26px;
  line-height: 55px;
  font-weight: 600;
  text-align: center;
}
.block p{
  font-size: 14px;
  color:#000;
}
.contentheader1{
  margin-bottom:20px!important;
}
.contentheader{
  margin-top: 20px;
	margin-bottom:20px!important;
}
.contentheader h1{
	font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader h2{
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -1px;
}
.contentheader hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}
.contentheaderNewPage hr{
   height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;

}

.contentheader1 h1{
  font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader1 h2{
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -1px;
}
.contentheader1 hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}

/*.block1height{
  height: 300px;
}
.block2height{
  height: 300px;
}
.block3height{
  height: 300px;
}
.block4height{
  height: 300px;
}
.block5height{
  height: 300px;
}
.block6height{
  height: 300px;
}
*/
.text-justify{
	text-align: justify;
}
@-webkit-keyframes rotation {
    
    to{
       
        -webkit-transform: perspective(100px) rotateY(0deg);
    }
}
.upstreamBg{
    padding: 150px 0px;
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/6.png");
    height: 785px;
}

.upstream-wrapper:before {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}
.upstream-wrapper:after {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}

.upstream-wrapper:before {
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    background-position: calc(50% - 100px) 50%;
    opacity: .2;
}

.upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}

.upstream-wrapper:after {
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    background-position: calc(50% + 100px) 50%;
    opacity: .1;
}
.upstream-wrapper:after, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -5px, 0);
  }
  50% {
    transform: translate3d(0, 15px, 0);
  }
}
.upstream-wrapperafter, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
.upstreamBg{
    position: relative;
    z-index: 1;
    background-position: 50%;
    background-repeat: repeat-x;
}
.svgAnimation{
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/data_image_svgs.svg");
    height: 540px;
}



.Main_Cards__Bubble:first-child {
    top: 450px;
    left: -75px;
}
.Main_Cards__Bubble:first-child {
    width: 893px;
    height: 893px;
    content: "";
}
.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color:#eeeeee4d;
    border-radius: 50%;
}
.Main_Cards__Bubble:nth-child(2) {
    right: 0px;
    top: 400px;
    width: 1000px;
    height: 1000px;
}
/*.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color: rgba(105,92,255,.05);
    border-radius: 50%;
}*/
.havh{
  
}




@keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; transform-origin: 350px -50px; transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; transform: scale(1);
  }
}
@keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; transform: scale(1) ; 
  }
  20% {
    transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  transform: scale(1);
  }
}
@keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; transform: scale(1) ; 
  }
  20% {
    transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    transform-origin: 550px -50px; transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    transform: scale(1);
  }
}
.bub1{
    animation:  bubblea1 30s linear infinite;


}

.bub2{
    animation: bubblea3 30s linear infinite;
}

.para0{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para2{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para4{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para1{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para3{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para5{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para6{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para7{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}
.para8{
  position: relative;
  top: 730px;
  transform: rotate(0deg);
  transition-duration: 1s;

}







.paraeff{
  position: relative;
  top :0px;
  cursor: pointer;
  /*margin-left: 8px;*/
  transform: rotate(0deg);
  transition-duration: 0.5s;
 /* margin-inline-start: 40px;
  margin-inline-end: 40px;*/
}
@media screen and (max-width: 768px){
  .paraeff{
    /*margin-inline-start:12px ! important;*/

  }
  .downTitleNew{
    font-size: 15px ! important;
  }
  .downTitle{
    font-size: 16px ! important;
  }
  .downTitle1{
    font-size: 15px ! important;
  }
}

.paraeff1{
  position: relative;
  top :0px;
  transform: rotate(0deg);
  transition-duration: 1.2s;
  transition-delay: 0.5s
}

.border{
  padding-top: 75px;
  /*border: 1px solid*/
}
.downTitle{
  font-size: 19px;
  margin-top: 20px;
  font-size: 21px;
}
.downTitle1{
  font-size: 21px;
  
}
.downTitleNew{
  margin-top: 0px;
  font-size: 20px;
}
.streamImg{
  margin-bottom: 15px;
}
.HOmeLine1{
  text-align: center;
  height: 4px;
  width: 90px;
  background: #f19000;
}
.breadcrumbtrain{
	width: 250px;
	background-color: transparent;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
	margin-left: 20px;
}
.mainpagetxt{
	position: absolute;
    left: 19%;
    top: 49%;
    font-weight: 600;
}
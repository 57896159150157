.addform{
	font-size: 25px;
	cursor: pointer;
	padding: 0px;
}
.formcontent-wrap{
	margin-top:60px;
	margin-bottom: 50px;
	color: #fff;
	background-size: 100% 100%;
	height: auto;
}
.jobpageform{
	box-shadow: 01px 1px 15px 1px #ccc;
	height: auto;
	padding: 25px;
    color: #555;
    margin-top: 20px
}
.jobformcontent{
	margin-bottom: 30px !important;
	margin-left: 383px;
}
.dropZindex{
	z-index: 10;
}
.css-1r4vtzz, .css-48ayfv, .css-1pcexqc-container{
	min-width: 100%!important;
	border: 1px solid #ccc !important;
	box-shadow: none !important;

}
 @media (max-width: 480px){

 	.jobpageform{
 		width: 300px ! important;
 	}
 	.jobformcontent{
 		margin-left: 0px ! important;
 	}
 	.formcontent-wrap{
 		margin-top: 150px ! important;
 	}

 }
@media screen and (device-width: 2560px) {
	.jobformcontent{
		margin-left: 900px ! important;
	}
	.jobpageform{
		margin-top: 50px ! important;
	}
}
@media screen and (device-width: 2048px) {
	.jobformcontent{
		margin-left: 700px ! important;
	}
}
@media screen and (min-width: 768px) {

	 /* .jobformcontent{
	  	margin-left: 400px ! important;
	  }*/
  }
.iogimplmntbrdCrm{
	z-index: 999;
	margin-top: 150px ! important;
	margin-left: 20px;
	/*position: fixed;*/
}
.breadcrumb2{
	background-color: transparent;
	width: 301px;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
	margin-left: 20px;
}
.implementimage{
	margin-top: 80px;
	border-top: 3px solid #333;
	border-left: 3px solid #333;
	border-bottom: 3px solid #333;
}
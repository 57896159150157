.contactModalBody{
  padding-top: 0px;
}
.closeButton{
    color: #fff;
    font-size: 15px;
}
.closeButton:hover{
    color: #f00;
}
.contactBar{
  position: absolute;
  right: 0px;
  background-color: #f19000;
  width: 40px;
  height: 31px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
  /*z-index: 100000;*/
  top:62px;
}
.envelopeIcon{
  padding-right: 3px;
  color: #fff;
}
.NopaddingToA{
  padding: 0px ! important;
}
.dropdownforAbiutus{
  margin-top: 45px;
}
.contactBarres{
  position: absolute;
  left: 0px;
  background-color: #f19000;
  width: 35px;
  height: 30px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
  /*z-index: 100000;*/
  top:95px;
}
.navbar-static-top{
  z-index: 0 ! important;
  /*padding-top: 20px;*/
}
.rescontactBar{
  position: absolute;
  right: 5px;
  top:156px;
  background-color: #f19000;
  width: 35px;
  height: 30px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
}
.hovertext{
  cursor: pointer;
  color: #fff;
  width : 100px;
  /*right: 5px;*/
  display: none;
}

.contactBar:hover i{
  display: none;
  font-size: 50px;
}
.contactBarres:hover i{
  display: none;
  font-size: 50px;
}
.contactBar:hover{
  width: 130px;
}
.contactBarres:hover{
  width: 130px;
}
.contactBar:hover .hovertext{
  display: block;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}
.contactBarres:hover .hovertext{
  display: block;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}
.fixedContactBar i{
  font-weight: bolder;
  font-size: 20px;
}

.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 115px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}

#container {
  margin: 0 auto;
  max-width: 890px;
}
/*p { text-align: center; }*/
 .toggle, [id^=drop] {
 display: none;
}

.topnav a {
  float: left;
  display: block;
  color: #333;
  text-align: center;
  padding: 14px 8px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
}

.active {
  /*background-color: #4CAF50;*/
  color: #f08f00;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 12px;    
  border: none;
  outline: none;
  /*color: #000;*/
  padding: 14px 8px;
  background-color: inherit;
  /*font-family: inherit;*/
  margin: 0;
  font-weight: 700;
}

.dropdown-content {
  border-top: 3px solid #aaf;
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}
.normaldropdown a{
  font-weight: 700 ! important;
}

.dropdown-content a {
  float: none;
  /*color: black;*/
  padding: 11px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px;  
  font-weight: normal;
}

.topnav a:hover, .dropdown:hover .dropbtn {
  /*background-color: #555;*/
  color: #f08f00;
}

.dropdown-content a:hover {
  /*background-color: #ddd;*/
  color: #f08f00;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen  and (device-width: 768px) {
    .dropdown-content a {
    float: none;
    /*color: black;*/
    padding: 15px 16px ! important;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 12px;  
    font-weight: normal;
  }

   
  }


@media only screen 
  and (device-width: 1440px) 
  and (device-height: 900px) 
  /*and (orientation: landscape) */
  {
     .dropdown .dropbtn {
      font-size: 15px ! important;
  }
    .topnav a {
      font-size: 15px ! important;
  }

}


@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .blogtext{
    height: 600px ! important;
  }
  .no-icon-list li{
    padding-bottom: 0px ! important;
  }
  .TopnavPadding{
    padding: 0px;
  }
  .topnav a.icon {
    top: 5px;
    right: 0px;
    position: absolute;
    float: right;
    /*right: -5px;*/
    display: block;
    /*background: #fff;*/
    border-radius: 4px;
    font-size: 27px;
    width: 70px;
    display: block;
  }
  .ct-topbar
  {

    position: fixed ! important;
    bottom: 0px ! important;
    top: 20px;
    left: 311px;
  }
  .headerlogo img{
    position: fixed;
    top: 12px;
    margin-left: -70px;
  }

}
@media screen and (max-width: 768px) {
 
  .topnav a {
   
    padding: 14px 3px;
    text-decoration: none;
    font-size: 9px;
    font-weight: 700;
    /*margin-top: 13px;*/
}
.dropdown .dropbtn {
    font-size: 9px;
    padding: 14px 2px;
    /*margin-top: 12px;*/
    
}
.contactBarres{
  right: 0px! important;

}

.dropdownintab a{
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 15px;
  /*font-weight: 400 ! important;*/
}
.dropdownintab{
  padding-bottom: 0px ! important;
  padding-top: 0px ! important;
  /*font-weight: 400 ! important;*/

}
.expertise_a{
  font-weight: 400 ! important;
  font-size: 10px ! important;
}
.contactBar{
  display: none;
}
.dropdown-content{
  min-width: 100px;
  min-height: 120px;
}
.headerlogo img{
  height: 68px ! important;

}
.setlogo{
  padding-left: 0 ! important;

}
.expertiesdropdown{
  margin-left: -276px ! important;
}
}
@media screen and (max-width: 480px) {
    .dropdown .dropbtn {
    font-size: 13px ! important;  
  }
  .topnav.responsive a{
     font-size: 13px ! important;  

  }
}

@media screen and (max-width: 600px) {

  .userNameDiv{
    z-index: 0;
  }
  .topnav.responsive {
    position: absolute;
    background-color: #333333f5;
    top: -45px;
    width: 410px;
    height: 529px;
    left: -14px;
    z-index: 1000;
    padding-top: 60px;
   }
  .topnav.responsive .icon {
    position: absolute;
    color:#f19000;
    right: 20px;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    color:#fff;
    display: block;
    /*text-align: right;*/
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    color:#fff;
    /*text-align: right;*/
    
  }
  .topnav.responsive .dropdown-content{
    left: 233px ! important;
    top: 78px ! important;

  }
  .ct-language__dropdown{
    right: 154px ! important;
  }
}
.headerflow{
  position: fixed;
  z-index: 1;
  background-color: transparent;
  transition-duration: 0.8s;
}
.headerlogo img{
  /*margin-top: 15px;*/
  height: 75px;
}
.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 115px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}
.expertiesdropdown{
    width: 945px;
    margin-left: -444px;
    padding-left: 40px;
    z-index: 1001 ! important;
}
.columnhead{
  /*line-height: 20px;*/
  font-weight: 700 ! important;
  font-size: 12px ! important;
  text-align: center;
  color: #333 ! important;
}
.columnhead a:hover{
  color:#f08f00 ! important;
}
.columnheadsub{
  font-size: 12px;
}
.column {
  float: left;
  /*width: 25%;*/
  padding: 10px;
  background-color:#fff;
  /*height: 430px;*/
  padding:0px;
}
.setlogo{
  padding-left: 60px ! important;
  /*padding-top: 7px;*/
}

.contactBar{
  position: absolute;
  right: 0px;
  background-color: #f19000;
  width: 40px;
  height: 31px;
  top:62px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
}
.fixedContactBar i{
  font-weight: bolder;
  font-size: 20px;
}
.contactBar:hover i{
  display: none;
  font-size: 50px;
}
.contactBar:hover{
  width: 130px;
}
.Headerdownarraow{
  margin-left: 4px;
}
.hidepoweredby{
    right: 73px;
    width: 60px;
    height: 11px;
    position: absolute;
    background-color: #eee;
    z-index: 2000;
    top: 46px;
}
.new_flagdiv{
    right: 113px;
    position: absolute;
    width: 122px;
    top: 20px;
    z-index: 3000;
}
.onscrollheader{
    width: 157px ! important;
    height: 17px;
    background-color: #fff;
    position: absolute;
    right: 0px;
    top: -17px;
}
.goog-tooltip {
    display: none !important;
}
.goog-tooltip:hover {
    display: none !important;
}
.goog-text-highlight {
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
}
.goog-logo-link {
   display:none !important;
} 

.goog-te-gadget{
   color: transparent !important;
}
 .goog-te-banner-frame{
    visibility:hidden !important;
    }

.goog-te-banner-frame.skiptranslate {
  display: none !important;
} 
body { top: 0px !important; }    





.ct-topbar {
  text-align: right;
  position: absolute;
  right: -31px;
  bottom: 65px;
  height: 20px;
  /*background: #eee;*/
}
.ct-topbar__list {
  margin-bottom: 0px;
}
.ct-language__dropdown{
  padding-top: 8px;
  max-height: 0;
  /*overflow: hidden;*/
  position: absolute;
   -ms-transform: rotate(-90deg); /* IE 9 */
  transform: rotate(-90deg);
  top: -12%;
  display: block;
  right: 135px;
 /* -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;*/
  width: 35px;
  text-align: center;
  padding-top: 0;
  z-index:200;
}
.ct-language__dropdown li{
  background: transparent;
  padding: 0px;
}
.ct-language__dropdown li a{
  display: block;
  color: #333;
}
.new_container{
  width: 117px ! important;
}
.ct-language__dropdown li:first-child{
  padding-top: 10px;
  border-radius: 3px 3px 0 0;
}
.ct-language__dropdown li:last-child{
  padding-bottom: 10px;
  border-radius: 0 0 3px 3px;
}
.ct-language__dropdown li:hover{
  /*background: #fff;*/
}
.ct-language__dropdown:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom: 8px solid transparent;
}
.ct-language{
  position: relative;
  background: transparent;
  color: #fff;
  padding: 10px 0;
}
.ct-language:hover .ct-language__dropdown{
/*  max-height: 200px;
  padding-top: 8px;*/
}
.goog-te-gadget-icon{
background:none !important;
background-color: #eee ! important;
border:1px solid #eee ! important;
}
.goog-te-gadget-simple{
  background-color: #eee ! important;
  border:none ! important;
  bottom: 8px;
  right: -50px;
  position: absolute;

}
.language_icon{
    position: absolute;
    right: -12px;
    top: 4px;
    z-index: 3000;
}
.topnav a >.active {
    color:red;
    }

  .Currentactivetab{
    color:orange ! important;
  }
  .normalTabColor{
    color:#333 ! important;
  }

 @media only screen and (device-width : 375px) and (device-height : 812px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
    /*top:-15px ! important;*/
     /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 68px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 0px;
    }
    .ct-topbar{
      left:293px ! important;
      top:38px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
     }
     .topnav.responsive{
      left:-41px;
     }

  } 
  @media only screen and (device-width : 400px) and (device-height : 700px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
  @media only screen and (device-width : 400px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
  @media only screen and (device-width : 400px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
   
   @media only screen and (device-width : 360px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 700px ! important;
      }
     .ct-topbar{
      left: 303px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive{
      left:-24px ! important;
    } 
    .topnav a.icon{
      color: #333;
    }
    .topnav a.icon {
      top: -10px;
      right: -26px;

     }
     .topnav.responsive .icon {
      color: #f19000 ! important;
      }
    }  
  @media only screen and (device-width : 414px) and (device-height : 736px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 12px;
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }  
  @media only screen and (device-width : 375px) and (device-height : 667px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 308px ! important;
      top:38px;
    }

  } 
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -15px;
    }
    .ct-topbar{
      left: 254px ! important;
    }
    .topnav.responsive{
      left:-62px;
    }

  } 
   @media only screen and (device-width : 320px) and (device-height : 480px) { 
     .ct-topbar{
      left: 264px ! important;
    }
    .topnav a.icon {
      top: -13px;
      right: -28px;
      color :#585755 ! important;
     }
    .topnav.responsive{
      top: -53px;
      width: 410px;
      height: 529px;
      left: -60px;
      } 
      .headerlogo img {
      height: 60px ! important;
      /*z-index: 1000;*/
      margin-left: -70px;
      margin-top: -5px;
      }
   }  
  @media only screen and (device-width : 375px) and (device-height : 700px) { 
  .topnav a.icon {
    top   : -11px;
    right : -20px;
    color :#585755 ! important;
  }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -5px;
    }
    .ct-topbar{
      left: 312px ! important;
    }
    .topnav.responsive{
      left:-62px;
    }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      right: -20px;
       position: absolute;
    }
    .topnav.responsive {
        left: -50px;
    }
    .headerflowscroll{
      height: 95px ! important;
    }

  } 
   @media only screen and (device-width : 384px) and (device-height : 640px) { 
    .topnav.responsive{
      left:-32px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

   } 
    @media only screen and (device-width : 600px) and (device-height : 960px) { 
    .resbanner {
        height: 1024px ! important;
    }
    .headerlogo img{
      margin-left: -40px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

   } 

  @media only screen and (device-width : 411px) and (device-height : 823px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-15px ! important;
    right: -16px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 12px;
    }
    .ct-topbar{
      left: 344px ! important;
      top:20px;
    }
    .topnav.responsive .icon{
      color: #f19000 ! important;
    }
    .topnav.responsive {
        background-color: #333333f5;
        top: -43px;
        width: 430px;
        left: -26px;
    }

  }  

 @media only screen and (device-width : 411px) and (device-height : 731px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-11px ! important;
    right: -16px ! important;
     color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 344px ! important;
      top:25px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
  @media only screen and (device-width : 384px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-11px ! important;
    right: -16px ! important;
     color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 319px ! important;
      top:25px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
    .headerflowscroll{
      height: 95px;
    }

  }  
   @media only screen and (device-width : 390px) and (device-height : 844px) { 
        .resbanner {
        margin-top: 104px;
        height: 748px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }

   @media only screen and (device-width : 360px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
     top: -9px;
     right: -26px;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 300px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive{
      left:-26px ! important;
    }

  }  
   @media only screen and (device-width : 360px) and (device-height : 700px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: 0px;
    }
    .ct-topbar{
      left: 293px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
   @media only screen and (device-width : 401px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
   @media only screen and (device-width : 240px) and (device-height : 320px) { 
    .headerlogo img{
      height: 40px ! important;
      margin-left: -70px ! important;
    }
    .topnav a.icon {
        top: -10px;
        right: -32px;
         color :#585755 ! important;
      }
      .topnav.responsive {
        position: absolute;
        background-color: #333333f5;
        top: -56px;
        width: 363px;
        height: 333px;
        left: -92px;
        z-index: 1000;
        padding-top: 10px;
    }
    .topnav a {
        padding: 11px 3px;
      }
      .topnav.responsive a {
          font-size: 9px ! important;
      }
      .dropdown .dropbtn{
        font-size: 9px ! important;
      }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 189px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    @media only screen and (device-width : 400px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    
    @media only screen and (device-width : 400px) and (device-height : 600px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
     .topnav a.icon {
        top: -10px;
        right: -32px;
         color :#585755 ! important;
      }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    @media only screen and (device-width : 400px) and (device-height : 700px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .topnav a.icon {
        top: -10px;
        right: -32px ! important;
         color :#585755 ! important;
      }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
     @media only screen and (device-width : 400px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive .icon {
      color: #f19000 ! important;
      } 
   }
    @media only screen and (device-width : 401px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
     @media only screen and (device-width : 401px) and (device-height : 700px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive .icon {
      color: #f19000 ! important;
      } 


   }
     @media only screen and (device-width : 320px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
   }
     @media only screen and (device-width : 600px) and (device-height : 1024px) { 
    .headerlogo img{
      margin-left: -50px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }

    @media only screen and (device-width : 320px) and (device-height : 533px) { 
    .headerlogo img{
      margin-left: -70px ! important;
      margin-top: -10px ! important;
    }
    .resbanner {
        height: 540px ! important;
      }
      .ct-topbar{
        left: 260px ! important;
         margin-top: 8px;
      }
      .topnav a.icon {
        top: -9px;
        right: -23px;
        color :#585755 ! important;
      }
      .topnav.responsive{
        left:-59px ! important;
      }
      .headerflowscroll{
        height: 90px ! important;
      }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
 @media only screen and (device-width : 480px) and (device-height : 854px) { 
  .ct-topbar{
    left:416px ! important;
  }
  .topnav a.icon{
    right: -18px ! important;
  }
  .topnav.responsive .icon {
      color: #f19000 ! important;
      }

 }

   @media only screen and (device-width : 412px) and (device-height : 732px) { 
     .topnav a{
      /*font-size: 0px ! important;*/
     }
     .topnav a.icon{
      color :#585755 ! important;
      right: -27px ! important;
      /*top:-15px ! important;*/
      /*right: -16px ! important;*/
     }
     .headerlogo img {
      height: 65px ! important;
      /*z-index: 1000;*/
          margin-left: -64px;
         margin-top: -5px;
      }
      .ct-topbar{
          left: 350px ! important;
          margin-top: 8px;
      }
       .headerflowscroll{
        height: 100px;
      }
      .topnav.responsive .icon {
        color: #f19000 ! important;
        }

  }  
  @media only screen and (device-width : 412px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top: -15px ! important;
    right: -16px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 68px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 345px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
   @media only screen and (device-width : 320px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top: -17px ! important;
    right: -32px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 271px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive{
      left:-64px ! important;
    }
    .topnav.responsive .icon{
          left: 320px;
          top: -9px ! important;
          color: #f19000 ! important;
      }

    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
  }  

@media only screen and (device-width : 393px) and (device-height : 851px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
   top: -10px ! important;
    right: -5px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -60px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 317px ! important;
      margin-top: 8px;
    }
    .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  

  @media only screen 
  and (device-width: 1440px) 
  and (device-height: 900px) 

  /*and (orientation: landscape) */
 { 
  .topnav a{
    font-size: 14px;
  }
  .dropdown .dropbtn{
    font-size: 14px;
  }
 } 

 @media only screen 
  and (device-width: 1024px) 
 {
    .setlogo{
      padding-left: 10px ! important;
    }
    .dropdown .dropbtn{
      padding: 14px 2px;
    }
    .topnav a{
      padding: 14px 2px;

    }
}
 
  @media only screen 
  and (device-width: 768px)  {
    .setlogo{
      padding-left: 15px ! important;
    }
    
}
 @media only screen 
  and (device-width: 1536px) 
  and (device-height: 2048px) 
  and (orientation: portrait){
     .topnav a{
      padding: 14px 14px;
     }
     .dropdown .dropbtn{
      padding: 14px 14px;

     }
  }   
 @media only screen and (device-width : 2560px) and (device-height : 1600px) {
  .headerlogo img{
    height: 100px ! important;
  }
  .topnav a{
    font-size: 20px ! important;
  }
  .dropdown .dropbtn{
    font-size: 20px ! important;
  }
 }
 @media only screen and (device-width : 800px) {
  .headerlogo img{
    height: 78px ! important;
    margin-left: -57px;
  }
  .topnav a{
    font-size: 9px ! important;
    padding: -2px 2px ! important; 
  }
  .dropdown .dropbtn{
    font-size: 9px ! important;
    padding: 14px 2px ! important;
  }
 }
  @media only screen and (device-width : 851px) {
  .headerlogo img{
    height: 78px ! important;
    margin-left: -57px;
  }
  .topnav a{
    font-size: 9px ! important;
    padding: -2px 2px ! important; 
  }
  .dropdown .dropbtn{
    font-size: 9px ! important;
    padding: 14px 2px ! important;
  }
 }
 @media screen and (device-width:1024px){
     .setlogo{
        padding-left: 22px ! important;
     }

}






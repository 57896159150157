.B2T1_line1{
  /*margin-left: 491px ! important;*/
  background-color: #f08f00;
  width: 80px;
  height: 4px;
  top: 36px;
  /*z-index: 999;*/
}
@media only screen and (max-width: 768px) {
	.newline2{
		margin-left: 310px ! important;
	}
}
.ContactmodalContent_brochure{
	margin-top: 18%;
    background: #fff;
    border-radius: 04px;
    border: none;
    height: 430px;
    z-index: 15000;

}
.contactHeader_brochure {
    color: #fff;
    background-color: #009de2;
    font-size: 25px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 3px;
    background-color: ;
}
.brochureformBtn{
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 0px;
    color: #fff;
}
.formcontent_new{
	height: 70px;
}
 @media (max-width: 480px){
  .newline2{
    margin-left: 97px ! important;
  }
}
.upstreamtext{
    padding:0px 40px;
    /*letter-spacing: 0.5px;*/
    height: 85px;
  }

.price-bodyhover:hover .upbuttonhover{
  color: #fff!important;
  background-color: #f19000;
  transition-duration:0.7s;
  cursor: pointer;
}
.uptitle{
  border-bottom: 1px solid #c0b1b1b1;
  padding-bottom:20px; 
  color:#333;
}

.cards
{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  position: relative;
  /*margin-top: 40px;*/
  
}
.upstream_tab{
  /*margin-left:170px;*/
}

.card
{
  /*display:flex;*/
  width:380px;
  height:314px;
  border-radius:0.5rem;
  transform-origin:center center;
  transform:scale(1) translate(0px,0px) perspective( 750px ) rotateY(0deg);
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 0.5s;
  position:absolute;
  top:0;
  box-shadow:0 30px 50px rgba(0,0,0,0.1);
  /*z-index:3;*/
  /*cursor: pointer;*/
  /*overflow:hidden;*/
  /*background-color: #eee;*/
  top:25px;
}

.card *
{
  pointer-events: none;
}

.card--left
{
  transform:scale(0.75) translate(-377px,0px) perspective( 750px ) rotateY(25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:2;
}
.card--left-left
{
  transform:scale(0.75) translate(-670px,0px) perspective( 750px ) rotateY(25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:1;
}

.card--center
{
  transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg);
  box-shadow:0 30px 50px rgba(0,0,0,0.1);
  z-index:3;
}

.card--right
{
  transform:scale(0.75) translate(377px,0px) perspective( 750px ) rotateY(-25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:2;
}
.card--right-right
{
  transform:scale(0.75) translate(670px,0px) perspective( 750px ) rotateY(-25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:1;
}

.card__icon
{
  width:30%;
  height:100%;
  background:rgba(255,255,255,0.5);
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card__icon:before
{
  content:attr(data-icon);
  font-size:3rem;
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100px;
  height:100px;
  border-radius: 50px;
  background:rgba(255,255,255,1);
}

.card__detail
{
  flex:1;
  display:flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width : 736px)
{
  .cards
  {
    flex-direction:column;
    margin:auto 0;
  }

  .card
  {
    display:flex;
    width:90%;
  }

  .card--left
  {
    transform:scale(0.75) translate(0px, -150px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
  }

  .card--center
  {
    transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg)  rotateX(0deg) translateZ(5px);
  }

  .card--right
  {
    transform:scale(0.75) translate(0px, 150px) perspective(750px) rotateY(0) rotateX(10deg) translateZ(-5px);
  }

  .card__icon:before
  {
    transform:scale(0.75);
  }
}
@media(max-width : 768px)
{
  .cards
  {
    flex-direction:column;
    margin:auto 0;
  }

  .card
  {
    display:flex;
    width:90%;
  }
  .hrnewclass{
    margin-left: 309px ! important;
  }
  .upstreamtext{
    font-size: 13px ! important;
  }
  .upstream_tab h3{
    font-size: 21px ! important;
  }

  /*.card--left
  {
    transform:scale(0.75) translate(1px,-150px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
  }

  .card--center
  {
    transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg)  rotateX(0deg) translateZ(5px);
     height: 440px;
  }

  .card--right
  {
    transform:scale(0.75) translate(0px, 150px) perspective(750px) rotateY(0) rotateX(10deg) translateZ(-5px);
     height: 440px;
  }*/

  .card__icon:before
  {
    transform:scale(0.75);
  }
  .card--right-right{
    transform: scale(0.75) translate(370px,0px) perspective( 750px ) rotateY(-25deg);

  }
}
.upstreamcontentheader h1{
  font-size: 41px;
}

.upstreamcontentheader hr{
  height: 2px;
  width: 100px;
  border: 1px solid #f19000;
  background-color: #f19000;
}
/*.card--left-left {
    transform: scale(0.75) translate(218px,0px) perspective( 750px ) rotateY(-18deg);
     height: 440px;
    }
    .card--right {
    transform: scale(0.75) translate(-202px, -26px) perspective(750px) rotateY(0) rotateX(4deg) translateZ(-5px);
     height: 440px;
}
.card--left {
    transform: scale(0.75) translate(-389px,-10px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
    height: 440px;
    }
*/


.upstreamfoot{
  pointer-events: auto!important;
  cursor: pointer;
  background: #f19000;  
}

.upstreamblock{
  height: 600px;
  padding: 50px 100px;
  margin-bottom:50px;


}
.upstreamblock_new{
  height: auto;
  padding: 60px 100px;
  margin-bottom:60px;
}

.owl-theme .owl-nav{
  /*background-color: red;*/
  /*margin-top: -300px!important;*/
}
.newupstreamTitle{
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  /*margin-left: 135px;*/
}
.newblogsTitle{
  color: #009de2;
  font-size: 40px;
  font-weight: 700;
  /*margin-left: 135px;*/
}
.hrnewclass{
    height: 3px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
    margin-bottom: 12px;
    margin-left: 587px;

}
.hrnewclassblog{
    height: 3px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
    margin-bottom: 12px;
    margin-left: 587px

}
@media screen and (max-width: 600px) {
  .upstreamblock_new{
    margin-bottom: 10px ! important;
    padding-top: 20px ! important; 
  }
}



.B2T1_line{


}
.innerBlocktepmlate1{
	height: auto;
}
.B2T_overviewTitle{
	  /*top: 53px;
    left: 609px;
    position: absolute;*/
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
    margin-top: 25px;
}


.B2T_p{
    padding-right: 45px;
	/*position: absolute;
    top: 154px;*/
    font-size: 17px;
}
.B2T_overviewTxt{
    height: 350px;

	/*height: 260px;*/
	/*margin-top: 150px;*/
}
.B2T_overviewTxt img{
    /*height: 100%;*/
}
.B2T_btn{
 position: absolute;
 top:350px;

}
.readmore{
	position: absolute;
    top: 360px;
    left: 130px;
    color: #ffffff;
}
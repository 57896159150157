.whatsupIcon{
  font-size: 19px !important;
  padding: 9px 11px !important;
}
.whatsupIcon:hover{
  background-color: #fff;
  color:#005b99;
}
.linkeninicon:hover{
  background-color: #fff;
  color:#005b99;
}
.twittericon:hover{
  background-color: #fff;
  color:#005b99;
}
.socialicon li a .fa:hover{
  color:#005b99;
}

.animationoveflow{
  overflow: hidden;
/*  width: 100%;
  top: 900px;
*/}

 .pt20{
      padding-top: 20px ! important;
    }
.footer-two{
 font-size: 16px;
 color: #fff ! important;
 /*background-color: #367588;*/
 /*border-top: 1px solid #eee;*/
 height: 335px;
 padding-top: 35px ! important;
 padding-bottom: 10px;
 padding-right: 0px;
}
.footer-two a{
color: #fff;

}
.no-icon-list li{
  padding-bottom: 10px;
}
.footer-end a{
color: #fff;

}
.footer-end{
    height: 55px!important;

}
.footer-two ul{
    list-style: none;
    text-decoration: none;

}
.footer-two ul li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    font-size: 15px;

}
.footer-two ul li a:hover{
    color: #42E8E0;

}
.footer-two p a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    font-style: normal;

}
.footer-two p a:hover{
    color: #42E8E0;
}
.bb-solid-1{
    /*border-bottom: 1px solid #eee;*/
}

.font-montserrat{
    /*font-family: 'Montserrat', sans-serif;*/
    font-size: 16px;
    font-weight: bold;
}
ul.no-icon-list {
    margin: 0;
    padding: 0;
}
.social.social-two a {
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    text-align: center;
    border: 1px solid #ddd;
    float: left;
    display: block;
    margin-right: 5px;
    border-radius: 2px;
}


.footer-two ul{
    list-style: none;
    text-decoration: none;

}
.footer-two ul li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;

}
.socialicon1 {
  margin-right: 34px ! important;
}
.socialicon li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    float: left;
    margin-right: 11px;

}
.socialicon1 li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    float: right;
    margin-right: 19px;
    margin-top: 15px;

}
.socialicon li a .fa{
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 11px 11px;
    /*background-color: #fff;*/
    color: #fff;
    font-size: 16px;
}
.facebook{
    padding: 11px 14px !important;
}
.facebook:hover{
  background-color: #fff;
  color:#005b99;
}
.footer-two ul li a:hover{
    /*color: #000;*/

}
.footer-two p a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    /*font-family: 'Open Sans', sans-serif !important;*/
    font-style: normal;

}
.footer-two p a:hover{
    /*color: #000;*/
}
.bb-solid-1{
    /*border-bottom: 1px solid #eee;*/
}

.font-montserrat{
    /*font-family: 'Montserrat', sans-serif;*/
}
ul.no-icon-list {
    margin: 0;
    padding: 0;
}
ul.no-icon-list1 {
    margin: 0;
    padding: 0;
}
.social.social-two a {
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    text-align: center;
    border: 1px solid #ddd;
    float: left;
    display: block;
    margin-right: 5px;
    border-radius: 2px;
}
.subscribeMail{
  border-radius: 3px;
  padding-left:22px;
  color: #555;
  height: 40px;
}
.subscribeBox{
    /*background-color: #fff;*/
    color: #fff;
    margin-top: 70px;
    width: 100%;
    /*border-radius: 20px;*/
    font-weight: 700;
    padding: 1px 5px;
    height: 80px;
}
.subscribeBox1{
  margin-top: 0px ! important;
}
.subscribeBtn{
  height: 41px;
  border-radius: 3px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  left:-3px;
  background-color: #f19000;
}
.footeranimation{
    position: absolute;
    z-index: 1;
    /*top: 0px;*/
    /*left: 0px;*/
    /*height: 60px;*/
}

.footimg1{
    position: relative;
    bottom: 59px;
    left: 39px;
    height: 60px;
}
.footimg7{
    position: relative;
    bottom: 97px;
    left: 985px;
    height: 100px;
}
.footimg5{
    position: relative;
    bottom: 106px;
    left: 619px;
    height: 109px;
}
.footimg4{
    position: relative;
    bottom: 49px;
    left: 485px;
    height: 50px;
}
.footimg3{
    position: relative;
    bottom: 49px;
    left: 330px;
    height: 50px;
}
.footimg2{
    position: relative;
    bottom: 40px;
    left: 141px;
    height: 43px
}
.footeranimationtruck{
    position: relative;
    top: 59.5px;
    left: -100px;
    z-index: 100;
    animation: truck1 25s linear infinite;
}

.foot8wheel1{
 position: relative;
    right: 85px;
    top: 9px;
  animation:  wheel 0.4s linear  infinite;
}
.foot8wheel2{
  position: relative;
    right: 42px;
    top: 9px;
  animation:  wheel 0.4s linear  infinite;
}

 .foot6wheel1{
  position: relative;
    left: 9px;
    bottom: 14px;
  animation:  wheel 0.4s linear  infinite;
}
.foot6wheel2{
  position: relative;
   left: 13px;
    bottom: 14px;
  animation:  wheel 0.4s linear  infinite;
}

.foot6wheel3{
  position: relative;
   left: 48px;
    bottom: 14px;
  animation:  wheel 0.4s linear  infinite;
}

.footeranimationtruck2{
    position: relative;
    top: 17px;
    left: -650px;
    z-index: 100;
    animation:  truck2 30s linear infinite;

}

.viman img{
    position: relative;
    top: 11px;
    left: -200px;
    z-index: 100;
    animation: viman 15s linear infinite;
}

.foot1anim{
    position: relative;
   left: 38.5px;
    bottom: 101px;
    overflow: hidden;

}
.foot1anim img{
  margin-top: -10px;
  animation:  foot1anim 2s linear  infinite;

}

.foot3anim{
    position: relative;
    left: 332px;
    bottom: 116px;
}
.foot3anim img{
  animation:  foot3anim 4s linear  infinite;

}

@keyframes foot3anim {
  
  0% {
  transform: rotate(0deg);
  }
  50% {
  transform: rotate(8deg);
  }
  
}

@keyframes foot1anim {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}


@keyframes viman {
  
  0% {
    left:-200px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes truck1 {
  
  0% {
    left:-100px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes truck2 {
  
 0% {
    left:-650px ; 
  }
  100% {
   left:1500px ;
  }
  
}

.footoverflow{
    width: 100%;
    overflow: hidden;
}


/*mob view*/
.responsivefooter{
  height: 600px ! important;

}
.resicons{
  /*margin-left: 35px;*/
}
.resNewsLetter{
  font-size: 15px ! important;
  padding-left: 161px ! important;
  padding-top: 94px ! important; 
}
.ressubscribeMail{
  margin-left: 35px;
}
.ressubscribeBtn{
  margin-left: 54px;
}
.resfooterend{
  position: absolute;
  height: 65px ! important;
  /*width: 345px;*/
}
.resmr20{
  /*font-size: 11px ! important;*/
}
.footerdivs{
  width: 180px;
  padding-left: 70px;
  letter-spacing: 1px;
}
.Hidden_div{
  height: 8px;
}
.footermailicon{
  font-size: 9px;
  color: #fff;
  padding-right: 5px;
}
.iconPadding{
  padding-right: 10px;
}
.pb351{
  margin-left: 55px;
}
.footerdiv1{
  width:140px;
  letter-spacing: 1px;
}
.footerText{
  color:#ffffff ! important;
}


/* Safari only */ 
.safari-col-4:not(:root:root) { 
	width:25% !important;
}
.newAddress{
  /*margin-bottom: 0px;*/
  letter-spacing: 1px;
  padding-left: 0px;
}
.newAddress1{
  margin-top: 10px;
  padding-left: 0px;
  letter-spacing: 1px;
}
.newaddressdiv{
  padding-left: 33px ! important;
}

@media screen and (max-width: 801px) {
  .fs15{
    margin-left: 20px;
    font-size: 13px ! important;
    }
    .font-montserrat{
      font-size: 14px ! important;
    }
    .connectUsdiv{
     margin-top: 20px ! important;
    margin-left: 10px ! important;
    }

  
}

.connectUsDiv{
  /*padding-left: 70px;*/
}
@media (min-width:600px)){
  

}
@media (min-width:320px) {
   .responsivefooter{
    height: 600px ! important;
  }


}
@media only screen 
  and (device-width: 1024px)
 {
    .footer-two{
      height: 340px ! important;
    }
}

@media only screen and (device-width: 1024px)
 {
    .footer-two{
      height: 438px ! important;
    }
     .footerdiv1{
    padding:0px ! important;
  }
}


@media only screen and (device-width: 1024px) {
    .pt20{
      padding-top: 20px ! important;
    }
    .blogtext p{
      height: 185px ! important;
    }
    .journytextblock{
      height: 129px ! important;
    }
    .Journycaro9{
      top:1145px ! important;
    }
    .add10{
      left: 128px ! important;
      top: -67px ! important;
    }
    .add9{
      left:172px ! important;
      top:157px ! important;
    }
    .Journycaro10{
      left:285px ! important;
    }
    .newcard_HD{
      width: 220px ! important;
    }
    .navigation_tab{
      margin-left: 25px ! important;
    }.new_wizardNotefor9{
      right: 0px ! important;
    }
  } 
  @media only screen and (device-width: 1024px) {
     .footer-two{
    height: 440px ! important;
  }
  .topnav a{
        padding: 14px 7px;
  }
  .footerdiv1{
      padding: 0px;
    }
}
@media only screen and (device-width: 1024px){
    .padding_640{
      margin-left: 0px ! important;
    }
    .footerdiv1{
      padding: 0px;
    }
    .pt20{
      padding-top: 20px;
    }

}
@media only screen and (device-width: 1024px) {
     .Journycaro9{
      left:-25px;
     }
     .Journycaro4{
      top: 681px;
     }
  }
 
  @media only screen and (device-width : 375px) and (device-height : 812px) { 
  .responsivefooter{
    height: 592px ! important;
  }
}  
@media only screen and (device-width : 393px) and (device-height : 851px) { 
  .responsivefooter{
    height: 593px ! important;
  }
} 
@media only screen and (device-width : 414px) and (device-height : 736px) { 
  .responsivefooter{
    height: 580px ! important;
  }
}  
 @media only screen and (device-width : 320px) and (device-height : 533px) { 
  .responsivefooter a{
    font-size: 12px ! important;
  }
  .footer-two ul li a{
    line-height: 20px;
  }
 }
@media only screen and (device-width : 2560px){
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 2288px ! important;
  }
  .footimg5{
    left:1900px ! important;
  }
  .footimg4{
    left:1668px ! important;
  }
  .footimg3{
   left:1430px ! important;
  }
  .foot3anim{
    left:1095px ! important;
  }
  .footimg2{
    left:675px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    /*padding-left: 250px ! important;*/
  }
  .newaddressdiv{
    padding-left: 325px ! important;
  }
}
@media only screen and (device-width : 2880px) {
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 2288px ! important;
  }
  .footimg5{
    left:1900px ! important;
  }
  .footimg4{
    left:1668px ! important;
  }
  .footimg3{
   left:1430px ! important;
  }
  .foot3anim{
    left:1095px ! important;
  }
  .footimg2{
    left:675px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    padding-left: 250px ! important;
  }
  .newaddressdiv{
    padding-left: 325px ! important;
  }
}
@media only screen and (device-width : 2048px) and (device-height : 1536px) {
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 1780px ! important;
  }
  .footimg5{
    left:1400px ! important;
  }
  .footimg4{
    left:1468px ! important;
  }
  .footimg3{
   left:1130px ! important;
  }
  .foot3anim{
    left:895px ! important;
  }
  .footimg2{
    left:352px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    /*padding-left: 250px ! important;*/
  }
  .newaddressdiv{
    padding-left: 195px ! important;
  }
}
@media only screen and (device-width : 1152px) { 
  .footer-two{
    height: 398px ! important;
  }
  .newaddressdiv{
    padding-left: 25px ! important;
  }
  .footerdiv1{
    padding:0px ! important;
  }
}
@media only screen and (device-width : 1280px) { 
  .footer-two{
    height: 375px ! important;
  }
  .pt20 {
    padding-top: 18px ! important;
  }
  .newaddressdiv{
    padding-left: 25px ! important;
  }
  .footerdiv1{
    padding:0px ! important;
  }

}
@media only screen and (device-width : 1920px) { 
  .footer-two{
    height: 375px ! important;
  }
  .pt20 {
    padding-top: 18px ! important;
  }
  .newaddressdiv{
    padding-left: 100px ! important;
  }

}


.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}


.servicebg1{
	background-image: url("/images/serviceback1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}
.servicebg2{
	background-image: url("/images/serviceback2.png");
	background-size: 100% 70%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}
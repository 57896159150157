.flip-card {
  background-color: transparent;
  width: 300px;
  height: 335px;
  perspective: 1000px;
  border-radius: 15px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 15px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  transition-duration:1.5s;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
}

.flip-card-front {
  background-color: #eee;
  color: black;
  border-radius: 15px;
    border-radius: 15px;

}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
  border-radius: 15px;
}

.reparebtn{
  font-size: 13px;
  background-color: #7B943F;
  border-radius: 0px;
  padding: 17px 45px;
  color: #fff;


}

.webclientbackimg{
    color: #333;
    font-size: 15px;
    font-weight: 500;
    /*box-shadow: 0 10px 29px rgba(92,141,255,.22);*/
    border-radius: 8px;
    width: 677px;
}


.webclientcaro h3{
  /*text-align: left;*/
  font-weight: bold;
}
.webclientcaro{
  height: 137px;
  width: 385px;
}

@media only screen and (device-width : 1440px){
  
  
}
@media screen and (min-width: 1600px) {
/*  .Journycaro1{
    left:1138px !important;
  }
  .add1{
    left:936px ! important;
  }

  
  .Journycaro2{
    left:874px !important;
  }
  .add2{
    right: -49px ! important;
  }
  .Journycaro3{
    left:648px !important;
  }
  .add3{
    left:990px !important;
  }
  .Journycaro4{
    left:1337px ! important;
  }
   .add4{
    left:613px ! important;
    top:265px ! important;
  }
  .add6{
    left:715px ! important;
  }
  .Journycaro7{
    left:1028px ! important;
  }
   .add7{
    top:1238px ! important;
    left:243px ! important;
  }
  .Journycaro8{
    top:1339px ! important;
    left:1280px ! important;
  }
  .Journycaro9{
    left:482px ! important;
  }
  .add9{
    left: 817px ! important;
  }
  .add8{
    right:-827px ! important;
  }*/
  
}
@media screen and (min-width: 1900px) {
 .Journycaro1{
    /*left:1138px !important;*/
  }
 /* .add1{
    left:936px ! important;
  }*/
  .Journycaro2{
    /*left:874px !important;*/
  }
 /* .add2{
    right: -49px ! important;
  }*/
  .Journycaro3{
    /*left:648px !important;*/
  }
 /* .add3{
    left:990px !important;
  }*/
  .Journycaro4{
    /*left:1337px ! important;*/
  }
 /* .add4{
    left:613px ! important;
    top:265px ! important;
  }*/
 /* .add6{
    left:715px ! important;
  }*/
  .Journycaro7{
    /*left:1028px ! important;*/
  }
  /*.add7{
    top:1238px ! important;
    left:243px ! important;
  }*/
  .Journycaro8{
    /*top:1339px ! important;
    left:1280px ! important;*/
  }
   .Journycaro9{
    /*left:482px ! important;*/
  }
  
 /* .add9{
    left: 817px ! important;
  }
  .add8{
    right:-827px ! important;
  }*/
  
}
.para10{
  position: relative;
  top: 800px;
  transform: rotate(20deg);
  transition-duration: 2s;

}

.para11{
  position: relative;
  top: 800px;
  transform: rotate(-20deg);
  transition-duration: 2s;

}
.para12{
  position: relative;
  top: 800px;
  transform: rotate(-20deg);
  transition-duration: 2s;

}
.paraeffect{
  position: relative;
  top :0px;
  transform: rotate(0deg);
  transition-duration: 0.5s;
  /*margin-inline-start: 45px;*/
  /*margin-inline-end: 28px;*/
}
.paraeffect12{
  position: relative;
  top :0px;
  transform: rotate(0deg);
  transition-duration: 0.5s;
}
/*
.para12{
	opacity: 0;
	transition-duration: 2s;
}*/
.para12effect{
	opacity: 1;
	transition-duration: 1.5s;
}
.downTitle{
  font-size: 19px;
  cursor: pointer;
}
.streamImg{
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
 
  .divinportrait{
    width:200px ! important;
  }
}
@media (max-width: 480px){
     .divinportrait{
    width:206px ! important;
  }
  .paraeffect{
    margin-inline-start:0px ! important;
  }
  .contentWraper{
    width: 100% ! important;
  }
  .contentheader h2{
    font-size: 28px ! important;
    padding:15px ! important; 
  }
  .para10{
    width: 235px ! important;
  }
  .para11{
    width: 235px ! important;
  }
 }   

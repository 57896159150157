

.scrollwrap {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 250px;
  left: 650px;
  z-index: 1000;
}

.scrollanim{
  top: 670px;
  opacity: 0;
  transition-duration: 3s;
}
.journytextblock{
  padding-top: 15px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}


.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #009de2;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #009de2;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.journybg {
    background-image: url(/images/journy1.png);
    background-size: 65% 100%;
    background-repeat: no-repeat;
    height: 400px;
    margin-top: 150px;
}
.journybg1{
    background-image: url(/images/journy2.png);
    background-size: 75% 90%;
    background-repeat: no-repeat;
    height: 150px;
}
.journybg2{
    background-image: url(/images/journy3.png);
    background-size: 30% 100%;
    background-repeat: no-repeat;
    height: 80px;
}
.journybg3{
    /*background-image: url(/images/journy7.png);*/
    background-size: 70% 100%;
    background-repeat: no-repeat;
    height: 267px;
    margin-top: 150px;
}
.journybg31{
    /*background-image: url(/images/journy7.png);*/
    background-size: 70% 100%;
    background-repeat: no-repeat;
    height: 267px;
}
.journybg4{
    /*background-image: url(/images/journy5.png);*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 700px;
}
.journybg5{
    /*background-image: url(/images/journy4.png);*/
    background-size: 17% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg6{
    /*background-image: url(/images/journy6.png);*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg7{
    background-image: url(/images/journy6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg8{
    background-image: url(/images/journy6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journytextblock{
  width: 70%;
  height: 85px;
  margin-top: 110px;

}
.journytextblock h1{
font-size: 46px;
color: #fff;
}

.roadjourny{
  position: absolute;
  z-index: 3;
  height: 0px;
  background: transparent;
  overflow: hidden;
  max-height: 1590px;
  /*left: -95px;*/
  right: 0px;
}
.roadjournyoverflow{
  overflow: none!important;
  height: 1590px!important;
  transition-duration: 10s;

}
.roadjourny img{
  position: relative;
  overflow: hidden;
  left: 155px;
}

.add1{
  position: relative;
    left: 550px;
    z-index: 100;
    height: 75px;
    top: 248px;
    opacity: 0;
}

.add2{
    position: relative;
    right: 141px;
    z-index: 100;
    height: 75px;
    top: 414px;
    /*display: none;*/
    opacity: 0;
}
.add3{
    position: relative;
    left: 651px;
    z-index: 100;
    height: 75px;
    top: 270px;
    opacity: 0;
}
.add4{
  position: relative;
    left: 259px;
    z-index: 100;
    height: 75px;
    top: 178px;
    opacity: 0;
}
.add5{
  position: relative;
    right: 282px;
    z-index: 100;
    height: 75px;
    top: 719px;
    opacity: 0;
}
.add6{
  position: relative;
    left: 167px;
    z-index: 100;
    height: 75px;
    top: -121px;
    opacity: 0;
}
.add7{
    position: relative;
    left: 854px;
    z-index: 100;
    height: 75px;
    top: 227px;
    opacity: 0;
}
.add8{
  position: relative;
    right: -460px;
    z-index: 100;
    height: 75px;
    top: 72px;
    opacity: 0;
}
.add9{
  position: relative;
    left: 274px;
    z-index: 100;
    height: 75px;
    top: 219px;
    opacity: 0;
}
.add10{
    position: relative;
    left: 81px;
    z-index: 100;
    height: 75px;
    top: 3px;
    opacity: 0;
}
.add11{
  position: relative;
    left: 266px;
    z-index: 100;
    height: 75px;
    top: 619px;
    opacity: 0;
}
.add12{
  position: relative;
    left: 266px;
    z-index: 100;
    height: 75px;
    top: 819px;
    opacity: 0;
}

.addanim{
  opacity: 1;
  animation:  addanim 1.5s linear  ;

}

@keyframes addanim {
  
  0% {
  transform: rotateY(0deg);
  transform: scale(1.5);
  }
  50% {
  transform: rotateY(360deg);
  }
  
}
.Journycaro1 p {
  text-align: right;

}
.Journycaro1 h3 {
  text-align: right;

}
.Journycaro2 p {
  text-align: right;

}
.Journycaro2 h3 {
  text-align: right;

}
.Journycaro3 p {
  text-align: right;

}
.Journycaro3 h3 {
  text-align: right;

}
.Journycaro7 p {
  text-align: right;

}
.Journycaro7 h3 {
  text-align: right;

}
.Journycaro9 p {
  text-align: right;

}
.Journycaro9 h3 {
  text-align: right;

}

.Journycaro4 p {
  text-align: left;

}
.Journycaro4 h3 {
  text-align: left;

}
.Journycaro5 p{
  text-align: left;
}
.Journycaro5 h3{
  text-align: left;
}
.Journycaro8 p{
  text-align: left;
}
.Journycaro8 h3{
  text-align: left;
}
.Journycaro1{
    z-index: 1000;
    position: absolute;
    top: 230px;
    left: 600px;
    opacity: 0;
}
.Journycaro2{
    z-index: 1000;
    position: absolute;
    top: 346px;
    left: 308px;
    opacity: 0;
}
.Journycaro3{
    z-index: 1000;
    position: absolute;
    top: 506px;
    left: 210px;
    opacity: 0;
}
.Journycaro4{
    z-index: 1000;
    position: absolute;
    top: 705px;
    left: 706px;
    opacity: 0;
}
.Journycaro5{
   z-index: 1000;
    position: absolute;
    top: 860px;
    left: 930px;
    opacity: 0;
}
.Journycaro6{
   z-index: 1000;
    position: absolute;
    top: 785px;
    left: 263px;
    opacity: 0;
}
.Journycaro7{
    z-index: 1000;
    position: absolute;
    top: 1054px;
    left: 486px;
    opacity: 0;
}
.Journycaro8{
    z-index: 1000;
    position: absolute;
    top: 1298px;
    left: 889px;
    opacity: 0;
}
.Journycaro9{
    z-index: 1000;
    position: absolute;
    top: 1191px;
    left: -30px;
    opacity: 0;
}
.Journycaro10{
    z-index: 1000;
    position: absolute;
    top: 1463px;
    left: 372px;
    opacity: 0;
}
.Journycaro11{
    z-index: 1000;
    position: absolute;
    top: 2000px;
    left: 372px;
    opacity: 0;
}
.Journycaro12{
    z-index: 1000;
    position: absolute;
    top: 1438px;
    left: 372px;
    opacity: 0;
}

.journyanim{
  opacity: 1;
  transition-duration: 0.5s;
}
.posrelative{
  position: relative;
  height: 750px;
  top: 250px;
}
#contactForm1{
 height: 500px;
 position: relative;
}
.newcommentbox1{
  height: 70px ! important;

}


/*previous toggle*/
react style.css
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

///////////////////////////////////////////////////////////////////////////////////

2nd css
a.nameParts{
  text-transform: capittialize;

}
.toUpper{
  text-transform: uppercase;
}
.display_map_sk{
  margin-top: 30px;
  padding-top: 30px;
  border-radius: 3px;
  border: 1px solid #e5e7e9;
  margin-left: 30px;
  box-shadow: 0 0 10px #ccc;
}

.heading_sk{
  font-size: 18px;
  padding-left: 20px;
}
.stars{
  padding-bottom: 50px;
}
.up_skill{
  padding-bottom: 20px;
}

.togle_position{
  /*padding-left: 450px;*/
}

.ht40{
  height: 33px;
}
.ht55{
  height:55px;
}
.h67{
  height:57px;
}
.ht90{
  height: 140px;
}
.skill_label{
  margin-top: -66px;
  margin-left: 7px;
}
.toggle_fix{
  padding-right: 40px;
  margin-top:-4px;
}
.skillRow{
  margin-top: 20px;
}
.rate_label{
  margin-left: 61px;
}

.ht105{
  height: 105px;
}

.description{
  border: 1px solid #979797 !important;
  margin-bottom: -12px;
  border-radius: 3px;
}
.skillsRow {
    margin-bottom: 30px;
}
.description:hover{
  border: 1px solid #33B5E5 !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
}

.description_label{
  /*margin-top: -70px;*/
}
.noMargin {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}
.skillForm{
  margin-top: 20px;
}
#size{
  width: 50px;
}
.skill_btn{
  margin-top: 50px;
}
.form_heading5{
  font-size: 20px;
  padding-left:15px;
  margin-top: 20px;
  }

.move_left{
  margin-left: 10px;
  height: 100px;
}
.switch2 {
  position: relative;
  height: 28px;
  width: 192px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.labelSwitch{
  margin-top: 10px;
}
.toggleSwitch{
  margin-left: px;

}
.mappp_bnt{
  margin-left: 140px;
}
.star{
  color: #ff0;
  font-size: 14px;
}
.descr_move{
  margin-left: 6px;
}
.toggelStop{
  margin-top: 0px!important;
  margin-left: 0px!important;
}
.switch {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label:active {
/*  font-weight: bold;
*/  background-color: transparent;
}

.switch-label-off {
  padding-left: 2px;
}

.switch-label-on {
  padding-right: 2px;
}


.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
 
  color: #e3e3e3;
 
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: color, text-shadow;
  -moz-transition-property: color, text-shadow;
  -ms-transition-property: color, text-shadow;
  -o-transition-property: color, text-shadow;
  transition-property: color, text-shadow;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 75px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4fc9ee), color-stop(100%, #3aa2d0));
  background-image: -webkit-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -moz-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -ms-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -o-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
}
.switch1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.switch-label1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1:active {
/*  font-weight: bold;
*/  background-color: transparent;
  color: #F7F7F7;
}

.switch-label-off1 {
  padding-left: 2px;
}

.switch-label-on1 {
  padding-right: 2px;
}


.switch-input1 {
  display: none;
}
.switch-input1:checked + .switch-label1 {
 
  color: #e3e3e3;

  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: color, text-shadow;
  -moz-transition-property: color, text-shadow;
  -ms-transition-property: color, text-shadow;
  -o-transition-property: color, text-shadow;
  transition-property: color, text-shadow;
}
.switch-input1:checked + .switch-label-on1 ~ .switch-selection1 {
  left: 70px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.move_left{
  margin-left: 8px;

}

.showSkill{
  display: block;
}
.hideSkill{
  display: none;
}
.showCerti{
  display: block;
}
.hideCerti{
  display: none;
}


.switch-selection {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4fc9ee), color-stop(100%, #3aa2d0));
  background-image: -webkit-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -moz-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -ms-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: -o-linear-gradient(top, #4fc9ee, #3aa2d0);
  background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
}


/* switch2*/

.switch1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
  /*-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);*/
  /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3);*/
}


.switch-label1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 92px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1:active {
/*  font-weight: bold;
*/  background-color: transparent;
  color: #F7F7F7;
}

.switch-label-off1 {
  padding-left: 2px;
}

.switch-label-on1 {
  padding-right: 2px;
}


.switch-input1 {
  display: none;
}
.switch-input1:checked + .switch-label1 {
 
  color: #e3e3e3;

  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: color, text-shadow;
  -moz-transition-property: color, text-shadow;
  -ms-transition-property: color, text-shadow;
  -o-transition-property: color, text-shadow;
  transition-property: color, text-shadow;
}
.switch-input1:checked + .switch-label-on1 ~ .switch-selection1 {
  left: 100px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 92px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.move_left{
  margin-left: 8px;

}
.display_map{
  border: 1px solid #060a0e42;
  margin-top: 79px;
  padding-top: 30px;
  width: 95%;
  margin-left: 21px;
 
}
.showSkill{
  display: block;
}
.hideSkill{
  display: none;
}
.showCerti{
  display: block;
}
.hideCerti{
  display: none;
}


.can-toggle {
  position: relative;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle__switch {
  margin-left: 0px !important;
}


.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    color: #ffffff !important;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 13px;
  top:0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
 
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;

  text-align: center;
  background: white;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #969899;
}

.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0, 1, 0.9, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
          transform: translate3d(65px, 0, 0);
}


.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}
.can-toggle__switch{
  margin-left: 400px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  /*box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #b53e74;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #8f315c;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  transition: background-color 0.9s ease-in-out;
  background: #c14b81;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  transition: -webkit-transform 0.9s ease-in-out;
  transition: transform 0.9s ease-in-out;
  transition: transform 0.9s ease-in-out, -webkit-transform 0.3s ease-in-out;
  color: #b53e74;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled] ~ label {
  color: rgba(68, 68, 68, 0.5);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #ffffff !important;
  width: 300px !important;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #2E3951;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  background: #666;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: #969899;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1),
   -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #ffffff;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-2 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(122px, 0, 0);
          transform: translate3d(122px, 0, 0);
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 37px;
  flex: 0 0 120px;
  border-radius: 60px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 60px;
  font-size: 13px;
  line-height: 28px;
  width: 60px;
  padding: 0 12px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 3px;
  left: 2px;
  border-radius: 30px;
  width: 58px;
  line-height: 25px;
  font-size: 13px;
}

.can-toggle {
  position: relative;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 13px;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;

  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
 
  text-align: center;
  background: #039BE5;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #ffffff;
}


.can-toggle label .can-toggle__label-text {
  flex: 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0, 1, 0.9, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
          transform: translate3d(65px, 0, 0);
}

.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}



.can-toggle.can-toggle--size-large input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(78px, 0, 0);
          transform: translate3d(78px, 0, 0);
}

.can-toggle.can-toggle--size-large label {
  font-size: 14px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 50px;
  flex: 0 0 160px;
  border-radius: 4px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 80px;
  font-size: 14px;
  line-height: 50px;
  width: 80px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 78px;
  line-height: 46px;
  font-size: 14px;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}


.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #44ae7f;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #368a65;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  transition: background-color 0.9s ease-in-out;
  background: #c14b81;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  color: #b53e74;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled] ~ label {
  color: rgba(68, 68, 68, 0.5);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #75d32d;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #666;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  background: #ffffff;
  border: 1px solid #979797;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: #2E3951;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #ffffff !important;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(122px, 0, 0);
          transform: translate3d(122px, 0, 0);
}

.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 30px;
  flex: 0 0 234px;
  font-weight: 800;
  border-radius: 60px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 120px;
  font-size: 13px;
  line-height: 28px;
  width: 60px;
  padding: 0 12px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 30px;
  width: 106px !important;
  line-height: 25px;
  font-size: 13px;
}

.rating {
    float:left;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input,
.rating1:not(:checked) > input {
    position:absolute;
    top:-9999px;
    clip:rect(0,0,0,0);
}

.rating:not(:checked) > label, .rating1:not(:checked) > label {
    float:right;
    width:1em;
    padding:0 .1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:200%;
    line-height:1.2;
    color:#ddd;
    /*text-shadow:1px 1px #bbb, 2px 2px #666, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating:not(:checked) > label:before,.rating1:not(:checked) > label:before {
    content: '★ ';
}

.rating > input:checked ~ label, .rating1 > input:checked ~ label {
    color: #f90;
    /*text-shadow:1px 1px #c60, 2px 2px #940, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label{
  color: gold;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}


.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ea0;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating > label:active {
  position:relative;
  top:2px;
  left:2px;
}

/* end of Lea's code */

/*
 * Clearfix from html5 boilerplate
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ea0;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}


/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

/* my stuff */
#status, button {
    margin: px 0;
}


.genderbtn.demo-rebrand-2 label .can-toggle__switch{
  height: 30px;
    flex: 0 0 150px;
    border-radius: 60px;

}


/*basic csss*/

.imgProfile{

  width: 150px;
}
.form_heading{
  font-size: 16px;
  padding-left:30px !important;
  margin-bottom: 20px;
  font-weight: 100px;
}
.pd_btm_all{
  padding-bottom: 8px;
}
.Attach_data{
  height: 180px;
  background-color: #eee;
}
.basic_btn{
  margin-top: 100px;
}
.drag_data{
  font-size: 12px;
  padding-top: 70px;
  padding-left: 25px;
}

.back_input{
  background-color: #fff !important;
}
.move_label{
  margin-left: 50px;
}

.img_1st{
  margin-top: 5px;
}

.border_none{
  border:none;
}
.labelSwitch_k1{
  margin-top: 10px;
}
.toggleSwitch_k1{
  margin-left: px;

}
.toggelStop_k1{
  margin-top: 0px!important;
  margin-left: 0px!important;
}

.switch1_k1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
  /*-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);*/
  /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3);*/
}
 
.set_status{
  border: none;
  background-color: #fff;
}
.input_status{
  background-color: #fff;
}
.switch-label1_k1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  text-align: center;
  line-height: 26px;
  font-size: 11px;
  /*color: rgba(255, 255, 255, 0.35);*/
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1_k1:active {
  font-weight: bold;
  background-color: transparent;
}

.switch-label-off1_k1 {
  padding-left: 2px;
}

.switch-label-on1_k1 {
  padding-right: 2px;
}


.switch-input1_k1 {
  display: none;
}
.switch-input1_k1:checked + .switch-label1_k1 {
 
  color: #e3e3e3;
 
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: color, text-shadow;
  -moz-transition-property: color, text-shadow;
  -ms-transition-property: color, text-shadow;
  -o-transition-property: color, text-shadow;
  transition-property: color, text-shadow;
}

.switch-input1_k1:checked + .switch-label-on1_k1 ~ .switch-selection1_k1 {
  left: 75px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1_k1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
 /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9dd993), color-stop(100%, #65bd63));
  background-image: -webkit-linear-gradient(top, #9dd993, #65bd63);
  background-image: -moz-linear-gradient(top, #9dd993, #65bd63);
  background-image: -ms-linear-gradient(top, #9dd993, #65bd63);
  background-image: -o-linear-gradient(top, #9dd993, #65bd63);
  background-image: linear-gradient(top, #9dd993, #65bd63);*/
  /*-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);*/
  /*box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);*/
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
 



 
/* switch2*/


.rating {
    float:left;
}
/*------------------*/

.genderbtn.demo-rebrand-2 label .can-toggle__switch:after{
   top:2px ! important;
   left: 4px ! important;
   width: 70px !important;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:before{
  left:66px;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:after{
  line-height: 24px;
}
.genderbtn.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after
{
  transform: translate3d(74px, 0, 0) !important;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:before {
  line-height: 28px;
}

.genderbtn.demo-rebrand-2 label .can-toggle__switch {
    height: 30px;
    flex: 0 0 153px ! important;
    border-radius: 60px;
}
.eventtogglediv{
  height: 800px;
  top:150px;
}


.new_connecting-line {
    height: 1px;
    background: #999;
    position: absolute;
    width: 100% ! important;
    margin: 0 auto;
    left: 0;
    right: 640px ! important;
    top: 57%;
    z-index: 1;
}
.new_tab{
    border: none ! important;  
}
.new_wizardNote
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 15px;
    color: #333;
    font-weight: 500;
 }
 .new_wizardNotefortwo
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 83px;
    color: #333;
 }
 .new_wizardNotefor4forJ
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 30px;
    color: #333;
    font-weight: 500;
 }
 .new_wizardNote_for6
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 35px;
    right: 36px;
    color: #333;
 }
 .wizard-inner{
    margin-top: 20px;
 }
 .new_wizard{
    height: 200px;
    color: #333;
    margin: 0px 0px;
    padding-top: 35px;
    background: #005b9929;

 }
 .new_wizard_length{
    height: 100px;
    color: #fff;
    margin: 45px 0px;
    padding-top: 35px;
    background: #004266;
    margin-left: 20px;

 }
 .forthreeCircles{
    margin-left: ''
 }

 .new_round-tab {
    width: 24px;
    margin-left: 29%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 2px solid #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
 .new_round-tabforTwo  {
    width: 24px;
    margin-left: 18%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
.new_round-tabfor4 {
    width: 24px;
    margin-left: 29%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 3px solid #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
 @media screen and (device-width: 1152px){
  .new_round-tabfor4{
    margin-left: 219% ! important;
   }
   .new_wizardNotefor4{
    right: -271px ! important;
   }
   .navigation_tab {
    margin-left: 30px ! important;
  }
  .new_wizardNotefor4forJ{
    right: -267px ! important;
  }
}

.nopadding{
    padding: 0px;
}
.new_round-tab:hover{
    cursor: pointer;
}
.active1{
    /* background-color: #a8a; */
   background-color: orange;
   box-shadow: 0px 0px 8px 0px #fff;
   font-weight: 600;
}
.navigation_img{
    height: 20px;
    width: 100%;
}
.navigation_tab{
    width: 112px;
    margin-left: 32px;
}
.navigation_tab_for6{
    /*width: 165px;*/
    margin-top: 42px;
}
#new_wizardNote_new0{
    width: 147px;
    color: #ffffff;
    position: relative;
    top: 24px;
    right: 21px;

}
#new_wizardNote_new1{
    width: 147px;
    color: #ffffff;
    position: relative;
    bottom: 27px;
    right: 21px;

}
#new_wizardNote_new2{
    color: #ffffff;
    width: 216px;
    top: 24px;
    position: relative;
    right: 21px;
}
#new_wizardNote_new3{
    color: #ffffff;
    position: relative;
    bottom: 27px;
    width: 183px;
    right: 20px;
}
.navigationTitle{
    color: #333;
    /*position: absolute; */
    bottom: 4px;
    left: 254px;
    font-weight: 600;
    font-size: 23px;
    
}
.new_navigationTitle{
    color: #333;
    /*position: absolute; */
    bottom: 4px;
    left: 578px;
    font-weight: 600;
    font-size: 25px;
    bottom:14px;

}
.navigationTitle_for5{
    color: #333;
    font-weight: 600;
    font-size: 25px;
    bottom:14px;

}
/*.navigationTitle_for6{
    color: #ffffff;
    position: absolute; 
    top: -12px;
    left: 3px;
    font-weight: 600;
    font-size: 23px;
    bottom:14px;

}*/

.navigationTitle_for6
{   
    color: #333;
    font-size: 25px;
    font-weight: 600;
    left: 596px;
    top: 19px;
    position: absolute;
}
#NavposFor51{
    top:-32px ! important;

}
#NavposFor53{
    top:-32px ! important;

}
.lineFor5{
    margin-top: 26px;
}
.innerdivFor5{
    margin-top: 24px;
}
@media only screen and (max-width : 736px)
{
  .navigation_tab
  {
   width:112px;
  }
  .navigation_tab{
    margin-left: 20px ! important;

  }

  
}
@media only screen and (max-width : 768px)
{
    .navigation_tab{
    margin-left: 20px ! important;

  }
  
  .new_wizardNote{
    font-size: 11px;
  }
 /* .serflip-card{
    width: 275px ! important;
  }*/
 .para-top_website{
    margin-top: 20px ! important;
 }
 /*.B4T_ServicesDiv{
    margin-left: 73px ! important;
    min-height: 150px;
    width: 580px;
 }*/
 /*.B2T1_line{
    margin-left: 174px ! important;
 }*/
/* .B2T1_line1{
    margin-left:345px ! important;
 }*/
 .B2T_serviceTitle{
    margin-left: 190px;
 }
 .B2T_challengesTitle{
    margin-left: 90px;
 }
 .BT4_imgBack{
    margin-left: 66px;
 }
 .BT4_txt{
    margin-left: 43px;
 }
/* .new_round-tabfor4{
    margin-left:127% ! important;
 }*/
 /*.new_wizardNotefor4{
    left:109px;
 }*/
 .adjustfor5{
    margin-left: 17px;
 }
}

@media only screen and (max-width : 768px){
  .Journycaro1{
    left: 100px ! important;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 20px;
}
.Journycaro2{
   left: 100px ! important;
   top:400px ! important;
   box-shadow: 0 10px 29px rgba(92,141,255,.22);
     margin-bottom: 20px;

}
.Journycaro3{
  left: 100px ! important;
  top:572px ! important;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.Journycaro4{
 left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22); 
  top:742px ! important;
 margin-bottom: 20px;

}
.Journycaro5{
  left: 100px ! important;
  top:1086px ! important;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.Journycaro6{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;
  top:916px ! important;
}
.Journycaro7{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;
  top:1264px ! important;

}
.Journycaro8{
 left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1435px ! important;

}
.Journycaro9{
 left: 100px ! important;
box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1610px ! important;

}
.Journycaro10{
 left: 100px ! important;
box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1770px ! important;

}
.Journycaro11{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.journytextblock{
  width: 90% ! important;
}
.new_wizard_journy{
  margin-top: 484px;
}
.add4 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add1 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add2 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
   .add3 {
    display: none;
    left: 167px ! important ;
    height: 40px! important;
    top: 180px ! important;
  }
   .add5 {
    display: none;
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
   .add6 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add7 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add8 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add9 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add10 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add11  {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add12 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }


  .webclientbackimg{

    width: 550px ! important;
  }
  .webclientcaro{
    width: 519px ! important;
  }
.Journycaro1 p {
  text-align: center ! important;
}
.Journycaro1 h3 {
  text-align: center ! important;
}
.Journycaro3 p {
  text-align: center ! important;
}
.Journycaro3 h3 {
  text-align: center ! important;
}
.Journycaro4 p {
  text-align: center ! important;
}
.Journycaro4 h3 {
  text-align: center ! important;
}
.Journycaro5 p {
  text-align: center ! important;
}
.Journycaro5 h3 {
  text-align: center ! important;
}
.Journycaro6 p {
  text-align: center ! important;
}
.Journycaro6 h3 {
  text-align: center ! important;
}
.Journycaro7 p {
  text-align: center ! important;
}
.Journycaro7 h3 {
  text-align: center ! important;
}
.Journycaro8 p {
  text-align: center ! important;
}
.Journycaro8 h3 {
  text-align: center ! important;
}
.Journycaro9 p {
  text-align: center ! important;
}
.Journycaro9 h3 {
  text-align: center ! important;
}
.Journycaro10 p {
  text-align: center ! important;
}
.Journycaro10 h3 {
  text-align: center ! important;
}
.Journycaro11 p {
  text-align: center ! important;
}
.Journycaro11 h3 {
  text-align: center ! important;
}
.add4 
 {
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }

.Journycaro2 p {
  text-align: center ! important;
}
.Journycaro2 h3 {
  text-align: center ! important;
}
  /*.Journycaro1{
    left:40px ! important;
  }
  .add1{
    left:204px ! important;
  }
  .add2{
    top:363px ! important;
    right:141px ! important;
  }
  .Journycaro2{
    left:-28px ! important;
  }*/
}


@media only screen and (max-width : 600px){
.BT4_imgBack{
    margin-left: 102px;
}
.BT4_txt{
    text-align: center;
    margin-left: 0px;
}
.BT3_subhead{
    margin-left: -11px;
}
.Journycaro1{
  left:0px ! important;
  top:205px ! important;
  opacity: 1;
}
.Journycaro2{
  /*left:0px ! important;*/
  top:380px ! important;
   opacity: 1;
}
.Journycaro3{
  left:0px ! important;
  top:565px ! important;
   opacity: 1;
}
.Journycaro4{
  left:0px ! important;
  top:750px ! important;
   opacity: 1;
}
.Journycaro5{
  left:0px ! important;
  top:1111px ! important;
   opacity: 1;
}
.Journycaro6{
  left:0px ! important;
  top:930px ! important;
   opacity: 1;
}
.Journycaro7{
  left:0px ! important;
  top:1295px ! important;
   opacity: 1;
}
.Journycaro8{
  left:0px ! important;
  top:1480px ! important;
   opacity: 1;
}
.Journycaro9{
  left:0px ! important;
  top:1665px ! important;
   opacity: 1;
}
.Journycaro10{
  left:0px ! important;
  top:1855px ! important;
   opacity: 1;
}
.Journycaro11{
  left:0px ! important;
   opacity: 1;
}
.new_wizard_journy{
  margin-top: 1065px;
}
.chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
.add4 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add1 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add2 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
   .add3 {
    display: none;
    left: 167px ! important ;
    height: 40px! important;
    top: 180px ! important;
  }
   .add5 {
    display: none;
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
   .add6 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .webclientbackimg{
    width: 340px ! important;
    height: 150px ! important;
    margin-left: 30px ! important;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
  }
  .webclientcaro{
    width: 250px ! important;
  }
.Journycaro1 p {
  text-align: center ! important;
}
.Journycaro1 h3 {
  text-align: center ! important;
}
.Journycaro3 p {
  text-align: center ! important;
}
.Journycaro3 h3 {
  text-align: center ! important;
}
.Journycaro4 p {
  text-align: center ! important;
}
.Journycaro4 h3 {
  text-align: center ! important;
}
.Journycaro5 p {
  text-align: center ! important;
}
.Journycaro5 h3 {
  text-align: center ! important;
}
.Journycaro6 p {
  text-align: center ! important;
}
.Journycaro6 h3 {
  text-align: center ! important;
}
.Journycaro7 p {
  text-align: center ! important;
}
.Journycaro7 h3 {
  text-align: center ! important;
}
.Journycaro8 p {
  text-align: center ! important;
}
.Journycaro8 h3 {
  text-align: center ! important;
}
.Journycaro9 p {
  text-align: center ! important;
}
.Journycaro9 h3 {
  text-align: center ! important;
}
.Journycaro10 p {
  text-align: center ! important;
}
.Journycaro10 h3 {
  text-align: center ! important;
}
.Journycaro11 p {
  text-align: center ! important;
}
.Journycaro11 h3 {
  text-align: center ! important;
}
.add4 
 {
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
.Journycaro2{
  left:0px ! important;
}
.Journycaro2 p {
  text-align: center ! important;
}
.Journycaro2 h3 {
  text-align: center ! important;
}
 .journytextblock{
        width: 100% ! important;
      }

}
@media (max-width: 480px){
.adjustfor5{
    margin-left: 10px ! important;
}
/*.new_round-tabfor4{
    margin-left:27% ! important;
}*/
/*.new_wizardNotefor4 {
    left: -6px ! important;
    font-size: 12px ! important;
}*/
}

@media screen and (min-width: 1400px) {
    .navigation_tab{
        margin-left: 48px ! important;
    }
}


@media screen and (min-width: 1600px) {
    .navigation_tab{
        margin-left: 48px ! important;
    }
   /* .Journycaro1{
      left:900px ! important;
    }
    .add2{
      right: -132px ! important;
    }
    .Journycaro3{
      right: 580px ! important;
      top:360px ! important;
    }
    .Journycaro4{
      left:999px ! important;
    }
    .Journycaro6{
    left:63px ! important;
  }
  .Journycaro5{
        left: 587px ! important;
        top: 834px ! important;
  }
  .Journycaro7{
    left:817px ! important;
  }
  .Journycaro9{
    left:231px ! important;
    top:1232px ! important;

  }
  .Journycaro8{
    left:945px ! important;
  }
  .Journycaro10{
    left:176px ! important;
  }
  .add2{
    right: -416px ! important;
    top:-252px ! important;
  }
  .add4 {
    left: 551px ! important;
    top: 192px ! important;
     }
    .add8{
      right: -756px ! important;
    }
    .add10{
      left: 408px ! important;
    }*/
}
@media screen and (min-width: 1900px) {
      .navigation_tab{
        margin-left: 48px ! important;
    }
      
}
@media (min-width:1025px){
  

}
@media (min-width:768px){
  .MobileDivHeight{
    /*height: 350px ! important;*/
  }

  .responsivefooter{
    height: 360px ! important;
  }
 /* .new_round-tabfor4{
    margin-left:127% ! important;
 }
 .new_wizardNotefor4{
  left:113px ! important;
 }*/
 .resmr20{
  color:#fff ! important;
 }

}
@media screen and(orientation: portrait) {
  .roadjourny img{
    left:535px ! important;
  }
  .Journycaro1{
    left:482px ! important;
  }
  .add2{
    right: -130px ! important;
  }
  .Journycaro2{
    left:394px ! important;
  }
  .add4{
    left:412px ! important;
  }
  .Journycaro4{
    left:615px ! important;
    top:723px ! important;
  }
  .add3{
    top:293px ! important;
    left:560px ! important;
  }
  .Journycaro6{
    top:819px ! important;
    left:330px ! important;
  }
  .add6{
    left:240px ! important;
    top:-58px ! important;
  }
  .Journycaro5{
    top:1002px ! important;
    left:575px ! important;
  }
  .Journycaro7{
    top:1132px ! important;
    left:515px ! important;
  }
  .Journycaro9{
    left:24px ! important;
  }
  .Journycaro8{
    top:1315px ! important;
    left:582px ! important;
  }
  .add5{
    right: 112px ! important;
  }
  .add8{
    right: -622px ! important;
  }
  .add7{
    left:904px ! important;
  }
  .add10{
    left:208px ! important;
  }
  .Journycaro10{
    left:424px ! important;
  }
  .add9{
    left:322px ! important;
  }
  .journytextblock{
    width: 75% ! important;
  }
 } 
 @media screen and (max-width: 812px){
 /* .new_round-tabfor4{
    margin-left: 143% ! important;
  }
  .new_wizardNotefor4{
    left:127px ! important;
  }*/
 }

 @media only screen  and (min-width: 1024px){
    .journytextblock{
      width: 76% ! important;
    }
  }

@media only screen and (device-width: 1024px){
   .roadjourny img{
    left:400px ! important;
   }
    .roadjourny{
    overflow:inherit;
   }  
   .Journycaro1{
    opacity: 1;
    left:483px ! important;
   }
   .add1{
    opacity: 1;
   }
   .add5{
    top:675px ! important;
    opacity: 1;
   }
   .Journycaro2{
    opacity: 1;
    left:256px ! important;
   }
   .add2{
    opacity: 1;
    right: 0px ! important;
   }
   .Journycaro4{
    opacity: 1;
    left:595px ! important;
    top:698px ! important;
   }
   .Journycaro6{
    opacity: 1;
    left:162px ! important;
   }
   .Journycaro5{
    opacity: 1;
    left:604px ! important;
    top:830px ! important;
   }
   .Journycaro7{
    opacity: 1;
    left:372px ! important;
   }
   .Journycaro8{
    opacity: 1;
    top: 1329px ! important;
    left: 582px ! important;
   }
   .add9{
    opacity: 1;
    left:185px ! important;
   }
   .add7{
    opacity: 1;
    left:596px ! important;
    top:241px ! important;
   }
   .add8{
    opacity: 1;
    right: -487px ! important;
   }
   .add6{
    opacity: 1;
    left:85px ! important;
   }
   .Journycaro10{
    opacity: 1;
    left:303px ! important;
   }
   .Journycaro9{
    opacity: 1;
    top: 1123px ! important;
   }
  } 
  @media only screen and (device-width: 1024px) {
   .roadjourny img{
    left:400px ! important;
   }
    .roadjourny{
    overflow:inherit;
   }  
   .Journycaro1{
    opacity: 1;
    left:483px ! important;
   }
   .add5{
    opacity: 1;
    top:717px ! important;
    opacity: 1;
   }
   .add{
    left:578px ! important;
   }
    
    .add10{
      opacity: 1;
      top: -18px ! important;
      left: 116px ! important;
    }
   .Journycaro2{
    opacity: 1;
    left:256px ! important;
   }
    .Journycaro3{
      opacity: 1;
    }
   .add2{
    opacity: 1;
    right: 0px ! important;
   }
   .Journycaro4{
    opacity: 1;
    left:595px ! important;
    top:698px ! important;
   }
   .Journycaro6{
    opacity: 1;
    left:162px ! important;
   }
   .Journycaro5{
    opacity: 1;
    left:604px ! important;
    top:830px ! important;
   }
   .Journycaro7{
    opacity: 1;
    left:372px ! important;
   }
   .Journycaro8{
    opacity: 1;
    top: 1329px ! important;
    left: 582px ! important;
   }
   .add9{
    opacity: 1;
    left:185px ! important;
   }
   .add7{
    opacity: 1 ! important;
    left:596px ! important;
    top:241px ! important;
   }
   .add8{
    opacity: 1;
    right: -487px ! important;
   }
   .add6{
    opacity: 1;
    left:85px ! important;
   }
   .Journycaro10{
    opacity: 1 ! important;
    left: 278px ! important;
   }
   .Journycaro9{
    opacity: 1;
    top: 1161px ! important;
   }
   .add2{
      opacity: 1;
   }
   .add3{
      opacity: 1;
      left:507px ! important;
   }
   .add4{
      opacity: 1;
   }
  } 
  @media only screen and (device-width: 851px)  {
.roadjourny img{
        display: none;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 73px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 73px ! important;
        top: 675px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 73px ! important;
         top:1230px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 73px ! important;
         top: 1420px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 73px ! important;
         top: 1790px ! important;
         text-align: center;
          opacity: 1;
    }

.Journycaro2{
     left: 73px ! important;
     top: 480px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 73px ! important;
      top: 855px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 73px ! important;
      top: 1040px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 73px ! important;
      top: 1605px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 73px ! important;
      top: 1980px ! important;
      opacity: 1;
    }
    .add11{
      display: none;
    }
    .add12{
      display: none;
    }
    .journybg2{
      display: none;
    }
    .new_wizard_journy{
      margin-top: 700px ! important;
    }
    .journytextblock{
      width: 100% ! important;
    }
    .new_round-tabfor4{
      margin-left: 127% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -110px! important;
    }
    
  } 
 @media only screen and (device-width: 800px)  {
.roadjourny img{
        display: none;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 73px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 73px ! important;
        top: 675px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 73px ! important;
         top:1230px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 73px ! important;
         top: 1420px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 73px ! important;
         top: 1790px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 73px ! important;
     top: 480px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 73px ! important;
      top: 855px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 73px ! important;
      top: 1040px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left: 73px ! important;
      top: 1605px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left: 73px ! important;
      top: 1980px ! important;
      opacity: 1;
    }
    .add11{
      display: none;
    }
    .add12{
      display: none;
    }
    .journybg2{
      display: none;
    }
    .new_wizard_journy{
      margin-top: 700px ! important;
    }
    .journytextblock{
      width: 100% ! important;
    }
    .new_round-tabfor4{
      margin-left: 127% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -110px! important;
    }
    
  } 
  @media screen and (min-width: 1900px) {
  .add10 {
    top: -22px ! important;
    left:785px ! important;
  }
}
@media only screen and (device-width : 1600px)  { 
    .Journycaro10{
       opacity: 1;
       left: 611px ! important;

      }
      .add9{
        opacity: 1;
        left:515px !important;
      }
      .roadjourny{
        overflow:inherit;
       }  
      .add10{
        opacity: 1;
        left:262px ! important;
      }
      .add8{
         opacity: 1;
        right: -624px ! important;
      }
      .Journycaro1{
         opacity: 1;
         left:820px ! important;
       }
       .Journycaro2{
         opacity: 1;
         left:583px ! important;
       }
       .Journycaro3{
         opacity: 1;
         left:534px ! important;
       }
       .Journycaro4{
         opacity: 1;
       }
      .Journycaro5{
         opacity: 1;
        left:1120px ! important;
      }
      .Journycaro6{
         opacity: 1;
        left:492px ! important;
      }
       .Journycaro7{
         opacity: 1;
        left:729px ! important;
      }
      .Journycaro9{
         opacity: 1;
        left:211px ! important;
      }
      .add6{
         opacity: 1;
         left:414px ! important;
      }
      .add7{
        opacity: 1;
      }
      .add5{
        opacity: 1;
      }
      .add4{
        opacity: 1;
        left:420px ! important;
      }
      .add3{
        opacity: 1;
        left:808px ! important;
      }
      .add2{
        opacity: 1;
        right: 51px ! important;
      }
      .add1{
        opacity: 1;
        left:740px ! important;
      }
      .Journycaro4{
         opacity: 1;
        left:877px ! important;
      }
      .iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 }

@media only screen and (device-width : 1440px)  { 
.Journycaro1{
    left:680px !important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add1{
    left:936px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add5{
    right: 344px ! important;
    top:807px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro2{
    left: 550px !important;
    opacity: 1;
    z-index: 0! important ;
  }
   .add2{
    right: -168px ! important;
    top: 252px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro3{
    left:365px !important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add3{
   top: 119px ! important;
    left: 961px !important;
   opacity: 1;
   z-index: 0! important ;
  }
  .Journycaro4{
    left:874px ! important;
    top: 683px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro5{
    top: 953px;
    left: 923px;
    opacity: 1;
    z-index: 0! important ;
  }
   .add4{
    left: 321px ! important;
    top: 202px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add6{
    left:251px ! important;
    opacity: 1;
    top:-59px ! important;
    z-index: 0! important ;
  }
   .Journycaro6{
    top: 840px;
    left: 375px;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro7{
    left: 572px ! important;
    top: 1146px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add7{
    left: 821px ! important;
    top: 331px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro8{
   top: 1398px ! important;
   left: 829px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
   .Journycaro9{
    left: 75px ! important;
    top: 1272px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
   .Journycaro10{
    opacity: 1;
    top: 1530px;
    left: 440px;
    z-index: 0! important ;
   }
  .add9{
    left: 358px ! important;
    top:266px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add8{
    right:-515px ! important;
    opacity: 1;
    top:183px ! important;
    z-index: 0! important ;
  }
  .add10{
    opacity: 1;
    left: 128px;
    z-index: 100;
    height: 75px;
    top: 99px;
    z-index: 0! important ;
  }
  .roadjourny img{
    left: 150px ! important;
  }    
.iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 .roadjourny{
        overflow:inherit;
        z-index: 0! important ;
        top:92px ! important;
        } 
 .add11 {
    position: relative;
    left: 758px;
    z-index: 100;
    height: 75px;
    top: -847px;
    opacity: 1;
    }           
 }
 @media only screen and (device-width : 1400px) { 
    .Journycaro10{
       opacity: 1;
      left: 480px ! important;

      }
      .add9{
        opacity: 1;
        left:316px !important;
      }
      .roadjourny{
        overflow:inherit;
        z-index: 0! important ;

   }  
      .add10{
         opacity: 1;
        left:138px ! important;

      }
      .add8{
          opacity: 1;
          right: -493px ! important;
      }
      .Journycaro1{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro2{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro3{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro4{
         opacity: 1;

          z-index: 0! important ;
       }
      .Journycaro5{
         opacity: 1;
        left:996px ! important;
      }
      .Journycaro6{
        opacity: 1;
        left:368px ! important;
         z-index: 0! important ;
      }
      .add6{
         opacity: 1;
        left:254px ! important;
         z-index: 0! important ;
      }
      .add7{
        opacity: 1;
         z-index: 0! important ;
      }
      .add5{
        opacity: 1;
         z-index: 0! important ;
      }
      .add4{
        opacity: 1;
         z-index: 0! important ;
      }
      .add3{
        opacity: 1;
         z-index: 0! important ;
      }
      .add2{
        opacity: 1;
         z-index: 0! important ;
      }
      .add1{
        opacity: 1;
         z-index: 0! important ;
      }
      .Journycaro4{
         opacity: 1;
        left:692px ! important;
      }
      .Journycaro7{
        opacity: 1;
      }
      .Journycaro8{
        opacity: 1;
      }
      .Journycaro9{
        opacity: 1;
      }
      .iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 }
  @media only screen and (min-device-width : 393px) and (device-height : 851px){
      .webclientbackimg{
        width: 310px ! important;
        margin-left: 49px ! important;
      }
      .journytextblock{
        width: 100% ! important;
      }
 }
@media only screen and (min-device-width : 1024px) and (device-height : 1366px){
      .Journycaro2{
        left:0px ! important;
      }
      .Journycaro1{
        left:287px ! important;
      }
 }
  @media only screen and (device-width : 1024px) and (device-height : 768px){
      .new_round-tabfor4 {
        margin-left: 216% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -232px ! important;
    }
      
 }
 @media only screen and (device-width : 400px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 412px){
   .new_wizardNotefor4forJ{
        right: 9px ! important;
    }

 }
 @media only screen and (device-width : 375px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 360px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 393px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
  @media only screen and (device-width : 320px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 800px)and (device-height : 1280px){ 
  .new_round-tabfor4 {
    margin-left: 132% ! important;
  }
  .new_wizardNotefor4forJ{
    right: -109px ! important;
  }
 }

 @media only screen and (device-width : 375px)and (device-height : 812px){ 
   .webclientbackimg{
    width: 300px ! important;
     border: 1px solid #b5b2b2;
   }
   .new_wizard_journy{
    margin-top: 965px ! important;
   }
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }  
  @media only screen and (device-width : 360px) and (device-height : 640px){ 
   .webclientbackimg{
    width: 300px ! important;
     border: 1px solid #b5b2b2;
   }
     .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 } 

@media only screen and (device-width : 393px) and (device-height : 851px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .webclientcaro{
      width:284px ! important;

    }
     .webclientbackimg{
      /*width: 310px ! important;*/
      border: 1px solid #b5b2b2;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 411px) and (device-height : 731px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 411px) and (device-height : 823px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 768px) and (device-height : 1024px) { 

    .scrollwrap{
    top: 169px;
    left: 370px;
    }
    .journyanim {
    opacity: 1;
    transition-duration: 0.5s;
    border: 1px solid #bdbcbc;
  }
  .new_wizardNotefor4forJ{
        right: -105px ! important;
    }
   
}

@media only screen and (device-width : 375px) and (device-height : 667px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .webclientbackimg{
      width: 310px ! important;
      border: 1px solid #b5b2b2;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }


}
@media only screen and (device-width : 375px) and (device-height : 812px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}



 /* @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 640px){
   .webclientbackimg{
    width: 285px ! important;
   }

 }  */
 @media only screen 
    and (min-device-width : 414px) 
    and (device-height :  736px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
    width: 310px ! important;
    }
     .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }

  } 
   @media only screen 
    and (min-device-width : 411px) 
    and (device-height :  823px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
    width: 310px ! important;
    }

 }  
  @media only screen 
    and (min-device-width : 411px) 
    and (device-height :  731px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
     width: 315px ! important; 
}

 }  
 @media only screen 
    and (min-device-width : 320px) 
   and (device-height : 568px){

       .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }

   .webclientbackimg{
        width: 255px ! important;
        margin-left: 30px ! important;
         border: 1px solid #b5b2b2;
      }
      .webclientcaro{
        width: 213px ! important;
      }
      .journytextblock{
        width: 100% ! important;
      }
      .journytextblock{
        height: 130px ! important;
      }
      .responsivefooter{
        height: 640px ! important;
      }
 } 
 @media only screen and (device-width : 1024px) { 

   .add1{
    left:583px;
    opacity: 1;
   }
   .add2{
    left:6px;
   }
   .roadjourny img{
    left:385px;
   }
   .Journycaro1 {
    left: 521px ! important;
    }
    .Journycaro2 {
    left: 260px ! important;
  }
  .add6{
    left:72px;
  }
  .Journycaro4{
    left:628px;
  }
  .Journycaro5{
    left:628px;
  }
  .Journycaro7{
    left:377px;
  }
  .add7 {
    position: relative;
    left: 811px;
    top: 83px;
    opacity: 0;
  }
  .Journycaro8 {
    z-index: 1000;
    position: absolute;
    top: 1323px;
    left: 566px;
    /* opacity: 0; */
}

.iogimg_4{
  margin-left: 0px ! important;
}


} 

 @media only screen and (device-width : 384px) and (device-height : 640px) { 
      .webclientcaro {
        width: 300px ! important;
    }
    .webclientbackimg{
      margin-left: 20px;
    }
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }

@media only screen and (device-width : 412px) and (device-height : 732px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 600px) and (device-height : 960px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
  @media only screen and (device-width : 320px) and (device-height : 533px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 480px) and (device-height : 854px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 320px) and (device-height : 568px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 414px) and (device-height : 736px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }

 @media only screen and (device-width : 401px) and (device-height : 700px) { 
      .webclientcaro {
        width: 300px ! important;
    }
      .topnav a.icon {
      top: -5px;
      right: -13px;
    }
   
 }
   @media only screen and (device-width : 600px) and (device-height : 960px) { 
    .new_round-tabfor6 {
        margin-left: 24% ! important;
    }
    .new_round-tabfor4 {
        margin-left: 39% ! important;
    }
    .new_wizardNotefor4 {
        left: -5px ! important;
    }
    .webclientbackimg{
      width: 500px;
      margin-left: 40px;
      border:1px solid  #eee;
    }
    .webclientcaro{
      width: 450px ! important;
    }
   }
@media only screen and (device-width : 400px) and (device-height : 700px) { 
   .webclientcaro {
      width: 300px ! important;
  }
  .webclientbackimg{
    border: 1px solid #aea7a7;
  }
} 
@media only screen and (device-width : 393px) and (device-height : 851px) { 
     .new_wizardNotefor4forJ{
        right: 4px ! important;
    }
  }
@media only screen and (device-width : 360px) and (device-height : 640px) { 
     .new_wizardNotefor4forJ{
        right: 4px ! important;
    }
  } 
@media only screen and (device-width : 400px) { 
   .webclientcaro {
      width: 300px ! important;
  }
} 
@media only screen and (device-width : 401px) { 
   .webclientcaro {
      width: 300px ! important;
  }
}
@media only screen and (device-width: 1680px) and (device-height: 1050px) 
 {
.roadjourny img{
    left:-249px ! important;
    z-index: 0! important ;
  }
.add1{
    opacity: 1;
    left: 387px ! important;
    z-index: 0! important ;
  }
.add9{
    display: none;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left: 552px ! important;
    z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 248px !important;
    top: 411px ! important;
    z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    left: 677px !important;
    top: 661px ! important;
    z-index: 0! important ;
  }
.add4{
    opacity: 1;
   left: -476px ! important;
    top: 461px ! important;
    z-index: 0! important ;
  }
  
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: left ! important;
  }
.Journycaro4 h3{
    text-align: left ! important;
  }
.Journycaro4{
    opacity: 1;
    top: 761px ! important;
    left: 200px ! important;
    text-align: right ! important;
    z-index: 0! important ;

  }
.add3 {
    opacity: 1;
    left: 603px ! important;
    top: 280px ! important;
    z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 810px ! important;
    left: 746px ! important;
    z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 696px ! important;
    top: 14px ! important;
    z-index: 0! important ;

  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 934px ! important;
    z-index: 0! important ;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 582px ! important;
    z-index: 0! important ;

  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 939px ! important;
    z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -271px ! important;
    top: 156px ! important;
    z-index: 0! important ;
  }
.add10{
    opacity: 1;
    top: -37px ! important;
    left: 304px ! important;
    z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    left: 279px ! important;
    z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    top: 1125px ! important;
    left: 282px ! important;
    z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
    left: 421px ! important;
    top: 1308px ! important;
    z-index: 0! important ;
  }
.Journycaro9 p{
    text-align: left ! important;
  }
.Journycaro9 h3{
    text-align: left ! important;
  }

.add7 {
    opacity: 1;
    left: 246px ! important;
    top: 348px ! important;
    z-index: 0! important ;
  }
.add12 {
    left: 189px ! important;
    top: -112px  !important;
    opacity: 1;
    z-index: 0! important ;
     
   }
.add2{
    opacity:1;
    right: 431px ! important;
    z-index: 0! important ;
   }
  .roadjourny{
    overflow:inherit;
    z-index: 0! important ;
   }      
  }   
@media only screen and (device-width: 1680px) 
 {
  .roadjourny img{
    left:-122px ! important;
    /*z-index: 0! important ;*/
    top:91px ! important;
  }
  .journybg1{
    display: none;
  }
  .add1{
    opacity: 1;
    left: 465px ! important;
    z-index: 0! important ;
  }
  .add9{
    display: none;
    z-index: 0! important ;
  }
  .Journycaro1{
    opacity: 1;
    top:265px ! important;
    left: 641px ! important;
    z-index: 0! important ;
  }
  .Journycaro2{
    opacity: 1;
    left: 407px !important;
    top: 411px ! important;
    z-index: 0! important ;
  }
  .Journycaro3 {
    opacity: 1;
    left: 840px !important;
    top: 661px ! important;
    z-index: 0! important ;
  }
  .add4{
    opacity: 1;
    left: -352px ! important;
    top: 526px ! important;
    z-index: 0! important ;
  }
  
  .Journycaro3 p{
    text-align: left ! important;
  }
  .Journycaro3 h3{
    text-align: left ! important;
  }
  .Journycaro4 p{
    text-align: left ! important;
  }
  .Journycaro4 h3{
    text-align: left ! important;
  }
  .Journycaro4{
    opacity: 1;
    top: 810px ! important;
    left: 746px ! important;
    text-align: right ! important;
    z-index: 0! important ;

  }
  .add3 {
    opacity: 1;
    left: 757px ! important;
    top: 280px ! important;
    z-index: 0! important ;
  }
  .Journycaro6 {
    opacity: 1;
     top: 866px ! important;
    left: 319px ! important;
    z-index: 0! important ;
  }
  .add6{
    opacity: 1;
    left: 696px ! important;
    top: -182px ! important;
    z-index: 0! important ;

  }
  .Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 934px ! important;
    z-index: 0! important ;
  }
  .add5{
    opacity: 1;
    top: 799px ! important;
    right: 550px ! important;

  }
  .Journycaro7 p{
    text-align: left ! important; 
  }
  .Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1071px ! important;
    z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -400px ! important;
    top: 156px ! important;
    z-index: 0! important ;
  }
.add10{
    opacity: 1;
    top: 69px ! important;
    left: 375px ! important;
    z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    top:1530px ! important;
    left: 426px ! important;
    z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    opacity: 1;
    top: 1193px ! important;
    left: 415px ! important;
    z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
    left: 588px ! important;
    top: 1413px ! important;
    z-index: 0! important ;
  }
.Journycaro9 p{
    text-align: left ! important;
  }
.Journycaro9 h3{
    text-align: left ! important;
  }

.add7 {
    opacity: 1;
    left: 513px ! important;
    top: 348px ! important;
    z-index: 0! important ;
  }
.add12 {
    left: 330px ! important;
    top: -112px  !important;
    opacity: 1;
    z-index: 0! important ;
   }
.add2{
    opacity:1;
    right: -40px ! important;
    top:465px ! important;
    z-index: 0! important ;
   }
.roadjourny{
    overflow:inherit;
    z-index: 0! important ;
   }    
  }   
@media only screen and (device-width: 1536px){
      .roadjourny img{
        display: none;
      }
      .add1{
        display: none;
      } 
      .add2{
        display: none;
      }
      .add3{
        display: none;
      } 
      .add4{
        display: none;
      } 
      .add5{
        display: none;
      } 
      .add6{
        display: none;
      } 
      .add7{
        display: none;
      } 
      .add8{
        display: none;
      } 
      .add9{
        display: none;
      }  
      .add10{
        display: none;
      } 
      .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
    .journybg{
      display: none;
    }
     .webclientcaro h3{
      text-align: center;
     }
     .Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
     .Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
    .Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
    .journybg2{
      display: none;
    }
    
  }   
  @media only screen and (device-width: 3200px){
      .roadjourny img{
        display: none;
      }
      .add1{
        display: none;
      } 
      .add2{
        display: none;
      }
      .add3{
        display: none;
      } 
      .add4{
        display: none;
      } 
      .add5{
        display: none;
      } 
      .add6{
        display: none;
      } 
      .add7{
        display: none;
      } 
      .add8{
        display: none;
      } 
      .add9{
        display: none;
      }  
      .add10{
        display: none;
      } 
      .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 1400px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
    .journybg{
      display: none;
    }
     .webclientcaro h3{
      text-align: center;
     }
     .Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
     .Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
    .Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 1562px ! important;
      top: 300px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 1562px ! important;
      top: 502px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 1562px ! important;
      top: 700px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left:1562px ! important;
      top: 915px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left:1562px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
    .journybg2{
      display: none;
    }
    
  }   
  @media only screen and (device-width: 1536px) and (orientation: portrait){
  .roadjourny img{
        display: none;
      }
  .add1{
        display: none;
      } 
  .add2{
        display: none;
      }
  .add3{
        display: none;
      } 
  .add4{
        display: none;
      } 
  .add5{
        display: none;
      } 
  .add6{
        display: none;
      } 
  .add7{
        display: none;
      } 
  .add8{
        display: none;
      } 
  .add9{
        display: none;
      }  
  .add10{
        display: none;
      } 
  .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
       text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }

    
  }   
@media only screen and (device-width: 2048px){
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-249px ! important;
    top:92px! important;
  }
.add1{
    opacity: 1;
    left: 567px ! important;
    top:291px ! important;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
   left: 877px ! important;
  }
.Journycaro2{
    opacity: 1;
    left: 669px !important;
    top: 411px ! important;
  }
.Journycaro3 {
    opacity: 1;
    top: 629px ! important;
    left: 1103px ! important;
  }
.add4{
    opacity: 1;
    left: 311px ! important;
    top: 239px ! important;
  }
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 346px !important;
    top: 618px ! important;
    text-align: right ! important;
  }
.add3 {
    opacity: 1;
    left: 747px ! important;
    top: 370px ! important;
  }
.Journycaro6 {
    opacity: 1;
    top: 840px ! important;
   left: 583px ! important;
  }
.add6{
    opacity: 1;
    left: 465px ! important;
    top: -47px ! important;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
   left: 1180px ! important;
  }
.add5{
    opacity: 1;
    top: 810px ! important;
    right: 624px ! important;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1283px ! important;
  }
.add8{
    opacity: 1;
    right: -527px ! important;
    top: 156px ! important;
  }
.add10{
    opacity: 1;
    top: 66px ! important;
    left: 390px ! important;
  }
.Journycaro10{
    opacity: 1;
   left: 682px ! important;
  }
.Journycaro8 {
    opacity: 1;
    top: 1199px ! important;
    left: 650px ! important;
  }
.Journycaro9{
    opacity: 1;
    left: 238px ! important;
    top: 1303px ! important;
  }
.add7 {
    opacity: 1;
    left: 651px ! important;
    top: 393px ! important;
  }
.add12 {
    left: 562px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
    right: 244px ! important;
    top:471px ! important;
   }
.roadjourny{
    overflow:inherit;
   }  
.add9{
    display: none;
   }
}
@media only screen and (device-width : 2560px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-249px ! important;
  }
.add1{
    opacity: 1;
    left:886px ! important;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
  }
.Journycaro2{
    opacity: 1;
    left: 1107px !important;
    top: 411px ! important;
  }
.Journycaro3 {
    opacity: 1;
    left: 608px !important;
    top: 613px ! important;
  }
.add4{
    opacity: 1;
    left:646px ! important;
    top: 239px ! important;
  }
  
.Journycaro3 p{
    text-align: right ! important;
  }
.Journycaro3 h3{
    text-align: right ! important;
  }
.Journycaro4 p{
    text-align: left ! important;
  }
.Journycaro4 h3{
    text-align: left ! important;
  }
.Journycaro4{
    opacity: 1;
    top: 543px ! important;
    left: 1586px ! important;
    text-align: right ! important;
}
.add3 {
    opacity: 1;
   left: 1061px ! important;
    top: 280px ! important;
  }
.Journycaro6 {
    opacity: 1;
    top: 785px ! important;
    left: 1230px ! important;
  }
.add6{
    opacity: 1;
    left: 1169px  ! important;
    top: 12px ! important;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 1799px ! important;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 429px ! important;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
   text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
        top: 1119px ! important;
       left: 1814px ! important;
  }
.add8{
    opacity: 1;
   right: -860px ! important;
    top: 156px ! important;
  }
.add10{
    opacity: 1;
  }
.Journycaro10{
    opacity: 1;
    left: 1180px ! important;
  }
.Journycaro8 {
    opacity: 1;
    top: 1086px ! important;
    left: 1042px ! important;

  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
     top: 1268px ! important;
  }
.add7 {
    opacity: 1;
    left: 1125px ! important;
    top: 348px ! important;
  }
.add12 {
    left: 1067px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
   }
.roadjourny{
    overflow:inherit;
   }     
} 
@media only screen and (device-width : 2880px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-567px ! important;
     /*z-index: 0! important ;*/
  }
.add1{
    opacity: 1;
    left:765px ! important;
    z-index: 0! important ;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
     z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 1107px !important;
    top: 411px ! important;
     z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    top: 543px ! important;
    left: 1586px ! important;
    text-align: left ! important;
     z-index: 0! important ;
  }
.add4{
    opacity: 1;
    left:546px ! important;
    top: 239px ! important;
     z-index: 0! important ;
  }
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 608px !important;
    top: 613px ! important;
    text-align: right ! important;
     z-index: 0! important ;
  }
.add3 {
    opacity: 1;
    left: 1067px ! important;
    top: 280px ! important;
     z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 785px ! important;
    left: 1087px ! important;
     z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 978px ! important;
    top: -110px ! important;
     z-index: 0! important ;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 1799px ! important;
     z-index: 0! important ;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 701px ! important;
     z-index: 0! important ;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1808px ! important;
     z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -760px ! important;
    top: 156px ! important;
     z-index: 0! important ;
  }
.add10{
    opacity: 1;
     z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    left: 1180px ! important;
     z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    top: 1086px ! important;
    left: 1042px ! important;
     z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
     top: 1268px ! important;
      z-index: 0! important ;
  }
.add7 {
    opacity: 1;
    left: 1131px ! important;
    top: 348px ! important;
     z-index: 0! important ;
  }
.add12 {
    left: 1067px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
    top:402px! important;
    right: 331px ! important;
     z-index: 0! important ;
   }
.roadjourny{
    overflow:inherit;
     z-index: 0! important ;
   }
.add9{
    display: none;
   }    
} 
 @media only screen and (device-width : 2560px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny{
        overflow: inherit;
        z-index: 0;
        top: 92px;
      }
.roadjourny img{
    left:-249px ! important;
  }
.add1{
    opacity: 1;
    left:886px ! important;
    /*z-index: 0! important ;*/
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
    z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 1168px !important;
    top: 433px ! important;
    z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    top: 620px ! important;
    left: 1617px ! important;
    /*z-index: 0! important ;*/
  }
.add4{
    opacity: 1;
    left: 661px ! important;
    top: 272px ! important;
    z-index: 0! important ;
  }  
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 680px !important;
    top: 652px ! important; 
    text-align: right ! important;
    /*z-index: 0! important ;*/
  }
.add3 {
    opacity: 1;
   left: 1086px ! important;
    top: 389px ! important;
    z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 879px ! important;
    left: 1088px ! important;
    z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 977px  ! important;
    top: 12px ! important;
    z-index: 0! important ;
  }
.Journycaro5{
    opacity: 1;
    top: 1005px ! important;
    left: 1776px ! important;
    /*z-index: 0! important ;*/
  }
.add5{
    opacity: 1;
    top: 839px ! important;
    right: 470px ! important;
    z-index: 0! important ;
  }
.Journycaro7 p{
    text-align: left ! important; 
    z-index: 0! important ;
  }
.Journycaro7 h3{
    text-align: left ! important; 
    z-index: 0! important ;
  }
.Journycaro8 p{
    text-align: right ! important; 
    z-index: 0! important ;
  }
.Journycaro8 h3{
    text-align: right ! important; 
    z-index: 0! important ;
  }
.Journycaro7{
    opacity: 1;
    top: 1158px ! important;
    left: 1814px ! important;
  }
.add8{
    opacity: 1;
    right: -860px ! important;
    top: 206px ! important;
  }
.add10{
    opacity: 1;
    top: 70px ! important;
    left: 919px ! important;
  }
.Journycaro10{
    opacity: 1;
    top: 1518px;
    left: 1180px ! important;
  }
.Journycaro8 {
    opacity: 1;
     top: 1207px ! important;
    left: 1166px ! important;
  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
    top: 1333px ! important;
  }
.add7 {
    opacity: 1;
    left: 1163px ! important;
    top: 423px ! important;
  }
.add12 {
    left: 1074px ! important;
    top: -80px !important;
    opacity: 1;  
   }
.add2{
    opacity:1;
    right: 81px;
    top: 487px;
    z-index: 0! important ;
   }
.add9{
    display: none;
   }
.roadjourny{
    overflow:inherit;
   }   
}  
 @media only screen and (device-width : 1600px) {
.roadjourny img{
        display: none;
      }
.roadjourny{
        overflow: inherit;
        z-index: 0;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }
 }

 @media only screen and (device-width: 1024px) {
.Journycaro4{
      display: none;
    }
.add3{
      display: none;
    }
  }
@media only screen and (device-width : 1280px) and (device-height : 800px) {
    
}
@media only screen and (device-width : 1280px) {
.roadjourny{
      overflow: inherit;
      top:92px ! important;
      z-index: 0;
    }
.Journycaro1{
      left:532px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add10{
      opacity: 1;
      top:78px ! important;
    }
.add1{
      left:495px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left:438px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 239px ! important;
       z-index: 0! important ;
    }
.Journycaro4{
         top: 711px! important;
        opacity: 1;
        left: 701px ! important;
      z-index: 0! important ;
    }
.Journycaro5{
      left:846px ! important;
      opacity: 1;
      top: 918px ! important;
      z-index: 0! important ;
    }
.add5{
      opacity: 1;
      top:805px ! important;
       z-index: 0! important ;
    }
.Journycaro7{
      left:423px ! important;
      top:1115px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -412px ! important;
      top:175px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left:760px ! important;
      opacity: 1;
      top:1412px ! important;
       z-index: 0! important ;
    }
.add7{
      left: 712px ! important;
      top:326px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 95px ! important;
      top: -46px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top:1149px ! important;
      top:1220px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
      left: 335px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left:224px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
      left:204px ! important;
      opacity: 1;
      top: 834px;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: -103px ! important;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 239px;
       z-index: 0! important ;
    }
.add3 {
      left: 630px;
      opacity: 1;
      top: 325px;
     z-index: 0! important ;
  }
.downimgtext{
    left:19em ! important;
  }
}
@media only screen and (device-width : 1152px) {
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.roadjourny img{
      left:205px ! important;
    }
.Journycaro1{
      left:425px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }
.add1{
      left:501px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left:170px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      left:92px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro4{
      top:650px! important;
      left:600px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left:746px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro7{
      left:323px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -761px ! important;
      opacity: 1;
      top:170px ! important;
       z-index: 0! important ;
    }
.Journycaro8{
      left:672px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 712px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: -754px ! important;
      top:527px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top:1149px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
      left: 213px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left:133px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
      left:106px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 548px;
       z-index: 0! important ;
    }
.add10{
      opacity: 1;
      top:50px;
       z-index: 0! important ;
    }
.add3 {
    left: 513px;
    opacity: 1;
    top: 271px;
     z-index: 0! important ;
  }
}
@media only screen and (device-width : 1920px) {
.roadjourny img{
    left:-147px ! important;
    top:91px !  important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0! important ;
    }
.Journycaro1{
     left: 850px ! important;
      opacity: 1;
      z-index: 0;
    }
.add1{
      left: 595px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro2{
      left: 758px ! important;   
      opacity: 1;
      top:363px ! important;
      z-index: 0;
    }
.Journycaro3{
      opacity: 1;
      left: 550px;
      z-index: 0;

    }
.Journycaro4{
      top: 697px! important;
      left: 1050px;
      opacity: 1;
      z-index: 0;
    }
.Journycaro5{
      left: 1169px ! important;
      top:950px ! important;
      opacity: 1;
      z-index: 0;
    }
.add5{
      opacity: 1;
      right: 511px;
      top:804px  ! important;
      z-index: 0;

    }
.Journycaro7{
      left: 759px ! important;
      top:1155px ! important;
      opacity: 1;
      z-index: 0;
    }
.add8{
      right: -538px ! important;
      top:196px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
      z-index: 0;
      top:1392px ! important;
    }
.add7{
      left: 930px ! important;
      top:333px ! important;
      opacity: 1;
      z-index: 0;
    }
.add6{
      left: 433px ! important;
      top:-34px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro9{
      top: 1360px ! important;
      left: 158px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro10{
     left: 706px ! important;
      top: 1543px;
      opacity: 1;
      z-index: 0;
    }
.add9{
      left: 532px ! important;
      top:319px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro6{
     left: 529px ! important;
     top:860px ! important;
      opacity: 1;
      z-index: 0;
    }
.add2{
      opacity: 1;
      right: 88px;
      top: 457px;
      z-index: 0;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 361px;
    }
.add3 {
      left: 940px;
      opacity: 1;
      top: 352px;
      z-index: 0;
  }
.add10{
    opacity: 1;
    top: 165px ! important;
    left: 109px ! important;
    z-index: 0;
  }
}
@media only screen and (device-width : 1856px) {
.roadjourny img{
    left:-147px ! important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.Journycaro1{
     left: 837px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add1{
      left: 550px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left: 548px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 476px;
       z-index: 0! important ;

    }
.Journycaro4{
      top: 697px! important;
      left: 967px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left: 1169px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
      right: 511px;
       z-index: 0! important ;

    }
.Journycaro7{
      left: 680px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -495px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 930px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 375px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top: 1266px ! important;
      left: 110px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
     left: 638px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left: 469px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
     left: 529px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: 223px;
      top: 375px;
       z-index: 0! important ;
    }
.add4{
      opacity: 1;
      position: relative;
      left: 291px;
       z-index: 0! important ;
    }
.add3{
      left: 869px;
      opacity: 1;
      top: 271px;
       z-index: 0! important ;
  }
.add10{
      opacity: 1;
      top: 81px ! important;
      left: 60px ! important;
       z-index: 0! important ;
    }
}
@media only screen and (device-width : 1792px) {
.roadjourny img{
    left:-147px ! important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.Journycaro1{
     left: 760px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add1{
      left: 510px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left: 482px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 412px;
       z-index: 0! important ;
    }
.Journycaro4{
      top: 673px! important;
      left: 901px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left: 1095px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
      right: 511px;
       z-index: 0! important ;

    }
.Journycaro7{
      left: 624px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -455px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 930px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 308px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top: 1266px ! important;
      left: 29px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
     left: 538px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left: 409px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
     left: 415px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: 223px;
      top: 375px;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 247px;
       z-index: 0! important ;
    }
.add3 {
        left: 781px;
        opacity: 1;
        top: 275px;
         z-index: 0! important ;
  }
.add10{
      opacity: 1;
      top: 81px ! important;
      left: 13px ! important;
    }
}






 .BT1_line{
 	background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 211px;
    left: 60px;
  	position: absolute;
  	z-index: 999
  }
  .innerBlocktepmlate{
  	height: auto;
  }
  .BT1_img{
  	height: 200px;
  	width: 100%;
  	margin-top: 140px;

  }
  .BT1_bannerTitle{
  	position: absolute;
  	color: #ffffff;
  	font-size: 43px;
    font-weight: 600;
    /*margin-top: 120px;*/
    /*left: 462px;*/
    top: 190px;
  }

   
.mobileIcon{
  font-size: 20px;
  padding: 0px 3px 0px 3px;
}
.contactcontent1-wrap{
	/*margin-top: 30px;*/
}
.contactcontent2-wrap{
  margin-top:100px;
  margin-bottom: 3px !important;
  color: #fff;
  /*background-image: url("/images/contactusbg.png");*/
  background-size: 100% 100%;
  /*height: 800px;*/
  background-color: #ffffff;
}
.contactcontent{
margin-bottom: 50px;
}

.contactcontent span{
 font-weight: bold;
 font-size: 50px !important;
 }
 .us{
  font-weight: 100!important;
 }


.bw{
  background-color: #f0f;
}

.contactcontbackimg{
  background-image: url("/images/Contact-Us.png");
  background-size: 100%;
  height: 450px;
  background-repeat: no-repeat;
  /*background-color: #555;*/
  margin-top: 110px;
}
.contactpageform{
  /*background-color: #ddd;*/
  height: 760px;
  color: #555;
}

.contacticon1{
  font-size: 55px;
  cursor: pointer;

}

.contacticon2{
    padding: 5px 6px;
    background-color: #009de2;
    font-size: 35px;
    border: 5px solid #009de2;
    border-radius: 50%;
    color: #fff;
}
.contacticon21{  
  padding: 5px 12px;
    background-color: #009de2;
    font-size: 35px;
    border: 5px solid #009de2;
    border-radius: 50%;
    color: #fff;
  }
.contacticon22{
  color: #fff;
  font-size: 25px;
  position: absolute;
  right: 38px;
  top: 12px;

}
.contacticon23{
  color: #fff;
  font-size: 27px;
  position: absolute;
  right: 38px;
  top: 12px;

}
.contacttextmargin{
  /*margin-top: 70px;*/
} 
.contacttextmargin2{
  /*margin-top: 60px;*/
} 
.addonColor{
  background-color: #fff;
}
.contacttextmargin h4{
  text-transform: capitalize;
  
} 
.contactformbtn{
  /*border-radius:0px;*/
  /*font-size: 12px;*/
  font-weight: 600;
  padding:10px 20px;
  margin-top: 50px;
  color: #fff;
}


#message{
  resize: none;
}

.formcontent{
  height: 80px;
  text-align: left;
}
.formcontent1{
  height: 138px;
}


.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.fullwidth{
  width: 100%;
  text-align: left;
}

.textright{
  text-align: right!important;
}

.adjustcaret{
  float: right;
  margin-top: 7px;
}

.redFont{
  color: #f00;
}

.contacticonwrap{
  /*margin-top: 50px;*/
}

.contacticons{
  margin-top: 5px;
}
.sxSelectHeader__Xlz5r{
    margin-bottom: 0px !important;
    height: 35px !important;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-left: 9px;
}
.separatorWrapper__eH30j{
  width:25%;
}
.sxSelectPlaceholder__1-20v{
  line-height: 1.2px !important;
}

.bold-icons{
  font-weight: bold;
}

@media(max-width : 600px){
  .contactpageform{
    height: 1100px;
  }
  .input-group .form-control{
    z-index: 0 ! important;
  }
 
}

@media only screen and (device-width : 393px) and (device-height : 851px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 21.1px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 

@media only screen and (device-width : 320px) and (device-height : 568px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 
  @media only screen and (device-width : 320px){ 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 
   @media only screen and (device-width : 360px){ 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }     
  @media only screen and (device-width : 768px) and (device-height : 1024px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }   
 @media only screen and (device-width : 375px) and (device-height : 812px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 375px) and (device-height : 667px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 375px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 400px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 412px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 414px) and (device-height : 736px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 360px) and (device-height : 640px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
   @media only screen and (device-width : 400px) and (device-height : 700px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
   @media only screen and (device-width : 800px) and (device-height : 1280px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 384px) and (device-height : 640px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 412px) and (device-height : 732px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 411px){ 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 411px) and (device-height : 823px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 390px) and (device-height : 844px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }  
  @media only screen and (device-width : 390px){
     .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }

  @media only screen and (device-width : 401px){
     .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }

  }   

@media screen and (max-width:600){
   .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }

}
@media only screen and (device-width : 400px) { 
  .serflip-cardservices img{
    width: 220px ! important;
  }
    .contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
}
@media only screen and (device-width : 401px) and (device-height : 700px) { 
  .serflip-cardservices img{
    width: 220px ! important;
  }
    .contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
}
.AllBlogsBox{
	/*background-color: #f6f6f6;*/
	/*border: 1px solid #eaeaea;*/
	padding: 20px;
}
.All1blog1{


}
.blogs_newhwading{

 position: absolute;
    top: -343px;
    color: #333;
    font-size: 55px
}
.Bolgs_div{
	margin-top: 120px ! important;
}
.BT1_bannerTitleblog_res{
	 position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 27px;*/
    top: 130px;
    letter-spacing: 2px;

}
.blogTitle{
	margin: 5px 0px;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
#data {
    width: 258px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#data:hover{
    overflow: visible; 
    white-space: normal; 
    width: auto;
    position: absolute;
    background-color:#FFF;
}
#data:hover+div {
    margin-top:20px;
}
.AllblogImgB{
	height: 173px!important;
	width: 100%;
}
.Allblog a:hover{
	text-decoration: none;
}
.Allblog:hover .blogTitle{
	color: #1db1f1;
}
.blogPara{
	overflow: hidden;
	height: 125px;
	text-align: justify;
	text-overflow: ellipsis;
	padding-top: 10px;
}
.All1blog1{
	position: relative;
	height: 310px;
	margin-left: 40px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
@media only screen 
    and (device-width : 1440px) 
    and (device-height : 900px) { 
    .All1blog1{
    margin-left:55px ! important;
  }
}
.blogSummeryp10{
	padding-left:  20px;
	padding-right: 20px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -55px;
	
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1blog1:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	    background-color: #111;
    border-radius: 12px;
}
.noBlogs{
	margin-top: 10%;
	margin-bottom: 10%;
	font-size:30px !important;
}
.premiumBlogIndicate{
	  position: absolute;
	  left: -20px;
	  top:14px;
	  padding-left: 20px;
	  padding-right:  20px;
	  transform: rotate(-40deg);
	  background-color:#f5d26e;
	  color: #333;
	  font-size: 16px;

}
.Allblog{
	/*margin-top: 66px ! important;*/

}
.pricehover-float:hover{
	transform: translateY(-6px);
}
.AllblogImgB {
    height: 173px!important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
}
.blogDateSBP{
	margin-top: 30px;
}
.mt40{
	text-align: justify;
}
.blogtext1{

	/*margin-top: 120px;*/
	left:8em;
}
.bloglist_margin{
	margin-left: 60px;
}

@media (max-width: 480px){
.bloglist_margin{
	margin-left: 0px ! important;
	background-color: #fff ! important;

  }
.blogtext1{
left: 35% ! important;
	} 
.All1blog1{
	margin-left: 20px ! important;
 } 
} 
@media screen and (min-width: 1900px) {
	.BolgsHoghDef{
		/*margin-left: 130px;*/
	}
	.Allblog{
		width: 400px ! important;
		height: 340px ! important;
		margin-left: 50px ! important;
	}

	.blogtext1{
		left:11em;
	}
	.iogimgblock2 img{
		width: 100% ! important;
	}
	.iogimgblock1 img{
		width: 100% ! important;
	}
	.iogimgblock3 img{
		width: 107% ! important;
	}
	.iogimg_4 img{
		width: 95% ! important;
        margin-top: -21px ! important;
	}
	.iogblock4text{
		/*padding-top: 157px ! important;*/
	}
	
	.iogblock2text{
		padding-top:140px ! important;
	}
	.ht_row4{
		margin-top: 8px ! important;
	}
	.iogblock3text{
		height: 510px ! important;
	}
	.career_para-top2{
		margin-top: 150px ! important;
	}
	.iogimg_4{
		width: 1143px ! important;
	}
}	

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

	.blogtext1{
		left:6em ! important;
	}
/* Styles */
}

@media only screen and (device-width: 1024px){
	.All1blog1{
		margin-left: 55px ! important;
	}
	.blogtext1{
		left:5em ! important;
	}
  }	

  @media only screen and (device-width : 411px) and (device-height : 823px) { 
    .All1blog1 {
		    width: 300px ! important;
		}
   
 } 

@media only screen and (device-width : 2560px){ 
 .All1blog1{
	 	margin-left: 80px ! important;
	 }
}
@media only screen and (device-width : 1366px) { 
 .All1blog1{
	 	margin-left: 45px ! important;
	 }
}
@media only screen and (device-width : 1360px) { 
 .All1blog1{
	 	margin-left: 45px ! important;
	 }
}
@media only screen and (device-width : 1280px){ 
  .All1blog1{
    margin-left: 101px ! important;
  }
 }
 @media only screen and (device-width : 1600px) {
   .All1blog1{
    margin-left: 90px ! important;
  }
}
 @media only screen and (device-width : 1680px) {
   .All1blog1{
    margin-left: 100px ! important;
  }
}
@media only screen and (device-width : 2048px) {
   .All1blog1{
       margin-left: 80px ! important;
  }
}
@media only screen and (device-width : 1152px) {
   .All1blog1{
       margin-left: 70px ! important;
  }
}
@media only screen and (device-width : 3200px) {
   .All1blog1{
     	 width: 465px ! important;
	    height: 340px ! important;
	    margin-left: 50px ! important;
  }
}
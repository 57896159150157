.paraaa-top{
    margin-bottom: -1px;
    /* font-size: 50px; */
    font-size: 40px;

    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 30px;
}
.newparaaa-top{
    
    margin-top: 85px;
}


.line_paraaaa{
    line-height: 30px;
    font-size: 17px;
    text-align: justify;
}
.line_subheadd{
    /*font-weight: 600;*/
    /* font-size: 33px; */
    font-size: 30px;
    color: #3a3737;
    font-weight: bold;
  }

.bluetext {
    color: #1378d4;
}

.line_overview {
    background-color: #f19000;
    width: 105px;
    margin-top: 32px;
    margin-bottom: 41px;
    height: 4px;
    /* margin-left: 500px; */
    text-align: center;
}

 .BT1_line{
    background-color: #f08f00;
    width: 90px;
    height: 4px;
    top: 211px;
    left: 60px;
    position: absolute;
    z-index: 999
  }
  .innerBlocktepmlate{
    height: auto;
  }
  .BT1_img{
    height: 200px;
    width: 100%;
    margin-top: 45px;

  }
  .BT1_bannerTitle{
    position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 500px;*/
    top: 165px;
    letter-spacing: 2px;
  }
  .HistorianTitle{
    color: #fff;
  }
  .dynamicpageimg{
    background-image: url("/images/acc.png");
  }
  .policyMainDiv{
    margin-top: 30px;
    padding: 40px;
    /*width: 1200px;*/
    /*margin-left: 66px;*/
    box-shadow: 0px 0px 39px -7px #eee;
  }
  @media(max-width : 600px){
    
    .BT1_bannerTitle{
      font-size: 37px ! important;
    }
    /*.serflip-card{
      width: 240px ! important;
    }*/

  }
 @media screen and (min-width: 1900px) {
  .policyMainDiv{
    width: 1500px ! important ;
    margin-left: 195px ! important;
  }
}
.Plicy_padding{
  padding: 80px;
}
@media only screen and (device-width : 393px) and (device-height : 851px) {
  .Plicy_padding{
    padding: 25px;
  }
  .policyMainDiv{
    padding: 10px;
  }

}
@media only screen and (device-width : 360px) and (device-height : 640px) {
  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }

}
@media only screen and (device-width : 411px) and (device-height : 731px) {

  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 411px) and (device-height : 823px) {

  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 320px) and (device-height : 568px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 375px) and (device-height : 667px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 414px) and (device-height : 736px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 375px) and (device-height : 812px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 384px) and (device-height : 640px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}



   
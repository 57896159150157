.siteMapBlock{
	padding-top: 100px;
	padding-bottom: 50px;
}
.iogpdfbtn{
	color: #ffffff;
	background-color: #009de2;
	border-radius: 4px;
	font-weight: 600;
	/*margin-left: -122px;*/
	height: 31px;
    border: none;
    width: 113px;
    margin-right: 70px;
}
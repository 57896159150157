body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
    overflow-x: hidden;
    margin-top: 0px ! important;
    font-family: 'Raleway', sans-serif ! important;
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
    font-family: 'Raleway', sans-serif ! important;
    /*color:#000;*/
}
.input-group .form-control{
    z-index: 0 ! important;
  }
.dropZindex{
    z-index: 0 ! important;
}
.marginTopMobile{
  margin-bottom: 35px ! important;
}
.TypecomponentBtnImg{
  width: 465px ! important;
  height: 380px ! important;
  padding-top: 50px;
  /*float:right;*/
}
.TypecomponentBtnImg img{
  height: 400px;
  object-fit: contain;
}
.contentheaderNewPage h2{
  letter-spacing: 0.5px;
}
.sharefacebook{
    padding-top: 150px;
}
.bw{
    background-color: #abc;
}
.bw1{
    background-color: #bcd;
}
.alternateblock{
    background-color: #f1f1f1c7;
}
.blogBox p > img {
  /*width: 300px ! important;*/
  object-fit: contain;

}
.blogBoxSmall p > img{
  width: 300px ! important;
  object-fit: contain;
}

.ApplicationImg{
  padding-left: 20px  !important;
}
.ApplicationImg img{
  object-fit: contain;
}
.fs12{
    font-size: 12px;
}
.downstreamtxt p{
    color: #fff ! important;
}
.NOpadding{
    padding-left: 0px;
    padding-right: 0px;
}
.minHeight{
    min-height: 600px;
}

.contactBg{
    
    /*background: linear-gradient(-45deg, #7474BF, #348AC7);*/
} 
.upstreamBg{
    
    /*background-color: #367588;*/
    height: 570px;
}   
.color-light {
    /*color: #555 !important;*/
    font-size: 20px;
}
.color-white{
  /*color: #fff;*/
}
.font-size-normal {
    font-weight: 300 !important;
}
.contact.contact-us-one {
    /*background-color: rgba(255,255,255,.9);*/
    /*border-bottom: 10px solid #ddd;*/
}

.contact.contact-us-one, .contact.contact-us-two {
    display: block;
    float: left;
    padding: 20px 20px 40px 20px;
    border-radius: 5px;
    /*background-color: #ffc60b;*/
}

.contactext{
    /*color: #333;*/
}
.input-md {
    height: 40px;
    padding: 4px 10px;
    font-size: 11px;
    /*font-family: 'Montserrat', sans-serif;*/
    text-transform: uppercase;
}
.input-rounded {
    border-radius: 4px;
}
.contactbutton-grad-stellar {
    /*background: linear-gradient(-45deg, #7474BF, #348AC7) !important;*/
    /*background-color: #367588;*/
}
.contactbutton-block {
    display: block;
    width: 100% !important;
    margin-top: 5px !important;
    border:none !important;
    border-radius: 7px !important;
    /*color: #ffffff !important;*/
   


}
.contactbutton-md {
     /*font-family: 'Montserrat', sans-serif !important;*/
    text-transform: uppercase !important;
    font-size: 13px !important;
    padding: 12px 25px !important;
}

.unih1{
    font-size: 30px;
}

.energypagedata h2{
    color:#333 ! important;
}













.pt75 {
    padding-top: 75px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.container4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 150px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}




.pricesmall{
     font-size: 50%

}


.price.price-three {
    /*transform: perspective(100px) rotateY(-5deg);
    -webkit-transform: perspective(100px) rotateY(-5deg);*/
    padding: 10px 0px;
    text-align: center;
    background-color: #fff;
    /*padding: 30px 0;*/
    height: 407px;
}
.price {
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    border-radius: 4px;
}
 .price1 {
    font-size: 80px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
    font-weight: ;
    line-height: 70px;
}


.footerlogo img{
    height: 80px;
    margin-top: 30px;
}

.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.bb-solid-1 {
    /*border-bottom: 1px solid #e9eaec;*/
}

.bg-gray {
    /*background-color: #f5f7f9 !important;*/
}
.pt100{
    padding-top: 100px;
}
.pb100{
    padding-bottom: 100px;
}
.pt20{
    padding-top: 20px;
}
.pt25{
    padding-top: 25px;
}
.pt30{
    padding-top: 30px;
}

.pt50{
    padding-top: 50px;
}
.pt75{
    padding-top: 75px;

}
.pb10{
    padding-bottom: 10px;
}
.pb20{
    padding-bottom: 20px;
}
.pb35{
    padding-bottom: 35px; 
}
.pb50{
    padding-bottom: 50px;
}
.pb35{
    padding-bottom: 35px;

}
.pb25{
    padding-bottom: 25px;
}
.mr20{
    margin-right: 20px;
}
.mr10{
    margin-right: 10px;
}
.mr50{
    margin-right: 50px;
}
.mb50{
    margin-bottom: 50px;
}
.mb20{
    margin-bottom: 20px;
}

.mt10{
    margin-top: 10px;
}

.color-blue{
    /*color: #3dace1;*/
}
.color-red{
/*color: #fe4530;*/
}
.color-primary{
    /*color: #337ab7;*/
}
.color-dark{
    /*color: #b0b2b4;*/
}
.color-green{
   /*color:  #b2cc71;*/
}
.color-purple{
/*color:#a85ad4;*/
}

.blog-one .blog-one-attrib .blog-author-name {
    font-size: 12px;
    font-weight: 600;
    margin-right: 20px;
    display: block;
    float: left;
    margin-top: 5px;
        /*color: #ff4530!important;*/
}

.blog-one .blog-one-attrib .blog-date {
    /*color: #bbb;*/
    font-size: 11px;
    font-weight: 600;
    float: right;
    display: block;
    margin-top: 5px;
}

.blog-one .blog-one-body .blog-title {
    font-size: 15px;
    /*color: #aaa !important;*/
}
.B2T_p ul {
    padding-left: 15px ! important;
}
.TextRightImg{
    width: 500px;
    height: 400px;
}
.TextRightImg img{
    width: 500px;
    /*height: 220px;*/
    object-fit: contain;
}
.blog-title {
    
    font-size: 18px;
    line-height: 1.45;
    /*colo'r: #aaa !important;*/
}


.blog-one .blog-one-body .blog-title a {
    color: #555!important;
}

.blog-one .blog-one-body p {
    border-bottom: 1px dashed #ddd;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.blog-one .blog-one-body {
    padding: 30px 25px 0 25px;
    background: #fff;
    /*background-color:#fff;*/
    overflow: hidden;
    margin-top: -25px;
    float: left;
    width: 100%;
    padding-bottom: 0;
    -moz-border-bottom-left-radius: 2px;
    border-bottom-left-radius: 2px;
    -moz-border-bottom-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.imh2{
     color: #ff4530;
}


.imgIcon{
  height:40px;
}


.adjust{
  margin-top: 50px;
}

.bg-gray-rs{
  /*background-color: #f5f7f9;*/
  height:400px;
}
.el-icon2x img{
  height: 40px;
}

.contactp{
    /*font-family: 'Open Sans', sans-serif ;*/
    margin: 0 0 25px 0;
    font-size: 13px;
    line-height: 25px;
    color: #747474;
}

.pricepb30 {
    padding-bottom: 30px;
}
.pricepb50 {
    padding-bottom: 50px;
}
.pricept75 {
    padding-top: 75px;
}
.pricemb50{
    margin-bottom: 25px;
}
.pricemt50
{
    margin-top: 50px;
}
.priceh1{
    line-height: 45px !important;
    padding-top: 13px;
}
.priceh4{
    line-height: 30px !important;
}

.pricecolor-light {
    color: #ffffff !important;
    
}
.pricefont-size-normal {
    font-weight: 300 !important;   
}


.pricesmall {
    font-size: 50%;
    opacity: .7;
    /*font-family: 'Open Sans', sans-serif;*/
}
.priceicon{
    font-size: 15px;
}

.pricehover-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-timing-function: ease-out;
}
.pricehover-float:hover, .pricehover-float:focus, .pricehover-float:active {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
}

.price.price-three {
    text-align: center;
    /*background-color: #fff;*/
    padding: 0px 0px 30px 0px;
}
.price {
    /*margin-top: 10px;*/
    margin-bottom: 10px;
    margin-left: 2px;
    transition: all .3s ease-in-out;
    border-radius: 4px;
}
.modal-backdrop{
    z-index: 0 ! important;
}


.price-three span {
    font-size: 80px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
    line-height: 70px;
}
.pricecolor-green {
    /*color: #b2cc71 !important;*/
}


.price-three sup {
    font-size: 30px;

}
.price-three sup {
    top: -.5em;
}
.price-three sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

.price-three .price-body {
    padding: 5px 0;
}

.price-body ul {
    font-size: 13px;
    /*color: #333;*/
    margin: 0;
    padding: 0;
    list-style: none;
}

.price-body ul li {
    
    line-height: 25px;
}
.price-footer_upstream{
    background-color: #eee;
    /*margin-top: 10px;*/
    /*position: absolute;*/
    /*cursor: pointer !important;*/

}
.pricebtn1,.pricebtn2,.pricebtn3,.pricebtn4{
    /*background-color: #367588;*/
    border-radius: 4px;
    font-size: 13px;
    margin-top: 10px;
    font-weight: 700;
    padding: 8px 20px;
    /*color: #fff !important;*/
    margin-bottom: 5px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    /*font-family: 'Montserrat', sans-serif;*/
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    z-index: 1000;
    color: #fff;

}
.pricebtn2{
    /*background-color*/: #367588;
        margin-bottom: -4px;

}
.pricebtn3{
    /*background-color: #367588;*/

}
.pricebtn4{
    /*background-color: #367588;*/

}
 .price-badge1,.price-badge2 {
    /*margin: -30px auto 15px auto;*/
    width: 50%;
    font-size: 11px;
    text-transform: uppercase;
    /*color: #fff;*/
    padding: 5px 10px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}
.price-badge2{
    margin: -30px auto 5px auto;

}
.bg-grad-blood-mary {
    /*background: linear-gradient(-45deg, #FF512F, #DD2476);*/
    /*background-color: #ffc60b;*/
    height: 40px;
    /*color: #333;*/
}

.pricecolor-success {
    /*color: #5cb85c !important;*/
}

.pricecolor-cyan {
    /*color: #1abc9c !important;*/
}
.pricecolor-blue {
    /*color: #3dace1 !important;*/
}

.iconsize{
    font-size: 22px!important;
}


.bgiogblue{
    background-color: #005b99;
}

.textcolorblue{
    color:#005b99;
}
.bgiogyellow{
    background-color: #f08f00;
}

.textcoloryellow{
    color:#f08f00;
}

.lightgray{
    background-color: #eeee;
}
.darkgray{
    background-color: #333333;
}

.buttonhover:hover{
    color: #fff!important;
    background-color: #f19000;
    transition-duration:0.7s;
    cursor: pointer;

}

.lightbluebg{
    background-color: #009de2;
}
.lightbluetext{
    color: #009de2;
}
.darkbluebg{
    background-color: #004266;
}
.darkbluetext{
    color: #004266;
}

.textcolorwhite{
    color: #fff;
}

.margin-top{
  margin-top: 150px;
}
.margin-bottom{
  margin-bottom:100px;
}

.errorMsg{
    color: #f00;
    font-family: 'Raleway', sans-serif;
    font-size:12px;
}
.nameSpaceUpper{
    text-transform: capitalize;
}

.mt50{
    margin-top: 50px;
}
.modalZindex{
    z-index: 10000;
}
.fs15{
    font-size: 14px;
    letter-spacing: 1px ! important; 
}
.fs15 p{
    font-size: 14px;
    letter-spacing: 1px ! important; 
}
.mar10{
    margin: 10px;
}
.sxSelectHeader__Xlz5r{
    height: 40px;
}
.ServiceTxt{
    font-size: 16px ! important;
    text-align: justify;
}

.fa{
    font-family: FontAwesome !important;
   }

.NOPadding{
    padding: 0;
} 
.error{
    font-size: 10px;
    color: red;
}
.astrick{
    /*font-size: 10px;*/
    color: red;
}
.blogtext p{
    font-size: 14px ! important;
}
.carousel-control.left , .carousel-control.right
{
  background-image: none !important;
}

.gt__2YKbG{
  margin-right: 59% ! important;
}
.sxSelectListsWrapper__2321j{
  width: 42% ! important;
}
@media screen and (min-width: 320px) {
.Typecomponent1Img_vision {
        width: 350px;
        height: 218px;
    }
}

@media screen and (min-width: 411px) {
.pt20{
        padding-top: 29px ! important;
    }

}
@media screen and (device-width:1024px){
.new_round-tabfor4{
        margin-left:219% ! important;
     }
.new_wizardNotefor4
       {
         right: -88px ! important;
       }
.new_wizardNotefor4{
      left:196px ! important;
     }
.blogdate{
      padding-top: 0px ! important;
     }
.setlogo{
        padding-left: 22px ! important;
     }

}
@media screen and (max-width:768px)  and (max-height: 1024px) {
.new_round-tabfor6{
      margin-left: 59% ! important;
     }
}
@media screen and (max-width:768px){
.ContactmodalContent_brochure{
      height: 460px ! important;
     }
.serflip-card-back p{
      font-size: 13.5px ! important;
     }
.dwstmTitle{
      font-size: 17px ! important;
     }

.new_round-tabfor6{
      margin-left: 59% ! important;
     }
.newcard_landscape{
      width: 270px ! important;
     }
.landscape_btn{
      float: right ! important;
     }
.ContactmodalContent{
      height: 500px ! important;
     }
.ContactmodalContent_brochure{
      margin-top: 46% ! important;
     }.new_round-tabfor4{
        margin-left:127% ! important;
     }
.new_wizardNotefor5{
      right: -12px ! important;
     }
.new_wizardNotefor4{
      left:113px ! important;
     }
.BT4_txt{
        font-size: 13px ! important;
        margin-left: 0px ! important;
     }
.iconCircle{
        margin-left: 30% ! important;
     }
.type1btnDiv{
        height: 630px ! important;
     }
.BtnImageDown{
        padding-left: 0px ! important;
     }
.serflip-card{
        width: 260px ! important;
     }
.righttextaboutus ul{
        padding-left: 12px ! important;
        font-size:15px ! important;
     }
.About5block{
        padding-top: 0px ! important;
     }
.About5block{

     }
.new_round-tab{
      margin-left: 55% ! important;
     }
.NwesBoxShadow{
        height: 380px ! important;
     }
.jobformcontent{
        margin-left: 200px ! important;
     }
.challengedivPadd{
        padding-left: 0px ! important;
     }
.TypecomponentBtnImg img{
        /*margin-top: 165px ! important;*/
     }.new_round-tabforTwo{
        margin-left: 98% ! important;
      }
.new_wizardNotefortwo{
        right: -130px ! important;
      }
.subdata{
        padding-left: 0px ! important;
      }
.Aboutuslink{
        margin-left: 0px ! important;
      }
.navigationTitle_for6{
        left:275px ! important;
      }
.new_round-tabfor3{
        margin-left: 99% ! important;
      }
.new_wizardNotefor3{
        right: -99px ! important;
      }
.new_round-tabfor9 {
       margin-left: 5% ! important;
     }
.new_round-tabfor5{
      margin-left: 56% ! important;
     }
.new_wizardNote_for6{
      right: -18px ! important;
     }
.new_wizardNotefor9{
        font-size: 10px ! important;
      }
     
  }
 @media only screen and (device-width : 320px) and (device-height : 568px) { 
.videocard1 {
    width: 265px ! important;
  }
.new_BT3_innerBlock{
    width: 310 ! important;
  }
.maindivWidth_mob {
    width: 300px ! important;
  }
 } 
  @media only screen and (device-width : 411px) and (device-height : 823px) { 
.new_BT3_innerBlock {
    width: 413px ! important;
  }
 } 
 
@media screen and (max-width:480px){
.new_round-tabfor4{
        margin-left:27% ! important;
     }
.uptitle h3{
      font-size: 22px ! important;
     }
.serflip-card{
        width: 220px ! important;
     }
.mobile_width{
        
     }
.contentheader1 h2{
      font-size: 30px ! important;
     }
.para-top{
      font-size: 20px ! important;
     }
.Typecomponent1Img_vision{
      width: 350px ! important;
      height: 185px ! important;
     }
.webclientbackimg{
      margin-left: 10px ! important;
     }
.MobileDivHeight{
      height: 600px ! important;
     }
.type1btnDiv{
        margin-bottom: 0px ! important;

     }
.navigationTitle_for6{
        left:74px ! important;
     }
.new_wizardNote_for6{
        right: -12px ! important;
     }
.All1blog1{
      width: 275px ! important;
      margin-left: 25px ! important;
     }
.videocard1{
      width: 315px ! important;
     }

.overviewPadding{
        padding-left: 0px ! important;
        padding-right: 0px ! important;
      }
.challengedivPadd{
        padding-left: 0px ! important;
     }
.new_wizardNotefor4{
      left:-12px ! important;
      font-size: 11px ! important;
     }
.Typecomp2Btn_img{
        padding-left: 0px ! important;
     }
.BtnImageDown{
        margin-top: 0px ! important;
     }
.new_round-tabforTwo{
        margin-left: 40% ! important;
      }
.new_wizardNotefortwo{
        right: 0px ! important;
      }
.Type1_comp_padd{
        padding-left: 0px ! important;
      }
.new_wizardNotefor5{
        font-size: 9px ! important;
        right: -14px ! important;
      }
.new_wizardNote{
        right: -75px ! important;
      }
.new_round-tabfor3{
        margin-left: 29% ! important;
      }
.new_wizardNotefor3{
        right: 7px ! important;
      }
.new_wizardNotefor9{
        font-size: 10px ! important;
      }    
} 

@media only screen (max-width:1024px) and(orientation: portrait) {
.price.price-three{
      margin-left: 165px ! important;
      margin-top: 35px ! important;
    }
.upstream_tab{
        margin-left:175px ! important;
    }
.oilimage{
        right: 136px ! important;
        top:120px ! important;
    }
}
@media only screen and (device-width: 1024px) {
.navigationTitle_for6{
      left:430px ! important;
     }
.servicekbtnarrow{
      margin-left: 2px ! important;
     }
.navigation_tab{
      margin-left: 27px ! important;
     }
  }
@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {  
.navigation_tab{
      margin-left: 20px ! important;
     }
  }
@media only screen and (device-width: 1024px){
.jobformcontent{
        margin-left: 250px ! important;
    }
.TypebtnText{
      margin-left: 0px ! important;
    }
.blogtext h4{
      height: 75px ! important;
      overflow: hidden;
    }
.blogtext p{
      height: 100px ! important;
    }
.blogdate{
      padding-left: 0px ! important;
    }
.new_wizardNotefor4forJ{
      right: -234px ! important;
    }
.jobpageform{
        margin-top: 80px ! important;
    }
.subdata{
        padding-left: 0px ! important;
    }
.TypecomponentBtnImg img{
        margin-top: 0px ! important;
     }
.B4T_ServicesDiv{
        margin-left: 55px ! important;
     }
.new_wizardNotefor3{
        right: -218px ! important;
     }
.new_round-tabfor3{
        margin-left: 164% ! important;
     }
.new_wizardNotefor5{
        right: 10px ! important;
     }
.innerBlocktepmlate6{
        margin-left: 145px ! important;
     }
.NwesBoxShadow{
      height: 350px ! important;
     }
.journytextblock{
      width: 76% ! important;
     }
.type1btnDiv{
      height: 575px ! important;
     }
.new_wizardNote_for6{
      right: 35px ! important;
     }
.navigationTitle_for6{
      left:430px ! important;
     }
.servicekbtnarrow{
      margin-left: 5px ! important;
     }
.ContactmodalContent_brochure{
      height: 460px ! important;
     }
.landscape_btn{
      float: right ! important;
     }
.ContactmodalContent{
      height: 500px ! important;
     }
.ContactmodalContent{
      margin-top: 20% ! important;
     }
.new_round-tab{
      left:-33px ! important;
     }
.new_wizardNotefor9{
      right: 0px ! important;
     }
.serflip-card-back p{
      font-size: 13.5px ! important;
     }
.dwstmTitle{
      font-size: 17px ! important;
     }
.B4T_ServicesDiv{
      min-height: 215px ! important;
     }
.img1 img{
      width: 100% ! important;
     }
  } 
@media only screen and (device-width : 1920px){
.ApplicationImg{
    padding-left: 90px ! important;
  }

.ApplicationImg img{
    object-fit: contain;
   }

} 
@media only screen and (device-width : 320px) and (device-height : 568px) {
.B3T_challengesDiv {

          width : 238px ! important;
          margin-left: 27px ! important;
          /*border: 1px solid #dedbdb;*/
      }
.padding_640{
        padding-left: 0px;
      }

  }  
@media only screen and (device-width : 360px) and (device-height : 640px) { 
.B3T_challengesDiv {
          width : 260px ! important;
          margin-left: 27px ! important;
      }
.BT3_text11{
        padding-right: 29px ! important;
      }
.padding_640{
        padding-left: 0px;
      }
  }  
@media only screen and (device-width : 411px) and (device-height : 823px) { 
.B3T_challengesDiv {
          width : 325px ! important;
      }
.serflip-card {
        width: 265px ! important;
    }  
.BT3_innerBlockImage{
      height: 370px ! important;
    }
.blck{
          padding: 32px 25px 56px;
          margin-top: 58px;
          margin: 20px;
        }
  }   
@media only screen and (device-width : 480px) and (device-height : 854px) { 
.serflip-card {
          width: 350px ! important;
      }
.serflip-card img{
        width: 295px ! important;
      }
  }   
@media only screen and (device-width : 600px) and (device-height : 960px) { 
.serflip-card {
          width: 350px ! important;
          margin-left: 45px; 
    }
.serflip-card img{
        width: 295px ! important;
      }
.ct-topbar{
        left:515px ! important;
      }
.B4T_ServicesDiv{
        width: 530px ! important;
      }
.topnav.responsive{
            width: 600px ! important;
            height: 529px ! important;
            left: -158px ! important;
      }
.newcard_landscape {
          width: 202px ! important;
          margin-left: 35px;
      }
#consultdown_img1{
        left:21em ! important;
      }
#consultdown_img3{
        left:21em ! important;
      }
 #consultdown_img5{
        left:21em ! important;
      }
#consultdown_img0{
        height: 106px ! important;
      }
#consultdown_data1 {
        left: -8% ! important;
        width: 282px ! important;
    }
#consultdown_img1 {
        height: 106px ! important;
        left: 18em ! important;
    }
 #consultdown_img2 {
      left: 1em ! important;
      height: 100px ! important;
     }
#consultdown_data3 {
        left: -7% ! important;
        width: 300px ! important;
    }
#consultdown_img3 {
        left: 17em ! important;
        height: 100px ! important;
    }
#consultdown_img4{
      left: 2em ! important;
       height: 100px ! important;
    }
#consultdown_data5 {
        left: -1% ! important;
        width: 278px ! important;
    }
#consultdown_img5 {
        left: 20em ! important;
    }

  }   
@media only screen and (device-width : 411px) and (device-height : 731px) { 
.serflip-card img{
      margin-left: 20px ! important;
    }   
  }   
@media only screen and (device-width : 414px) and (device-height : 736px) { 
.B3T_challengesDiv {
          width : 261px ! important;
          margin-left: 60px ! important
          /*border: 1px solid #dedbdb;*/
      }
.B4T_img{
        margin-left: 60px ! important;
      }
 .serflip-cardservices img{
      margin-left: 20px ! important;
    } 
.pt20{
      padding-top: 20px ! important;
    }

  } 
@media only screen and (device-width : 800px) and (device-height : 1220px) { 
.serflip-cardservices img{
     width: 220px ! important;
    } 
  } 
@media only screen and (device-width : 1024px) and (device-height : 768px) { 
.serflip-cardservices img{
     width: 170px ! important;
    } 
  } 
 @media only screen and (device-width : 768px) and (device-height : 1024px) { 
.serflip-cardservices img{
     width: 220px ! important;
    } 
  } 
 @media only screen and (device-width : 412px) and (device-height : 732px) {   
.serflip-cardservices img{
     width: 227px ! important;
    } 
  } 
@media only screen and (device-width : 412px) {  
.serflip-cardservices img{
     width: 227px ! important;
    } 
.serflip-card {
        width: 275px ! important;
    }
.serflip-card img{
      width: 225px ! important;
    }
  } 
@media only screen and (device-width : 384px) {   
    .serflip-card{
      width: 255px ! important;
    }    
}
@media only screen and (device-width : 390px) {  
.serflip-card{
      width: 275px ! important;
    }
  } 
@media only screen and (device-width : 411px) {  
.serflip-card{
      width: 270px ! important;
    }
.serflip-card img{
      width: 210px ! important;
    }
  } 
@media only screen and (device-width : 375px) { 
.serflip-card{
      width: 260px ! important;
    }
.serflip-card img{
      width: 210px ! important;
    }   
  } 
@media only screen and (device-width : 414px) {   
.serflip-card{
      width: 260px ! important;
    }
  } 
@media only screen and (device-width : 600px) and (device-height : 960px) {   
.serflip-cardservices img{
     width: 340px ! important;
    } 
.newcard_landscape {
      width: 380px ! important;
    }
    

  } 
@media only screen and (device-width : 480px) and (device-height : 854px) {    
.serflip-cardservices img{
     width: 340px ! important;
    } 
.newcard_landscape {
      width: 350px ! important;
    }
  } 
@media only screen and (device-width : 375px) and (device-height : 667px) { 
.B3T_challengesDiv {

          width : 261px ! important;
          margin-left: 43px ! important
          /*border: 1px solid #dedbdb;*/
      }
.B4T_img{
        margin-left: 60px ! important;
      }
  }
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
.righttextaboutus{
      width: 245px ! important;
    }
.serflip-card img{
      /*margin-left: 10px ! important;*/
    }
.serflip-card {
        width: 237px ! important;
    }
.Typecomponent1Img_vision {
        width: 300px ! important;
    }
.blck1heightnew{
      height: 284px ! important;
    }
.blck1heightnew{
      width:239px ! important;
    }
  } 
@media only screen and (device-width : 393px) and (device-height : 851px) { 
.innerBlocktConsult{
      height: 1721px ! important;

    } 
#consultdown_img3{
      top:94em ! important;
    }
.BT3_innerBlockImage{
      height: 450px ! important;
    }
 #consultdown_data3{
      top:100em ! important;
    }   
.resfooterend{
      height: 65px ! important;
    } 
.responsivefooter{
      height: 591px ! important;
    }
.contactcontent {
      margin-bottom: 35px;
      margin-left: 23px;
      margin-top: 26px;
   
    }
.serflip-card img{
      width: 220px ! important;
    }
.videocard{
      widows: 270px ! important;
    }
.BT3_text11{
      padding-right: 29px ! important;
    }
.serflip-card img{
      /*margin-left: 15px ! important;*/
    }

.NewLoader{
      margin-left: 0px !important;
    }
.energypagedata p{
      letter-spacing: 0.5px ! important;
    }
.sxSelectHeader__Xlz5r{
        height: 50px ! important;
    }
  } 
 @media screen and (-webkit-min-device-pixel-ratio:0) and (max-width:768px){
   /* Safari only override */ ::i-block-chrome,
.services_title { 
      letter-spacing:0.5px ! important; 
       }
.title_Description { 
      letter-spacing:0.5px ! important; 
       }
.contentheaderNewPage h2{
      letter-spacing: 0.5px;
      }   
    }
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
  { @media {

.services_title { 
      letter-spacing:0.5px ! important; 
       }
.title_Description { 
      letter-spacing:0.5px ! important; 
       }
.contentheaderNewPage h2{
      letter-spacing: 0.5px;
    }   

  }}  
@media only screen and (device-width: 1024px) {
.pt20{
      padding-top: 20px ! important;
    }
.blogtext p{
      height: 185px ! important;
    }
.journytextblock{
      height: 129px ! important;
    }
.Journycaro9{
      top:1145px ! important;
    }
.add10{
      left: 128px ! important;
      top: -67px ! important;
    }
.add9{
      left:172px ! important;
      top:157px ! important;
    }
.Journycaro10{
      left:285px ! important;
    }
.newcard_HD{
      width: 220px ! important;
    }
.newcard {
      width: 200px ! important;
    }
.serflip-card img{
      width: 150px ! important;
    }
.navigation_tab{
      margin-left: 25px ! important;
    }
.new_wizardNotefor9{
      right: 0px ! important;
    }
.secondblockk {
    padding-top: 100px;
    }
  } 
@media only screen and (device-width: 1024px) {
.righttextaboutus h3{
      font-size: 23px ! important;
    }
.landscape_css{
      margin-right: 20px;
    }
.contacticon21{
      /*margin-left: 20px ! important;*/
    }
.contacticon2{
      /*margin-left: 20px;*/
    }
.newcard_HD{
      width: 220px ! important;
    }
.iconCircle{
      margin-left: 28% ! important;
    }
  
  }

 @media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
.righttextaboutus h3{
      font-size: 23px ! important;
    }
.landscape_css{
      margin-right: 20px;
    }
.contacticon21{
      /*margin-left: 20px ! important;*/
    }
.contacticon2{
      /*margin-left: 20px;*/
    }
  
  }
@media only screen and (device-width : 401px) { 
.serflip-cardservices img{
    width: 220px ! important;
  }
}
  @media only screen and (device-width : 400px) and (device-height :640px) { 
.serflip-cardservices img{
    width: 245px ! important;
  }
.contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
.serflip-card {
      width: 270px ! important;
  }
}
@media only screen and (device-width : 393px) { 
.serflip-cardservices img{
    margin-left: 0px ! important;
  }  
}  
@media only screen and (device-width : 360px) { 
.newcard_landscape {
      width: 245px ! important;
  }
}
@media only screen and (device-width : 360px) { 
.serflip-card{
    width: 240px ! important;
   } 
}
@media only screen and (device-width : 600px) and (device-height :1024px) { 
.newcard_landscape {
    width: 337px ! important;
    margin-left: 71px;
  }
 .serflip-cardservices img{
  width: 285px ! important;
 } 
} 
@media only screen and (device-width : 360px) and (device-height :640px) { 
 .serflip-card{
  width: 270px ! important;
 } 
 .serflip-card img {
    width: 215px ! important;
  }
  .blck1heightnew{
    width: 278px ! important;
  }
} 

@media only screen and (device-width : 320px) and (device-height : 533px){ 
.newcard_landscape {
      width: 215px ! important;
  }
}  
@media only screen and (device-width : 320px) and (device-height : 480px){ 
.newcard_landscape {
      width: 210px ! important;
  }
}    

@media only screen and (device-width : 240px) and (device-height : 320px){ 
.newcard_landscape {
      width: 155px ! important;
  }
}  
@media only screen and (device-width: 2048px) and (device-height:1536px ) and (orientation: landscape){
.newcard_landscape {
      width: 370px ! important;
      margin-left: 25px ! important;
      }
.newcard {
        width: 410px ! important;
        margin-left: 10px;
    }
.Expertise_maindiv{
      margin-left: 0px ! important;
    }

  } 
@media only screen and (device-width: 1536px){
.TypecomponentBtnImg{
      margin-left: 230px ! important;
    }
.TypebtnText{
      margin-left: 175px ! important;
    }
.righttextaboutus p{
      /*text-align: left ! important;*/
    }
.All1blog1 {
        width: 320px ! important;
    }
.Typecomp_1_img img{
      width: 100%;

    }
.BtnImageDown{
      padding-left: 0px ! important;
    }
.downimgtext{
      left:29em ! important;
    }
.B3T_challengesDiv{
      margin-left: 125px ! important;
    }
.ApplicationImg{
      padding-left: 50px ! important;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
.B4T_ServicesDiv{
      width: 638px ! important;
      margin-left: 75px ! important;
     }
  } 
@media only screen and (device-width: 2560px){
.Typecomp_1_img img{
      width: 100%;

    }
  }
@media only screen and (device-width: 2048px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img{
      width: 80% ! important;
    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 120px ! important;
    }
.B3T_challengesDiv {
    top: 45px;
    box-shadow: 1px 2px 4px 3px #c4bfbf;
    margin-left: 79px;
    margin-bottom: 40px;
    min-height: 200px;
    width: 493px;
    height: 232px;
    padding-top: 35px;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
  }   
@media only screen and (device-width: 2048px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;
    }
.iconCircle{
        margin-left: 39% ! important;
     }
.Type2Img{
      width: 80% ! important;
    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 120px ! important;
    }
.B3T_challengesDiv {
    top: 45px;
    box-shadow: 1px 2px 4px 3px #c4bfbf;
    margin-left: 79px;
    margin-bottom: 40px;
    min-height: 200px;
    width: 493px;
    height: 232px;
    padding-top: 35px;
    }
.newcard_landscape {
      width: 370px ! important;
      margin-left: 25px ! important;
      }
.newcard {
        width: 410px ! important;
        margin-left: 10px;
    }
.Expertise_maindiv{
      margin-left: 0px ! important;
    }
  }   
@media only screen and (min-device-width: 2560px){
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;
    }
.Type2Img img{
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 147px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 585px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 475px ! important;
    }
.new_round-tabfor9{
      margin-left: 67% ! important;
    }
.new_wizardNotefor9{
      right: -52px ! important;
    }
.new_wizardNote_for6{
      right: 102px ! important;
    }
.Allblog{
    margin-left: 80px  ! important;
    }
.newcard{
      width: 450px ! important;
      margin-left: 15px ! important
    }
.Mobile_leftPadd{
      /*margin-left: 290px !important;*/
    }
.MacProPdding{
      margin-left: 50px ! important;
    }
.TypebtnText_Padding{
          padding-top: 4% ! important;
      }
.MacProPddingfor3{
        margin-left: 290px ! important;
      }
.MacProPddingfor_{
        margin-left: 265px ! important;
      }
.newcard_HD {
        width: 500px ! important;
        margin-left: 30px ! important;
      }
.input-group .form-control{
        z-index: 0 ! important;
      } 
   }    
@media only screen and (device-width: 2560px){
.Allblog{
    width: 430px ! important;
   }
.iconCircle{
    margin-left: 41% ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img{
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 147px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 585px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 475px ! important;
    }
.new_round-tabfor9{
      margin-left: 67% ! important;
    }
.new_wizardNotefor9{
      right: -52px ! important;
    }
.new_wizardNote_for6{
      right: 102px ! important;
    }
.Allblog{
        margin-left: 80px  ! important;
      }
.newcard{
      width: 450px ! important;
      margin-left: 15px ! important
    }
.Mobile_leftPadd{
      /*margin-left: 290px !important;*/
    }
.MacProPdding{
      margin-left: 50px ! important;
    }
.TypebtnText_Padding{
          padding-top: 4% ! important;
    }
.MacProPddingfor3{
        margin-left: 290px ! important;
    }
.MacProPddingfor_{
        margin-left: 265px ! important;
      }
.input-group .form-control{
        z-index: 0 ! important;
      }  
   }    
 @media only screen and (device-width : 1680px) and (device-height : 1050px) { 
.newcard_HD {
    width: 330px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 }
 @media only screen and (device-width : 1680px){ 
.newcard_HD {
    width: 350px ! important;
  }
.newcard{
    width: 350px ! important;
  }
.dwstmTitle{
    font-size: 22px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.B3T_challengesDiv{
    width: 450px ! important;
  }
.B4T_img{
    margin-left: 150px ! important;
  }
.ApplicationImg{
    padding-left: 70px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        margin-left: 500px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
 }
 @media only screen and (device-width : 1280px) and (device-height : 800px) { 
.All1blog1{
    margin-left: 85px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.newcard{
    width: 250px ! important;
  }
 }
 @media only screen and (device-width : 1280px){ 
.All1blog1{
    margin-left: 85px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.DirectorText{
    padding-top: 10px ! important;
  }
.newcard_HD {
    width: 250px ! important;
  }
.newcard{
    width: 260px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 }
 @media screen and (device-width: 1920px){

.Type2Img{
    width: 85% ! important;

  }
.newcard{
    width: 370px ! important;
    margin-left: 25px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left:  ! important;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px ! important;
  }
.newcard_HD {
    width: 400px ! important;
  }
.roadjourny{
    z-index: 0 ! important;
  }
.Typecomp_1_img img{
    width: 100% ! important;
  }
.Type2Img{
    width: 85% ! important;
  }
.iconCircle{
      margin-left: 40% ! important;
     }
.jobformcontent{
        margin-left: 630px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }   

 }
@media screen and (device-width: 1856px){

.Type2Img{
    width: 85% ! important;
  }
.newcard{
    width: 385px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left: 85px;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px;
  }
.newcard_HD {
    width: 370px ! important;
  }
.All1blog1{
    margin-left: 80px ! important;
  }
.roadjourny{
    z-index: -1;
  }
.iconCircle{
    margin-left: 39% ! important;
 }
.input-group .form-control{
    z-index: 0 ! important;
  }
 .jobformcontent{
        margin-left: 600px ! important;
    }
 .jobpageform{
        margin-top: 50px ! important;
    }   
 }
  @media screen and (device-width: 1792px){

.Type2Img {
    width: 85% ! important;

  }
.newcard{
    width: 370px ! important;
  }
.ApplicationImg{
    padding-left: 110px ! important;
  }
.B3T_challengesDiv{
    margin-left: 85px;
    margin-bottom: 54px;
    min-height: 200px;
    width: 465px;
  }
.newcard_HD {
    width: 370px ! important;
  }
.All1blog1{
    margin-left: 80px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        margin-left: 600px ! important;
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
 }
@media only screen and (device-width : 1152px) {
.newcard_HD{
    width: 240px ! important;

   }
.newcard {
    width: 240px ! important;
  }
.All1blog1{
    margin-left: 71px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.journybg{
    display: none;
  }
.TypebtnText{
    margin-left: 0px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
 } 

@media only screen and (device-width : 1600px) {
.B3T_challengesDiv{
  width: 430px ! important;
 } 
.newcard {
  margin-left: 20px ! important;
 }
.ApplicationImg {
    padding-left: 81px !important;
  }
.All1blog1{
    margin-left: 71px ! important;
  }
.roadjourny{
    z-index: 0;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }
.jobformcontent{
        /*margin-left: 500px ! important;*/
    }
.jobpageform{
        margin-top: 50px ! important;
    }  
}
@media only screen and (device-width : 800px) {
.serflip-cardservices img{
    width: 230px ! important;
  }
.TypebtnText{
    margin-left: 0px ! important;
  }
.B3T_challengesDiv{
    width: 175px ! important;
  }
.B4T_img{
    margin-left: 18px;
  }
.B4T_txt{
    margin:0px;
    font-size: 10px ! important;
  }
 } 
@media only screen and (device-width : 2880px) {
.newcard_HD {
      width: 420px ! important;
      margin-left: 100px ! important;
  }
.commonBlockpadd {
    padding-left: 150px ! important;
  }
.iconCircle{
    margin-left: 43% ! important;
  }
.Allblog{
    width: 430px ! important;
   }
.Typecomp_1_img img{
      width: 100%;

    }
.Type2Img {
      width: 85% ! important;

    }
.TypebtnText{
      margin-left: 270px ! important;
    }
.TypecomponentBtnImg{
      margin-left: 200px ! important;
    }
.ApplicationImg{
      padding-left: 225px ! important;
    }
.B3T_challengesDiv {
      top: 45px;
      box-shadow: 0px 0px 3px 2px #c4bfbf;
      margin-left: 133px;
      margin-bottom: 40px;
      min-height: 200px;
      width: 678px;
      height: 232px;
      padding-top: 35px;
    }
.newcard {
        width: 425px ! important;
        margin-left: 80px ! important;
    }
.Expertise_maindiv{
        margin-left: 0px ! important;
    }
.input-group .form-control{
    z-index: 0 ! important;
  }
 } 
 @media only screen and (device-width : 3200px) {
.newcard_HD{
    width: 460px ! important;
  }
.input-group .form-control{
    z-index: 0 ! important;
  }

 }
.contactModalBody{
  padding-top: 0px;
}
.closeButton{
    color: #fff;
    font-size: 15px;
}
.closeButton:hover{
    color: #f00;
}
.contactBar{
  position: absolute;
  right: 0px;
  background-color: #f19000;
  width: 40px;
  height: 31px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
  /*z-index: 100000;*/
  top:62px;
}
.envelopeIcon{
  padding-right: 3px;
  color: #fff;
}
.NopaddingToA{
  padding: 0px ! important;
}
.dropdownforAbiutus{
  margin-top: 45px;
}
.contactBarres{
  position: absolute;
  left: 0px;
  background-color: #f19000;
  width: 35px;
  height: 30px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
  /*z-index: 100000;*/
  top:95px;
}
.navbar-static-top{
  z-index: 0 ! important;
  /*padding-top: 20px;*/
}
.rescontactBar{
  position: absolute;
  right: 5px;
  top:156px;
  background-color: #f19000;
  width: 35px;
  height: 30px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
}
.hovertext{
  cursor: pointer;
  color: #fff;
  width : 100px;
  /*right: 5px;*/
  display: none;
}

.contactBar:hover i{
  display: none;
  font-size: 50px;
}
.contactBarres:hover i{
  display: none;
  font-size: 50px;
}
.contactBar:hover{
  width: 130px;
}
.contactBarres:hover{
  width: 130px;
}
.contactBar:hover .hovertext{
  display: block;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}
.contactBarres:hover .hovertext{
  display: block;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}
.fixedContactBar i{
  font-weight: bolder;
  font-size: 20px;
}

.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 115px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}

#container {
  margin: 0 auto;
  max-width: 890px;
}
/*p { text-align: center; }*/
 .toggle, [id^=drop] {
 display: none;
}

.topnav a {
  float: left;
  display: block;
  color: #333;
  text-align: center;
  padding: 14px 8px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
}

.active {
  /*background-color: #4CAF50;*/
  color: #f08f00;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 12px;    
  border: none;
  outline: none;
  /*color: #000;*/
  padding: 14px 8px;
  background-color: inherit;
  /*font-family: inherit;*/
  margin: 0;
  font-weight: 700;
}

.dropdown-content {
  border-top: 3px solid #aaf;
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
}
.normaldropdown a{
  font-weight: 700 ! important;
}

.dropdown-content a {
  float: none;
  /*color: black;*/
  padding: 11px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px;  
  font-weight: normal;
}

.topnav a:hover, .dropdown:hover .dropbtn {
  /*background-color: #555;*/
  color: #f08f00;
}

.dropdown-content a:hover {
  /*background-color: #ddd;*/
  color: #f08f00;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen  and (device-width: 768px) {
    .dropdown-content a {
    float: none;
    /*color: black;*/
    padding: 15px 16px ! important;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 12px;  
    font-weight: normal;
  }

   
  }


@media only screen 
  and (device-width: 1440px) 
  and (device-height: 900px) 
  /*and (orientation: landscape) */
  {
     .dropdown .dropbtn {
      font-size: 15px ! important;
  }
    .topnav a {
      font-size: 15px ! important;
  }

}


@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .blogtext{
    height: 600px ! important;
  }
  .no-icon-list li{
    padding-bottom: 0px ! important;
  }
  .TopnavPadding{
    padding: 0px;
  }
  .topnav a.icon {
    top: 5px;
    right: 0px;
    position: absolute;
    float: right;
    /*right: -5px;*/
    display: block;
    /*background: #fff;*/
    border-radius: 4px;
    font-size: 27px;
    width: 70px;
    display: block;
  }
  .ct-topbar
  {

    position: fixed ! important;
    bottom: 0px ! important;
    top: 20px;
    left: 311px;
  }
  .headerlogo img{
    position: fixed;
    top: 12px;
    margin-left: -70px;
  }

}
@media screen and (max-width: 768px) {
 
  .topnav a {
   
    padding: 14px 3px;
    text-decoration: none;
    font-size: 9px;
    font-weight: 700;
    /*margin-top: 13px;*/
}
.dropdown .dropbtn {
    font-size: 9px;
    padding: 14px 2px;
    /*margin-top: 12px;*/
    
}
.contactBarres{
  right: 0px! important;

}

.dropdownintab a{
  padding-bottom: 0px;
  padding-top: 0px;
  padding-left: 15px;
  /*font-weight: 400 ! important;*/
}
.dropdownintab{
  padding-bottom: 0px ! important;
  padding-top: 0px ! important;
  /*font-weight: 400 ! important;*/

}
.expertise_a{
  font-weight: 400 ! important;
  font-size: 10px ! important;
}
.contactBar{
  display: none;
}
.dropdown-content{
  min-width: 100px;
  min-height: 120px;
}
.headerlogo img{
  height: 68px ! important;

}
.setlogo{
  padding-left: 0 ! important;

}
.expertiesdropdown{
  margin-left: -276px ! important;
}
}
@media screen and (max-width: 480px) {
    .dropdown .dropbtn {
    font-size: 13px ! important;  
  }
  .topnav.responsive a{
     font-size: 13px ! important;  

  }
}

@media screen and (max-width: 600px) {

  .userNameDiv{
    z-index: 0;
  }
  .topnav.responsive {
    position: absolute;
    background-color: #333333f5;
    top: -45px;
    width: 410px;
    height: 529px;
    left: -14px;
    z-index: 1000;
    padding-top: 60px;
   }
  .topnav.responsive .icon {
    position: absolute;
    color:#f19000;
    right: 20px;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    color:#fff;
    display: block;
    /*text-align: right;*/
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-content {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    color:#fff;
    /*text-align: right;*/
    
  }
  .topnav.responsive .dropdown-content{
    left: 233px ! important;
    top: 78px ! important;

  }
  .ct-language__dropdown{
    right: 154px ! important;
  }
}
.headerflow{
  position: fixed;
  z-index: 1;
  background-color: transparent;
  transition-duration: 0.8s;
}
.headerlogo img{
  /*margin-top: 15px;*/
  height: 75px;
}
.headerflowscroll{
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  height: 115px;
  box-shadow: 0 10px 14px rgba(92,141,255,.2);
  transition-duration: 0.8s;
}
.expertiesdropdown{
    width: 945px;
    margin-left: -444px;
    padding-left: 40px;
    z-index: 1001 ! important;
}
.columnhead{
  /*line-height: 20px;*/
  font-weight: 700 ! important;
  font-size: 12px ! important;
  text-align: center;
  color: #333 ! important;
}
.columnhead a:hover{
  color:#f08f00 ! important;
}
.columnheadsub{
  font-size: 12px;
}
.column {
  float: left;
  /*width: 25%;*/
  padding: 10px;
  background-color:#fff;
  /*height: 430px;*/
  padding:0px;
}
.setlogo{
  padding-left: 60px ! important;
  /*padding-top: 7px;*/
}

.contactBar{
  position: absolute;
  right: 0px;
  background-color: #f19000;
  width: 40px;
  height: 31px;
  top:62px;
  color: #fff;
  padding: 6px 8px 6px 6px ;
  border-radius: 3px;
  text-align: right;
  cursor: pointer;
  transition-duration: 0.7s;
  transition: width 1s;
  border: none;
}
.fixedContactBar i{
  font-weight: bolder;
  font-size: 20px;
}
.contactBar:hover i{
  display: none;
  font-size: 50px;
}
.contactBar:hover{
  width: 130px;
}
.Headerdownarraow{
  margin-left: 4px;
}
.hidepoweredby{
    right: 73px;
    width: 60px;
    height: 11px;
    position: absolute;
    background-color: #eee;
    z-index: 2000;
    top: 46px;
}
.new_flagdiv{
    right: 113px;
    position: absolute;
    width: 122px;
    top: 20px;
    z-index: 3000;
}
.onscrollheader{
    width: 157px ! important;
    height: 17px;
    background-color: #fff;
    position: absolute;
    right: 0px;
    top: -17px;
}
.goog-tooltip {
    display: none !important;
}
.goog-tooltip:hover {
    display: none !important;
}
.goog-text-highlight {
    background-color: transparent !important;
    border: none !important; 
    box-shadow: none !important;
}
.goog-logo-link {
   display:none !important;
} 

.goog-te-gadget{
   color: transparent !important;
}
 .goog-te-banner-frame{
    visibility:hidden !important;
    }

.goog-te-banner-frame.skiptranslate {
  display: none !important;
} 
body { top: 0px !important; }    





.ct-topbar {
  text-align: right;
  position: absolute;
  right: -31px;
  bottom: 65px;
  height: 20px;
  /*background: #eee;*/
}
.ct-topbar__list {
  margin-bottom: 0px;
}
.ct-language__dropdown{
  padding-top: 8px;
  max-height: 0;
  /*overflow: hidden;*/
  position: absolute; /* IE 9 */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  top: -12%;
  display: block;
  right: 135px;
 /* -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;*/
  width: 35px;
  text-align: center;
  padding-top: 0;
  z-index:200;
}
.ct-language__dropdown li{
  background: transparent;
  padding: 0px;
}
.ct-language__dropdown li a{
  display: block;
  color: #333;
}
.new_container{
  width: 117px ! important;
}
.ct-language__dropdown li:first-child{
  padding-top: 10px;
  border-radius: 3px 3px 0 0;
}
.ct-language__dropdown li:last-child{
  padding-bottom: 10px;
  border-radius: 0 0 3px 3px;
}
.ct-language__dropdown li:hover{
  /*background: #fff;*/
}
.ct-language__dropdown:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 8px;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom: 8px solid transparent;
}
.ct-language{
  position: relative;
  background: transparent;
  color: #fff;
  padding: 10px 0;
}
.ct-language:hover .ct-language__dropdown{
/*  max-height: 200px;
  padding-top: 8px;*/
}
.goog-te-gadget-icon{
background:none !important;
background-color: #eee ! important;
border:1px solid #eee ! important;
}
.goog-te-gadget-simple{
  background-color: #eee ! important;
  border:none ! important;
  bottom: 8px;
  right: -50px;
  position: absolute;

}
.language_icon{
    position: absolute;
    right: -12px;
    top: 4px;
    z-index: 3000;
}
.topnav a >.active {
    color:red;
    }

  .Currentactivetab{
    color:orange ! important;
  }
  .normalTabColor{
    color:#333 ! important;
  }

 @media only screen and (device-width : 375px) and (device-height : 812px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
    /*top:-15px ! important;*/
     /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 68px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 0px;
    }
    .ct-topbar{
      left:293px ! important;
      top:38px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
     }
     .topnav.responsive{
      left:-41px;
     }

  } 
  @media only screen and (device-width : 400px) and (device-height : 700px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
  @media only screen and (device-width : 400px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
  @media only screen and (device-width : 400px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -67px;
    /*margin-top: 12px;*/
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }   
   
   @media only screen and (device-width : 360px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 700px ! important;
      }
     .ct-topbar{
      left: 303px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive{
      left:-24px ! important;
    } 
    .topnav a.icon{
      color: #333;
    }
    .topnav a.icon {
      top: -10px;
      right: -26px;

     }
     .topnav.responsive .icon {
      color: #f19000 ! important;
      }
    }  
  @media only screen and (device-width : 414px) and (device-height : 736px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    top:-15px ! important;
     right: -16px ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 12px;
    }
    .ct-topbar{
      right: -78px ! important;
    }

  }  
  @media only screen and (device-width : 375px) and (device-height : 667px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 308px ! important;
      top:38px;
    }

  } 
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -15px;
    }
    .ct-topbar{
      left: 254px ! important;
    }
    .topnav.responsive{
      left:-62px;
    }

  } 
   @media only screen and (device-width : 320px) and (device-height : 480px) { 
     .ct-topbar{
      left: 264px ! important;
    }
    .topnav a.icon {
      top: -13px;
      right: -28px;
      color :#585755 ! important;
     }
    .topnav.responsive{
      top: -53px;
      width: 410px;
      height: 529px;
      left: -60px;
      } 
      .headerlogo img {
      height: 60px ! important;
      /*z-index: 1000;*/
      margin-left: -70px;
      margin-top: -5px;
      }
   }  
  @media only screen and (device-width : 375px) and (device-height : 700px) { 
  .topnav a.icon {
    top   : -11px;
    right : -20px;
    color :#585755 ! important;
  }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -5px;
    }
    .ct-topbar{
      left: 312px ! important;
    }
    .topnav.responsive{
      left:-62px;
    }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      right: -20px;
       position: absolute;
    }
    .topnav.responsive {
        left: -50px;
    }
    .headerflowscroll{
      height: 95px ! important;
    }

  } 
   @media only screen and (device-width : 384px) and (device-height : 640px) { 
    .topnav.responsive{
      left:-32px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

   } 
    @media only screen and (device-width : 600px) and (device-height : 960px) { 
    .resbanner {
        height: 1024px ! important;
    }
    .headerlogo img{
      margin-left: -40px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

   } 

  @media only screen and (device-width : 411px) and (device-height : 823px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-15px ! important;
    right: -16px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: 12px;
    }
    .ct-topbar{
      left: 344px ! important;
      top:20px;
    }
    .topnav.responsive .icon{
      color: #f19000 ! important;
    }
    .topnav.responsive {
        background-color: #333333f5;
        top: -43px;
        width: 430px;
        left: -26px;
    }

  }  

 @media only screen and (device-width : 411px) and (device-height : 731px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-11px ! important;
    right: -16px ! important;
     color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 344px ! important;
      top:25px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
  @media only screen and (device-width : 384px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top:-11px ! important;
    right: -16px ! important;
     color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -50px;
    margin-top: -12px;
    }
    .ct-topbar{
      left: 319px ! important;
      top:25px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
    .headerflowscroll{
      height: 95px;
    }

  }  
   @media only screen and (device-width : 390px) and (device-height : 844px) { 
        .resbanner {
        margin-top: 104px;
        height: 748px ! important;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }

   @media only screen and (device-width : 360px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
     top: -9px;
     right: -26px;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 300px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive{
      left:-26px ! important;
    }

  }  
   @media only screen and (device-width : 360px) and (device-height : 700px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
     color :#585755 ! important;
    /*top:-15px ! important;*/
    /*right: -16px ! important;*/
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: 0px;
    }
    .ct-topbar{
      left: 293px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
   @media only screen and (device-width : 401px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
   @media only screen and (device-width : 240px) and (device-height : 320px) { 
    .headerlogo img{
      height: 40px ! important;
      margin-left: -70px ! important;
    }
    .topnav a.icon {
        top: -10px;
        right: -32px;
         color :#585755 ! important;
      }
      .topnav.responsive {
        position: absolute;
        background-color: #333333f5;
        top: -56px;
        width: 363px;
        height: 333px;
        left: -92px;
        z-index: 1000;
        padding-top: 10px;
    }
    .topnav a {
        padding: 11px 3px;
      }
      .topnav.responsive a {
          font-size: 9px ! important;
      }
      .dropdown .dropbtn{
        font-size: 9px ! important;
      }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 189px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    @media only screen and (device-width : 400px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    
    @media only screen and (device-width : 400px) and (device-height : 600px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
     .topnav a.icon {
        top: -10px;
        right: -32px;
         color :#585755 ! important;
      }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
    @media only screen and (device-width : 400px) and (device-height : 700px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .topnav a.icon {
        top: -10px;
        right: -32px ! important;
         color :#585755 ! important;
      }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
     @media only screen and (device-width : 400px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 720px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive .icon {
      color: #f19000 ! important;
      } 
   }
    @media only screen and (device-width : 401px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }  
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
     @media only screen and (device-width : 401px) and (device-height : 700px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
     .ct-topbar{
      left: 333px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    } 
    .topnav.responsive .icon {
      color: #f19000 ! important;
      } 


   }
     @media only screen and (device-width : 320px) and (device-height : 768px) { 
    .headerlogo img{
      margin-left: -70px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
   }
     @media only screen and (device-width : 600px) and (device-height : 1024px) { 
    .headerlogo img{
      margin-left: -50px ! important;
    }
    .resbanner {
        height: 770px ! important;
      }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }

    @media only screen and (device-width : 320px) and (device-height : 533px) { 
    .headerlogo img{
      margin-left: -70px ! important;
      margin-top: -10px ! important;
    }
    .resbanner {
        height: 540px ! important;
      }
      .ct-topbar{
        left: 260px ! important;
         margin-top: 8px;
      }
      .topnav a.icon {
        top: -9px;
        right: -23px;
        color :#585755 ! important;
      }
      .topnav.responsive{
        left:-59px ! important;
      }
      .headerflowscroll{
        height: 90px ! important;
      }
      .topnav.responsive .icon {
      color: #f19000 ! important;
      }
   }
 @media only screen and (device-width : 480px) and (device-height : 854px) { 
  .ct-topbar{
    left:416px ! important;
  }
  .topnav a.icon{
    right: -18px ! important;
  }
  .topnav.responsive .icon {
      color: #f19000 ! important;
      }

 }

   @media only screen and (device-width : 412px) and (device-height : 732px) { 
     .topnav a{
      /*font-size: 0px ! important;*/
     }
     .topnav a.icon{
      color :#585755 ! important;
      right: -27px ! important;
      /*top:-15px ! important;*/
      /*right: -16px ! important;*/
     }
     .headerlogo img {
      height: 65px ! important;
      /*z-index: 1000;*/
          margin-left: -64px;
         margin-top: -5px;
      }
      .ct-topbar{
          left: 350px ! important;
          margin-top: 8px;
      }
       .headerflowscroll{
        height: 100px;
      }
      .topnav.responsive .icon {
        color: #f19000 ! important;
        }

  }  
  @media only screen and (device-width : 412px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top: -15px ! important;
    right: -16px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 68px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 345px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  
   @media only screen and (device-width : 320px) and (device-height : 640px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
    top: -17px ! important;
    right: -32px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -70px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 271px ! important;
       margin-top: 8px;
    }
     .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive{
      left:-64px ! important;
    }
    .topnav.responsive .icon{
          left: 320px;
          top: -9px ! important;
          color: #f19000 ! important;
      }

    .topnav.responsive .icon {
      color: #f19000 ! important;
      }
  }  

@media only screen and (device-width : 393px) and (device-height : 851px) { 
   .topnav a{
    /*font-size: 0px ! important;*/
   }
   .topnav a.icon{
   top: -10px ! important;
    right: -5px ! important;
    color :#585755 ! important;
   }
   .headerlogo img {
    height: 60px ! important;
    /*z-index: 1000;*/
    margin-left: -60px;
    margin-top: -10px;
    }
    .ct-topbar{
      left: 317px ! important;
      margin-top: 8px;
    }
    .headerflowscroll{
      height: 100px;
    }
    .topnav.responsive .icon {
      color: #f19000 ! important;
      }

  }  

  @media only screen 
  and (device-width: 1440px) 
  and (device-height: 900px) 

  /*and (orientation: landscape) */
 { 
  .topnav a{
    font-size: 14px;
  }
  .dropdown .dropbtn{
    font-size: 14px;
  }
 } 

 @media only screen 
  and (device-width: 1024px) 
 {
    .setlogo{
      padding-left: 10px ! important;
    }
    .dropdown .dropbtn{
      padding: 14px 2px;
    }
    .topnav a{
      padding: 14px 2px;

    }
}
 
  @media only screen 
  and (device-width: 768px)  {
    .setlogo{
      padding-left: 15px ! important;
    }
    
}
 @media only screen 
  and (device-width: 1536px) 
  and (device-height: 2048px) 
  and (orientation: portrait){
     .topnav a{
      padding: 14px 14px;
     }
     .dropdown .dropbtn{
      padding: 14px 14px;

     }
  }   
 @media only screen and (device-width : 2560px) and (device-height : 1600px) {
  .headerlogo img{
    height: 100px ! important;
  }
  .topnav a{
    font-size: 20px ! important;
  }
  .dropdown .dropbtn{
    font-size: 20px ! important;
  }
 }
 @media only screen and (device-width : 800px) {
  .headerlogo img{
    height: 78px ! important;
    margin-left: -57px;
  }
  .topnav a{
    font-size: 9px ! important;
    padding: -2px 2px ! important; 
  }
  .dropdown .dropbtn{
    font-size: 9px ! important;
    padding: 14px 2px ! important;
  }
 }
  @media only screen and (device-width : 851px) {
  .headerlogo img{
    height: 78px ! important;
    margin-left: -57px;
  }
  .topnav a{
    font-size: 9px ! important;
    padding: -2px 2px ! important; 
  }
  .dropdown .dropbtn{
    font-size: 9px ! important;
    padding: 14px 2px ! important;
  }
 }
 @media screen and (device-width:1024px){
     .setlogo{
        padding-left: 22px ! important;
     }

}






.megamenuWrapper{
	width: 90%;
	height: 500px;
	background-color: #fff;
	padding: 15px;
	border: 1px solid #999;
	border-top: 3px solid #aaf;
	margin: auto; 
	margin-top: 100px;
}

.menuCol{
	width: 20%;
}
.evencol{
	background-color: #FAFAFA;
}
.evencol{
	background-color: #FAFAFA;
}
.contactHeader{
  color: #fff;
  font-size: 25px;   
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: 
}
.contactformBtn{
  font-weight: 600;
  padding: 10px 20px;
  /*margin-top: 15px;*/
  color: #fff
}
.cuformWall{
	height: 500px;
  margin-top: -67px;
  padding: 0px;
	/*background-color: #aaa;*/
}
.cuformWall h1, .cuformWall h3, .cuformWall p{
    color: #555;
}
.bt30{
		margin-bottom:  10px;
	}
.sbtn{
    /*background: -webkit-linear-gradient(left,#ffc600 0%,#ff4200 50%,#ffc600 100%);*/
    color: #FFF!important;
}
.sbtn:focus{    
    border: none !important;
    box-shadow: none !important;
}
.ContactmodalContent{
  /*background-image: linear-gradient(to bottom right,#f19000d9,rgba(241, 144, 0, 0.45)); */
  margin-top: 6%;
  background: #fff;
  border-radius: 04px;
  border :none;
  height: 480px;
  z-index: 15000;
}
.padd10{
  padding-top: 10px;
}
.panel-title>.small, .panel-title>.small>a, .panel-title>a, .panel-title>small, .panel-title>small>a {
    color: #f57507;
    font-weight: 550;  
}


.inputtextContent{
  border: 1px solid #ccc;    
}
.closeBtn{
  font-size: 35px;
  padding-right: 15px !important;
  color: #000;
}

.cancelBtn{
  background-color: #999;
}
.cancelBtn:hover{
  background-color: #666;
}
.whatsupIcon{
  font-size: 19px !important;
  padding: 9px 11px !important;
}
.whatsupIcon:hover{
  background-color: #fff;
  color:#005b99;
}
.linkeninicon:hover{
  background-color: #fff;
  color:#005b99;
}
.twittericon:hover{
  background-color: #fff;
  color:#005b99;
}
.socialicon li a .fa:hover{
  color:#005b99;
}

.animationoveflow{
  overflow: hidden;
/*  width: 100%;
  top: 900px;
*/}

 .pt20{
      padding-top: 20px ! important;
    }
.footer-two{
 font-size: 16px;
 color: #fff ! important;
 /*background-color: #367588;*/
 /*border-top: 1px solid #eee;*/
 height: 335px;
 padding-top: 35px ! important;
 padding-bottom: 10px;
 padding-right: 0px;
}
.footer-two a{
color: #fff;

}
.no-icon-list li{
  padding-bottom: 10px;
}
.footer-end a{
color: #fff;

}
.footer-end{
    height: 55px!important;

}
.footer-two ul{
    list-style: none;
    text-decoration: none;

}
.footer-two ul li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    font-size: 15px;

}
.footer-two ul li a:hover{
    color: #42E8E0;

}
.footer-two p a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    font-style: normal;

}
.footer-two p a:hover{
    color: #42E8E0;
}
.bb-solid-1{
    /*border-bottom: 1px solid #eee;*/
}

.font-montserrat{
    /*font-family: 'Montserrat', sans-serif;*/
    font-size: 16px;
    font-weight: bold;
}
ul.no-icon-list {
    margin: 0;
    padding: 0;
}
.social.social-two a {
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    text-align: center;
    border: 1px solid #ddd;
    float: left;
    display: block;
    margin-right: 5px;
    border-radius: 2px;
}


.footer-two ul{
    list-style: none;
    text-decoration: none;

}
.footer-two ul li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;

}
.socialicon1 {
  margin-right: 34px ! important;
}
.socialicon li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    float: left;
    margin-right: 11px;

}
.socialicon1 li a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    float: right;
    margin-right: 19px;
    margin-top: 15px;

}
.socialicon li a .fa{
    border: 1px solid #fff;
    border-radius: 50%;
    padding: 11px 11px;
    /*background-color: #fff;*/
    color: #fff;
    font-size: 16px;
}
.facebook{
    padding: 11px 14px !important;
}
.facebook:hover{
  background-color: #fff;
  color:#005b99;
}
.footer-two ul li a:hover{
    /*color: #000;*/

}
.footer-two p a{
    line-height: 25px;
    text-decoration: none;
    text-align: left;
    /*font-family: 'Open Sans', sans-serif !important;*/
    font-style: normal;

}
.footer-two p a:hover{
    /*color: #000;*/
}
.bb-solid-1{
    /*border-bottom: 1px solid #eee;*/
}

.font-montserrat{
    /*font-family: 'Montserrat', sans-serif;*/
}
ul.no-icon-list {
    margin: 0;
    padding: 0;
}
ul.no-icon-list1 {
    margin: 0;
    padding: 0;
}
.social.social-two a {
    width: 30px;
    height: 30px;
    padding: 5px 8px;
    text-align: center;
    border: 1px solid #ddd;
    float: left;
    display: block;
    margin-right: 5px;
    border-radius: 2px;
}
.subscribeMail{
  border-radius: 3px;
  padding-left:22px;
  color: #555;
  height: 40px;
}
.subscribeBox{
    /*background-color: #fff;*/
    color: #fff;
    margin-top: 70px;
    width: 100%;
    /*border-radius: 20px;*/
    font-weight: 700;
    padding: 1px 5px;
    height: 80px;
}
.subscribeBox1{
  margin-top: 0px ! important;
}
.subscribeBtn{
  height: 41px;
  border-radius: 3px;
  font-weight: 700;
  color: #fff;
  position: absolute;
  left:-3px;
  background-color: #f19000;
}
.footeranimation{
    position: absolute;
    z-index: 1;
    /*top: 0px;*/
    /*left: 0px;*/
    /*height: 60px;*/
}

.footimg1{
    position: relative;
    bottom: 59px;
    left: 39px;
    height: 60px;
}
.footimg7{
    position: relative;
    bottom: 97px;
    left: 985px;
    height: 100px;
}
.footimg5{
    position: relative;
    bottom: 106px;
    left: 619px;
    height: 109px;
}
.footimg4{
    position: relative;
    bottom: 49px;
    left: 485px;
    height: 50px;
}
.footimg3{
    position: relative;
    bottom: 49px;
    left: 330px;
    height: 50px;
}
.footimg2{
    position: relative;
    bottom: 40px;
    left: 141px;
    height: 43px
}
.footeranimationtruck{
    position: relative;
    top: 59.5px;
    left: -100px;
    z-index: 100;
    -webkit-animation: truck1 25s linear infinite;
            animation: truck1 25s linear infinite;
}

.foot8wheel1{
 position: relative;
    right: 85px;
    top: 9px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.foot8wheel2{
  position: relative;
    right: 42px;
    top: 9px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}

 .foot6wheel1{
  position: relative;
    left: 9px;
    bottom: 14px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.foot6wheel2{
  position: relative;
   left: 13px;
    bottom: 14px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}

.foot6wheel3{
  position: relative;
   left: 48px;
    bottom: 14px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}

.footeranimationtruck2{
    position: relative;
    top: 17px;
    left: -650px;
    z-index: 100;
    -webkit-animation:  truck2 30s linear infinite;
            animation:  truck2 30s linear infinite;

}

.viman img{
    position: relative;
    top: 11px;
    left: -200px;
    z-index: 100;
    -webkit-animation: viman 15s linear infinite;
            animation: viman 15s linear infinite;
}

.foot1anim{
    position: relative;
   left: 38.5px;
    bottom: 101px;
    overflow: hidden;

}
.foot1anim img{
  margin-top: -10px;
  -webkit-animation:  foot1anim 2s linear  infinite;
          animation:  foot1anim 2s linear  infinite;

}

.foot3anim{
    position: relative;
    left: 332px;
    bottom: 116px;
}
.foot3anim img{
  -webkit-animation:  foot3anim 4s linear  infinite;
          animation:  foot3anim 4s linear  infinite;

}

@-webkit-keyframes foot3anim {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  50% {
  -webkit-transform: rotate(8deg);
          transform: rotate(8deg);
  }
  
}

@keyframes foot3anim {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  50% {
  -webkit-transform: rotate(8deg);
          transform: rotate(8deg);
  }
  
}

@-webkit-keyframes foot1anim {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}

@keyframes foot1anim {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}


@-webkit-keyframes viman {
  
  0% {
    left:-200px ;
  }
  100% {
   left:1500px ;
  }
  
}


@keyframes viman {
  
  0% {
    left:-200px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes truck1 {
  
  0% {
    left:-100px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes truck1 {
  
  0% {
    left:-100px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes truck2 {
  
 0% {
    left:-650px ; 
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes truck2 {
  
 0% {
    left:-650px ; 
  }
  100% {
   left:1500px ;
  }
  
}

.footoverflow{
    width: 100%;
    overflow: hidden;
}


/*mob view*/
.responsivefooter{
  height: 600px ! important;

}
.resicons{
  /*margin-left: 35px;*/
}
.resNewsLetter{
  font-size: 15px ! important;
  padding-left: 161px ! important;
  padding-top: 94px ! important; 
}
.ressubscribeMail{
  margin-left: 35px;
}
.ressubscribeBtn{
  margin-left: 54px;
}
.resfooterend{
  position: absolute;
  height: 65px ! important;
  /*width: 345px;*/
}
.resmr20{
  /*font-size: 11px ! important;*/
}
.footerdivs{
  width: 180px;
  padding-left: 70px;
  letter-spacing: 1px;
}
.Hidden_div{
  height: 8px;
}
.footermailicon{
  font-size: 9px;
  color: #fff;
  padding-right: 5px;
}
.iconPadding{
  padding-right: 10px;
}
.pb351{
  margin-left: 55px;
}
.footerdiv1{
  width:140px;
  letter-spacing: 1px;
}
.footerText{
  color:#ffffff ! important;
}


/* Safari only */ 
.safari-col-4:not(:root:root) { 
	width:25% !important;
}
.newAddress{
  /*margin-bottom: 0px;*/
  letter-spacing: 1px;
  padding-left: 0px;
}
.newAddress1{
  margin-top: 10px;
  padding-left: 0px;
  letter-spacing: 1px;
}
.newaddressdiv{
  padding-left: 33px ! important;
}

@media screen and (max-width: 801px) {
  .fs15{
    margin-left: 20px;
    font-size: 13px ! important;
    }
    .font-montserrat{
      font-size: 14px ! important;
    }
    .connectUsdiv{
     margin-top: 20px ! important;
    margin-left: 10px ! important;
    }

  
}

.connectUsDiv{
  /*padding-left: 70px;*/
}
@media (min-width:600px)){
  

}
@media (min-width:320px) {
   .responsivefooter{
    height: 600px ! important;
  }


}
@media only screen 
  and (device-width: 1024px)
 {
    .footer-two{
      height: 340px ! important;
    }
}

@media only screen and (device-width: 1024px)
 {
    .footer-two{
      height: 438px ! important;
    }
     .footerdiv1{
    padding:0px ! important;
  }
}


@media only screen and (device-width: 1024px) {
    .pt20{
      padding-top: 20px ! important;
    }
    .blogtext p{
      height: 185px ! important;
    }
    .journytextblock{
      height: 129px ! important;
    }
    .Journycaro9{
      top:1145px ! important;
    }
    .add10{
      left: 128px ! important;
      top: -67px ! important;
    }
    .add9{
      left:172px ! important;
      top:157px ! important;
    }
    .Journycaro10{
      left:285px ! important;
    }
    .newcard_HD{
      width: 220px ! important;
    }
    .navigation_tab{
      margin-left: 25px ! important;
    }.new_wizardNotefor9{
      right: 0px ! important;
    }
  } 
  @media only screen and (device-width: 1024px) {
     .footer-two{
    height: 440px ! important;
  }
  .topnav a{
        padding: 14px 7px;
  }
  .footerdiv1{
      padding: 0px;
    }
}
@media only screen and (device-width: 1024px){
    .padding_640{
      margin-left: 0px ! important;
    }
    .footerdiv1{
      padding: 0px;
    }
    .pt20{
      padding-top: 20px;
    }

}
@media only screen and (device-width: 1024px) {
     .Journycaro9{
      left:-25px;
     }
     .Journycaro4{
      top: 681px;
     }
  }
 
  @media only screen and (device-width : 375px) and (device-height : 812px) { 
  .responsivefooter{
    height: 592px ! important;
  }
}  
@media only screen and (device-width : 393px) and (device-height : 851px) { 
  .responsivefooter{
    height: 593px ! important;
  }
} 
@media only screen and (device-width : 414px) and (device-height : 736px) { 
  .responsivefooter{
    height: 580px ! important;
  }
}  
 @media only screen and (device-width : 320px) and (device-height : 533px) { 
  .responsivefooter a{
    font-size: 12px ! important;
  }
  .footer-two ul li a{
    line-height: 20px;
  }
 }
@media only screen and (device-width : 2560px){
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 2288px ! important;
  }
  .footimg5{
    left:1900px ! important;
  }
  .footimg4{
    left:1668px ! important;
  }
  .footimg3{
   left:1430px ! important;
  }
  .foot3anim{
    left:1095px ! important;
  }
  .footimg2{
    left:675px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    /*padding-left: 250px ! important;*/
  }
  .newaddressdiv{
    padding-left: 325px ! important;
  }
}
@media only screen and (device-width : 2880px) {
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 2288px ! important;
  }
  .footimg5{
    left:1900px ! important;
  }
  .footimg4{
    left:1668px ! important;
  }
  .footimg3{
   left:1430px ! important;
  }
  .foot3anim{
    left:1095px ! important;
  }
  .footimg2{
    left:675px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    padding-left: 250px ! important;
  }
  .newaddressdiv{
    padding-left: 325px ! important;
  }
}
@media only screen and (device-width : 2048px) and (device-height : 1536px) {
   .pt20 {
      padding-top: 17px ! important;
  }
  .footimg7{
       left: 1780px ! important;
  }
  .footimg5{
    left:1400px ! important;
  }
  .footimg4{
    left:1468px ! important;
  }
  .footimg3{
   left:1130px ! important;
  }
  .foot3anim{
    left:895px ! important;
  }
  .footimg2{
    left:352px ! important;
    bottom: 84px;
    height: 94px;
  }
  .footerdivs{
    /*padding-left: 250px ! important;*/
  }
  .newaddressdiv{
    padding-left: 195px ! important;
  }
}
@media only screen and (device-width : 1152px) { 
  .footer-two{
    height: 398px ! important;
  }
  .newaddressdiv{
    padding-left: 25px ! important;
  }
  .footerdiv1{
    padding:0px ! important;
  }
}
@media only screen and (device-width : 1280px) { 
  .footer-two{
    height: 375px ! important;
  }
  .pt20 {
    padding-top: 18px ! important;
  }
  .newaddressdiv{
    padding-left: 25px ! important;
  }
  .footerdiv1{
    padding:0px ! important;
  }

}
@media only screen and (device-width : 1920px) { 
  .footer-two{
    height: 375px ! important;
  }
  .pt20 {
    padding-top: 18px ! important;
  }
  .newaddressdiv{
    padding-left: 100px ! important;
  }

}


.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}

/* for safari */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
	 */
	 
	 .new_HoverDiv1 img, .new_HoverDiv2 img, .new_HoverDiv3 img, .new_HoverDiv4 img, .new_HoverDiv5 img, .new_HoverDiv6 img, .new_HoverDiv7 img, .new_HoverDivforboat img, .new_HoverDiv img{
		-webkit-animation : ''
	 }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .new_HoverDiv1 img, .new_HoverDiv2 img, .new_HoverDiv3 img, .new_HoverDiv4 img, .new_HoverDiv5 img, .new_HoverDiv6 img, .new_HoverDiv7 img, .new_HoverDivforboat img, .new_HoverDiv img{
		-webkit-animation : ' ' !important;      
	 }

}





.banner-wrapper{
	margin-top: 0px;
  /*margin-bottom: 18px;*/
  overflow: hidden;
}
.full-height {
  height: 100%;
  }
.banner{
}
.bannerContainer1{
  opacity: 1;
  background-size: 100% 100% ;
  width: 100%;
  height: 48em;
  margin-bottom: 50px ! important;
  background-image: url(/images/Banner3_.png);

}
.bannerContainer {
    background-size: 100% 100% ;
    width: 100%;
    height: 48em;
    background-image: url(/images/banner.png);
}
.resbannerimg{
  background-size: 100% 100%;
  height: 500px ! important;
  background-repeat: no-repeat;
  background-image: url("/images/newbanner_1.png");
}
.lead {
    font-size: 18px;
    line-height: 28px;
}
.title-h1{
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
    font-weight: 500;
    letter-spacing: -.4px;
}
.Home_Buttons {
    margin-top: 40px;
}
.Home_BlueButton {
    margin-right: 20px;
}
.btn--fill {
	border-radius: 28px;
    background-color: #37cd8f;
    color: #fff;
}
.btn--shadow-blue {
    box-shadow: 0 10px 25px 0 rgba(65,54,181,.45);
}
.btn-style{
	display: inline-block;
    border: 2px solid transparent;
    border-radius: 28px;
    cursor: pointer;
    font: inherit;
    font-weight: 500;
    line-height: 44px;
    outline: none;
    text-align: center;
    text-decoration: none;
    padding: 0 30px;
    transition: background-color .2s,color .2s,box-shadow .2s;
}
.btn-style:hover{
	color: #fff !important;
}
.btn-style:focus{
	color: #fff !important;
}
.btn--stroke-white {
	background: transparent;
    border-color: #fff;
    color: #fff;
}

.banner-wrapper:before {
    left: -50px;
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    opacity: .3;
}

.banner-wrapper:after {
    left: 50px;
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    opacity: .1;
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}

.banner-wrapper:after, .banner-wrapper:before {
    z-index: 0;
    bottom: -5px;
    left: 0;
    background-color: #eee;
    content: "";
}

.cloud1{
    position: absolute;
    left: -250px;
    -webkit-animation:  cloud1 70s linear  infinite;
            animation:  cloud1 70s linear  infinite;
    z-index: 100;
    top: 3px;

}
.cloud1 img{
height: 170px;
}
.cloud2 img{
height: 140px;
}
.cloud3 img{
height: 120px;
}
.cloud4 img{
height: 150px;
}
.car{
    position: absolute;
    right: 5em;
    top: 42em;
    -webkit-animation:  car 10s linear  infinite;
            animation:  car 10s linear  infinite;
}
.car img{
}
.contanim{
    position: absolute;
    right: 5em;
    top: 26em;
    -webkit-animation:  contanim 10s linear  infinite;
            animation:  contanim 10s linear  infinite;
}
.contanim img{
}
.wheel1{
  position: absolute;
  right: 22px;
  top: 21px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.wheel2{
  position: absolute;
  right: 89px;
  top: 21px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.truckanim1{
    position: relative;
    left: 721px;
    top: 331px;
    overflow: hidden;
    /*background-color: red;*/
    height: 57px;
    width: 240px;
}
.truckanim{
    position: absolute;
    right: 0px;
    top: 0px;
    -webkit-animation:  truckanim 10s linear  infinite;
            animation:  truckanim 10s linear  infinite;
}
.truckanim img{
  margin-right:127px;
}
.truckanim2{
    position: relative;
    left: 959px;
    top: 387px;
    overflow: hidden;
    /* background-color: red; */
    height: 57px;
    width: 418px;
    z-index: 2;
}
.truckanimtion{
    position: absolute;
    right: 0px;
    top: 0px;
    -webkit-animation:  truckanimtion 10s linear  infinite;
            animation:  truckanimtion 10s linear  infinite;
}
.truckanimtion img{
  margin-right:645px;
}
.twheel4{
  position: absolute;
  right: 18px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.twheel5{
  position: absolute;
  right: 72px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.twheel6{
  position: absolute;
  right: 91px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}

.truckfront{
  position: absolute;
   right: 325px;
    top: 351px;
    overflow: hidden;
    /*background-color: red;*/
    z-index: 1;
    height: 123px;
}
.truckfront img{
  margin-top:0px;
  -webkit-animation: truckfront 10s 7s linear  infinite;
          animation: truckfront 10s 7s linear  infinite;
  opacity: 0;
}
.twheel1{
  position: absolute;
  right: 18px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.twheel2{
  position: absolute;
  right: 72px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.twheel3{
  position: absolute;
  right: 91px;
  top: 34px;
  -webkit-animation:  wheel 0.4s linear  infinite;
          animation:  wheel 0.4s linear  infinite;
}
.cloud2{
    position: absolute;
    left: -330px;
    /*top: 0px;*/
    -webkit-animation:  cloud2 110s linear  infinite;
            animation:  cloud2 110s linear  infinite;
    z-index: 101;
    top: 3px;
}
.cloud3{
    position: absolute;
    left: -750px;
    -webkit-animation:  cloud3 140s linear  infinite;
            animation:  cloud3 140s linear  infinite;
    z-index: 102;
    top: 3px;
}
.cloud4{
    position: absolute;
    left: -950px;
    width: 100px;
    -webkit-animation:  cloud4 165s linear  infinite;
            animation:  cloud4 165s linear  infinite;
    z-index: 103;
    top: 3px;

}
.bannersun{
    position: absolute;
    left: 419px;
    z-index: 10;
    bottom: 0px;
    right: 20px;
    top: 120px;
}
.tree1{
    position: absolute;
    right: 269px;
    top: 367px;
    z-index: 10;
}
.tree2{
    position: absolute;
    right: 101px;
    top: 375px;
    z-index: 10;
}
.tree3{
    position: absolute;
    right: 208px;
    top: 587px;
    z-index: 10;
}
.tree4{
    position: absolute;
    right: 0px;
    top: 421px;
    z-index: 10;
}
.bannersun img{
height: 90px;
}

.tank{
    position: absolute;
    left: 49em;
    top: 36em;
    z-index: 11;
}

.boat{
    position: absolute;
    left: -250px;
    top: 300px;
    -webkit-animation:  boat 101s linear  infinite;
            animation:  boat 101s linear  infinite;

}
.up{
    position: absolute;
    left: 64px;
    top: 300px;

}
.up img{
  height: 160px;
}
.up1{
    position: absolute;
    left: 62.5px;
    top: 345px;
    overflow: hidden;

}
.up1 img{
  margin-top: -10px;
  -webkit-animation:  up1 2s linear  infinite;
          animation:  up1 2s linear  infinite;

}
.animnewimg{
  top: 38.5em;
  left: 8em;
  position: absolute;
}
.animnewimg img{
  -webkit-animation:  anim 8s linear  infinite;
          animation:  anim 8s linear  infinite;

}
.anim{
    position: absolute;
    left: 109px;
    top: 377px;
}
.anim img{
  -webkit-animation:  anim 8s linear  infinite;
          animation:  anim 8s linear  infinite;

}
.oilsign{
    position: absolute;
    left: 163px;
    top: 405px;
    z-index: 1;
    -webkit-animation:   8s linear  infinite;
            animation:   8s linear  infinite;

}
.oilsign2{
    position: absolute;
    left: 31.7em;
    top: 32em;
    -webkit-animation:   8s linear  infinite;
            animation:   8s linear  infinite;
}
.oilsign3{
    position: absolute;
    left: 11.6em;
    top: 39.4em;
    -webkit-animation: 8s linear infinite;
            animation: 8s linear infinite;
}
.oilsign4{
    position: absolute;
    right: 37em;
    top: 34.5em;
    -webkit-animation:   8s linear  infinite;
            animation:   8s linear  infinite;
}
.oilsign5{
    position: absolute;
    left: 9.6em;
    top: 20.3em;
    -webkit-animation:   8s linear  infinite;
            animation:   8s linear  infinite;
}
.oilsign6{
    position: absolute;
    right: 16em;
    top: 35em;
    -webkit-animation:   8s linear  infinite;
            animation:   8s linear  infinite;
}
.oilsign7{
    position: absolute;
    right: 16.5em;
    top: 15em;
    -webkit-animation:  8s linear  infinite;
            animation:  8s linear  infinite;
}
.oilsign8 {
    position: absolute;
    right: 40.9em;
    top: 16.7em;
    -webkit-animation:  8s linear infinite;
            animation:  8s linear infinite;
    /*z-index: 1;*/
}
.oilsign8 img:hover .dataonHover1 {
      display: block !important;
      cursor: pointer;
}
.oilsign8 img:hover .triangle-down1  {
  display: block ! important;
  cursor: pointer;
}
.oilsign8 img:hover .triangle-down211  {
  display: block ! important;
  cursor: pointer;
}
.other{
    position: absolute;
    left: 720px;
    top: 94px;
}


@-webkit-keyframes truckfront {
  
  0% {
    margin-top:-20px ;
    opacity: 1;
  }
  100% {
   margin-top:120px ;
    opacity: 1;
  }
  
}


@keyframes truckfront {
  
  0% {
    margin-top:-20px ;
    opacity: 1;
  }
  100% {
   margin-top:120px ;
    opacity: 1;
  }
  
}
@-webkit-keyframes up1 {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}
@keyframes up1 {
  
  0% {
    margin-top:-10px ;
  }
  50% {
   margin-top:0px ;
  }
  
}
@-webkit-keyframes car {
  
  0% {
    right: 260px
  }
  100% {
  right: -150px;
  }
  
}
@keyframes car {
  
  0% {
    right: 260px
  }
  100% {
  right: -150px;
  }
  
}
@-webkit-keyframes contanim {
  
  0% {
    right: 189px
  }
  100% {
  right: -250px;
  }
  
}
@keyframes contanim {
  
  0% {
    right: 189px
  }
  100% {
  right: -250px;
  }
  
}
@-webkit-keyframes truckanim {
  
  0% {
    margin-right:127px;
  }
  100% {
    margin-right: -243px;
    opacity: 1;
    
  }
  
}
@keyframes truckanim {
  
  0% {
    margin-right:127px;
  }
  100% {
    margin-right: -243px;
    opacity: 1;
    
  }
  
}@-webkit-keyframes truckanimtion {
  
  0% {
    margin-right:645px;
  }
  100% {
    margin-right: -793px;
  }
  
}@keyframes truckanimtion {
  
  0% {
    margin-right:645px;
  }
  100% {
    margin-right: -793px;
  }
  
}
@-webkit-keyframes anim {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  50% {
  -webkit-transform: rotate(8deg);
          transform: rotate(8deg);
  }
  
}
@keyframes anim {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  50% {
  -webkit-transform: rotate(8deg);
          transform: rotate(8deg);
  }
  
}
@-webkit-keyframes oilsign {
  
  0% {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  }
  50% {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
  }
  
}
@keyframes oilsign {
  
  0% {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  }
  50% {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
  }
  
}

@-webkit-keyframes wheel {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  }
  
}

@keyframes wheel {
  
  0% {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  }
  100% {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  }
  
}
@-webkit-keyframes cloud1 {
  
  0% {
    left:-250px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud1 {
  
  0% {
    left:-250px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes cloud2 {
  
  0% {
    left:-330px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud2 {
  
  0% {
    left:-330px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes cloud3 {
  
  0% {
    left:-750px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud3 {
  
  0% {
    left:-750px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes cloud4 {
  
  0% {
    left:-950px ;
  }
  100% {
   left:1500px ;
  }
  
}
@keyframes cloud4 {
  
  0% {
    left:-950px ;
  }
  100% {
   left:1500px ;
  }
  
}
@-webkit-keyframes boat {
  
  0% {
    left:-250px ;
  }
  50% {
   left:300px ;
  }
  
}
@keyframes boat {
  
  0% {
    left:-250px ;
  }
  50% {
   left:300px ;
  }
  
}



/*.hoveroilsign1:hover .dataonHover1  {
  display: block ! important;
  cursor: pointer;
}*/
/*try*/
.hoveroilsign1:hover{
  cursor: pointer;
}
/*.oilsign9:hover .dataonHover1{
  display: block;
  cursor: pointer;
}*/
.hoveroilsign8{
    /*display: none;*/
    height: 50px;
    position: absolute;
    width: 50px;
    top: 213px;
    left: 709px;
    background-color: #333;
    z-index: 999;
}
.oilsign7:hover .triangle-down1_oil7 {
  display: block;
  cursor: pointer;
}
.oilsign7:hover .dataonHover  {
  display: block;
  cursor: pointer;
}
.dataonHover{
    display: none;
    height: 113px;
    width: 233px;
    border-radius: 5px;
    background-color: #ffffff;
    left: 58.4em;
    top: 11.4em;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 200;
}
.dataonHover1{
  display: none;
  height: 117px;
  width: 241px;
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 44.2em;
  top: 7.2em;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
}
.dataonHover2 {
    display: none;
    height: 105px;
    width: 233px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-left: -101px;
    margin-top: -147px;
    position: absolute;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
}
 .triangle-down {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #ffffff;
      margin-top: -43px
    }
.triangle-down1 {
    position: absolute;
     display: none; 
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.1);
    top: 219px;
    left: 721px;
    }
    .triangle-down1_oil7 {
      position: absolute;
     display: none; 
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid rgba(0, 0, 0, 0.1);
    top: 142px;
    left: 73px;
    }
    .triangle-down21 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: -17px;
      right: -15px;
    }
    .triangle-down211 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: 114px;
    right: 112px;
    left: -14px;
    bottom: 2px;
    }
    .triangle-down211_oil7 {
      position: absolute;
      /*display: none;*/
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #fff;
      margin-top: 114px;
      right: 112px;
    }
    .triangle-down2 {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      z-index: 1000;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid rgba(0, 0, 0, 0.1);
      margin-top: -42px
    }
    .triangle-left {
      position: absolute;
      display: none;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-right: 15px solid #ffffff;
      border-bottom: 15px solid transparent;
      left:31px;
      top:-2px;
    }

.hoveroilsign4:hover .dataonHover  {
      display: block ! important;
      cursor: pointer;
}
.hoveroilsign4:hover .arrow-right  {
  display: block ! important;
  cursor: pointer;
}

.hoveroilsign2:hover .dataonHover2  {
      display: block ! important;
      cursor: pointer;
}
.hoveroilsign2:hover .triangle-down2  {
  display: block ! important;
  cursor: pointer;
}
.hoveroilsign2:hover .triangle-down21  {
  display: block ! important;
  cursor: pointer;
}

.New_hoverdiv{
   /* height: 50px;
    width: 50px;
    background-color: #333;
    position: absolute;
    left: 51em;
    top: 16em;
    z-index: 2;*/

}
/*.New_hoverdiv:hover .dataonHover2{
  cursor: pointer;
  display: block;
}
*/


.pinhoverdata{
  color: #333;
  font-size: 12px;
  padding: 10px 6px 6px 3px;
}

.Hoverreadmore{
    font-size: 12px;
    color: #009de2;
}
.Hoverreadmore:hover{
  border-bottom: 1px solid #009de2;
}
.movingimg{
  display: none;
}
.movingimg1:hover .movingimg{
 display:block;
}
.result {
    height: 72px;
    width: 100%;
    border: 1px solid #000;
  }
  .result_hover {
    border: 1px solid #fff;
  }

@media screen and (max-width: 600px) {
   .resbanner{
        /*height: 670px ! important;*/
       }    
}  
  @media only screen 
    and (device-width : 375px) 
    and (device-width : 667px){
      .resbanner{
        height: 660px ! important;

      }
 }
  @media only screen 
    and (device-width : 401px) 
    and (device-width : 768px){
      .resbanner{
        height: 660px ! important;

      }
 }

  @media only screen 
    and (device-width : 390px) 
    and (device-height : 844px){
      .resbanner{
        margin-top: 100px;
        height: 800px ! important;

      }
 }
 
  @media only screen 
    and (device-width : 414px) 
    and (device-height : 736px){
      .resbanner{
        height: 730px ! important;

      }
 }
  @media only screen 
    and (device-width : 360px) 
    and (device-height : 640px){
      .resbanner{
        /*background-repeat: no-repeat;*/
        height: 690px ! important;

      }
 }
  @media only screen and (device-width : 360px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
   @media only screen and (device-width : 360px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
@media only screen and (device-width : 375px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
  @media only screen and (device-width : 375px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
  @media only screen and (device-width : 375px) and (device-height : 490px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
   @media only screen and (device-width : 384px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 700px ! important;

      }
  }
   @media only screen and (device-width : 384px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 700px ! important;

      }
  }
   @media only screen and (device-width : 240px) and (device-height : 320px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 420px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 600px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 812px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px) and (device-height : 823px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 411px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
  @media only screen and (device-width : 411px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 710px ! important;

      }
  }
   @media only screen and (device-width : 240px){ 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 420px ! important;

      }
  }
   @media only screen and (device-width : 412px) {
     .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }

   }
   @media only screen and (device-width : 412px) and (device-height : 732px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
  @media only screen and (device-width : 412px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 640px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 600px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 700px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
  @media only screen and (device-width : 320px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
  @media only screen and (device-width : 320px) and (device-height : 823px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 640px ! important;

      }
  }
   @media only screen and (device-width : 412px) and (device-height : 500px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 730px ! important;

      }
  }
   @media only screen and (device-width : 320px) and (device-height : 480px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 545px ! important;

      }
  }
   @media only screen and (device-width : 480px) and (device-height : 854px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 855px ! important;

      }
  }
   @media only screen and (device-width : 480px) and (device-height : 712px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 820px ! important;

      }
  }
  @media only screen and (device-width : 480px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 820px ! important;

      }
  }
   @media only screen and (device-width : 375px) { 
    .resbanner{
        /*background-repeat: no-repeat;*/
        height: 720px ! important;

      }
  }
 @media only screen 
    and (device-width : 411px) 
    and (device-height : 731px){
      .resbanner{
        /*margin-top: 130px ! important;*/
        height: 765px ! important;

      }
 }
  @media only screen 
    and (device-width : 411px) 
    and (device-height : 823px){
      .resbanner{
        /*margin-top: 120px ! important;*/
        height: 860px ! important;

      }
 }
  @media only screen 
    and (min-device-width : 320px) 
    and (device-height : 568px){
      .resbanner{
        height: 570px ! important;
        background:50%;

      }
    }
  @media only screen 
    and (min-device-width : 393px) 
    and (device-height : 851px){
      .resbanner{
       /*background-image: url(/images/Mobile.png);*/
        /*background-size: cover;*/
        height: 850px ! important;
        /*object-fit: contain;*/
         background: 50%; 
        /*background-repeat: no-repeat;*/

      }
      .webclientbackimg{
        width: 310px ! important;
        margin-left: 49px ! important;
      }
 }
 @media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) { 
      .resbanner{
        height: 812px ! important;
        background: 50%,100%;
        /*margin-top: 100px ! important;*/

      }
 }

  .resbanner{
    /*margin-top: 105px;*/
    background-image: url("/images/Mobile.png");
    background-size: cover;
    /*height: 730px ! important;*/
    object-fit: contain;
    /*background:50%;*/
    background-repeat: no-repeat;
  } 
  .arrow-right {
  display: none; 
  width: 0; 
  height: 0; 
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  right: 255px;
  top: 201px;
  position: absolute;
  border-left: 15px solid #ffffff;
} 
.oilimage{
  position: absolute;
  height: 124px;
  /*left: 968px;*/
  bottom: 0px;
  right: 248px;
  top: 160px;
  height: 101px;
  -webkit-animation: slide-left 4s ;
          animation: slide-left 4s ;
  /*z-index: 110;*/
  /*opacity: 0.9;*/
}
.petrolimage1{
  position: absolute;
  right: 277px;
  top: 424px;
  height: 221px;
  -webkit-animation: slide-top 4s;
          animation: slide-top 4s;
   /*opacity: 0.7;*/

}
.boat_img2_img{
  width: 240px;
}
.waterimg2Div_img{
  width:135px; 
}
.petrolimage1_img{
  width: 255px;
}
.oilimage_img{
  width: 255px;

}
.plantimage11_img{
  width: 255px;
}
@media screen and (min-width: 1900px) {
  .bannerContainer1{
    height: 55em ! important;
  }
  #blackbannerDiv{
    height: 55em ! important;
  }
  .boat_img2{
    left:545px ! important;
    top:331px ! important;
  }
  .boat_img2_img{
    width: 330px ! important;
  }
  .waterimg2Div_img{
    width:230px ! important; 
  }
  .waterimg2{
    top:62% ! important;
    left:16% ! important;
  }
  .nexttogasimg_size{
    width: 330px ! important;
  }
  .nexttogasimage{
    right: 541px ! important;
    top:350px ! important;
  }
  .petrolimage1_img{
    width: 330px ! important;
  }
  .petrolimage1{
    top:500px ! important;
  }
  .oilimage_img{
    width: 330px ! important;
    
  }
  .plantimage11_img{
    width: 330px ! important;
  }
  .plantimage11{
    right: 26px ! important;
    top:341px ! important;
  }
  .boat_img{
    width: 500px ! important;
    height:270px ! important;
  }
  .boat_img_size{
    width: 230px ! important;
  }
  .waterimg_size{
    width: 190px ! important;
  }
  .topnav a{
    font-size: 18px ! important;
    padding: 15px 15px;
  }
  .expertiesdropdown{
    width:1316px ! important;
  }
  .dropdown .dropbtn{
    font-size: 18px ! important;
    padding: 15px 15px;
  }
}
@media only screen (min-width: 1600px) {
  .bannerContainer1{
    height: 55em ! important;
  }
  .tabimage{
    width: 350px ! important;
  }
  .tabimage_1{
    width: 300px ! important;
  }
  .plantimage1{
    right: 320px ! important;
    top: 245px ! important;
  }
   .nexttogasimage{
    left: 865px ! important;
    top: 578px ! important;
    width: 300px ! important;
  }
  .petrolimage1{
    right: 516px ! important;
    top: 392px ! important;
  }
  .gasimage{
    right: 264px ! important;
    top: 538px ! important;
  }
  .plantimage11 {
    right: 509px ! important;
    top: 159px ! important;
  }
  .petrolimage {
    right: 225px ! important;
    top: 296px ! important;
  }
  .oilimage {
    right: 196px ! important;
    top: 78px ! important;
  }
  .boat_img2 {
    left: 447px ! important;
    top: 377px ! important;
  }
}
.waterimg_size{
  width: 130px;
}
.boat_img_size{
  width: 155px;
  position: absolute;
  left: 151px;
  top: 90px;
}
.nexttogasimg_size{
  width: 240px;

}

@media only screen and (max-width: 768px) {
.oilimage{
  right: 108px;
  top: 159px;
}
.boat_img2_img{
  width: 173px ! important;
}
.plantimage11_img{
  width: 173px ! important;
}

.petrolimage1_img{
  width: 173px ! important;

}
.oilimage_img{
   width: 173px ! important;
}
.waterimg2_img {
  width:110px ! important;
}

.waterimg_size{
  width: 100px;
}
.nexttogasimg_size{
  width: 150px;
}
.boat_img_size{
  width: 125px;
  left: 116px;
  top: 65px;
}
.bannerContainer1{
  height: 38em ! important;
}
.tabimage{
  width: 180px ! important;
}
.plantimage11{
  right: -7px ! important;
  top: 278px ! important;
 }
 .petrolimage{
   right: 45px ! important;
   top: 229px ! important;
 }
 .boat_img{
    left: 38px ! important;
    top: 68px ! important;
 }
 .plantimage1{
    right: 285px !important;
    top: 211px ! important;
 }
 .petrolimage1{
  right: 140px ! important;
  top: 364px ! important;
 }
 .boat_img2{
  left: 158px ! important;
  top: 308px ! important;
 }
 .gasimage{
  right: 34px !important;
  top: 355px ! important;
 }
 .nexttogasimage_img{
  width: 173px ! important;
 }
 .nexttogasimage{
   right: 243px ! important;
   top: 268px ! important;
 }
 .waterimg{
  left: 41px ! important;
  top: 193px ! important;
 }
 .new_HoverDivforboat{
    margin-left: -46px;
    margin-top: -40px;
 }
 
 .waterimg2{
  top: 77% ! important;
  left: 10% ! important;
 }
 .new_HoverDiv1{
    margin-left: -157px ! important;
    margin-top: -164px !important;
 }
 .new_HoverDiv4{
    margin-left: -124px ! important;
    margin-top: -238px ! important;
 }
 .new_HoverDiv5{
  margin-left: -129px ! important;
  margin-top: -230px ! important;
 }
 .new_HoverDiv7{
    margin-left: -70px ! important;
    margin-top: -197px ! important;
 }
 .new_HoverDiv2{
    margin-left: -84px ! important;
    margin-top: -231px ! important;
 }
}
.gasimage{
  position: absolute;
  right: 88px;
  top: 282px;
  height: 168px;
  -webkit-animation: slide-tl 4s;
          animation: slide-tl 4s;

}
.nexttogasimage{
   position: absolute;
   -webkit-animation: slide-top 4s;
           animation: slide-top 4s;
   bottom: 0px;
   right: 470px;
   top: 305px;
   height: 129px;
}
.petrolimage{
  position: absolute;
  bottom: 0px;
  right: 126px;
  top: 267px;
  height: 221px;
  -webkit-animation: slide-tl 4s;
          animation: slide-tl 4s;


}

.plantimage1{
  position: absolute;
  bottom: 0px;
  right: 262px;
  top: 153px;
  height: 221px;
  -webkit-animation: slide-bottom 4s ;
          animation: slide-bottom 4s ;

  /*z-index: 110;*/

}
.plantimage11{
  position: absolute;
  right: 65px;
  top: 299px;
  height: 100px;
  -webkit-animation: slide-tl 4s;
          animation: slide-tl 4s;
  /*opacity: 1;*/

}

.fade-in {
animation: fadeIn ease 20s;
-webkit-animation: fadeIn ease 10s;
-moz-animation: fadeIn ease 10s;
-o-animation: fadeIn ease 10s;
-ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

.waterimg{
  position: absolute;
  bottom: 0px;
  left: 67px;
  top: 261px;
  height: 221px;
  -webkit-animation: slide-right 4s ease-out;
          animation: slide-right 4s ease-out;
  
  /*opacity:1;*/
  /*z-index: 110;*/
}
/*.waterimg:hover{
  z-index: 120;
  
}*/
.waterimg:hover #blackbannerDiv{
  display: block;
  /*text-transform: */

}
/*.waterimg2:hover #blackbannerDiv{
  display: block;

}
*/
/*.scaleimage:hover{
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1); 
  transition: all 2s ease-in-out;

}*/
.boat_img2:hover{
  z-index: 120;
}
.boat_img2 :hover{
/*  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.1); 
  transition: all 2s ease-in-out;*/

}
.boat_img2 :hover .waterimg2{
  -webkit-transform: scale(1.2);
  transform: scale(1.1) ! important; 
  transition: all 2s ease-in-out;

}

.boat_img{
  position: absolute;
  bottom: 0px;
  left: 97px;
  top: 104px;
  width: 304px;
  height: 257px;
  -webkit-animation: slide-br 4s;
          animation: slide-br 4s
  /*z-index: 110;*/
  /*opacity:1;*/

}
.boat_img2{
  position: absolute;
  bottom: 0px;
  left: 361px;
  top: 336px;
  height: 221px;
  -webkit-animation: slide-br 4s ;
          animation: slide-br 4s ;
  /*z-index: 110;*/
  /*opacity: 1;*/

}
.waterimg2{
  position: absolute;
  top: 72%;
  left: 16%;
  height: 221px;
  -webkit-animation: slide-tr 4s;
          animation: slide-tr 4s;
  /*z-index: 110;*/
  /*opacity: 0.9;*/

}
.new_HoverDivforboat{
   height: 275px;
    width: 355px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -38px;
    margin-top: -41px;
    position: absolute;
    background-size: 100% 100%;
    -webkit-animation: fadeIn ease 2s;
            animation: fadeIn ease 2s;
    z-index: 110;
    display: none;

}
.new_HoverDiv {
    display: none;
    height: 275px;
    width: 275px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -1px;
    margin-top: -167px;
    position: absolute;
    background-size: 100% 100%;
    -webkit-animation: fadeIn ease 2s;
            animation: fadeIn ease 2s;
    z-index: 110;
  }

.new_HoverDiv img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv1 img {
       
       width: 400px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv2  img {

       width: 395px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDivforboat  img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv3  img {
        
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv4  img {
        width: 345px;
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv5  img {
    
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv6 img {
    
       -webkit-animation: rotation 45s infinite linear;
}
.new_HoverDiv7 img {
    
        width: 390px;
       -webkit-animation: rotation 45s infinite linear;
}
.rotate {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg);
  }
}

  #blackbackground{
    display: none;
    background-color:#21252999;
    width: 1353px;
    height: 810px;
  }
    

.petrolimage1:hover{
  z-index: 110 ! important;
  /*opacity: 1;*/
}
/*.waterimg:hover .new_HoverDiv {
      display: block ! important;
      cursor: pointer;
      z-index: 140;
}
.waterimg:hover .hidepoweredby{
  display: none ! important;

}*/

.boat_img2:hover .  {
      z-index: 110;
}

.plantimage1fv:hover  {
     z-index: 110;
}
.nexttogasimage:hover{

  /*z-index: 110;*/

}
.oilimage:hover{
  z-index: 110 ! important;

}

.hiddendiv {
  visibility: hidden ! important;
}

.plantimage11:hover  {
     z-index: 110 ! important;

}
.new_HoverDiv1 {
    display: none;
    height: 275px;
    width: 400px ! important;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -220px;
    margin-top: -143px;
    position: absolute;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv5 {
    display: none;
    height: 260px;
    width: 410px ! important;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -84px;
    margin-top: -227px;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    position: absolute;
    background-size: 100% 100%;
    
}
.new_HoverDiv5 img{
  width: 400px ! important;
}

.new_HoverDiv3 {
    display: none;
    height: 275px;
    width: 355px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -26px;
    margin-top: -130px;
    position: absolute;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv4 {
    display: none;
    height: 275px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -33px;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    margin-top: -235px;
    position: absolute;
    background-size: 100% 100%;
    opacity: 1;
    z-index: 1000000;
    
}
.new_HoverDiv4 img{
  width: 409px ! important;
}
.new_HoverDiv6 {
    display: none;
    height: 275px;
    width: 355px;
    border-radius: 5px;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -98px;
    margin-top: -175px;
    position: absolute;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv7 {
    display: none;
    height: 274px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -37px;
    margin-top: -192px;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    position: absolute;
    background-size: 100% 100%;
    z-index: 110;
    
}
.new_HoverDiv2 {
    display: none;
    height: 275px;
    width: 400px;
    border-radius: 5px;
    /*background-image: url(/images/newbanner_img14.png);*/
    margin-left: -12px;
    -webkit-animation: fadeIn ease 1.5s;
            animation: fadeIn ease 1.5s;
    margin-top: -241px;
    position: absolute;
    background-size: 100% 100%;
    z-index:110;
    
}
/*.oilimage:hover .new_HoverDiv7  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;

}*/

.boat_img2:hover .new_HoverDiv1  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}

/*.boat_img:hover .new_HoverDiv  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}*/
/*.plantimage1:hover .new_HoverDiv3  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}*/
.nexttogasimage:hover .new_HoverDiv5 {
      display: block ! important;
      cursor: pointer;
      z-index: 120;
      /*opacity: 1;*/
}
.nexttogasimage:hover{
  z-index: 120;

  
}

.plantimage11:hover .new_HoverDiv4  {
      display: block ! important;
      cursor: pointer;
      opacity: 1;
}
/*.petrolimage1:hover .new_HoverDiv2{
   display: block ! important;
   cursor: pointer;
  opacity: 1;

}*/
.petrolimage1:hover .new_HoverDiv2{
  display: block ! important;
  cursor: pointer;
}
.oilimage:hover .new_HoverDiv7{
  display: block ! important;
  cursor: pointer;
}

.onhoverimages{
  opacity: 0.7;

}
.onhoverBackgImg{
  opacity: 0.3;
}
.newhovertxt{
    margin-top: -252px;
    margin-left: 84px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    text-align: justify;
    position: absolute;
    width: 177px;
}
.newhovertxt_again{
    top: 132px;
    position: absolute;
    width: 160px;
    font-weight: 500;
    left: 92px;
    text-align: justify;
    font-size: 13px;
}
.newhovertxt_6{
  margin-top: -176px;
  margin-left: 68px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  text-align: center;
  position: absolute;
  width: 113px;
}
.newhovertxt_8{
    margin-top: -260px;
    margin-left: 95px;
    font-size: 13px;
    padding-left: 10px;
    text-align: justify;
    font-weight: 500;
    width: 169px;
    position: absolute;
}
.newhovertxt_1{
  margin-top: -181px;
  margin-left: 62px;
  font-size: 12px;
  padding-left: 10px;
  font-weight: 500;
  text-align: center;
  position: fixed;
  width: 122px;
}
.newhovertxt_4{
    margin-top: -260px;
    margin-left: 102px;
    font-size: 13px;
    width: 164px;
    font-weight: 500;
    /* padding-left: 10px; */
    text-align: justify;
    position: absolute;
}
.newhovertxt_5{
  margin-top: 78px;
  margin-left: 62px;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
  text-align: center;
}
.newhovertxt_e_p{
    margin-top: -260px;
    margin-left: 85px;
    font-size: 13px;
    font-weight: 500;
    padding-left: 10px;
    text-align: justify;
    position: absolute;
    width: 183px;

}
.newhovertxt_e_p1{
    margin-top: -226px;
    margin-left: 81px;
    font-size: 13px;
    padding-left: 10px;
    text-align: justify;
    font-weight: 500;
    position: absolute;
    width: 170px;
    height: 100px;
    /*overflow: scroll;*/

}
#style-14::-webkit-scrollbar-thumb
{
  /*background-color: #7a7676;*/
  /*border-radius: 30px;*/

  /*background: red;*/
}
#style-3::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

#style-14::-webkit-scrollbar-track
{
  width: 6px;
  /*background-color: #F5F5F5;*/
 }

#style-3::-webkit-scrollbar-thumb
{
  background-color: #000000;
}
#style-2::-webkit-scrollbar-track
{
  -webkit-box-shadow: 0px 11px 15px 1px #ccc;;
  border-radius: 10px;
  background-color: #F5F5F5;
}
::-webkit-scrollbar {
    width: 7px;
    background-color: #eee;
}
::-webkit-scrollbar-button {
    background: #e9e8e8;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    /*border-radius: 30px;*/
}
::-webkit-scrollbar-track-piece {
    background:#e9e8e8;
}
::-webkit-scrollbar-thumb {
    background: #009de2;
}



.scrollbar
{
 margin-left: 87px;
 float: left;
 height: 85px;
 width: 165px;
 padding-right: 11px;
 overflow-y: scroll;
 margin-bottom: 25px;
}
.scrollbar1 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 195px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 22px;
    padding-right: 12px;
}
.scrollbar2 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 195px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 20px;
    padding-right: 15px;
}
.scrollbar3 {
    margin-left: 87px;
    float: left;
    height: 103px;
    width: 186px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 19px;
    padding-right: 12px;
}
.scrollbar4{
    /*margin-left: 87px;*/
    float: left;
    height: 103px;
    width: 186px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 14px;
    padding-right: 12px;
}
.scrollbar5{
    /*margin-left: 87px;*/
    float: left;
    height: 103px;
    width: 180px;
    overflow-y: scroll;
    margin-bottom: 25px;
    padding-left: 12px;
    padding-right: 18px;
}



.force-overflow
{
  min-height: 102px;
  /*background-color: red;*/
}
.new_HoverDivforboat img{
  width: 365px;
}
.newhovertxt_ptrchemical{
    margin-top: -184px;
    margin-left: 72px;
    font-size: 12px;
    padding-left: 10px;
    text-align: center;
    position: absolute;
    width: 111px;
}
.servicekbtnarrow1{
   margin-left: 177px;
    color: #000;
    margin-top: -142px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow2{
    margin-left: 124px;
    color: #000;
    margin-top: -106px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow3 {
    margin-left: 178px;
    color: #000;
    margin-top: -139px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow4 {
    margin-left: 173px;
    color: #000;
    margin-top: -139px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow4_1 {
    margin-left: 154px;
    color: #000;
    margin-top: -121px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow5 {
    left: 124px;
    color: #000;
    top:164px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow7{
    margin-left: 172px;
    color: #000;
    margin-top: -146px;
    font-size: 13px;
    position: absolute;
}
.servicekbtnarrow8 {
    left: 177px;
    color: #000;
    top: 257px;
    font-size: 13px;
    position: absolute;
}
@mixin rings($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    border: 8px solid rgba(0,0,0,0.2);
    border-radius: 100%;
    -webkit-animation-name: ripple;
            animation-name: ripple;
    -webkit-animation-duration: $duration;
            animation-duration: $duration;
    -webkit-animation-delay: $delay;
            animation-delay: $delay;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(.65,0,.34,1);
            animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.request-loader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  border-radius: 100%;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include rings(3s, 0s);
  }
  
  &::before {
    @include rings(3s, 0.5s);
  }
}


@-webkit-keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: scale3d(0.75,0.75,1);
            transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    -webkit-transform: scale3d(1.5,1.5,1);
            transform: scale3d(1.5,1.5,1);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    -webkit-transform: scale3d(0.75,0.75,1);
            transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    -webkit-transform: scale3d(1.5,1.5,1);
            transform: scale3d(1.5,1.5,1);
  }
}
.ripplebox:hover{
  cursor: pointer;
}
.ripplebox1:hover{
  cursor: pointer;
}
.ripplebox2:hover{
  cursor: pointer;
}
.ripplebox3:hover{
  cursor: pointer;
}
.ripplebox4:hover{
  cursor: pointer;
}
.ripplebox5:hover{
  cursor: pointer;
}

.ripplebox{
  width:40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 51px;
  top: -24px;
  /*z-index: 10000000;*/

}
.ripplebox1 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 115px;
    top: 24px;
  }
  .ripplebox2 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 95px;
    top: -18px;
  }
   .ripplebox3 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 109px;
    top: -9px;
  }
   .ripplebox4 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 42px;
    top: -20px;
  }
   .ripplebox5 {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 71px;
    top: -10px;
  }
.ripplebox span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}
.ripplebox1 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}
.ripplebox2 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}
.ripplebox3 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}
.ripplebox4 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}
.ripplebox5 span{
  position: absolute;
  box-sizing: border-box;
  background:rgba(255,140,0,0.50); 
  /*border:2px solid rgb(255,69,0);*/
  border-radius: 50%;
  -webkit-animation: animate 6s linear infinite;
          animation: animate 6s linear infinite;
  -webkit-animation-delay: calc(-1.5s * var(--i));
          animation-delay: calc(-1.5s * var(--i));
}

@-webkit-keyframes animate{
  0%{
    width: 0px;
    height: 0px;
  }
  50%{
    opacity: 1;
  }
  100%{
    width:40px;
    height: 40px;
  }
}

@keyframes animate{
  0%{
    width: 0px;
    height: 0px;
  }
  50%{
    opacity: 1;
  }
  100%{
    width:40px;
    height: 40px;
  }
}
.hover_close
{
   position: absolute;
    top: 15px;
    left: 222px;
    color: #fff;
    font-size: 33px;
    z-index: 110;
  }
  .hover_close:hover{
    cursor: pointer;
  }
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 20s linear forwards;
          animation: dash 20s linear forwards;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}

svg {
  width: 80vw;
}
.squiggle {
  stroke-dasharray: 10;
  stroke-dashoffset: 200;
  -webkit-animation: draw 8s linear forwards;
          animation: draw 8s linear forwards;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.svgBox{
    margin-top: 172px;
    margin-left: 100px;

}
#blackbannerDiv{

  background:#0f0f0f9e;
  position: absolute;
  /*animation: dripple1 1s ease-out 1s;*/
  -webkit-animation: fadeIn ease 3s;
          animation: fadeIn ease 3s;
  height: 53em;
  z-index: 50;
}
#hidepoweredbyblack{
     height: 50px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 1348px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack2{
     height: 100px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 48px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack3{
     height: 100px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 210px;
  left: 70px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
}
#hidepoweredbyblack1{
  height: 95px;
  /*display: none;*/
  background:#2323236e;
  position: absolute;
  width: 1348px;
  right: 0px;
  /*animation: dripple1 1s ease-out 1s;*/
  /*animation: fadeIn ease 3s;*/
  /*height: 53em;*/
  z-index: 30002;
 
}
/*.waterimg:hover .hidepoweredby{
  background-color:#0f0f0fbf ! important;
}
*/

.circle_wrapper {
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  width: 500px; height: 250px;
  overflow: hidden;
  border: 3px dashed gold;
  border-bottom: none;
}
.circle_wrapper:after {
  content: '';
  display: block;
  width: 100px; height: 100%;
  background: gold;
  -webkit-animation: fill 7s linear infinite;
  animation: fill 7s linear infinite;
}
@-webkit-keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}

.mini_circle {
  border-top-left-radius: 500px;
  border-top-right-radius: 500px;
  width: 500px; height: 250px;
  overflow: hidden;
  /*border: 3px dashed gold;*/
  border-bottom: none;
}
.mini_circle:after {
  content: '';
  display: block;
  width: 100px; height: 100%;
  background: #fff;
  -webkit-animation: fill 7s linear infinite;
  animation: fill 7s linear infinite;
}
@-webkit-keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes fill {
  from { width: 0; }
  to { width: 100%; }
}
 .page-wrap {
   position: absolute;
   top: 187px;
   left: 123px;
}
 .gauge-wrapper {
   position: relative;
   -webkit-transform: translate3d(0, 0, 0);
   transform: translateZ(0);
   -webkit-backface-visibility: hidden;
   -webkit-perspective: 1000;
   backface-visibility: hidden;
   perspective: 1000;
}
 .gauge2, .gauge1 {
   position: absolute;
   top: 33px;
   left: 25px;
   width: 400px;
   height: 400px;
}
 .box-wrap {
   height: 113px;
   width: 226px;
   overflow: hidden;
   position: relative;
}
 .box {
   transition: 1s all;
   top: 0px;
   left: 0px;
   position: absolute;
   width: 200px;
   height: 200px;
   border: 13px solid #2f87c3;
   border-radius: 50%;
   clip: rect(0, 300px, 113px, 0px);
   -webkit-transform: rotate(-180deg);
}
 .gauge2 {
   top: 23px;
   left: 23px;
}
 .gauge2 .box {
   border: 13px solid #2ecc71;
   width: 154px;
   height: 154px;
   clip: rect(0, 231px, 90px, 0px);
}
 .gauge2 .box-wrap {
   height: 90px;
   width: 200px;
}

.chart-skills {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills *,
.chart-skills::before {
  box-sizing: border-box;
}


/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills {
  position: relative;
  width: 311px;
  height: 202px;
  overflow: hidden;
  left: 44px;
  top: 38px;
}

.chart-skills::before,
.chart-skills::after {
  position: absolute;
}

.chart-skills::before {
  content: '';
  width: inherit;
  height: inherit;
  /*border: 45px solid rgba(211, 211, 211, .3);*/
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.chart-skills::after {
  content: '';
  left: 50%;
  bottom: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
}

.chart-skills li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 4px dashed;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}


.chart-skills li:nth-child(4) {
  z-index: 1;
  border-color: orange;
  -webkit-animation-name: rotate-four;
          animation-name: rotate-four;
  -webkit-animation: rotation 8s infinite linear;

}

.chart-skills span {
  position: absolute;
  font-size: .85rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: fade-in .4s linear forwards ;
          animation: fade-in .4s linear forwards ;
}

.chart-skills li:nth-child(1) span {
  top: 5px;
  left: 10px;
  -webkit-transform: rotate(-21.6deg);
          transform: rotate(-21.6deg);
}

.chart-skills li:nth-child(2) span {
  top: 20px;
  left: 10px;
  -webkit-transform: rotate(-79.2deg);
          transform: rotate(-79.2deg);
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

.chart-skills li:nth-child(3) span {
  top: 18px;
  left: 10px;
  -webkit-transform: rotate(-140.4deg);
          transform: rotate(-140.4deg);
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.chart-skills li:nth-child(4) span {
  top: 10px;
  left: 10px;
  /*animation:  truckanim 10s linear  infinite;*/
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  -webkit-animation: rotation1 2s infinite linear;
}

/*2nd acr css*/
 .chart-skills1 {
  margin: 0 auto;
  padding: 0;
  list-style-type: none;
}

.chart-skills1 *,
.chart-skills1::before {
  box-sizing: border-box;
}


/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.chart-skills1 {
    position: relative;
    width: 311px;
    height: 198px;
    overflow: hidden;
    left: 17px;
    top: 237px;
}

.chart-skills1::before,
.chart-skills1::after {
  position: absolute;
}

.chart-skills1::before {
  content: '';
  width: inherit;
  height: inherit;
  /*border: 45px solid rgba(211, 211, 211, .3);*/
  border-bottom: none;
  border-top-left-radius: 175px;
  border-top-right-radius: 175px;
}

.chart-skills1::after {
  content: '';
  left: 50%;
  bottom: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 1.1rem;
  font-weight: bold;
  color: cadetblue;
}

.chart-skills1 li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 4px dashed;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}


.chart-skills1 li:nth-child(4) {
  z-index: 1;
  border-color: orange;
  -webkit-animation-name: rotate-four;
          animation-name: rotate-four;
  -webkit-animation: rotation 8s infinite linear;
  -webkit-animation-delay: 8s;
          animation-delay: 8s;

}

.chart-skills1 span {
  position: absolute;
  font-size: .85rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-animation: fade-in .4s linear forwards ;
          animation: fade-in .4s linear forwards ;
}

.chart-skills1 li:nth-child(1) span {
  top: 5px;
  left: 10px;
  -webkit-transform: rotate(-21.6deg);
          transform: rotate(-21.6deg);
}

.chart-skills1 li:nth-child(2) span {
  top: 20px;
  left: 10px;
  -webkit-transform: rotate(-79.2deg);
          transform: rotate(-79.2deg);
  -webkit-animation-delay: .4s;
          animation-delay: .4s;
}

.chart-skills1 li:nth-child(3) span {
  top: 18px;
  left: 10px;
  -webkit-transform: rotate(-140.4deg);
          transform: rotate(-140.4deg);
  -webkit-animation-delay: .8s;
          animation-delay: .8s;
}

.chart-skills1 li:nth-child(4) span {
  top: 10px;
  left: 10px;
  -webkit-animation-delay:10s;
          animation-delay:10s;
  /*animation:  truckanim 10s linear  infinite;*/
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  -webkit-animation: rotation1 2s infinite linear;
}


@-webkit-keyframes rotation1 {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}


/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */



@-webkit-keyframes rotate-four {
  from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(180deg);
    }
}



@keyframes rotate-four {
  from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(180deg);
    }
}
@-webkit-keyframes blackbannerDiv {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes blackbannerDiv {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
div.drop-container {
  position: absolute;
  top: 718px;
  right: 0;
  bottom: 0;
  left: 14px;
  margin: auto;
  height: 200px;
  width: 200px;
}


div.drop {
  position: absolute;
  top: -25%;
  width: 20%;
  height: 20%;
  border-radius: 100% 5% 100% 100%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin: 0px;
  background: transparent;
  -webkit-animation: drip 4s linear;
          animation: drip 4s linear;
}

h1 {
  color: white;
  position: absolute;
  font-size: 2.5em;
  height: 1em;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 2;
  margin: auto;
  text-align: center;
  opacity: 0;
  -webkit-animation: appear 2s 2.5s forwards;
          animation: appear 2s 2.5s forwards;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    z-index: 10;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    z-index: 10;
  }
}

div.drop-container:before,
div.drop-container:after {
  content: '';
  position: absolute;
  z-index: 221;
  top: 40%;
  right: 103%;
  -webkit-transform: translate(50%) rotateX(100deg);
          transform: translate(50%) rotateX(100deg);
  border-radius: 100%;
  opacity: 0;
  width: 130%;
  height: 43%;
  border: 5px solid skyblue;
  -webkit-animation: dripple 5s linear infinite;
          animation: dripple 5s linear infinite;
}

div.drop-container:after {
  -webkit-animation: dripple 2s ease-out 1.7s;
          animation: dripple 2s ease-out 1.7s;
}

@-webkit-keyframes drip {
  45% {
    top: 0;
    border-radius: 100% 5% 100% 100%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 100%;
  }
}

@keyframes drip {
  45% {
    top: 0;
    border-radius: 100% 5% 100% 100%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100% {
    top: 0;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    border-radius: 100%;
  }
}

@-webkit-keyframes dripple {
  0% {
    width: 150px;
    height: 150px;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 500px;
    height: 500px;
    top: -20%;
    /*opacity: 0;*/
  }
}

@keyframes dripple {
  0% {
    width: 150px;
    height: 150px;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 500px;
    height: 500px;
    top: -20%;
    /*opacity: 0;*/
  }
}
@-webkit-keyframes dripple1 {
  0% {
    width: 5%;
    height: 5%;
  }
  
  100% {
    width: 100%;
    height: 100%;
    /*top: -20%;*/
    /*opacity: 0;*/
  }
}
@keyframes dripple1 {
  0% {
    width: 5%;
    height: 5%;
  }
  
  100% {
    width: 100%;
    height: 100%;
    /*top: -20%;*/
    /*opacity: 0;*/
  }
}

/*slide objects*/
@-webkit-keyframes slide-right {

  0% {

    position: absolute;
    bottom: 0px;
    right: 493px;
    top: 232px;
    -webkit-transform: translateX(150px);
            transform: translateX(150px);
  }
  100% {
    -webkit-transform: translateX(300px);
            transform: translateX(300px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX();
            transform: translateX();
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    -webkit-transform: translateX();
            transform: translateX();
  }
}
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY();
            transform: translateY();
  }
}
@-webkit-keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
            transform: translateY(-100px) translateX(-100px);
  }
}
@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(50px);
            transform: translateY(0) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX();
            transform: translateY(-100px) translateX();
  }
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    -webkit-transform: translateY();
            transform: translateY();
  }
}
@-webkit-keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
            transform: translateY(-100px) translateX(100px);
  }
}
@keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(-100px);
            transform: translateY(0) translateX(-100px);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX();
            transform: translateY(-100px) translateX();
  }
}
@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(100px);
            transform: translateY(100px) translateX(100px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(-40px);
            transform: translateY(0) translateX(-40px);
  }
  100% {
    -webkit-transform: translateY(100px) translateX();
            transform: translateY(100px) translateX();
  }
}



.slide-right {

  -webkit-animation: slide-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
   animation: slide-right 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  -webkit-animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-left 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
  -webkit-animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-bottom 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-tl {
  -webkit-animation: slide-tl 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-tl 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-top {
  -webkit-animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-tr {
  -webkit-animation: slide-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-tr 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-br {
  -webkit-animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-br 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.translatediv{
  z-index: 2000;
  position: absolute;
  margin-top: 9px;
  /*position: absolute;*/
  right: 30px;
}
.translatediv span{
  display: none;
}
.translatediv {
  color: transparent;
}
.navbar-form .moduletable:first-child {
    float: right;
}
.flagdiv{
  padding:2px 2px 2px 2px;
  width: 20px;
  height: 20px;
}
/*.goog-te-banner-frame{
  position: absolute ! important;
  z-index: 0 ! important;

}*/
/*.noselection{
  margin-top: -40px ! important;
}
.goog-te-banner-content{
  display: none;
}
.goog-te-banner-frame{
  width: 0px ! important;
  height:0px ! important;
}*/
.goog-te-banner-frame{
  position: absolute;
  width: 0px ! important;
  height:0px ! important;
}
.goog-te-combo{
  border: none;
  top: -20px;
  right: 0px;
  position: absolute;
  background-color: #eee;
}
.goog-te-gadget .goog-te-combo{
  margin: 0px;
}



@media only screen and (device-width: 1024px)  {

  .oilimage{
        right: 170px ! important;
        top: 124px ! important;
    }
  .plantimage11{
    right: -7px ! important;
    top: 263px ! important;
  }  
  .nexttogasimage{
    right: 358px ! important;
    top: 284px ! important;
  } 
  .boat_img2{
    left: 187px ! important;
    top: 388px ! important;
  } 
  .waterimg2{
    top: 76% ! important;
    left: 9% ! important;
  } 
  .petrolimage1{
    right: 167px ! important;
    top: 421px ! important;
  }
  .topnav a{
    padding-left:  5px ! important;
  } 
  
  .dropdown .dropbtn{
    padding-left: 5px ! important;
  }

}

@media screen 
  and (device-width: 411px) 
  and (device-height: 823px) 
  and (-webkit-device-pixel-ratio: 3.5) 
  and (orientation: portrait) {
    .resbanner{
      height: 820px ! important;
    }

}
@media screen 
  and (device-width: 411px) 
  and (device-height: 731px) 
  and (-webkit-device-pixel-ratio: 3.5) 
  and (orientation: portrait) {
    .resbanner{
      height: 730px ! important;
    }

}

@media only screen and (device-width : 1024px) and (device-height : 1366px) { 

   #consultdown_data3{
    top:62em ! important;
    width: 450px;
   }
   #consultdown_img0{
    /*left:6em ! important;*/
   }
   #consultdown_data0{
    right: 0% ! important;
    width: 450px;
   }
   #consultdown_data2{
    right: 0% ! important;
   }
    #consultdown_img2{
    /*left:6em ! important;*/
   }
    #consultdown_img4{
    /*left:6em ! important;*/
   }
   #consultdown_data1{
        left: -3%;
       width: 450px;
   }
   #consultdown_img3{
    top:62em ! important;
    left:33em;
   }
   #consultdown_img1{
    left:33em;
   }
   #consultdown_data4{
    right: 0%;
    width: 450px;
   }
   #consultdown_data5{
    width: 450px;
   }
   #consultdown_img5{
    left:40em;
   }
   .oilimage{
    right: 160px;
    top: 108px;
   }
   .oilimage_img{
    width: 220px;
   }
   .plantimage11_img {
    width: 220px;
    } 
    .plantimage11{
      right: 19px;
      top: 247px;
    }
    .nexttogasimg_size {
    width: 220px;
    }
    .nexttogasimage{
      right: 335px;
       top: 216px;
    }
    .petrolimage1_img {
    width: 221px;
    }
    .petrolimage1{
      right: 192px;
     top: 350px;
    }
    .waterimg2{
          top: 78%;
         left: 8%;
    }
    .boat_img2_img {
    width: 220px;
    }
    .boat_img2{
      left: 194px;
       top: 404px;
    }
    .new_HoverDiv4{
      margin-left: -85px;
    }
} 
@media only screen and (device-width : 1024px){ 

   #consultdown_data3{
    top:62em ! important;
    width: 450px;
   }
   #consultdown_img0{
    /*left:6em ! important;*/
   }
   #consultdown_data0{
    right: 0% ! important;
    width: 450px;
   }
   #consultdown_data2{
    right: 0% ! important;
   }
    #consultdown_img2{
    /*left:6em ! important;*/
   }
    #consultdown_img4{
    /*left:6em ! important;*/
   }
   #consultdown_data1{
        left: -3%;
       width: 450px;
   }
   #consultdown_img3{
    top:62em ! important;
    left:33em;
   }
   #consultdown_img1{
    left:33em;
   }
   #consultdown_data4{
    right: 0%;
    width: 450px;
   }
   #consultdown_data5{
    width: 450px;
   }
   #consultdown_img5{
    left:40em;
   }
   .oilimage{
    right: 160px;
    top: 108px;
   }
   .oilimage_img{
    width: 220px;
   }
   .plantimage11_img {
    width: 220px;
    } 
    .plantimage11{
      right: 19px;
      top: 247px;
    }
    .nexttogasimg_size {
    width: 220px;
    }
    .nexttogasimage{
      right: 335px;
       top: 216px;
    }
    .petrolimage1_img {
    width: 221px;
    }
    .petrolimage1{
      right: 192px;
     top: 350px;
    }
    .waterimg2{
          top: 78%;
         left: 8%;
    }
    .boat_img2_img {
    width: 220px;
    }
    .boat_img2{
      left: 194px;
       top: 404px;
    }
    .new_HoverDiv4{
      margin-left: -85px;
    }
} 

@media only screen and (device-width : 1170px) and (device-height : 2532px) { 
  .waterimg2{
        left: 9%;
  }
  .boat_img2{
    left: 197px;
    top: 390px;
  }
}

@media only screen and (device-width : 1440px) and (device-height : 900px) { 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  

}
@media only screen and (device-width : 1440px){ 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  
  .new_HoverDiv1 {
    margin-left: -232px ! important;
    margin-top: -131px ! important;
  }

}
@media only screen and (device-width : 1600px) { 
  .oilimage{
    right: 220px ! important;
    top:170px ! important;
  }
  .nexttogasimage{
    right: 460px ! important;
    top: 301px ! important;
  }
  .plantimage11 {
    right: 50px ! important;
    top: 318px ! important;
  }
  .petrolimage1{
    right: 260px ! important;
    top:440px ! important;
  }
  .boat_img2 {
    left: 311px ! important;
  }
  .waterimg2Div_img{
    width: 170px;
  }
  .waterimg2{
    left:8% ! important;
    top:72% ! important;
  }  

}
@media only screen and (device-width : 1680px) and (device-height : 1050px) { 
  .waterimg2 {
    top: 69% ! important;
    left: 11% ! important;
 }
 .waterimg2Div_img{
  width: 200px ! important
 }
 .Type2Img img{
  width: 85% ! important;

 }
}
@media only screen and (device-width : 1680px){ 
  .waterimg2 {
    top: 69% ! important;
    left: 11% ! important;
 }
 .waterimg2Div_img{
  width: 200px ! important
 }
 .Type2Img img{
  width: 85% ! important;

 }
}
@media only screen and (device-width : 2560px) and (device-height : 1600px) {
 .boat_img{
  left:167px ! important;
 } 
 .boat_img2 {
    left: 691px ! important;
    top: 361px ! important;
  }
  .nexttogasimage {
    right: 771px ! important;
    top: 398px ! important;
  }
  .oilimage{
    right: 429px ! important;
    top: 218px ! important;
  }
  .nexttogasimage {
    right: 771px ! important;
    top: 366px ! important;
  }
  .petrolimage1 {
    top: 525px ! important;
    right: 414px;
  }
  .plantimage11 {
    right: 118px ! important;
    top: 415px ! important;
  }
}
@media only screen and (device-width : 2560px) and (device-height : 1600px) {
  .nexttogasimg_size {
    width: 370px ! important;
  }
    .oilimage_img {
      width: 370px ! important;
  }
  .plantimage11_img{
     width: 370px ! important;
  }
  .petrolimage1_img{
    width: 370px ! important;
  }
  .boat_img2_img{
    width: 370px ! important;
  }
  .waterimg2Div_img{
    width: 300px ! important;
  }
  .boat_img_size{
    width: 370px ! important;
  }
  .waterimg_size{
    width: 230px ! important;
  }
}
@media only screen and (device-width : 2560px){
  .nexttogasimg_size {
    width: 370px ! important;
  }
    .oilimage_img {
      width: 370px ! important;
  }
  .plantimage11_img{
     width: 370px ! important;
  }
  .petrolimage1_img{
    width: 370px ! important;
  }
  .boat_img2_img{
    width: 370px ! important;
  }
  .waterimg2Div_img{
    width: 300px ! important;
  }
  .boat_img_size{
    width: 370px ! important;
  }
  .waterimg_size{
    width: 230px ! important;
  }
}
@media only screen and (device-width : 1280px) and (device-height : 800px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 264px;
    top: 366px;
  }

}
@media only screen and (device-width : 1280px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 249px ! important;
    top: 388px ! important;
  }
  .nexttogasimage {
    right: 476px ! important;
    top: 295px ! important;
  }
  .petrolimage1 {
    right: 250px ! important;
    top: 405px ! important;
  }
  .oilimage {
    right: 232px ! important;
    top: 141px ! important;
  }
  .plantimage11 {
    right: 31px ! important;
    top: 272px ! important;
  }
  .boat_img2_img{
    width: 255px;
  }
  .waterimg2Div_img{
    width: 175px ! important;
  }
  .waterimg_size{
    width: 150px ! important;
  }

}
@media only screen and (device-width : 1152px) { 
  .waterimg2 {
    position: absolute;
    top: 72%;
    left: 10%;
  }
  .boat_img2 {
    position: absolute;
    bottom: 0px;
    left: 249px ! important;
    top: 388px ! important;
  }
  .nexttogasimage {
    right: 441px ! important;
    top: 277px ! important;
  }
  .petrolimage1 {
    right: 250px ! important;
    top: 398px ! important;
  }
  .oilimage {
    right: 232px ! important;
    top: 141px ! important;
  }
  .plantimage11 {
    right: 45px ! important;
    top: 288px ! important;
  }
  .boat_img2_img{
    width: 200px;
  }
  .waterimg2Div_img{
    width: 175px ! important;
  }
  .waterimg_size{
    width: 150px ! important;
  }
  .plantimage11_img{
    width: 225px ! important;

  }
  .oilimage_img {
    width: 225px;
  }
  .nexttogasimg_size{
    width: 225px ! important;
  }
  .petrolimage1_img{
    width: 225px ! important;
  }

}
@media screen and (device-width: 1920px){
  .nexttogasimage {
    right: 597px ! important;
    top: 338px ! important;
  }
  .petrolimage1 {
    right: 308px ! important;
    top: 456px ! important;
   }
   .plantimage11 {
    right: 66px ! important;
    top: 291px ! important;
  }
  .oilimage {
    right: 335px ! important;
    top: 159px ! important;
  }
  .boat_img2 {
    left: 467px ! important;
    top: 363px ! important;
  }
  .waterimg2 {
    top: 66% ! important;
    left: 10% ! important;
  }
}
@media screen and (device-width: 1856px){
  .nexttogasimage {
    right: 597px ! important;
    top: 338px ! important;
  }
  .petrolimage1 {
   right: 374px ! important;
    top: 475px ! important;
   }
   .plantimage11 {
    right: 125px ! important;
    top: 331px ! important;
  }
  .oilimage {
    right: 335px ! important;
    top: 159px ! important;
  }
  .boat_img2 {
    left: 467px ! important;
    top: 363px ! important;
  }
  .waterimg2 {
    top: 69% ! important;
    left: 15% ! important;
  }
  .waterimg2Div_img{
    width: 200px ! important;
  }
  .nexttogasimg_size {
    width: 285px;
  }
  .oilimage_img {
    width: 290px;
  }
  .plantimage11_img {
    width: 290px;
  }
  .petrolimage1_img {
    width: 290px;
  }
}
@media screen and (device-width: 1792px){
  .nexttogasimage {
    right: 619px ! important;
    top: 318px ! important;
  }
  .petrolimage1 {
   right: 374px ! important;
    top: 455px ! important;
   }
   .plantimage11 {
    right: 125px ! important;
    top: 294px ! important;
  }
  .oilimage {
    right: 351px ! important;
    top: 130px ! important;
  }
  .boat_img2 {
    left: 441px ! important;
    top: 320px ! important;
  }
  .waterimg2 {
    top: 69% ! important;
    left: 15% ! important;
  }
  .waterimg2Div_img{
    width: 200px ! important;
  }
  .nexttogasimg_size {
    width: 285px;
  }
  .oilimage_img {
    width: 290px;
  }
  .plantimage11_img {
    width: 290px;
  }
  .petrolimage1_img {
    width: 290px;
  }
  .boat_img2_img{
    width: 290px ! important;
  }
}
@media screen and (device-width: 2560px){
  .boat_img_size {
    width: 333px ! important;
    height: 187px;
  }
  .boat_img2 {
    left: 713px ! important;
  }
  .waterimg2 {
    top: 57% ! important;
    left: 16% ! important;
  }
  .nexttogasimage {
    right: 671px ! important;
  }
  .petrolimage1{
    right: 360px ! important;
  }
  .oilimage{
    right: 365px ! important;
  }
  .plantimage11 {
    right: 91px ! important;
  }
}

@media screen and (min-device-width: 2880px){
  .boat_img_size {
    width: 333px ! important;
    height: 187px;
  }
  .boat_img2 {
    left: 713px ! important;
  }
  .waterimg2 {
    top: 57% ! important;
    left: 16% ! important;
  }
  .nexttogasimage {
    right: 858px ! important;
  }
  .petrolimage1{
    right: 437px ! important;
  }
  .oilimage{
    right: 490px ! important;
  }
  .plantimage11 {
    right: 91px ! important;
  }
  .waterimg2Div_img {
    width: 270px ! important;
  }
  .boat_img2_img {
    width: 370px ! important;
  }
  .nexttogasimg_size {
    width: 428px ! important;
  }
  .petrolimage1_img {
    width: 421px ! important;
  }
  .oilimage_img {
    width: 420px ! important;
  }
  .plantimage11_img {
    width: 421px ! important;
  }
}

@media only screen and (device-width : 800px) {
  .oilimage_img {
    width: 200px;
  }
  .oilimage {
    right: 161px;
    top: 159px;

  }

  .plantimage11_img {
    width: 200px;
  }
  .plantimage11 {
    right: 38px;
    top: 304px;
  }
  .boat_img_size {
    left: 45px;
    top: 32px;
  }
  .waterimg {
    left: 31px;
    top: 205px;
  }
  .nexttogasimg_size {
    width: 200px;
  }
  .nexttogasimage {
    right: 295px;
    top: 294px;
  }
  .boat_img2_img {
    width: 200px;
  }
  .petrolimage1_img {
    width: 200px;
  }
  .boat_img2 {
    left: 117px;
    top: 381px;
  }
  .waterimg2 {
    top: 72%;
    left: 4%;
  }
  .petrolimage1 {
    right: 175px;
    top: 424px;
  }
  .new_HoverDiv4{
    margin-left: -93px ! important;
  }
}
@media only screen and (device-width : 851px) {
  .oilimage_img {
    width: 200px;
  }
  .oilimage {
    right: 161px;
    top: 159px;

  }

  .plantimage11_img {
    width: 200px;
  }
  .plantimage11 {
    right: 38px;
    top: 304px;
  }
  .boat_img_size {
    left: 45px;
    top: 32px;
  }
  .waterimg {
    left: 31px;
    top: 205px;
  }
  .nexttogasimg_size {
    width: 200px;
  }
  .nexttogasimage {
    right: 295px;
    top: 294px;
  }
  .boat_img2_img {
    width: 200px;
  }
  .petrolimage1_img {
    width: 200px;
  }
  .boat_img2 {
    left: 117px;
    top: 381px;
  }
  .waterimg2 {
    top: 72%;
    left: 4%;
  }
  .petrolimage1 {
    right: 175px;
    top: 424px;
  }
  .new_HoverDiv4{
    margin-left: -93px ! important;
  }
}


.contentWraper{
	height: auto;
	background-color: transparent;
	padding-top: 0px!important;
	/*background-color: #fff;*/
	/*padding-top: 35px;*/
  overflow: hidden;
}

.block{

	  background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 30px;
    padding:13px 12px 10px 12px;
    height: 225px;
    padding-top: 30px;
    /*width: 287px;*/
}
.block img{
  height: 55%;
}
.block img:hover
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  overflow-wrap: hidden;
  /*transition-delay: 0.10s;*/
  transition-duration: 0.20s;
}
.block h2{
	/*margin-top: 70px;*/
	font-size: 26px;
  line-height: 55px;
  font-weight: 600;
  text-align: center;
}
.block p{
  font-size: 14px;
  color:#000;
}
.contentheader1{
  margin-bottom:20px!important;
}
.contentheader{
  margin-top: 20px;
	margin-bottom:20px!important;
}
.contentheader h1{
	font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader h2{
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -1px;
}
.contentheader hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}
.contentheaderNewPage hr{
   height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;

}

.contentheader1 h1{
  font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader1 h2{
  font-size: 42px;
  line-height: 55px;
  letter-spacing: -1px;
}
.contentheader1 hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}

/*.block1height{
  height: 300px;
}
.block2height{
  height: 300px;
}
.block3height{
  height: 300px;
}
.block4height{
  height: 300px;
}
.block5height{
  height: 300px;
}
.block6height{
  height: 300px;
}
*/
.text-justify{
	text-align: justify;
}
@-webkit-keyframes rotation {
    
    to{
       
        -webkit-transform: perspective(100px) rotateY(0deg);
    }
}
.upstreamBg{
    padding: 150px 0px;
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/6.png");
    height: 785px;
}

.upstream-wrapper:before {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}
.upstream-wrapper:after {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}

.upstream-wrapper:before {
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    background-position: calc(50% - 100px) 50%;
    opacity: .2;
}

.upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}

.upstream-wrapper:after {
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    background-position: calc(50% + 100px) 50%;
    opacity: .1;
}
.upstream-wrapper:after, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}
.upstream-wrapperafter, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
.upstreamBg{
    position: relative;
    z-index: 1;
    background-position: 50%;
    background-repeat: repeat-x;
}
.svgAnimation{
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/data_image_svgs.svg");
    height: 540px;
}



.Main_Cards__Bubble:first-child {
    top: 450px;
    left: -75px;
}
.Main_Cards__Bubble:first-child {
    width: 893px;
    height: 893px;
    content: "";
}
.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in, -webkit-transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color:#eeeeee4d;
    border-radius: 50%;
}
.Main_Cards__Bubble:nth-child(2) {
    right: 0px;
    top: 400px;
    width: 1000px;
    height: 1000px;
}
/*.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color: rgba(105,92,255,.05);
    border-radius: 50%;
}*/
.havh{
  
}




@-webkit-keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}




@keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}
@-webkit-keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@-webkit-keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
@keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
.bub1{
    -webkit-animation:  bubblea1 30s linear infinite;
            animation:  bubblea1 30s linear infinite;


}

.bub2{
    -webkit-animation: bubblea3 30s linear infinite;
            animation: bubblea3 30s linear infinite;
}

.para0{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para2{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para4{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para1{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para3{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para5{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para6{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para7{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}
.para8{
  position: relative;
  top: 730px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1s;

}







.paraeff{
  position: relative;
  top :0px;
  cursor: pointer;
  /*margin-left: 8px;*/
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 0.5s;
 /* margin-inline-start: 40px;
  margin-inline-end: 40px;*/
}
@media screen and (max-width: 768px){
  .paraeff{
    /*margin-inline-start:12px ! important;*/

  }
  .downTitleNew{
    font-size: 15px ! important;
  }
  .downTitle{
    font-size: 16px ! important;
  }
  .downTitle1{
    font-size: 15px ! important;
  }
}

.paraeff1{
  position: relative;
  top :0px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 1.2s;
  transition-delay: 0.5s
}

.border{
  padding-top: 75px;
  /*border: 1px solid*/
}
.downTitle{
  font-size: 19px;
  margin-top: 20px;
  font-size: 21px;
}
.downTitle1{
  font-size: 21px;
  
}
.downTitleNew{
  margin-top: 0px;
  font-size: 20px;
}
.streamImg{
  margin-bottom: 15px;
}
.HOmeLine1{
  text-align: center;
  height: 4px;
  width: 90px;
  background: #f19000;
}
.upstreamtext{
    padding:0px 40px;
    /*letter-spacing: 0.5px;*/
    height: 85px;
  }

.price-bodyhover:hover .upbuttonhover{
  color: #fff!important;
  background-color: #f19000;
  transition-duration:0.7s;
  cursor: pointer;
}
.uptitle{
  border-bottom: 1px solid #c0b1b1b1;
  padding-bottom:20px; 
  color:#333;
}

.cards
{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  position: relative;
  /*margin-top: 40px;*/
  
}
.upstream_tab{
  /*margin-left:170px;*/
}

.card
{
  /*display:flex;*/
  width:380px;
  height:314px;
  border-radius:0.5rem;
  -webkit-transform-origin:center center;
          transform-origin:center center;
  -webkit-transform:scale(1) translate(0px,0px) perspective( 750px ) rotateY(0deg);
          transform:scale(1) translate(0px,0px) perspective( 750px ) rotateY(0deg);
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  transition-duration: 0.5s;
  position:absolute;
  top:0;
  box-shadow:0 30px 50px rgba(0,0,0,0.1);
  /*z-index:3;*/
  /*cursor: pointer;*/
  /*overflow:hidden;*/
  /*background-color: #eee;*/
  top:25px;
}

.card *
{
  pointer-events: none;
}

.card--left
{
  -webkit-transform:scale(0.75) translate(-377px,0px) perspective( 750px ) rotateY(25deg);
          transform:scale(0.75) translate(-377px,0px) perspective( 750px ) rotateY(25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:2;
}
.card--left-left
{
  -webkit-transform:scale(0.75) translate(-670px,0px) perspective( 750px ) rotateY(25deg);
          transform:scale(0.75) translate(-670px,0px) perspective( 750px ) rotateY(25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:1;
}

.card--center
{
  -webkit-transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg);
          transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg);
  box-shadow:0 30px 50px rgba(0,0,0,0.1);
  z-index:3;
}

.card--right
{
  -webkit-transform:scale(0.75) translate(377px,0px) perspective( 750px ) rotateY(-25deg);
          transform:scale(0.75) translate(377px,0px) perspective( 750px ) rotateY(-25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:2;
}
.card--right-right
{
  -webkit-transform:scale(0.75) translate(670px,0px) perspective( 750px ) rotateY(-25deg);
          transform:scale(0.75) translate(670px,0px) perspective( 750px ) rotateY(-25deg);
  box-shadow:0 15px 25px rgba(0,0,0,0.1);
  z-index:1;
}

.card__icon
{
  width:30%;
  height:100%;
  background:rgba(255,255,255,0.5);
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card__icon:before
{
  content:attr(data-icon);
  font-size:3rem;
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100px;
  height:100px;
  border-radius: 50px;
  background:rgba(255,255,255,1);
}

.card__detail
{
  flex:1 1;
  display:flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width : 736px)
{
  .cards
  {
    flex-direction:column;
    margin:auto 0;
  }

  .card
  {
    display:flex;
    width:90%;
  }

  .card--left
  {
    -webkit-transform:scale(0.75) translate(0px, -150px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
            transform:scale(0.75) translate(0px, -150px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
  }

  .card--center
  {
    -webkit-transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg)  rotateX(0deg) translateZ(5px);
            transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg)  rotateX(0deg) translateZ(5px);
  }

  .card--right
  {
    -webkit-transform:scale(0.75) translate(0px, 150px) perspective(750px) rotateY(0) rotateX(10deg) translateZ(-5px);
            transform:scale(0.75) translate(0px, 150px) perspective(750px) rotateY(0) rotateX(10deg) translateZ(-5px);
  }

  .card__icon:before
  {
    -webkit-transform:scale(0.75);
            transform:scale(0.75);
  }
}
@media(max-width : 768px)
{
  .cards
  {
    flex-direction:column;
    margin:auto 0;
  }

  .card
  {
    display:flex;
    width:90%;
  }
  .hrnewclass{
    margin-left: 309px ! important;
  }
  .upstreamtext{
    font-size: 13px ! important;
  }
  .upstream_tab h3{
    font-size: 21px ! important;
  }

  /*.card--left
  {
    transform:scale(0.75) translate(1px,-150px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
  }

  .card--center
  {
    transform:scale(1) translate(0px, 0px) perspective( 750px ) rotateY(0deg)  rotateX(0deg) translateZ(5px);
     height: 440px;
  }

  .card--right
  {
    transform:scale(0.75) translate(0px, 150px) perspective(750px) rotateY(0) rotateX(10deg) translateZ(-5px);
     height: 440px;
  }*/

  .card__icon:before
  {
    -webkit-transform:scale(0.75);
            transform:scale(0.75);
  }
  .card--right-right{
    -webkit-transform: scale(0.75) translate(370px,0px) perspective( 750px ) rotateY(-25deg);
            transform: scale(0.75) translate(370px,0px) perspective( 750px ) rotateY(-25deg);

  }
}
.upstreamcontentheader h1{
  font-size: 41px;
}

.upstreamcontentheader hr{
  height: 2px;
  width: 100px;
  border: 1px solid #f19000;
  background-color: #f19000;
}
/*.card--left-left {
    transform: scale(0.75) translate(218px,0px) perspective( 750px ) rotateY(-18deg);
     height: 440px;
    }
    .card--right {
    transform: scale(0.75) translate(-202px, -26px) perspective(750px) rotateY(0) rotateX(4deg) translateZ(-5px);
     height: 440px;
}
.card--left {
    transform: scale(0.75) translate(-389px,-10px) perspective(750px) rotateY(0) rotateX(-10deg) translateZ(-5px);
    height: 440px;
    }
*/


.upstreamfoot{
  pointer-events: auto!important;
  cursor: pointer;
  background: #f19000;  
}

.upstreamblock{
  height: 600px;
  padding: 50px 100px;
  margin-bottom:50px;


}
.upstreamblock_new{
  height: auto;
  padding: 60px 100px;
  margin-bottom:60px;
}

.owl-theme .owl-nav{
  /*background-color: red;*/
  /*margin-top: -300px!important;*/
}
.newupstreamTitle{
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  /*margin-left: 135px;*/
}
.newblogsTitle{
  color: #009de2;
  font-size: 40px;
  font-weight: 700;
  /*margin-left: 135px;*/
}
.hrnewclass{
    height: 3px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
    margin-bottom: 12px;
    margin-left: 587px;

}
.hrnewclassblog{
    height: 3px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
    margin-bottom: 12px;
    margin-left: 587px

}
@media screen and (max-width: 600px) {
  .upstreamblock_new{
    margin-bottom: 10px ! important;
    padding-top: 20px ! important; 
  }
}



.upstreamcontentheader h1{
  font-size: 40px;
}

.upstreamcontentheader hr{
  height: 2px;
  width: 100px;
  border: 1px solid #f19000;
  background-color: #f19000;
}
.owl-carousel .owl-wrapper-outer{
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1; 
    filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}

.carousel, .carousel-inner {
   height: 100%;
   -webkit-backface-visibility:visible;
           backface-visibility:visible;
   filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}
.carouselInner{
    height: 100% !important;
    position:relative;
    -webkit-backface-visibility:visible;
            backface-visibility:visible;
    filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;
}
.owl-carousel owl-theme owl-loaded owl-drag{
   filter: Alpha(Opacity=100);
    opacity: 1;
    text-decoration: none;

}
.owl-theme div:hover {

    /*filter: Alpha(Opacity=100);*/
    opacity: 1;
    text-decoration: none;
}
.summary_blogs_HP{
  height: 150px;
  margin-bottom: 20px;
}
.carousel-inner{
  -webkit-backface-visibility:visible;
          backface-visibility:visible;
}


.blogsimg img{
  height: 200px;
  border-radius: 8px 8px 0px 0px;
}

.blogtext p{
  text-align: justify;
  padding: 0px 20px;
  font-size: 11px;
  height: 135px;
  color:#000;
  /*margin-top: 10px;*/

}
.newfooterjoin{

}
.blogdate{
  text-align: justify;
  padding: 10px 20px;
  font-size: 13px;
  margin-top: -16px;
  font-weight: 600;

}
.blogtext h4{
  /*text-align: justify;*/
  padding: 0px 20px;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 7px;

}
.blogtext {
	height: 550px;
	border-radius: 8px;
	margin-top: 5px
}

.latestblog{
  margin-bottom: 50px;
}

.blogblock{
  height: 495px ! important;
	box-shadow: 0px -2px 12px 0px #d6d0d0;
	border-radius: 8px;
  padding:0px;

}
.blogtext p{
  overflow: hidden;
}

.blogbtn1{
  	margin-top: -15px;
    /*border-radius: 20px;*/
    color: #fff;
    font-weight: 600;
}
.blogbtn1:focus{
  outline: 0px auto -webkit-focus-ring-color!important;
}

.blogleftarrow{
    /*background-color: #009de2;*/
    box-shadow: 0px 0px 5px 4px #eee;
    color: #333;
    border-radius: 50%;
    padding: 9px 8px;
    font-size: 25px;
    left: -80px;
    height: 45px;
    width: 45px;
    top: 204px;
    position: absolute;

}
.blogrightarrow{
    /*background-color: #009de2;*/
    box-shadow: 0px 0px 5px 4px #eee;
    color: #333;
    border-radius: 50%;
    padding: 9px 8px;
    font-size: 25px;
    right: -80px;
    height: 45px;
    width: 45px;
    top: 204px;
    position: absolute;

}
.gt__2YKbG{
  margin-right: 59% ! important;
}
.sxSelectListsWrapper__2321j{
  width: 42% ! important;
}

@media screen and (max-width: 600px) {
  .blogblock{
    height: 600px ! important;
  }
  .price-footer{
    margin-top: 38px ! important;
  }
  .blogrightarrow{
    right: -42px ! important;
  }
  .blogleftarrow{
    left:-42px ! important;
  }
  .blogtext h4{
    font-size: 15px ! important;
    height: 74px ! important;
  }
  .blogtext p{
    margin-bottom: 60px ! important;
  }
  .blogdate{
    margin-bottom: 15px ! important;
    padding: 7px 11px;
  }
  .New_Study_shadow{
    width: 250px ! important;
  }
  .iconCircle{
    margin-left: 29% ! important;
  }
  .padding_Mob{
    padding-left: 11px ! important;
  }
  .blogbtn1{
      padding: 7px 0px;
      width: 80px;
      float: right;

    }
}
@media only screen and (device-width: 1024px) {
  .blogblock{
    height: 515px ! important;

    }
  }
@media only screen and (device-width: 1024px) {
    .blogbtn1{
      padding: 7px 0px;
      width: 205px;
    }
    .blogblock{
      height: 555px ! important;
    }
    .price-footertab{
      margin-top: 35px ! important;
      padding-left: 20px! important;
    }  
  }
@media only screen and (device-width: 728px)  {
    .blogbtn1{
      padding: 7px 0px;
      width: 220px;
      float: right;
    }
    .blogdate{
      padding-top: 113px ! important;
    }


   
  }
@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .blogbtn1{
      padding: 7px 0px;
      width: 180px;
      float: right;

    }
    .blogdate{
      padding-top: 0px ! important;
    }
   
  }
  @media only screen and (device-width : 412px) and (device-height : 712px) { 
    .All1blog1{
      width: 305px ! important;
    }

  }
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
    
  .blogblock {
        height: 661px ! important;
    }
  .blogdate{
      margin-top: 40px;
     
  }
  .blogtext h4{
    padding: 0px 3px;
  } 
  .blogtext{
    height: 726px;
  }

}
  @media only screen and (device-width : 320px) and (device-height : 533px) { 
    .blogtext {
        height: 693px ! important;
    }
    .blogblock {
        height: 689px ! important;
    }
    .price-footerBlog {
        margin-top: 100px ! important;
    }
    .blogtext h4{
          margin-bottom: 20px;
          line-height: 1;
    }
  }
  @media only screen and (device-width : 320px){ 
    .blogtext {
        height: 693px ! important;
    }
    .blogblock {
        height: 689px ! important;
    }
    .price-footerBlog {
        margin-top: 100px ! important;
    }
    .blogtext h4{
          margin-bottom: 20px;
          line-height: 1;
    }
   /*  .index0{
      width: 182px ! important;
    }
    .index2{
      width: 182px ! important;
    }
    .index1{
      width: 182px ! important;
    }
    .index3{
      width: 182px ! important;
    }
    .index4{
      width: 182px ! important;
    }*/
  }
  @media only screen and (device-width : 375px) {
  .blogdate{
    font-size: 12px ! important;
  }
}
.para10{
  position: relative;
  top: 800px;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  transition-duration: 2s;

}

.para11{
  position: relative;
  top: 800px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  transition-duration: 2s;

}
.para12{
  position: relative;
  top: 800px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  transition-duration: 2s;

}
.paraeffect{
  position: relative;
  top :0px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 0.5s;
  /*margin-inline-start: 45px;*/
  /*margin-inline-end: 28px;*/
}
.paraeffect12{
  position: relative;
  top :0px;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition-duration: 0.5s;
}
/*
.para12{
	opacity: 0;
	transition-duration: 2s;
}*/
.para12effect{
	opacity: 1;
	transition-duration: 1.5s;
}
.downTitle{
  font-size: 19px;
  cursor: pointer;
}
.streamImg{
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
 
  .divinportrait{
    width:200px ! important;
  }
}
@media (max-width: 480px){
     .divinportrait{
    width:206px ! important;
  }
  .paraeffect{
    -webkit-margin-start:0px ! important;
            margin-inline-start:0px ! important;
  }
  .contentWraper{
    width: 100% ! important;
  }
  .contentheader h2{
    font-size: 28px ! important;
    padding:15px ! important; 
  }
  .para10{
    width: 235px ! important;
  }
  .para11{
    width: 235px ! important;
  }
 }   

.mapouter{
	position:relative;
	text-align:right;
	/*margin-top: 100px;*/
/*	height:500px;
	width:600px;*/
}
.gmap_canvas {
	overflow:hidden;
	background:none!important;
	height:500px;width:100%;
}
.left_h2{
	padding-left: 215px;
	padding-bottom: 30px;
}
.left_h21{
	/*padding-left: 215px;*/
	
}
.left_h21txt{
	border-bottom:  1px solid #000;

}
.subdata{
	padding-left: 22px;
}
.Aboutuslink{
	color:#2B2B2B;
	margin-bottom: 40px;
	margin-left: 33px;
	font-weight: 700;
}
.Aboutuslink1{
	margin-bottom: 40px;
	font-weight: 600;
}
.mainpages{
	margin-bottom: 10px;
}
..mainpages1{
	margin-bottom: 30px;
}
.maindiv12{
	margin-top: 100px;
}
.submenu{
	line-height: 23px;
	padding-left: 10px;
	color:#2F2F2F;
}
.submenu a{
	color:#2F2F2F;
	font-size:12px;
	text-decoration: none;
}
.submenu a:hover{
	color:#ffa500 !important;
}
.sitemapimg{
	height: 100px;
	width: 100px;
	background-size: 100% 100%;
	margin-top: 100px;
}
.bottomlinksraquo1{
	padding-left: 35px;
}
.bottomlinksraquo2{
	padding-left: 25px;
	margin-top: 20px;
}
.expetisesubdiv{
	margin-top: 15px;
}
.servicediv{
	padding-left: 40px;
}
.maindiv123{
	margin-top: 30px;
}
.submenu1{
	line-height: 27px;
    padding-left: 20px;
}
.cirlriconsitemap{
	font-size: 7px;
    padding: 4px 5px 3px 4px;
}
.maindivleft{
	margin-top: 40px;
}
.maindiv1234{
	margin-top: 30px;
}



.sitemapline{
	border-bottom: 1px solid #DDDDDD;
    margin-left: 31px;
    padding-bottom: 6px;
	}   
.submenuindustries
 {

	}
.link1expertise{
	border-bottom:1px solid #eee;
}
.sitemapheading{
	padding-top: 25px;
	font-weight: 700;
}
.sitemapheading1{
	border-bottom:3px solid #aaf;
}
.mobileIcon{
  font-size: 20px;
  padding: 0px 3px 0px 3px;
}
.contactcontent1-wrap{
	/*margin-top: 30px;*/
}
.contactcontent2-wrap{
  margin-top:100px;
  margin-bottom: 3px !important;
  color: #fff;
  /*background-image: url("/images/contactusbg.png");*/
  background-size: 100% 100%;
  /*height: 800px;*/
  background-color: #ffffff;
}
.contactcontent{
margin-bottom: 50px;
}

.contactcontent span{
 font-weight: bold;
 font-size: 50px !important;
 }
 .us{
  font-weight: 100!important;
 }


.bw{
  background-color: #f0f;
}

.contactcontbackimg{
  background-image: url("/images/Contact-Us.png");
  background-size: 100%;
  height: 450px;
  background-repeat: no-repeat;
  /*background-color: #555;*/
  margin-top: 110px;
}
.contactpageform{
  /*background-color: #ddd;*/
  height: 760px;
  color: #555;
}

.contacticon1{
  font-size: 55px;
  cursor: pointer;

}

.contacticon2{
    padding: 5px 6px;
    background-color: #009de2;
    font-size: 35px;
    border: 5px solid #009de2;
    border-radius: 50%;
    color: #fff;
}
.contacticon21{  
  padding: 5px 12px;
    background-color: #009de2;
    font-size: 35px;
    border: 5px solid #009de2;
    border-radius: 50%;
    color: #fff;
  }
.contacticon22{
  color: #fff;
  font-size: 25px;
  position: absolute;
  right: 38px;
  top: 12px;

}
.contacticon23{
  color: #fff;
  font-size: 27px;
  position: absolute;
  right: 38px;
  top: 12px;

}
.contacttextmargin{
  /*margin-top: 70px;*/
} 
.contacttextmargin2{
  /*margin-top: 60px;*/
} 
.addonColor{
  background-color: #fff;
}
.contacttextmargin h4{
  text-transform: capitalize;
  
} 
.contactformbtn{
  /*border-radius:0px;*/
  /*font-size: 12px;*/
  font-weight: 600;
  padding:10px 20px;
  margin-top: 50px;
  color: #fff;
}


#message{
  resize: none;
}

.formcontent{
  height: 80px;
  text-align: left;
}
.formcontent1{
  height: 138px;
}


.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.fullwidth{
  width: 100%;
  text-align: left;
}

.textright{
  text-align: right!important;
}

.adjustcaret{
  float: right;
  margin-top: 7px;
}

.redFont{
  color: #f00;
}

.contacticonwrap{
  /*margin-top: 50px;*/
}

.contacticons{
  margin-top: 5px;
}
.sxSelectHeader__Xlz5r{
    margin-bottom: 0px !important;
    height: 35px !important;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding-left: 9px;
}
.separatorWrapper__eH30j{
  width:25%;
}
.sxSelectPlaceholder__1-20v{
  line-height: 1.2px !important;
}

.bold-icons{
  font-weight: bold;
}

@media(max-width : 600px){
  .contactpageform{
    height: 1100px;
  }
  .input-group .form-control{
    z-index: 0 ! important;
  }
 
}

@media only screen and (device-width : 393px) and (device-height : 851px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 21.1px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 

@media only screen and (device-width : 320px) and (device-height : 568px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 
  @media only screen and (device-width : 320px){ 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  } 
   @media only screen and (device-width : 360px){ 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }     
  @media only screen and (device-width : 768px) and (device-height : 1024px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }   
 @media only screen and (device-width : 375px) and (device-height : 812px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 375px) and (device-height : 667px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 375px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 400px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 412px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
  @media only screen and (device-width : 414px) and (device-height : 736px) { 

    .sxSelectHeader__Xlz5r{
        height: 45px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }    
   @media only screen and (device-width : 360px) and (device-height : 640px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
   @media only screen and (device-width : 400px) and (device-height : 700px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
   @media only screen and (device-width : 800px) and (device-height : 1280px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 384px) and (device-height : 640px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 412px) and (device-height : 732px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 411px){ 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 411px) and (device-height : 823px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 11px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 11px ! important;
    }


  }    
  @media only screen and (device-width : 390px) and (device-height : 844px) { 

    .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }  
  @media only screen and (device-width : 390px){
     .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }


  }

  @media only screen and (device-width : 401px){
     .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }

  }   

@media screen and (max-width:600){
   .sxSelectHeader__Xlz5r{
        height: 40px ! important;

    }
    .sxSelectPlaceholder__1-20v{
      line-height: 13px ! important;
    }
    .sxSubListItem__1gP3t .sxSubListItemLabel__1vcup{
      font-size: 12px ! important;
    }
    .sxListItem__2kr9F .sxListItemLabel__S0lF1{
      font-size: 12px ! important;
    }

}
@media only screen and (device-width : 400px) { 
  .serflip-cardservices img{
    width: 220px ! important;
  }
    .contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
}
@media only screen and (device-width : 401px) and (device-height : 700px) { 
  .serflip-cardservices img{
    width: 220px ! important;
  }
    .contactcontent {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-top: 20px;
  }
}
.l_BT1_para{
    line-height: 23px;
    letter-spacing: 0.5px;
    margin-top: 188px;  
    /*color: #000;*/
    margin-left: 52px;
    font-size: 17px;
}


.takeleft{
	padding-left: 45px;
	background-color: transparent !important;
}
.breadcrumb1{
	background-color: transparent;
	width: 306px;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
}
.breadcrumb1responsive{
    background-color: transparent;
    width: 280px;
    height: 34px;
    background-color: #f5f5f5;
    border-radius: 100px;
}
.triangleright{

      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-left: 36px solid red;
      border-bottom: 19px solid transparent;
 }/* Base
------------------------------------------------------------------------- */
            
    /*body {
        font: 14px/1.5 Helvetica, Arial, "Lucida Grande", "Lucida Sans", Tahoma, Verdana, sans-serif;
        }  
    */
/* Step Navigation
------------------------------------------------------------------------- */
    
    .stepNav {
      /*margin: 30px 20px;*/
        height: 43px;
        padding-right: 20px;
        position: relative;
        z-index: 0;
        }
    
    /* z-index to make sure the buttons stack from left to right */
    
    .stepNav li {
        float: left;
        position: relative;
        z-index: 3;
        box-shadow: 0 1px 1px rgba(0,0,0,0.12);
        }
        
    .stepNav li:first-child {
        border-radius: 5px 0 0 5px;
        }
        
    .stepNav li:nth-child(2) { z-index: 1; }
    .stepNav li:nth-child(3) { z-index: 0; }
    
    /* different widths */
    
    .stepNav.twoWide li { width: 50%; }
    .stepNav.threeWide li { width: 33.33%; }
    
       /* step links */
    
    .stepNav a, .stepNav a:visited {
        width: 100%;
        height: 34px;
        padding: 0 0 0 25px;
        color: #717171;
        text-align: center;
        text-shadow: 0 1px 0 #fff;
        line-height: 43px;
        white-space: nowrap;
        border: 1px solid #cbcbcb;
        text-decoration: none;
        border-top-color: #dddddd;
        border-right: 0;
        background-color: #fbfbfb;
        background-image: linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        float: left;
        position: relative;
        box-sizing: border-box;
        }
        
    .stepNav li:first-child a {
        padding-left: 12px;
        border-radius: 5px 0 0 5px;
        }
    
    .stepNav a:before {
        content: "";
        width: 27px;
        height: 22px;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #cbcbcb;
        background-image: linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        display: block;
        position: absolute;
        top: 6px;
        right: -13px;
        z-index: -1;
        -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }
        
    .stepNav a:hover {
        color: #2e2e2e;
        background-color: #f5f5f5;
        background-image: linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
        
    .stepNav a:hover:before {
        background-image: linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
    
    /* selected */
        
    .stepNav li.selected {
        box-shadow: none;
        }
                                
    .stepNav li.selected a, .stepNav li.selected a:before {
        background: #ebebeb;
        }
        
    .stepNav li.selected a {
        border-top-color: #bebebe;
        box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
        }
        
    .stepNav li.selected a:before {
        border-right: 1px solid #bebebe;
        border-bottom: 1px solid #cbcbcb;
        box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
        }

 /* Base
------------------------------------------------------------------------- */
            
   /* body {
        font: 14px/1.5 Helvetica, Arial, "Lucida Grande", "Lucida Sans", Tahoma, Verdana, sans-serif;
        }*/  
    
/* Step Navigation
------------------------------------------------------------------------- */
    
    .stepNav {
      margin: 30px 20px;
        height: 43px;
        padding-right: 20px;
        position: relative;
        z-index: 0;
        }
    
    /* z-index to make sure the buttons stack from left to right */
    
    .stepNav li {
        float: left;
        position: relative;
        z-index: 3;
        box-shadow: 0 1px 1px rgba(0,0,0,0.12);
        }
        
    .stepNav li:first-child {
        border-radius: 5px 0 0 5px;
        }
        
    .stepNav li:nth-child(2) { z-index: 1; }
    .stepNav li:nth-child(3) { z-index: 0; }
    
    /* different widths */
    
    .stepNav.twoWide li { width: 50%; }
    .stepNav.threeWide li { width: 33.33%; }
    
       /* step links */
    
    .stepNav a, .stepNav a:visited {
        width: 100%;
        height: 34px;
        padding: 0 0 0 25px;
        color: #717171;
        text-align: center;
        text-shadow: 0 1px 0 #fff;
        line-height: 43px;
        white-space: nowrap;
        border: 1px solid #cbcbcb;
        text-decoration: none;
        border-top-color: #dddddd;
        border-right: 0;
        background-color: #fbfbfb;
        background-image: linear-gradient(top, rgb(251, 251, 251), rgb(233, 233, 233));
        float: left;
        position: relative;
        box-sizing: border-box;
        }
        
    .stepNav li:first-child a {
    	/*width:110px;*/
        padding-left: 12px;
        border-radius: 5px 0 0 5px;
        }
    
    .stepNav a:before {
        content: "";
        width: 27px;
        height: 22px;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #cbcbcb;
        background-image: linear-gradient(right top, rgb(251, 251, 251), rgb(233, 233, 233));
        display: block;
        position: absolute;
        top: 6px;
        right: -13px;
        z-index: -1;
        -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }
        
    .stepNav a:hover {
        color: #2e2e2e;
        background-color: #f5f5f5;
        background-image: linear-gradient(top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
        
    .stepNav a:hover:before {
        background-image: linear-gradient(right top, rgb(242, 242, 242), rgb(217, 217, 217));
        }
    
    /* selected */
        
    .stepNav li.selected {
        box-shadow: none;
        }
                                
    .stepNav li.selected a, .stepNav li.selected a:before {
        background: #ebebeb;
        }
        
    .stepNav li.selected a {
        border-top-color: #bebebe;
        box-shadow: inset 2px 1px 2px rgba(0,0,0,0.12);
        }
        
    .stepNav li.selected a:before {
        border-right: 1px solid #bebebe;
        border-bottom: 1px solid #cbcbcb;
        box-shadow: inset -1px -1px 1px rgba(0,0,0,0.1);
        }
    .thirdbox{

    }    
  

.breadcrumb>li+li:before{
	
}
.contentWraper{
    height: auto;
    background-color: transparent;
    padding-top: 161px;
 /* padding-top: 282px;*/
    padding-left: 00px;
    padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}

.aboutus_block{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    /*padding: 32px 40px 56px;*/
    margin-top: 58px;
    text-align: center;
   
    
 /*   margin: 10px;
    margin-left: 43px;*/
}
.icn_1{
    margin-top: 60px;
}

.aboutus_block:hover .icn1_hover{
  display: block;
}
.aboutus_block:hover .icn_1{
  display: none;
}
.aboutus_block .icn1_hover{
  display: none;
}

.icn_2{
  margin-top: 60px;
  margin-bottom: -57px;
}

.aboutus_block:hover .icn2_hover{
  display: block;
}
.aboutus_block:hover .icn_2{
  display: none;
}
.aboutus_block .icn2_hover{
  display: none;
}

.icn_3{
    margin-top: 60px;
}
.aboutus_block:hover .icn3_hover{
  display: block;
}
.aboutus_block:hover .icn_3{
  display: none;
}
.aboutus_block .icn3_hover{
  display: none;
}

.icn_4{
   margin-top: 60px;
}

.aboutus_block:hover .icn4_hover{
  display: block;
}
.aboutus_block:hover .icn_4{
  display: none;
}
.aboutus_block .icn4_hover{
  display: none;
}
.aboutus_block:hover{
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    background-color: #009de2;
    color:white;
    background-image:url("/images/11.png") no-repeat;
    height: 200px;
    top:12px;

}
.aboutus_block1height:hover{
    height: 290px;
    margin-bottom: -5px;
    margin-top: 15px;
    transition-duration: 1.2s;

}

.aboutus_block h2{
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    margin-top: 13px;
}

/*.contentheader{
    padding-bottom:35px;
}
.contentheader h1{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: -.4px;
}
*/
.aboutus_block1height{
    top: 15px;
    /*height: 186px;*/
    height: 240px;
    width: 286px;
    /*width: 260px;*/
    /*margin-left: 44px;*/
}
.aboutus_block2height{
  top:15px;
  height: 300px;
}
.aboutus_block3height{
  top:15px;

  height: 300px;
}
.aboutus_block4height{
  top:15px;

  height: 300px;
}
.aboutus_block5height{
  top:15px;

  height: 300px;
}
.aboutus_block6height{
    top:15px;

  height: 300px;
}

.text-justify{
    text-align: justify;
}
.para-top{
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
}
.respara-top{
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
}

.para1-top{
    margin-top: 56px;
    margin-bottom: -1px;
    font-size: 30px;
    font-weight: 700;
}
@media(max-width: 768px) {
  .para1-top{
   font-size: 27px ! important;
  }
  .line2{
    width: 90px ! important;
  }
}

.iog_txt{
    margin-top: 73px;
    /*margin-left: 34px;*/
    font-size: 20px;
    }

/*.iog_txt1{
    margin-top: 57px;
    margin-left: 34px;
    font-size: 20px;
    }*/
/*
.block1{
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    background-color: #003bb3;
    color:white;
    margin: 10px;
}
.iog_txt1{
    margin-top: 203px;
    margin-left: 57px;
    font-size: 20px;
    }*/

  .btm_txt{
     margin-top: 53px;
  } 

  .b1width{
    width: 377px;
    margin-left: 211px;
    padding-top: 0px;
    height: 163px;
}
   .b2width{
    width: 300px;
    margin-left: 211px;
    padding-top: 0px;
    height: 264px ! important;
}
  .vision_align{
    padding-bottom: 31px;
    font-size: 17px;
  } 
  .vision_align1{
/*padding-top: 16px;*/
  }

  .txt-size-director{
    font-size: 52px;
    
  } 
  .txt-size-founder{
    font-weight: 700;
    font-size: 27px;
    
  }
 .head_spacing{
margin-top: 100px;
}

 .para_spacing{
    font-size: 17px;
    padding: 0px 0px 22px 0px;
    
  }

  .line_subhead{
    /*font-weight: 600;*/
    font-size: 28px;
    /*margin-top: 25px;*/
    color: #000;
  }

  .line_para{
    line-height: 25px;
    letter-spacing: 1px;
     /*margin-top: 20px; */
    font-size: 20px;
}
.line_para1{
    line-height: 23px;
    letter-spacing: 0.5px;
    margin-top: 228px;
    /* padding-right: 110px; */
    margin-left: 50px;
    font-size: 18px;
}

.line_para2{
    line-height: 25px;
    letter-spacing: 1px;
    font-size: 19px;
    color: #000;
}
.line_para3{
    line-height: 23px;
    letter-spacing: 0.5px;
    margin-top: 188px;  
    color: #000;
    margin-left: 52px;
    font-size: 18px;
  }
.line_para4{
    line-height: 23px;
    letter-spacing: 1px;
}
.para-top3{
  font-size: 31px;
  /*margin-top: 160px;*/
}

.alg_lft{
      /*margin-left: -22px;*/
      -webkit-padding-start: 25px!important;
              padding-inline-start: 25px!important;
    font-size: 20px;
    padding: 0 125px 10px 10px;
}

.blck{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    margin-top: 58px;
    margin: 10px;
}

.blck1{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    /*padding: 32px 40px 56px;*/
    margin-top: 58px;
    margin: 10px;
}


.blck1height{
    top: 137px;
    height: 224px;
    width: 427px;
    margin-left: 161px;
    margin-top: 10px;
    border-radius: 10px;
}
.blck1heightnew{
    top: 137px;
    height: 200px;
    width: 600px;
    /*margin-left: 161px;*/
    margin-top: 10px;
    border-radius: 10px;
    float: right;
}
.blck1height1{
    /*top: 137px;*/
    height: 224px;
    width: 300px;
    /*margin-left: 161px;*/
    margin-top: 86px;
    border-radius: 10px;
}
   /*.anim_box{ color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}*/

.blk_ht{
  height: 325px;
  margin-top: 24px;
}

.line1{
  background-color: #f08f00;
  width: 90px;
  height: 4px;
  margin-top: 40px;
  margin-left: 35px;
}

.line2{
  background-color: #f19000;
  width: 105px;
  margin-top: 35px;
  height: 4px;
  /*margin-left: 500px;*/
  text-align: center;
}
.resline2{
   background-color: #f19000;
  width: 105px;
  margin-top: 35px;
  height: 4px;
  margin-left: 100px;
  text-align: center;

}

.line_ht{
  line-height: 30.8px;
  margin-bottom: 10px;
}

.ln_ht{
  margin-bottom: 10px;
}
.ln_ht2{
  margin-bottom: 107px;
}

.img1{
  height: ;
  width: ;
}

/*****************************************************************************************/
.intro_img{
    /*width: 100%;
    margin-top: 50px;*/
  }
.third_img{
  width: 70%;
  /*margin-top: 46px;*/
}
.line_yellow{
  background-color: #f19000;
  width: 105px;
  margin-top: 145px;
  height: 4px;
  margin-left: 145px;
}

.truck{
    width: 90%;
    /*margin-top: 26px;*/
    /*margin-left: 113px;*/
}

.plane{
    width: 90%;
    /*margin-left: 49px;*/
    margin-top: 35px;
}

.director_img{
  width: 100%;
  margin-top: 65px;
  /* margin-left: 171px; */
  /*margin-top: 235px;*/
  border: 9px solid #fff;
  box-shadow: 0 10px 29px rgba(92,141,255,0.5);
}
.director_imgDiv{
  /*padding-left: 170px;*/
    padding-top: 30px;
}
.DirectorText{
  padding-top: 27px;
}

.curve_img{
    width: 100%;
    margin-top: -135px;
    margin-left: 250px;
}
.icon1{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn1_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
./*block:hover  .icon1{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icon1:hover .icn1_hover{
  background-image: url("/images/11.png");
  margin-top: 37px;
  margin-bottom: -55px;
}*/
.icon2{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn2{
  margin-top: 35px;
}
.icn2_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.icon3{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn3_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.icon4{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn4_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.blck2height{
    top: 101px;
    height: 196px;
    width: 427px;
    margin-left: 161px;
    margin-top: 86px;
    border-radius: 10px;
}

.bckgrnd_curve_img {
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  background-size: 100% 100%;
  height: 550px !important;
  margin-top: 50px;
}

.backgrd_curve1{
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  /*height: auto;*/
  height: 640px;
}
.backgrd_curve1_mob{
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  /*height: auto;*/
  height: 1150px;
}


.servicekbtn{
   color: #fff;
    margin-top: 15px;
    width: 76%;
    font-weight: 600;
    padding: 5px;
    font-size: 15px;
    padding: 8px;
}
.servicekbtnarrow{
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
}
@media(max-width : 767px){
  .line1{
    /*margin-left:135px ! important;*/

  }
}
@media(max-width : 600px){
  .line1{
    margin-left:135px ! important;

  }
}
@media(max-width : 768px){
  .line1{
    /*margin-left:118px ! important;*/

  }
}
@media (max-width: 480px){
    .line1{
    margin-left:99px ! important;

  }
  .plane{
    width: 65% ! important;
  }
}

.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
/*    padding-top: 282px;
*/ padding-left: 0px;
   padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}

.NOpadding{
  padding-left: 0px;
  padding-right: 0px;
}

.line_1{
  background-color: #f19000;
  width: 105px;
  height: 4px;
}

.career_line_subhead{
    font-size: 50px;
    margin-top: 15px;
    padding-bottom: 0px;
    padding-left: 12px;
  }

  .career_line_paraone{
    padding-bottom: 46px;
    padding-top: 32px;
    font-size: 20px;
    padding-left: 12px;
}

.career_intro_img{
    width: 73%;
    margin-top: 0px;
    margin-left: 64px;
}
  

.butt_1 {
    background-color: #003bb3;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
    padding-right: 22px;
    padding-bottom: 10px;
    padding-left: 14px;
    width: 136px;
    transition: all 0.5s;
    cursor: pointer;
    /*margin-left: 22px;*/
    height: -24px;
    border-radius: 40px;
}


.butt_1 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.butt_1 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 105px;
  transition: 0.5s;
}

.butt_1:hover span {
  /*padding-right: 25px;*/

}

.butt_1:hover span:after {
  opacity: 1;
  right: 0;
}

/*

.career_para-top{
    font-size: 50px;
    font-weight: 100;
    color: #666666;
    padding-left: 22px;
    margin-top: 44px;
}

.line_subhead1{
  padding-left: 22px;
  font-size: 20px;
  color: #808080;
   margin-bottom: 41px;
   margin-top: 28px;
}
.iogimgblock1{
  width: 100%;
  padding-top: 142px;
}

.iogblock1text{
  padding-top: 100px;
  font-size: 15px;
  margin-top: 142px;
}

.iogblock1text span{
  font-size: 18px;
  font-weight: bold;
}*/

.colorblue_txt{
	color: #1378d4;
}

.career1_img{
width: 100%;
padding-right: 45px;
}


.line_headd hr {
    height: 2px;
    width: 100px;
    border: 1px solid #f19000;
    background-color: #f19000;
}

.career_linee_1 {
    background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 165px;
    margin-left: 69px;
}

.rcorners1 {
    border-radius: 32px;
    background: #1378d4;
    padding: 20px;
    width: 235px;
    height: 60px;
    padding-left: 38px;
    color: white;
    font-size: 22px;
    padding-top: 13px;
}


.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
 padding-left: 0px;
   padding-right: 0px;
    
}

.career_para-top5{
  font-size: 50px;
    font-weight: 100;
    color: #666666;
    padding-left: 79px;
    letter-spacing: 1px;
}

.icnn_1{
	width: 30%;
    margin-left: 188px;
}
.txt_1{
    padding-left: 165px;
    margin-top: 22px;
}

.icnn_2{
	width: 30%;
   /* margin-left: 188px;*/
}
.txt_2{
	
}

.icnn_3{
	width: 30%;
    /*margin-left: 188px;*/
}
.txt_3{
	
}
.icnn_4{
	width: 30%;
    /*margin-left: 188px;*/
}
.txt_4{
	padding-left: 184px;
    margin-top: 22px;
}
.icnn_5{
	width: 30%;
    margin-left: 188px;
    /*margin-left: 188px;*/
}
.txt_5{
	padding-left: 196px;
    margin-top: 22px;
}
.icnn_6{
	width: 30%;
    /*margin-left: 188px;*/
}
.txt_6{
	padding-left: 184px;
    margin-top: 22px;
}

.button_3 {
    border-radius: 4px;
    background-color: #00bfff;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
    padding-right: 22px;
    padding-bottom: 10px;
    padding-left: 17px;
    width: 136px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
    margin-top: 51px;
    margin-left: 656px;
    height: -24px;
    border-radius: 40px;
}


.button_3 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button_3 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 105px;
  transition: 0.5s;
}

.button_3:hover span {
  /*padding-right: 25px;*/

}

.button_3:hover span:after {
  opacity: 1;
  right: 0;
}

.career_para-top6{
	font-size: 50px;
  font-weight: 100;
  color: #666666;
  padding-left: 79px;
  letter-spacing: 1px;
}
.blk_ht1{
  height: 325px;
  margin-top: 24px;
}

.career_people{
margin-left: 164px;
padding-top: 20px;
}

.career_txt1{
   text-align: center;
    padding-top: 20px;
  }

.team-member-position {
    font-weight: normal;
    font-style: normal;
    color: #b4c734;
    text-transform: uppercase;
    text-align: center;
}

.quote_1 {
    font-weight: normal;
    font-style: normal;
   /* color: #828e96;*/
    color: #000;
    margin-top: 21px;
    text-align: center;
}

.button_4 {
    background-color: #00bfff;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
    padding-right: 22px;
    padding-bottom: 10px;
    padding-left: 17px;
    width: 176px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
    margin-top: 189px;
    margin-left: 595px;
    height: -24px;
    border-radius: 40px;
}


.button_4 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button_4 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 105px;
  transition: 0.5s;
}

.button_4:hover span {
  /*padding-right: 25px;*/

}

.button_4:hover span:after {
  opacity: 1;
  right: 0;
}

  
.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
/*    padding-top: 282px;
*/ padding-left: 0px;
   padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}
.careerAnchorhover:hover, .careerAnchorhover:focus{
  text-decoration: none
}
.career_para-top2{
  font-size: 40px;
   /* font-weight: 100;*/
   color: #1378d4;
    padding-left: 22px;
    
}
.line_subhead2{
  padding-left: 22px;
  font-size: 20px;
  color: #808080;
  margin-bottom: 41px;
  margin-top: 28px;
}
.career2_img{
  width: 95%;
    margin-top: 206px;
    /*padding-left: 66px;*/
    height: 434px;
}

.spacing1{
  margin-top: 37px;
}
.position_title_1{
font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
}
.position_title_1:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_1 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_1 a:visited * { color: #1eb0e9 !important; }


.position_content{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .first-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing2{
  margin-top: 37px;
}
.position_title_2{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}

/*.position_title_2:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7;
  margin-left: 24px;
  padding-bottom: 16px;
}*/

.position_title_2:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_2 a{
  text-decoration: none;
  color: #1eb0e9 !important;


}
.position_title_2 a:visited * { color: #1eb0e9 !important; }



.position_content2{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department2 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location2 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button2 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .second-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing3{
  margin-top: 37px;
}
.position_title_3{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}

/*.position_title_3:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7; 
  margin-left: 24px;
  padding-bottom: 16px;
}*/
.position_title_3:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_3 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_3 a:visited * { color: #1eb0e9 !important; }



.position_content3{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department3 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location3 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button3 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .third-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing4{
  margin-top: 37px;
}
.position_title_4{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}
/*
.position_title_4:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7;
  margin-left: 24px;
  padding-bottom: 16px;
}
*/
.position_title_4:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_4 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_4 a:visited * { color: #1eb0e9 !important; }

.blockdefault {
    border-color: #77a0d2;
}
.position_content4{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department4 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location4 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button4 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .fourth-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .button_2 {
    background-color: #003bb3;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 18px;
    padding-right: 22px;
    padding-bottom: 18px;
    padding-left: 14px;
    width: 231px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
    margin-top: 86px;
    height: -24px;
    border-radius: 40px;
}


.button_2 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button_2 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 185px;
  transition: 0.5s;
}

.button_2:hover span {
  /*padding-right: 25px;*/

}

.button_2:hover span:after {
  opacity: 1;
  right: 0;
}
.lineee1{
  background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 55px;
    margin-left: 69px;
}

/******************/




/* */

.panel-default>.panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  -webkit-user-select: none;
          user-select: none;
}

.panel-default>.panel-heading a {
  display: block;
  padding: 10px 15px;
}

.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: -webkit-transform .25s linear;
  transition: transform .25s linear;
  transition: transform .25s linear, -webkit-transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.panel-default>.panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
  content: "\2212";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.panel-body{
  color: #000;
}

.full_head {
    display: block;
    border-bottom: 2px dotted #EEE;
  }

  .padding_zero {
    padding: 0 !important;
}

.bottom_pad {
    padding-bottom: 44px !important;
}border-radius: 23px;
.simple_list {
    list-style: disc;
}

.buttonn_1{
    width: 161px;
    height: 40px;
 /*   background-color: #0375ef;*/
    color: white;
    border-radius: 23px;
    padding:9px;
}

.lineee2 {
    background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 55px;
    margin-left: 730px;
}

/*.lightbluebg {
    background-color: #009de2;
}
*/
.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
/*    padding-top: 282px;
*/ padding-left: 0px;
   padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}
.iogimgblock1{
    width: 96%;
    /* padding-top: 143px; */
    /*padding-left: 69px;*/
    /*margin-left: 52px;*/
    height: 389px;
}

.iogblock1text{
    padding-top: 130px; 
    font-weight: normal;
    font-style: normal;
    text-align: center;
    /* font-size: 115px; */
    line-height: 1;
    margin-bottom: 4px;
    color: #000000bd;
    /* padding-bottom: 136px; */
    font-size: 15px;
    padding-left: 25px;
    /* margin-top: 144px; */
    /* padding-bottom: 150px; */
    height: 399px;
}

.iogblock1text span{
  font-size: 18px;
  font-weight: bold;
}

.careerBox{
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
}

.iogblock2text span{
  font-size: 18px;
  font-weight: bold;
}
.alignment_rw{
    margin-top: -95px;
}




.iogblock3text span{
  font-size: 18px;
  font-weight: bold;
}
.alignment_rw{
    /*margin-top: -95px;*/
}
.c-block{
    margin-top: 194px;
    margin-left: 48px;
}
.c-block-content-box.stat.half-width {
    display: inline-block;
    float: left;
    width: 50%;
    padding-right:50px;
    padding-left:60px;
    float: right !important;
}
.c-block-content-box {
    color: #2570af;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.stat-number {
    font-weight: normal;
    font-style: normal;
    /* font-size: 115px; */
    line-height: 1;
    margin-bottom: 4px;
    /*color: #2570af;*/
}
.stat-desc {
 /*   font-weight: normal;
    font-style: normal;
    font-size: 0.75em;
    text-transform: uppercase;*/
    letter-spacing: 0.5px;
    padding-top: 33px;
    }

.iogimgblock4{
    width: 95%;
    padding-top: 95px;
    padding-left: 69px;
    margin-left: -48px;
}

.iogblock4text{
    padding-top: 35px;
    font-size: 15px;
    /*padding-left: 39px;*/
    margin-left:7px;
    width: 100%;
    padding-bottom: 59px;
}

.iogblock4text span{
  font-size: 18px;
  font-weight: bold;
}
.dist_top{
  padding-top: 13px;
}

.video_block{
    background-image: url("/images/video1.png");
    height: 443px;
    margin-left: 14px;
    margin-top: -20px;
}
.head_firstblock{

}
.firstblockk{
   padding-top: 28px;
   letter-spacing: 1px;
    line-height: 20px;
}
.secondblockk{
    padding-top: 16px;
 }

.thirdblockk{
    margin-top: 17px;
}

.content_sizee{
    font-size: 18px;
}

.bckgblck_color_yellow{
    background-color: #ffbd41;
}

.bckgblck_color_blue{
    background-color: #1378d4;
    color: #fff;
}

.iogimg_4{
    width: 88%;
    height: 315px;
    /*margin-left: 28px;*/
    /* margin-top: -20px; */
    padding-right: 5px;
    /* margin-top: -20px; */
}

.fourthblockk{
   padding-top: 17px;
}

.center_block_text{

}

.blckk_imgg{
    margin-left: 23px;
    margin-top: 81px;
    width: 79%;
}

.ht_row2{
        height: 290px;
}

.ht_row1{
      height: 393px;  
}
.ht_row3{
        
}
.ht_row4{
        
}

.ht_top{
    margin-top: 82px;
}

.mb_100{
    margin-bottom: 100px;
}








.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}


.servicebg1{
	background-image: url("/images/serviceback1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}
.servicebg2{
	background-image: url("/images/serviceback2.png");
	background-size: 100% 70%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}








.industrybg1{
	background-image: url("/images/serviceback1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}
.industrybg2{
	background-image: url("/images/serviceback2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}

.servicekbtn:focus{
  outline: 0px auto -webkit-focus-ring-color!important;
}
.mt100{
	margin-top: 40px;
}

.mt70{
	margin-top: 70px;
}



.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -10px;
  color: white;
  font-size: 25px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.videocard {
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  height: 200px;
  text-decoration: none;
  z-index: 0;

  overflow: hidden;
  color: #000;
}
.videocard h3{

height: 100px;  
}
.videosDiv{
  margin-bottom: 50px;
}
.videocard:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -32px;
    right: 35px;
    background: #00838d;
    height: 32px;
    width: 36px;
    border-radius: 32px;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }

  .videocard:hover:before {
    -webkit-transform: scale(21);
            transform: scale(21);
  }

.videocard:hover p{
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);  
}

.videocard:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
.videocard:hover{
	text-decoration: none;
  cursor: pointer;
}
.videocard:focus{
	text-decoration: none;
}
.BT1_img1{
    height: 200px;
    width: 100%;
    /*margin-top: 45px;*/

  }
  .reactplayerDiv{
     width:324px ! important;
     height:211px ! important;
  }

.videocard1{
  display: block;
  position: relative;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 15px 12px;
  margin: 12px;
  height: 242px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.BT1_bannerTitle_res{

    position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 27px;*/
    top: 130px;
    letter-spacing: 2px;
  }

.videocard1:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #00838d;
    height: 32px;
    width: 50px;
    border-radius: 32px;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  }

  .videocard1:hover:before {
    -webkit-transform: scale(21);
            transform: scale(21);
  }

.videocard1:hover p{
    transition: all 0.3s ease-out;
    color: rgba(255, 255, 255, 0.8);  
}

.videocard1:hover h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
.videocard1:hover{
  text-decoration: none;
}
.videocard1:focus{
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .videocard1{
    width: 275px;
    margin: 0px;
  }
  .videocard{
    /*width: 230px;*/
    margin: 0px;
  }
  .Mobile_leftPadd{
    padding-left: 0px ! important;
  }
  .mobileright_padd{
    padding-right: 0px ! important;
  }
  
}
@media screen and (min-width: 1900px) {
  .reactplayerDiv{
    width: 510px ! important;
  }

}
@media screen and (min-width: 1680px) {
  .reactplayerDiv{
    width: 433px ! important;
  }

}

@media only screen and (device-width : 393px) and (device-height : 851px) { 
       
    .resfooterend{
      height: 61px ! important;
    } 
    .contactcontent {
      margin-bottom: 35px;
      margin-left: 23px;
      margin-top: 26px;
   
    }
    .videocard{
      widows: 270px ! important;
    }


  } 
  @media only screen and (device-width : 320px) and (device-height : 568px) { 
  .videocard1 {
    width: 260px ! important;
  }
 } 
  @media only screen and (device-width : 411px) and (device-height : 731px) { 
  .videocard1 {
    width: 350px ! important;
  }
 } 
  @media only screen and (device-width : 360px) and (device-height : 640px) { 
  .videocard1 {
    width: 295px ! important;
  }
 } 
  @media only screen and (device-width : 600px) and (device-height : 960px) { 
  .videocard1 {
    width: 355px ! important;
    margin-left: 95px;
  }
 } 
 @media only screen and (device-width : 1792px) { 
  .reactplayerDiv {
    width: 463px ! important;
  }
 } 
 @media only screen and (device-width : 1856px) { 
  .reactplayerDiv {
    width: 430px ! important;
  }
 } 
 @media only screen and (device-width : 1600px) { 
  .reactplayerDiv {
    width: 405px ! important;
  }
 } 
 @media only screen and (device-width : 2880px) { 
  .reactplayerDiv {
    width: 828px ! important;
  }
 }
 @media only screen and (device-width : 2560px) { 
   .reactplayerDiv {
      width: 722px ! important;
  } 
 } 


.B2T1_line1{
  /*margin-left: 491px ! important;*/
  background-color: #f08f00;
  width: 80px;
  height: 4px;
  top: 36px;
  /*z-index: 999;*/
}
@media only screen and (max-width: 768px) {
	.newline2{
		margin-left: 310px ! important;
	}
}
.ContactmodalContent_brochure{
	margin-top: 18%;
    background: #fff;
    border-radius: 04px;
    border: none;
    height: 430px;
    z-index: 15000;

}
.contactHeader_brochure {
    color: #fff;
    background-color: #009de2;
    font-size: 25px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 3px;
    background-color: ;
}
.brochureformBtn{
    font-weight: 600;
    padding: 10px 20px;
    margin-top: 0px;
    color: #fff;
}
.formcontent_new{
	height: 70px;
}
 @media (max-width: 480px){
  .newline2{
    margin-left: 97px ! important;
  }
}
.contactHeader{
  color: #fff;
  font-size: 25px;   
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: 
}
.contactformBtn{
  font-weight: 600;
  /*padding: 10px 20px;*/
  /*margin-top: 15px;*/
  color: #fff
}
.cuformWall{
	height: 500px;
  margin-top: -67px;
  padding: 0px;
	/*background-color: #aaa;*/
}
.cuformWall h1, .cuformWall h3, .cuformWall p{
    color: #555;
}
.cuformWall p{
  font-size: 14px;
  font-weight: 700
}
.bt30{
		margin-bottom:  10px;
	}
.sbtn{
    /*background: -webkit-linear-gradient(left,#ffc600 0%,#ff4200 50%,#ffc600 100%);*/
    color: #FFF!important;
}
.sbtn:focus{    
    border: none !important;
    box-shadow: none !important;
}
.ContactmodalContent{
  /*background-image: linear-gradient(to bottom right,#f19000d9,rgba(241, 144, 0, 0.45)); */
  margin-top: 6%;
  background: #fff;
  border-radius: 04px;
  border :none;
  height: 480px;
  z-index: 15000;
}
.padd10{
  padding-top: 10px;
}
.panel-title>.small, .panel-title>.small>a, .panel-title>a, .panel-title>small, .panel-title>small>a {
    color: #f57507;
    font-weight: 550;  
}


.inputtextContent{
  border: 1px solid #ccc;    
}
.closeBtn{
  font-size: 35px;
  padding-right: 15px !important;
  color: #000;
}

.cancelBtn{
  background-color: #999;
}
.cancelBtn:hover{
  background-color: #666;
}
.center_block{
	padding-left: 78px;
}

.blckk-ht{
	height: 450px;
    margin-bottom: 10px;
}
.blckk-ht1{
    height: 680px;
    margin-bottom: 10px;
}

.resource_blck{
    border-radius: 4px;
    padding-top: 60px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    margin-top: 30px;
    text-align: center;
    padding: 8px;
}

.resource_txt{
    margin-top: 40px;
    font-size: 20px;
}

.resource_para{
	padding-top: 10px;
    font-size: 18px;
}
.resource_privacy{
  margin-left: 60px;
}
@media only screen and (device-width : 414px) and (device-height : 736px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 401px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 400px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 401px) { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 400px)  { 
  .resource_blck{
    padding: 30px ! important;
    border: 1px solid #9a9696;
    margin-left: 14px;
  }

}
@media only screen and (device-width : 375px) and (device-height : 667px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  .videocard1{}

}
@media only screen and (device-width : 320px) and (device-height : 568px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 411px) and (device-height : 823px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 411px) and (device-height : 731px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 360px) and (device-height : 640px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 600px) and (device-height : 960px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 412px) and (device-height : 732px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 384px) and (device-height : 640px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 400px) and (device-height : 700px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 393px) and (device-height : 851px) { 
  .resource_blck{
    padding: 30px ! important;
     border: 1px solid #9a9696;
    margin-left: 14px;
  }
  

}
@media only screen and (device-width : 768px) and (device-height : 1024px) { 
  .resource_blck{
   height: 400px;
  }
  

}
@media only screen and (device-width : 1024px) and (device-height : 768px) { 
  .resource_privacy{
    margin-left: 40px;
  }
}
.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}


.resourcebg1{
	/*background-image: url("/images/serviceback1.png");*/
	background-size: 100% 100%;
	background-repeat: no-repeat;
	/*width: 100%;*/
}

.challenges_line_paraaaa{
    line-height: 30px;
    font-size: 19px;
    color: #7b6f6f;
}
.challenges_line_paraaaa1{
    line-height: 30px;
    font-size: 19px;
    color: #7b6f6f;
}
.challenges_line_subheadd{
    /*font-weight: 600;*/
    font-size: 25px;
    color: #3a3737;
    font-weight: bold;
  }

   .challenges_list{
  	font-size: 19px;
    color: #7b6f6f;
    background-repeat: no-repeat;
  }

  .challenges_line_paraaaa2{
    line-height: 30px;
    font-size: 19px;
    /*color: #7b6f6f;*/
    /*margin-left: 15px;*/
}
.Policy_Challenges{
  padding: 30px;
}
@media only screen and (device-width : 393px) and (device-height : 851px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 360px) and (device-height : 640px) {
   .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }
}
@media only screen and (device-width : 411px) and (device-height : 731px) {
   .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }
}
@media only screen and (device-width : 411px) and (device-height : 823px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 320px) and (device-height : 568px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 375px) and (device-height : 667px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 414px) and (device-height : 736px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 375px) and (device-height : 812px) {
  .Policy_Challenges{
    padding: 0px;
  }
  .Policy_Challenges ul{
    padding-left: 23px ! important;
  }

}
@media only screen and (device-width : 384px) and (device-height : 640px) {

 .Policy_Challenges {
    padding: 0px;
}
  .contactcontent{
    padding-left: 20px;
    padding-top: 20px;
  }
}




.customPanel{
  /*border: 1px solid;*/
  height: 380px !important;
  padding: 0px;
  border-radius: 0px !important;
  margin-top: 15px;
  box-shadow: 0px 11px 15px 1px #ccc;
  /*box-shadow: 0 10px 29px rgba(92,141,255,.22);*/

}
.blogsText{
  padding: 0px 15px;
  text-decoration: none !important;

}
.All1blog1 a:hover{
  text-decoration: none !important;
  
}
.customDefaultPanel>.panel-heading {
  color: #333;
  background-color: #fff;
  padding: 0;
  -webkit-user-select: none;
          user-select: none;
  border: none !important;
}
.customDefaultPanel{
    border: none !important;
    border-bottom: none !important;

}
.customDefaultPanel>.panel-heading a {
  display: block;
  /*padding: 10px 15px;*/
}

.customDefaultPanel>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: -webkit-transform .25s linear;
  transition: transform .25s linear;
  transition: transform .25s linear, -webkit-transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.customDefaultPanel>.panel-heading a[aria-expanded="true"] {
  background-color: #fff;
  border-radius: 5px !important;

  color: #fff;
}
.customDefaultPanel>.panel-heading a[aria-expanded="true"] .blogImgContainer{
  display:  none !important;
}

.customDefaultPanel>.panel-heading a[aria-expanded="true"]:after {
    content: "\2212";
    margin-right: 50px;
    padding: 35px;
    margin-top: 95px;
    /*border-radius: 5px;*/
    background-color: #0076d8;
    color:#fff;

    -webkit-transform: rotate(180deg);

            transform: rotate(180deg);
}

.customDefaultPanel>.panel-heading a[aria-expanded="false"]:after {
    content: "\002b";
    margin-right: 50px;
    margin-top: -95px;
    padding: 35px;
    box-shadow: 5px -7px 4px -1px #cde2f3;

    /*border-radius: 5px;*/
    background-color: #0076d8;
    color:#fff;  -webkit-transform: rotate(90deg);  transform: rotate(90deg);
}
.panel-group .panel+.panel{
margin-top: 24px !important;
}
.panelBody{
  color: #000;
  background-color: #fff !important;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
}
/*PL*/
.mt80{
  margin-top:70px !important;
}

.customDefaultPanel a{
  text-decoration: none !important;
}
.blogImgContainer{
  height: 180px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /*margin-top: 15px;*/
  margin-bottom: 15px;
  padding: 0px;
}

.blogImgContainer {
  position: relative;
  background-color: #000; 

}
.blogImgContainer img{
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.blogImgContainer:hover img {
  opacity: 0.3;
}

.blogImgContainerInner{
  height: 550px;
  /*border-radius: 10px;*/
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0px;

}/*
.blogImgContainer img:hover{
  transform: scale(0.8, 0.8);
  overflow-wrap: hidden;
  transition-duration: 0.20s;
}*/

.blogImgContainerInner img{
  height: 550px;
  width: 100%;
    /*border-radius: 5px;*/

}
.blogTitle{
  font-weight: normal;  
  font-size: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  color: #303133 !important;
  /*color: #0076d8 !important;*/
  margin-top: 5px;
  text-decoration: none;

}
.blogTitle:hover{
  color: #f19000 !important;
  transition-duration: 0.7s;
  cursor: pointer;
  text-decoration: none;

}
.likeDiv{
  color:#404444;
  font-size: 16px;
  margin-top: 5px;
  font-weight: normal;
}
.blogDate{
  color:#303133;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;

}
.panel-group .panel-heading+.panel-collapse>.list-group, .panel-group .panel-heading+.panel-collapse>.panel-body{
  border: none !important;
}
.panel-collapse  +.panel-heading>.blogImgContainerInner{
  display:none;
}
.ht1100{
  height: 1100px;
}
.blankBox{
  height: 50px;
}
.blogContent{
  font-size: 15px;
  color: #777;
  font-weight: normal;
  text-align: justify;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogImageContainer{
  overflow: hidden;
}
.blogImageContainer:hover img{
  cursor: pointer;
  transition-duration: 0.2s;
  overflow: hidden;
  -webkit-animation: scale 5s ;
          animation: scale 5s ;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes blogScale {
  from {-webkit-transform: scale(0.9);transform: scale(0.9)}
  to {-webkit-transform: scale(0.8);transform: scale(0.8)}
}

@keyframes blogScale {
  from {-webkit-transform: scale(0.9);transform: scale(0.9)}
  to {-webkit-transform: scale(0.8);transform: scale(0.8)}
}

.AllBlogsBox{
  /*background-color: #f6f6f6;*/
  /*border: 1px solid #eaeaea;*/
  padding: 20px;
}
/*.mtop75{
  margin-top: 75px; 

}*/
.blogTitle{
  margin: 5px 0px;
}
.AllblogImgB{
  height: 173px!important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
}
.Allblog{
  /*background-color: #888;*/
  
}
.All1blog1{
  min-height: 280px;
  /*height: 375px;*/
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 0px;
  box-shadow: 0px 11px 15px 1px #ccc;
}
.p10{
  padding-left:  25px;
  padding-right: 10px;
}
.imgRoundrb{
  border-radius: 100%;
  height: 50px !important;
  width: 50px !important;
  position: relative;
  padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
  margin-bottom: 0px!important;
}

.mtop10{
  margin-top: 10px;
}

.mtop20{
  margin-top: 20px;
}
.graycolor{
  color:#777;
}

.z50{
  position: relative;
  top: -75px;
  width:270px;
}
.blogContentDiv p, .blogContentDiv li{
	/*font-family: 'Open Sans', sans-serif;*/
	font-size: 16px !important;
	line-height: 25px;
	color:#303133;
}
.imgContainerInner{
	height: auto;
}
.blogcontenttext{
    height: 460px;
    border-radius: 8px;
    margin-top: 50px;
}
.blogSubImg:hover{
	/*transform: scale(0.8, 0.8);
    overflow-wrap: hidden;
    transition-duration: 0.20s;*/
}
.blogSubImg{
    height: 270px;
    border-radius: 5px;
    width: 100%;
}
.pdfHeight{
  height: 800px;
}
.blogBox table{
  width: 200px ! important;
}
#pdfWrap{
  /*background-color:#f5f5f5;
  width: 210mm;
  min-height: 297mm;
  margin-left: auto;
  margin-right : auto;*/
}
.blogImgContainerInner{
  height: 450px;
  width: 100%;
  /*border-radius: 10px;*/
  margin-top: 70px;
  margin-bottom: 15px;
  padding: 0px;
  background-color: #121212; 

} 
.blogBox  img {
  width: 300px;
  height: auto;

}
.blogImgContainerInner:hover img{
  /*transform: scale(1);*/
}

.blogImgContainerInner img {
	position: relative;
	opacity: 0.3;
  /*transform: scale(2);*/
	/*opacity: 1;*/
    height: 450px;
    width: 100%;
}
.middle {
  transition: .5s ease;
  /*opacity: 0;*/
  /*position: absolute;*/
  margin-left: 15px;
}


.fbBtn{
  border: none;
  background-color: transparent;
}
.blogImgContainerInner:hover .blogimgtext {
  /*opacity: 1;*/
}

.middle .blogfb .fb:hover, .middle .blogtwit a:hover, .middle .blogLin a:hover{
  color: #009de2;
}

.middle .blogfb .fb, .middle .blogtwit a, .middle .blogLin a{
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  top: 410px;

}
.blogtwit{
}
.middle .blogtwit {
  position: absolute;
   left: 90%; 
  /*top: 10px;*/
  padding: 12px 0px 0px 0px;
  /*right: 60px;*/
  top: 400px;

}
.middle .blogfb .fb{
  position: absolute;
   /*left: 42%; */
  /*top: 5px;*/
  padding: 6px 0px 0px 0px;
  right: 35px;
}
.middle .blogLin a{
  position: absolute;
  padding: 10px 0px 0px 0px;
   /*left: 42%; */
  /*top: 8px;*/
  right: 0px;
}


.blogimgtext{
	color: #fff;
	font-weight: bold;
	font-size: 35px;
	padding: 16px 32px;
	position: absolute;
	 /*left: 42%; */
	top: 200px;
	text-align: center;
}
.blogdatetext{
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 16px 32px;
  position: absolute;
   /*left: 42%; */
  top: 260px;
  text-align: center;
}
.header-scrolldown i :hover{
	top: 490px;
  transition: opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
}
.header-scrolldown i {
	color: #fff;
	font-size : 30px;
}
.header-scrolldown {
  position: absolute;
  left: 50%;
  z-index: 100;
  top: 410px;
  transition: opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
}
.header-scrolldown:hover {
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  opacity: .8 !important;
  transition: opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s !important;
  transition: transform 0.2s ease-in-out, opacity 0.2s linear 0.2s, -webkit-transform 0.2s ease-in-out !important;
}

.commentBox{
  height: 125px !important;
  margin-bottom: 10px;
}
.commentBoxText{
  height: 90px !important;
}
.commentBoxbtn {
  font-weight: 600;
  padding: 10px 20px;
  color: #fff;
  margin-top: 10px;
}

.miniDiv1Filter img {
  /*transform: scale(1);*/
  cursor: pointer;
  transition-duration: 0.5s;
   -webkit-animation: scale 5s ;
           animation: scale 5s ;
   -webkit-animation-iteration-count: 2;
           animation-iteration-count: 2;
   -webkit-animation-timing-function: linear;
           animation-timing-function: linear;
}
.miniDiv1Filter{
  overflow: hidden;
  margin-bottom: 20px;
}
.miniDiv1Filter:hover img{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  cursor: pointer;
  transition-duration: 0.5s;
}

@-webkit-keyframes scale {
  from {-webkit-transform: scale(1.5);transform: scale(1.5)}
  to {-webkit-transform: scale(1);transform: scale(1)}
}

@keyframes scale {
  from {-webkit-transform: scale(1.5);transform: scale(1.5)}
  to {-webkit-transform: scale(1);transform: scale(1)}
}


.userImgB{
  height: 80px;
}
.commentBox{
  height: 125px !important;
  margin-bottom: 10px;
  text-align: left;
}
.commentBoxText{
  height: 90px !important;
}
.commentBoxbtn {
  font-weight: 600;
  padding: 10px 20px;
  color: #fff;
  margin-top: 10px;
}

.blogPage-wrap{
	margin-top: 100px;
    margin-bottom: 100px;
    color: #fff;
    background-size: 100% 100%;
    height: auto;
    background-color: #fff;
}
.blankblock{
	height: 100px;
}
.latestblog1{
	margin-bottom: 0px ! important;
}

.overhidden{
	overflow: hidden;
	/*height: 2424px;*/
}


.scrollwrap {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 250px;
  left: 650px;
  z-index: 1000;
}

.scrollanim{
  top: 670px;
  opacity: 0;
  transition-duration: 3s;
}
.journytextblock{
  padding-top: 15px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
          transform: scale3d(0.5, 0.5, 0.5);
  -webkit-animation: move 3s ease-out infinite;
          animation: move 3s ease-out infinite;
}


.chevron:first-child {
  -webkit-animation: move 3s ease-out 1s infinite;
          animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  -webkit-animation: move 3s ease-out 2s infinite;
          animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #009de2;
}

.chevron:before {
  left: 0;
  -webkit-transform: skew(0deg, 30deg);
          transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  -webkit-transform: skew(0deg, -30deg);
          transform: skew(0deg, -30deg);
}

@-webkit-keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  67% {
    opacity: 1;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

@keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  67% {
    opacity: 1;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
            transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #009de2;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  -webkit-animation: pulse 2s linear alternate infinite;
          animation: pulse 2s linear alternate infinite;
}

@-webkit-keyframes pulse {
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}

.journybg {
    background-image: url(/images/journy1.png);
    background-size: 65% 100%;
    background-repeat: no-repeat;
    height: 400px;
    margin-top: 150px;
}
.journybg1{
    background-image: url(/images/journy2.png);
    background-size: 75% 90%;
    background-repeat: no-repeat;
    height: 150px;
}
.journybg2{
    background-image: url(/images/journy3.png);
    background-size: 30% 100%;
    background-repeat: no-repeat;
    height: 80px;
}
.journybg3{
    /*background-image: url(/images/journy7.png);*/
    background-size: 70% 100%;
    background-repeat: no-repeat;
    height: 267px;
    margin-top: 150px;
}
.journybg31{
    /*background-image: url(/images/journy7.png);*/
    background-size: 70% 100%;
    background-repeat: no-repeat;
    height: 267px;
}
.journybg4{
    /*background-image: url(/images/journy5.png);*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 700px;
}
.journybg5{
    /*background-image: url(/images/journy4.png);*/
    background-size: 17% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg6{
    /*background-image: url(/images/journy6.png);*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg7{
    background-image: url(/images/journy6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journybg8{
    background-image: url(/images/journy6.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 300px;
}
.journytextblock{
  width: 70%;
  height: 85px;
  margin-top: 110px;

}
.journytextblock h1{
font-size: 46px;
color: #fff;
}

.roadjourny{
  position: absolute;
  z-index: 3;
  height: 0px;
  background: transparent;
  overflow: hidden;
  max-height: 1590px;
  /*left: -95px;*/
  right: 0px;
}
.roadjournyoverflow{
  overflow: none!important;
  height: 1590px!important;
  transition-duration: 10s;

}
.roadjourny img{
  position: relative;
  overflow: hidden;
  left: 155px;
}

.add1{
  position: relative;
    left: 550px;
    z-index: 100;
    height: 75px;
    top: 248px;
    opacity: 0;
}

.add2{
    position: relative;
    right: 141px;
    z-index: 100;
    height: 75px;
    top: 414px;
    /*display: none;*/
    opacity: 0;
}
.add3{
    position: relative;
    left: 651px;
    z-index: 100;
    height: 75px;
    top: 270px;
    opacity: 0;
}
.add4{
  position: relative;
    left: 259px;
    z-index: 100;
    height: 75px;
    top: 178px;
    opacity: 0;
}
.add5{
  position: relative;
    right: 282px;
    z-index: 100;
    height: 75px;
    top: 719px;
    opacity: 0;
}
.add6{
  position: relative;
    left: 167px;
    z-index: 100;
    height: 75px;
    top: -121px;
    opacity: 0;
}
.add7{
    position: relative;
    left: 854px;
    z-index: 100;
    height: 75px;
    top: 227px;
    opacity: 0;
}
.add8{
  position: relative;
    right: -460px;
    z-index: 100;
    height: 75px;
    top: 72px;
    opacity: 0;
}
.add9{
  position: relative;
    left: 274px;
    z-index: 100;
    height: 75px;
    top: 219px;
    opacity: 0;
}
.add10{
    position: relative;
    left: 81px;
    z-index: 100;
    height: 75px;
    top: 3px;
    opacity: 0;
}
.add11{
  position: relative;
    left: 266px;
    z-index: 100;
    height: 75px;
    top: 619px;
    opacity: 0;
}
.add12{
  position: relative;
    left: 266px;
    z-index: 100;
    height: 75px;
    top: 819px;
    opacity: 0;
}

.addanim{
  opacity: 1;
  -webkit-animation:  addanim 1.5s linear  ;
          animation:  addanim 1.5s linear  ;

}

@-webkit-keyframes addanim {
  
  0% {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  }
  50% {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
  }
  
}

@keyframes addanim {
  
  0% {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  }
  50% {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
  }
  
}
.Journycaro1 p {
  text-align: right;

}
.Journycaro1 h3 {
  text-align: right;

}
.Journycaro2 p {
  text-align: right;

}
.Journycaro2 h3 {
  text-align: right;

}
.Journycaro3 p {
  text-align: right;

}
.Journycaro3 h3 {
  text-align: right;

}
.Journycaro7 p {
  text-align: right;

}
.Journycaro7 h3 {
  text-align: right;

}
.Journycaro9 p {
  text-align: right;

}
.Journycaro9 h3 {
  text-align: right;

}

.Journycaro4 p {
  text-align: left;

}
.Journycaro4 h3 {
  text-align: left;

}
.Journycaro5 p{
  text-align: left;
}
.Journycaro5 h3{
  text-align: left;
}
.Journycaro8 p{
  text-align: left;
}
.Journycaro8 h3{
  text-align: left;
}
.Journycaro1{
    z-index: 1000;
    position: absolute;
    top: 230px;
    left: 600px;
    opacity: 0;
}
.Journycaro2{
    z-index: 1000;
    position: absolute;
    top: 346px;
    left: 308px;
    opacity: 0;
}
.Journycaro3{
    z-index: 1000;
    position: absolute;
    top: 506px;
    left: 210px;
    opacity: 0;
}
.Journycaro4{
    z-index: 1000;
    position: absolute;
    top: 705px;
    left: 706px;
    opacity: 0;
}
.Journycaro5{
   z-index: 1000;
    position: absolute;
    top: 860px;
    left: 930px;
    opacity: 0;
}
.Journycaro6{
   z-index: 1000;
    position: absolute;
    top: 785px;
    left: 263px;
    opacity: 0;
}
.Journycaro7{
    z-index: 1000;
    position: absolute;
    top: 1054px;
    left: 486px;
    opacity: 0;
}
.Journycaro8{
    z-index: 1000;
    position: absolute;
    top: 1298px;
    left: 889px;
    opacity: 0;
}
.Journycaro9{
    z-index: 1000;
    position: absolute;
    top: 1191px;
    left: -30px;
    opacity: 0;
}
.Journycaro10{
    z-index: 1000;
    position: absolute;
    top: 1463px;
    left: 372px;
    opacity: 0;
}
.Journycaro11{
    z-index: 1000;
    position: absolute;
    top: 2000px;
    left: 372px;
    opacity: 0;
}
.Journycaro12{
    z-index: 1000;
    position: absolute;
    top: 1438px;
    left: 372px;
    opacity: 0;
}

.journyanim{
  opacity: 1;
  transition-duration: 0.5s;
}
.posrelative{
  position: relative;
  height: 750px;
  top: 250px;
}
#contactForm1{
 height: 500px;
 position: relative;
}
.newcommentbox1{
  height: 70px ! important;

}


/*previous toggle*/
react style.css
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

///////////////////////////////////////////////////////////////////////////////////

2nd css
a.nameParts{
  text-transform: capittialize;

}
.toUpper{
  text-transform: uppercase;
}
.display_map_sk{
  margin-top: 30px;
  padding-top: 30px;
  border-radius: 3px;
  border: 1px solid #e5e7e9;
  margin-left: 30px;
  box-shadow: 0 0 10px #ccc;
}

.heading_sk{
  font-size: 18px;
  padding-left: 20px;
}
.stars{
  padding-bottom: 50px;
}
.up_skill{
  padding-bottom: 20px;
}

.togle_position{
  /*padding-left: 450px;*/
}

.ht40{
  height: 33px;
}
.ht55{
  height:55px;
}
.h67{
  height:57px;
}
.ht90{
  height: 140px;
}
.skill_label{
  margin-top: -66px;
  margin-left: 7px;
}
.toggle_fix{
  padding-right: 40px;
  margin-top:-4px;
}
.skillRow{
  margin-top: 20px;
}
.rate_label{
  margin-left: 61px;
}

.ht105{
  height: 105px;
}

.description{
  border: 1px solid #979797 !important;
  margin-bottom: -12px;
  border-radius: 3px;
}
.skillsRow {
    margin-bottom: 30px;
}
.description:hover{
  border: 1px solid #33B5E5 !important;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,.15);
}

.description_label{
  /*margin-top: -70px;*/
}
.noMargin {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}
.skillForm{
  margin-top: 20px;
}
#size{
  width: 50px;
}
.skill_btn{
  margin-top: 50px;
}
.form_heading5{
  font-size: 20px;
  padding-left:15px;
  margin-top: 20px;
  }

.move_left{
  margin-left: 10px;
  height: 100px;
}
.switch2 {
  position: relative;
  height: 28px;
  width: 192px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.labelSwitch{
  margin-top: 10px;
}
.toggleSwitch{
  margin-left: px;

}
.mappp_bnt{
  margin-left: 140px;
}
.star{
  color: #ff0;
  font-size: 14px;
}
.descr_move{
  margin-left: 6px;
}
.toggelStop{
  margin-top: 0px!important;
  margin-left: 0px!important;
}
.switch {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label:active {
/*  font-weight: bold;
*/  background-color: transparent;
}

.switch-label-off {
  padding-left: 2px;
}

.switch-label-on {
  padding-right: 2px;
}


.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
 
  color: #e3e3e3;
 
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 75px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  transition: left 0.15s ease-out;
}
.switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
}
.switch1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
}
.switch-label1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1:active {
/*  font-weight: bold;
*/  background-color: transparent;
  color: #F7F7F7;
}

.switch-label-off1 {
  padding-left: 2px;
}

.switch-label-on1 {
  padding-right: 2px;
}


.switch-input1 {
  display: none;
}
.switch-input1:checked + .switch-label1 {
 
  color: #e3e3e3;

  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input1:checked + .switch-label-on1 ~ .switch-selection1 {
  left: 70px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  transition: left 0.15s ease-out;
}
.move_left{
  margin-left: 8px;

}

.showSkill{
  display: block;
}
.hideSkill{
  display: none;
}
.showCerti{
  display: block;
}
.hideCerti{
  display: none;
}


.switch-selection {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  transition: left 0.15s ease-out;
}
.switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
}


/* switch2*/

.switch1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
  /*-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);*/
  /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3);*/
}


.switch-label1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 92px;
  line-height: 26px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1:active {
/*  font-weight: bold;
*/  background-color: transparent;
  color: #F7F7F7;
}

.switch-label-off1 {
  padding-left: 2px;
}

.switch-label-on1 {
  padding-right: 2px;
}


.switch-input1 {
  display: none;
}
.switch-input1:checked + .switch-label1 {
 
  color: #e3e3e3;

  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input1:checked + .switch-label-on1 ~ .switch-selection1 {
  left: 100px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 92px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
  transition: left 0.15s ease-out;
}
.move_left{
  margin-left: 8px;

}
.display_map{
  border: 1px solid #060a0e42;
  margin-top: 79px;
  padding-top: 30px;
  width: 95%;
  margin-left: 21px;
 
}
.showSkill{
  display: block;
}
.hideSkill{
  display: none;
}
.showCerti{
  display: block;
}
.hideCerti{
  display: none;
}


.can-toggle {
  position: relative;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle__switch {
  margin-left: 0px !important;
}


.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    color: #ffffff !important;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 13px;
  top:0;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;
 
  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;

  text-align: center;
  background: white;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #969899;
}

.can-toggle label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0, 1, 0.9, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.9, 1);
  transition: -webkit-transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
          transform: translate3d(65px, 0, 0);
}


.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}
.can-toggle__switch{
  margin-left: 400px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}
.can-toggle label .can-toggle__switch:hover:after {
  /*box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:hover:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch {
  background-color: #b53e74;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-1 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  color: #8f315c;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  transition: background-color 0.9s ease-in-out;
  background: #c14b81;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  transition: -webkit-transform 0.9s ease-in-out;
  transition: transform 0.9s ease-in-out;
  transition: transform 0.9s ease-in-out, -webkit-transform 0.9s ease-in-out;
  transition: transform 0.9s ease-in-out, -webkit-transform 0.3s ease-in-out;
  color: #b53e74;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled] ~ label {
  color: rgba(68, 68, 68, 0.5);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #ffffff !important;
  width: 300px !important;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #2E3951;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  background: #666;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: #969899;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(.86, 0, 0.07, 1);
  transition: -webkit-transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1),
   -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #ffffff;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-2 input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(122px, 0, 0);
          transform: translate3d(122px, 0, 0);
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle.demo-rebrand-2 input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
  /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);*/
}
.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 37px;
  flex: 0 0 120px;
  border-radius: 60px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 60px;
  font-size: 13px;
  line-height: 28px;
  width: 60px;
  padding: 0 12px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 3px;
  left: 2px;
  border-radius: 30px;
  width: 58px;
  line-height: 25px;
  font-size: 13px;
}

.can-toggle {
  position: relative;
}
.can-toggle *, .can-toggle *:before, .can-toggle *:after {
  box-sizing: border-box;
}
.can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}
.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
  opacity: 0.4;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
  content: attr(data-unchecked);
  left: 13px;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  content: attr(data-checked);
}
.can-toggle label {
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}
.can-toggle label .can-toggle__label-text {
  flex: 1 1;
  padding-left: 32px;
}
.can-toggle label .can-toggle__switch {
  position: relative;
}
.can-toggle label .can-toggle__switch:before {
  content: attr(data-checked);
  position: absolute;
  top: 0;

  text-align: center;
}
.can-toggle label .can-toggle__switch:after {
  content: attr(data-unchecked);
  position: absolute;
  z-index: 5;
 
  text-align: center;
  background: #039BE5;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.can-toggle input[type="checkbox"][disabled] ~ label {
  color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #70c767;
}
.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #ffffff;
}


.can-toggle label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0, 1, 0.9, 1);
  background: #848484;
}
.can-toggle label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.5);
}
.can-toggle label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: -webkit-transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.9s cubic-bezier(0, 1, 0.9, 1);
  transition: transform 0.9s cubic-bezier(0, 1, 0.9, 1), -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #777;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(65px, 0, 0);
          transform: translate3d(65px, 0, 0);
}

.can-toggle label {
  font-size: 14px;
}
.can-toggle label .can-toggle__switch {
  height: 36px;
  flex: 0 0 134px;
  border-radius: 4px;
}
.can-toggle label .can-toggle__switch:before {
  left: 67px;
  font-size: 12px;
  line-height: 36px;
  width: 67px;
  padding: 0 12px;
}
.can-toggle label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 65px;
  line-height: 32px;
  font-size: 12px;
}



.can-toggle.can-toggle--size-large input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(78px, 0, 0);
          transform: translate3d(78px, 0, 0);
}

.can-toggle.can-toggle--size-large label {
  font-size: 14px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch {
  height: 50px;
  flex: 0 0 160px;
  border-radius: 4px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:before {
  left: 80px;
  font-size: 14px;
  line-height: 50px;
  width: 80px;
  padding: 0 12px;
}
.can-toggle.can-toggle--size-large label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 2px;
  width: 78px;
  line-height: 46px;
  font-size: 14px;
}

.can-toggle.demo-rebrand-1 input[type="checkbox"][disabled] ~ label {
  color: rgba(181, 62, 116, 0.5);
}


.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #44ae7f;
}
.can-toggle.demo-rebrand-1 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #368a65;
}

.can-toggle.demo-rebrand-1 label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch {
  transition: background-color 0.9s ease-in-out;
  background: #c14b81;
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:before {
  color: rgba(255, 255, 255, 0.6);
}
.can-toggle.demo-rebrand-1 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  color: #b53e74;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"][disabled] ~ label {
  color: rgba(68, 68, 68, 0.5);
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch {
  background-color: #75d32d;
}
.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  color: #666;
}

.can-toggle.demo-rebrand-2 label .can-toggle__label-text {
  flex: 1 1;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  transition: background-color 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  background: #ffffff;
  border: 1px solid #979797;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  color: #2E3951;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  transition: -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.9s cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 0.9s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  color: #ffffff !important;
}

.can-toggle.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
  -webkit-transform: translate3d(122px, 0, 0);
          transform: translate3d(122px, 0, 0);
}

.can-toggle.demo-rebrand-2 label {
  font-size: 13px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch {
  height: 30px;
  flex: 0 0 234px;
  font-weight: 800;
  border-radius: 60px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:before {
  left: 120px;
  font-size: 13px;
  line-height: 28px;
  width: 60px;
  padding: 0 12px;
}
.can-toggle.demo-rebrand-2 label .can-toggle__switch:after {
  top: 2px;
  left: 2px;
  border-radius: 30px;
  width: 106px !important;
  line-height: 25px;
  font-size: 13px;
}

.rating {
    float:left;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input,
.rating1:not(:checked) > input {
    position:absolute;
    top:-9999px;
    clip:rect(0,0,0,0);
}

.rating:not(:checked) > label, .rating1:not(:checked) > label {
    float:right;
    width:1em;
    padding:0 .1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:200%;
    line-height:1.2;
    color:#ddd;
    /*text-shadow:1px 1px #bbb, 2px 2px #666, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating:not(:checked) > label:before,.rating1:not(:checked) > label:before {
    content: '★ ';
}

.rating > input:checked ~ label, .rating1 > input:checked ~ label {
    color: #f90;
    /*text-shadow:1px 1px #c60, 2px 2px #940, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label{
  color: gold;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}


.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ea0;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}

.rating > label:active {
  position:relative;
  top:2px;
  left:2px;
}

/* end of Lea's code */

/*
 * Clearfix from html5 boilerplate
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ea0;
    /*text-shadow:1px 1px goldenrod, 2px 2px #B57340, .1em .1em .2em rgba(0,0,0,.5);*/
}


/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

/* my stuff */
#status, button {
    margin: px 0;
}


.genderbtn.demo-rebrand-2 label .can-toggle__switch{
  height: 30px;
    flex: 0 0 150px;
    border-radius: 60px;

}


/*basic csss*/

.imgProfile{

  width: 150px;
}
.form_heading{
  font-size: 16px;
  padding-left:30px !important;
  margin-bottom: 20px;
  font-weight: 100px;
}
.pd_btm_all{
  padding-bottom: 8px;
}
.Attach_data{
  height: 180px;
  background-color: #eee;
}
.basic_btn{
  margin-top: 100px;
}
.drag_data{
  font-size: 12px;
  padding-top: 70px;
  padding-left: 25px;
}

.back_input{
  background-color: #fff !important;
}
.move_label{
  margin-left: 50px;
}

.img_1st{
  margin-top: 5px;
}

.border_none{
  border:none;
}
.labelSwitch_k1{
  margin-top: 10px;
}
.toggleSwitch_k1{
  margin-left: px;

}
.toggelStop_k1{
  margin-top: 0px!important;
  margin-left: 0px!important;
}

.switch1_k1 {
  position: relative;
  height: 28px;
  width: 154px;
  margin: 20px auto;
  background: rgba(245, 236, 236, 0.25);
  border-radius: 23px;
  /*-webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);*/
  /*box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3);*/
}
 
.set_status{
  border: none;
  background-color: #fff;
}
.input_status{
  background-color: #fff;
}
.switch-label1_k1 {
  position: relative;
  z-index: 2;
  float: left;
  width: 75px;
  text-align: center;
  line-height: 26px;
  font-size: 11px;
  /*color: rgba(255, 255, 255, 0.35);*/
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}
.switch-label1_k1:active {
  font-weight: bold;
  background-color: transparent;
}

.switch-label-off1_k1 {
  padding-left: 2px;
}

.switch-label-on1_k1 {
  padding-right: 2px;
}


.switch-input1_k1 {
  display: none;
}
.switch-input1_k1:checked + .switch-label1_k1 {
 
  color: #e3e3e3;
 
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}

.switch-input1_k1:checked + .switch-label-on1_k1 ~ .switch-selection1_k1 {
  left: 75px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection1_k1 {
  position: absolute;
  z-index: 1;
  top: 3px;
  left: 2px;
  display: block;
  width: 75px;
  height: 22px;
  border-radius: 23px;
  background-color: #444;
 /* background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9dd993), color-stop(100%, #65bd63));
  background-image: -webkit-linear-gradient(top, #9dd993, #65bd63);
  background-image: -moz-linear-gradient(top, #9dd993, #65bd63);
  background-image: -ms-linear-gradient(top, #9dd993, #65bd63);
  background-image: -o-linear-gradient(top, #9dd993, #65bd63);
  background-image: linear-gradient(top, #9dd993, #65bd63);*/
  /*-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);*/
  /*box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);*/
  transition: left 0.15s ease-out;
}
 



 
/* switch2*/


.rating {
    float:left;
}
/*------------------*/

.genderbtn.demo-rebrand-2 label .can-toggle__switch:after{
   top:2px ! important;
   left: 4px ! important;
   width: 70px !important;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:before{
  left:66px;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:after{
  line-height: 24px;
}
.genderbtn.demo-rebrand-2 input[type="checkbox"]:checked ~ label .can-toggle__switch:after
{
  -webkit-transform: translate3d(74px, 0, 0) !important;
          transform: translate3d(74px, 0, 0) !important;
}
.genderbtn.demo-rebrand-2 label .can-toggle__switch:before {
  line-height: 28px;
}

.genderbtn.demo-rebrand-2 label .can-toggle__switch {
    height: 30px;
    flex: 0 0 153px ! important;
    border-radius: 60px;
}
.eventtogglediv{
  height: 800px;
  top:150px;
}


.new_connecting-line {
    height: 1px;
    background: #999;
    position: absolute;
    width: 100% ! important;
    margin: 0 auto;
    left: 0;
    right: 640px ! important;
    top: 57%;
    z-index: 1;
}
.new_tab{
    border: none ! important;  
}
.new_wizardNote
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 15px;
    color: #333;
    font-weight: 500;
 }
 .new_wizardNotefortwo
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 83px;
    color: #333;
 }
 .new_wizardNotefor4forJ
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 31px;
    right: 30px;
    color: #333;
    font-weight: 500;
 }
 .new_wizardNote_for6
 {
    /* border: 1px solid; */
    text-align: center;
    margin-right: 0px;
    position: relative;
    top: 35px;
    right: 36px;
    color: #333;
 }
 .wizard-inner{
    margin-top: 20px;
 }
 .new_wizard{
    height: 200px;
    color: #333;
    margin: 0px 0px;
    padding-top: 35px;
    background: #005b9929;

 }
 .new_wizard_length{
    height: 100px;
    color: #fff;
    margin: 45px 0px;
    padding-top: 35px;
    background: #004266;
    margin-left: 20px;

 }
 .forthreeCircles{
    margin-left: ''
 }

 .new_round-tab {
    width: 24px;
    margin-left: 29%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 2px solid #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
 .new_round-tabforTwo  {
    width: 24px;
    margin-left: 18%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
.new_round-tabfor4 {
    width: 24px;
    margin-left: 29%;
    height: 24px;
    line-height: 36px;
    display: inline-block;
    border-radius: 100px;
    background: #f1f1f1;
    border: 3px solid #fff;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    /*top:44px;*/
}
 @media screen and (device-width: 1152px){
  .new_round-tabfor4{
    margin-left: 219% ! important;
   }
   .new_wizardNotefor4{
    right: -271px ! important;
   }
   .navigation_tab {
    margin-left: 30px ! important;
  }
  .new_wizardNotefor4forJ{
    right: -267px ! important;
  }
}

.nopadding{
    padding: 0px;
}
.new_round-tab:hover{
    cursor: pointer;
}
.active1{
    /* background-color: #a8a; */
   background-color: orange;
   box-shadow: 0px 0px 8px 0px #fff;
   font-weight: 600;
}
.navigation_img{
    height: 20px;
    width: 100%;
}
.navigation_tab{
    width: 112px;
    margin-left: 32px;
}
.navigation_tab_for6{
    /*width: 165px;*/
    margin-top: 42px;
}
#new_wizardNote_new0{
    width: 147px;
    color: #ffffff;
    position: relative;
    top: 24px;
    right: 21px;

}
#new_wizardNote_new1{
    width: 147px;
    color: #ffffff;
    position: relative;
    bottom: 27px;
    right: 21px;

}
#new_wizardNote_new2{
    color: #ffffff;
    width: 216px;
    top: 24px;
    position: relative;
    right: 21px;
}
#new_wizardNote_new3{
    color: #ffffff;
    position: relative;
    bottom: 27px;
    width: 183px;
    right: 20px;
}
.navigationTitle{
    color: #333;
    /*position: absolute; */
    bottom: 4px;
    left: 254px;
    font-weight: 600;
    font-size: 23px;
    
}
.new_navigationTitle{
    color: #333;
    /*position: absolute; */
    bottom: 4px;
    left: 578px;
    font-weight: 600;
    font-size: 25px;
    bottom:14px;

}
.navigationTitle_for5{
    color: #333;
    font-weight: 600;
    font-size: 25px;
    bottom:14px;

}
/*.navigationTitle_for6{
    color: #ffffff;
    position: absolute; 
    top: -12px;
    left: 3px;
    font-weight: 600;
    font-size: 23px;
    bottom:14px;

}*/

.navigationTitle_for6
{   
    color: #333;
    font-size: 25px;
    font-weight: 600;
    left: 596px;
    top: 19px;
    position: absolute;
}
#NavposFor51{
    top:-32px ! important;

}
#NavposFor53{
    top:-32px ! important;

}
.lineFor5{
    margin-top: 26px;
}
.innerdivFor5{
    margin-top: 24px;
}
@media only screen and (max-width : 736px)
{
  .navigation_tab
  {
   width:112px;
  }
  .navigation_tab{
    margin-left: 20px ! important;

  }

  
}
@media only screen and (max-width : 768px)
{
    .navigation_tab{
    margin-left: 20px ! important;

  }
  
  .new_wizardNote{
    font-size: 11px;
  }
 /* .serflip-card{
    width: 275px ! important;
  }*/
 .para-top_website{
    margin-top: 20px ! important;
 }
 /*.B4T_ServicesDiv{
    margin-left: 73px ! important;
    min-height: 150px;
    width: 580px;
 }*/
 /*.B2T1_line{
    margin-left: 174px ! important;
 }*/
/* .B2T1_line1{
    margin-left:345px ! important;
 }*/
 .B2T_serviceTitle{
    margin-left: 190px;
 }
 .B2T_challengesTitle{
    margin-left: 90px;
 }
 .BT4_imgBack{
    margin-left: 66px;
 }
 .BT4_txt{
    margin-left: 43px;
 }
/* .new_round-tabfor4{
    margin-left:127% ! important;
 }*/
 /*.new_wizardNotefor4{
    left:109px;
 }*/
 .adjustfor5{
    margin-left: 17px;
 }
}

@media only screen and (max-width : 768px){
  .Journycaro1{
    left: 100px ! important;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 20px;
}
.Journycaro2{
   left: 100px ! important;
   top:400px ! important;
   box-shadow: 0 10px 29px rgba(92,141,255,.22);
     margin-bottom: 20px;

}
.Journycaro3{
  left: 100px ! important;
  top:572px ! important;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.Journycaro4{
 left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22); 
  top:742px ! important;
 margin-bottom: 20px;

}
.Journycaro5{
  left: 100px ! important;
  top:1086px ! important;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.Journycaro6{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;
  top:916px ! important;
}
.Journycaro7{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;
  top:1264px ! important;

}
.Journycaro8{
 left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1435px ! important;

}
.Journycaro9{
 left: 100px ! important;
box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1610px ! important;

}
.Journycaro10{
 left: 100px ! important;
box-shadow: 0 10px 29px rgba(92,141,255,.22);
 margin-bottom: 20px;
 top:1770px ! important;

}
.Journycaro11{
  left: 100px ! important;
 box-shadow: 0 10px 29px rgba(92,141,255,.22);
  margin-bottom: 20px;

}
.journytextblock{
  width: 90% ! important;
}
.new_wizard_journy{
  margin-top: 484px;
}
.add4 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add1 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add2 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
   .add3 {
    display: none;
    left: 167px ! important ;
    height: 40px! important;
    top: 180px ! important;
  }
   .add5 {
    display: none;
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
   .add6 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add7 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add8 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add9 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add10 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add11  {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .add12 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }


  .webclientbackimg{

    width: 550px ! important;
  }
  .webclientcaro{
    width: 519px ! important;
  }
.Journycaro1 p {
  text-align: center ! important;
}
.Journycaro1 h3 {
  text-align: center ! important;
}
.Journycaro3 p {
  text-align: center ! important;
}
.Journycaro3 h3 {
  text-align: center ! important;
}
.Journycaro4 p {
  text-align: center ! important;
}
.Journycaro4 h3 {
  text-align: center ! important;
}
.Journycaro5 p {
  text-align: center ! important;
}
.Journycaro5 h3 {
  text-align: center ! important;
}
.Journycaro6 p {
  text-align: center ! important;
}
.Journycaro6 h3 {
  text-align: center ! important;
}
.Journycaro7 p {
  text-align: center ! important;
}
.Journycaro7 h3 {
  text-align: center ! important;
}
.Journycaro8 p {
  text-align: center ! important;
}
.Journycaro8 h3 {
  text-align: center ! important;
}
.Journycaro9 p {
  text-align: center ! important;
}
.Journycaro9 h3 {
  text-align: center ! important;
}
.Journycaro10 p {
  text-align: center ! important;
}
.Journycaro10 h3 {
  text-align: center ! important;
}
.Journycaro11 p {
  text-align: center ! important;
}
.Journycaro11 h3 {
  text-align: center ! important;
}
.add4 
 {
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }

.Journycaro2 p {
  text-align: center ! important;
}
.Journycaro2 h3 {
  text-align: center ! important;
}
  /*.Journycaro1{
    left:40px ! important;
  }
  .add1{
    left:204px ! important;
  }
  .add2{
    top:363px ! important;
    right:141px ! important;
  }
  .Journycaro2{
    left:-28px ! important;
  }*/
}


@media only screen and (max-width : 600px){
.BT4_imgBack{
    margin-left: 102px;
}
.BT4_txt{
    text-align: center;
    margin-left: 0px;
}
.BT3_subhead{
    margin-left: -11px;
}
.Journycaro1{
  left:0px ! important;
  top:205px ! important;
  opacity: 1;
}
.Journycaro2{
  /*left:0px ! important;*/
  top:380px ! important;
   opacity: 1;
}
.Journycaro3{
  left:0px ! important;
  top:565px ! important;
   opacity: 1;
}
.Journycaro4{
  left:0px ! important;
  top:750px ! important;
   opacity: 1;
}
.Journycaro5{
  left:0px ! important;
  top:1111px ! important;
   opacity: 1;
}
.Journycaro6{
  left:0px ! important;
  top:930px ! important;
   opacity: 1;
}
.Journycaro7{
  left:0px ! important;
  top:1295px ! important;
   opacity: 1;
}
.Journycaro8{
  left:0px ! important;
  top:1480px ! important;
   opacity: 1;
}
.Journycaro9{
  left:0px ! important;
  top:1665px ! important;
   opacity: 1;
}
.Journycaro10{
  left:0px ! important;
  top:1855px ! important;
   opacity: 1;
}
.Journycaro11{
  left:0px ! important;
   opacity: 1;
}
.new_wizard_journy{
  margin-top: 1065px;
}
.chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
.add4 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add1 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
  .add2 {
    display: none;
    left: 45px ! important ;
    height: 40px! important;
    top: 57px ! important;
  }
   .add3 {
    display: none;
    left: 167px ! important ;
    height: 40px! important;
    top: 180px ! important;
  }
   .add5 {
    display: none;
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
   .add6 {
    display: none;
    left: 172px ! important ;
    height: 46px! important;
    top: 66px ! important;
  }
  .webclientbackimg{
    width: 340px ! important;
    height: 150px ! important;
    margin-left: 30px ! important;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
  }
  .webclientcaro{
    width: 250px ! important;
  }
.Journycaro1 p {
  text-align: center ! important;
}
.Journycaro1 h3 {
  text-align: center ! important;
}
.Journycaro3 p {
  text-align: center ! important;
}
.Journycaro3 h3 {
  text-align: center ! important;
}
.Journycaro4 p {
  text-align: center ! important;
}
.Journycaro4 h3 {
  text-align: center ! important;
}
.Journycaro5 p {
  text-align: center ! important;
}
.Journycaro5 h3 {
  text-align: center ! important;
}
.Journycaro6 p {
  text-align: center ! important;
}
.Journycaro6 h3 {
  text-align: center ! important;
}
.Journycaro7 p {
  text-align: center ! important;
}
.Journycaro7 h3 {
  text-align: center ! important;
}
.Journycaro8 p {
  text-align: center ! important;
}
.Journycaro8 h3 {
  text-align: center ! important;
}
.Journycaro9 p {
  text-align: center ! important;
}
.Journycaro9 h3 {
  text-align: center ! important;
}
.Journycaro10 p {
  text-align: center ! important;
}
.Journycaro10 h3 {
  text-align: center ! important;
}
.Journycaro11 p {
  text-align: center ! important;
}
.Journycaro11 h3 {
  text-align: center ! important;
}
.add4 
 {
    left: 45px ! important ;
    height: 46px! important;
    top: 57px ! important;
  }
.Journycaro2{
  left:0px ! important;
}
.Journycaro2 p {
  text-align: center ! important;
}
.Journycaro2 h3 {
  text-align: center ! important;
}
 .journytextblock{
        width: 100% ! important;
      }

}
@media (max-width: 480px){
.adjustfor5{
    margin-left: 10px ! important;
}
/*.new_round-tabfor4{
    margin-left:27% ! important;
}*/
/*.new_wizardNotefor4 {
    left: -6px ! important;
    font-size: 12px ! important;
}*/
}

@media screen and (min-width: 1400px) {
    .navigation_tab{
        margin-left: 48px ! important;
    }
}


@media screen and (min-width: 1600px) {
    .navigation_tab{
        margin-left: 48px ! important;
    }
   /* .Journycaro1{
      left:900px ! important;
    }
    .add2{
      right: -132px ! important;
    }
    .Journycaro3{
      right: 580px ! important;
      top:360px ! important;
    }
    .Journycaro4{
      left:999px ! important;
    }
    .Journycaro6{
    left:63px ! important;
  }
  .Journycaro5{
        left: 587px ! important;
        top: 834px ! important;
  }
  .Journycaro7{
    left:817px ! important;
  }
  .Journycaro9{
    left:231px ! important;
    top:1232px ! important;

  }
  .Journycaro8{
    left:945px ! important;
  }
  .Journycaro10{
    left:176px ! important;
  }
  .add2{
    right: -416px ! important;
    top:-252px ! important;
  }
  .add4 {
    left: 551px ! important;
    top: 192px ! important;
     }
    .add8{
      right: -756px ! important;
    }
    .add10{
      left: 408px ! important;
    }*/
}
@media screen and (min-width: 1900px) {
      .navigation_tab{
        margin-left: 48px ! important;
    }
      
}
@media (min-width:1025px){
  

}
@media (min-width:768px){
  .MobileDivHeight{
    /*height: 350px ! important;*/
  }

  .responsivefooter{
    height: 360px ! important;
  }
 /* .new_round-tabfor4{
    margin-left:127% ! important;
 }
 .new_wizardNotefor4{
  left:113px ! important;
 }*/
 .resmr20{
  color:#fff ! important;
 }

}
@media screen and(orientation: portrait) {
  .roadjourny img{
    left:535px ! important;
  }
  .Journycaro1{
    left:482px ! important;
  }
  .add2{
    right: -130px ! important;
  }
  .Journycaro2{
    left:394px ! important;
  }
  .add4{
    left:412px ! important;
  }
  .Journycaro4{
    left:615px ! important;
    top:723px ! important;
  }
  .add3{
    top:293px ! important;
    left:560px ! important;
  }
  .Journycaro6{
    top:819px ! important;
    left:330px ! important;
  }
  .add6{
    left:240px ! important;
    top:-58px ! important;
  }
  .Journycaro5{
    top:1002px ! important;
    left:575px ! important;
  }
  .Journycaro7{
    top:1132px ! important;
    left:515px ! important;
  }
  .Journycaro9{
    left:24px ! important;
  }
  .Journycaro8{
    top:1315px ! important;
    left:582px ! important;
  }
  .add5{
    right: 112px ! important;
  }
  .add8{
    right: -622px ! important;
  }
  .add7{
    left:904px ! important;
  }
  .add10{
    left:208px ! important;
  }
  .Journycaro10{
    left:424px ! important;
  }
  .add9{
    left:322px ! important;
  }
  .journytextblock{
    width: 75% ! important;
  }
 } 
 @media screen and (max-width: 812px){
 /* .new_round-tabfor4{
    margin-left: 143% ! important;
  }
  .new_wizardNotefor4{
    left:127px ! important;
  }*/
 }

 @media only screen  and (min-width: 1024px){
    .journytextblock{
      width: 76% ! important;
    }
  }

@media only screen and (device-width: 1024px){
   .roadjourny img{
    left:400px ! important;
   }
    .roadjourny{
    overflow:inherit;
   }  
   .Journycaro1{
    opacity: 1;
    left:483px ! important;
   }
   .add1{
    opacity: 1;
   }
   .add5{
    top:675px ! important;
    opacity: 1;
   }
   .Journycaro2{
    opacity: 1;
    left:256px ! important;
   }
   .add2{
    opacity: 1;
    right: 0px ! important;
   }
   .Journycaro4{
    opacity: 1;
    left:595px ! important;
    top:698px ! important;
   }
   .Journycaro6{
    opacity: 1;
    left:162px ! important;
   }
   .Journycaro5{
    opacity: 1;
    left:604px ! important;
    top:830px ! important;
   }
   .Journycaro7{
    opacity: 1;
    left:372px ! important;
   }
   .Journycaro8{
    opacity: 1;
    top: 1329px ! important;
    left: 582px ! important;
   }
   .add9{
    opacity: 1;
    left:185px ! important;
   }
   .add7{
    opacity: 1;
    left:596px ! important;
    top:241px ! important;
   }
   .add8{
    opacity: 1;
    right: -487px ! important;
   }
   .add6{
    opacity: 1;
    left:85px ! important;
   }
   .Journycaro10{
    opacity: 1;
    left:303px ! important;
   }
   .Journycaro9{
    opacity: 1;
    top: 1123px ! important;
   }
  } 
  @media only screen and (device-width: 1024px) {
   .roadjourny img{
    left:400px ! important;
   }
    .roadjourny{
    overflow:inherit;
   }  
   .Journycaro1{
    opacity: 1;
    left:483px ! important;
   }
   .add5{
    opacity: 1;
    top:717px ! important;
    opacity: 1;
   }
   .add{
    left:578px ! important;
   }
    
    .add10{
      opacity: 1;
      top: -18px ! important;
      left: 116px ! important;
    }
   .Journycaro2{
    opacity: 1;
    left:256px ! important;
   }
    .Journycaro3{
      opacity: 1;
    }
   .add2{
    opacity: 1;
    right: 0px ! important;
   }
   .Journycaro4{
    opacity: 1;
    left:595px ! important;
    top:698px ! important;
   }
   .Journycaro6{
    opacity: 1;
    left:162px ! important;
   }
   .Journycaro5{
    opacity: 1;
    left:604px ! important;
    top:830px ! important;
   }
   .Journycaro7{
    opacity: 1;
    left:372px ! important;
   }
   .Journycaro8{
    opacity: 1;
    top: 1329px ! important;
    left: 582px ! important;
   }
   .add9{
    opacity: 1;
    left:185px ! important;
   }
   .add7{
    opacity: 1 ! important;
    left:596px ! important;
    top:241px ! important;
   }
   .add8{
    opacity: 1;
    right: -487px ! important;
   }
   .add6{
    opacity: 1;
    left:85px ! important;
   }
   .Journycaro10{
    opacity: 1 ! important;
    left: 278px ! important;
   }
   .Journycaro9{
    opacity: 1;
    top: 1161px ! important;
   }
   .add2{
      opacity: 1;
   }
   .add3{
      opacity: 1;
      left:507px ! important;
   }
   .add4{
      opacity: 1;
   }
  } 
  @media only screen and (device-width: 851px)  {
.roadjourny img{
        display: none;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 73px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 73px ! important;
        top: 675px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 73px ! important;
         top:1230px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 73px ! important;
         top: 1420px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 73px ! important;
         top: 1790px ! important;
         text-align: center;
          opacity: 1;
    }

.Journycaro2{
     left: 73px ! important;
     top: 480px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 73px ! important;
      top: 855px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 73px ! important;
      top: 1040px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 73px ! important;
      top: 1605px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 73px ! important;
      top: 1980px ! important;
      opacity: 1;
    }
    .add11{
      display: none;
    }
    .add12{
      display: none;
    }
    .journybg2{
      display: none;
    }
    .new_wizard_journy{
      margin-top: 700px ! important;
    }
    .journytextblock{
      width: 100% ! important;
    }
    .new_round-tabfor4{
      margin-left: 127% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -110px! important;
    }
    
  } 
 @media only screen and (device-width: 800px)  {
.roadjourny img{
        display: none;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 73px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 73px ! important;
        top: 675px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 73px ! important;
         top:1230px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 73px ! important;
         top: 1420px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 73px ! important;
         top: 1790px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 73px ! important;
     top: 480px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 73px ! important;
      top: 855px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 73px ! important;
      top: 1040px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left: 73px ! important;
      top: 1605px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left: 73px ! important;
      top: 1980px ! important;
      opacity: 1;
    }
    .add11{
      display: none;
    }
    .add12{
      display: none;
    }
    .journybg2{
      display: none;
    }
    .new_wizard_journy{
      margin-top: 700px ! important;
    }
    .journytextblock{
      width: 100% ! important;
    }
    .new_round-tabfor4{
      margin-left: 127% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -110px! important;
    }
    
  } 
  @media screen and (min-width: 1900px) {
  .add10 {
    top: -22px ! important;
    left:785px ! important;
  }
}
@media only screen and (device-width : 1600px)  { 
    .Journycaro10{
       opacity: 1;
       left: 611px ! important;

      }
      .add9{
        opacity: 1;
        left:515px !important;
      }
      .roadjourny{
        overflow:inherit;
       }  
      .add10{
        opacity: 1;
        left:262px ! important;
      }
      .add8{
         opacity: 1;
        right: -624px ! important;
      }
      .Journycaro1{
         opacity: 1;
         left:820px ! important;
       }
       .Journycaro2{
         opacity: 1;
         left:583px ! important;
       }
       .Journycaro3{
         opacity: 1;
         left:534px ! important;
       }
       .Journycaro4{
         opacity: 1;
       }
      .Journycaro5{
         opacity: 1;
        left:1120px ! important;
      }
      .Journycaro6{
         opacity: 1;
        left:492px ! important;
      }
       .Journycaro7{
         opacity: 1;
        left:729px ! important;
      }
      .Journycaro9{
         opacity: 1;
        left:211px ! important;
      }
      .add6{
         opacity: 1;
         left:414px ! important;
      }
      .add7{
        opacity: 1;
      }
      .add5{
        opacity: 1;
      }
      .add4{
        opacity: 1;
        left:420px ! important;
      }
      .add3{
        opacity: 1;
        left:808px ! important;
      }
      .add2{
        opacity: 1;
        right: 51px ! important;
      }
      .add1{
        opacity: 1;
        left:740px ! important;
      }
      .Journycaro4{
         opacity: 1;
        left:877px ! important;
      }
      .iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 }

@media only screen and (device-width : 1440px)  { 
.Journycaro1{
    left:680px !important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add1{
    left:936px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add5{
    right: 344px ! important;
    top:807px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro2{
    left: 550px !important;
    opacity: 1;
    z-index: 0! important ;
  }
   .add2{
    right: -168px ! important;
    top: 252px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro3{
    left:365px !important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add3{
   top: 119px ! important;
    left: 961px !important;
   opacity: 1;
   z-index: 0! important ;
  }
  .Journycaro4{
    left:874px ! important;
    top: 683px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro5{
    top: 953px;
    left: 923px;
    opacity: 1;
    z-index: 0! important ;
  }
   .add4{
    left: 321px ! important;
    top: 202px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add6{
    left:251px ! important;
    opacity: 1;
    top:-59px ! important;
    z-index: 0! important ;
  }
   .Journycaro6{
    top: 840px;
    left: 375px;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro7{
    left: 572px ! important;
    top: 1146px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add7{
    left: 821px ! important;
    top: 331px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .Journycaro8{
   top: 1398px ! important;
   left: 829px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
   .Journycaro9{
    left: 75px ! important;
    top: 1272px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
   .Journycaro10{
    opacity: 1;
    top: 1530px;
    left: 440px;
    z-index: 0! important ;
   }
  .add9{
    left: 358px ! important;
    top:266px ! important;
    opacity: 1;
    z-index: 0! important ;
  }
  .add8{
    right:-515px ! important;
    opacity: 1;
    top:183px ! important;
    z-index: 0! important ;
  }
  .add10{
    opacity: 1;
    left: 128px;
    z-index: 100;
    height: 75px;
    top: 99px;
    z-index: 0! important ;
  }
  .roadjourny img{
    left: 150px ! important;
  }    
.iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 .roadjourny{
        overflow:inherit;
        z-index: 0! important ;
        top:92px ! important;
        } 
 .add11 {
    position: relative;
    left: 758px;
    z-index: 100;
    height: 75px;
    top: -847px;
    opacity: 1;
    }           
 }
 @media only screen and (device-width : 1400px) { 
    .Journycaro10{
       opacity: 1;
      left: 480px ! important;

      }
      .add9{
        opacity: 1;
        left:316px !important;
      }
      .roadjourny{
        overflow:inherit;
        z-index: 0! important ;

   }  
      .add10{
         opacity: 1;
        left:138px ! important;

      }
      .add8{
          opacity: 1;
          right: -493px ! important;
      }
      .Journycaro1{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro2{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro3{
         opacity: 1;
          z-index: 0! important ;
       }
       .Journycaro4{
         opacity: 1;

          z-index: 0! important ;
       }
      .Journycaro5{
         opacity: 1;
        left:996px ! important;
      }
      .Journycaro6{
        opacity: 1;
        left:368px ! important;
         z-index: 0! important ;
      }
      .add6{
         opacity: 1;
        left:254px ! important;
         z-index: 0! important ;
      }
      .add7{
        opacity: 1;
         z-index: 0! important ;
      }
      .add5{
        opacity: 1;
         z-index: 0! important ;
      }
      .add4{
        opacity: 1;
         z-index: 0! important ;
      }
      .add3{
        opacity: 1;
         z-index: 0! important ;
      }
      .add2{
        opacity: 1;
         z-index: 0! important ;
      }
      .add1{
        opacity: 1;
         z-index: 0! important ;
      }
      .Journycaro4{
         opacity: 1;
        left:692px ! important;
      }
      .Journycaro7{
        opacity: 1;
      }
      .Journycaro8{
        opacity: 1;
      }
      .Journycaro9{
        opacity: 1;
      }
      .iogimg_4{
        width: 866px ! important;
        margin-left: 0px ! important
      }
 }
  @media only screen and (min-device-width : 393px) and (device-height : 851px){
      .webclientbackimg{
        width: 310px ! important;
        margin-left: 49px ! important;
      }
      .journytextblock{
        width: 100% ! important;
      }
 }
@media only screen and (min-device-width : 1024px) and (device-height : 1366px){
      .Journycaro2{
        left:0px ! important;
      }
      .Journycaro1{
        left:287px ! important;
      }
 }
  @media only screen and (device-width : 1024px) and (device-height : 768px){
      .new_round-tabfor4 {
        margin-left: 216% ! important;
    }
    .new_wizardNotefor4forJ{
      right: -232px ! important;
    }
      
 }
 @media only screen and (device-width : 400px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 412px){
   .new_wizardNotefor4forJ{
        right: 9px ! important;
    }

 }
 @media only screen and (device-width : 375px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 360px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 393px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
  @media only screen and (device-width : 320px){
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }
 @media only screen and (device-width : 800px)and (device-height : 1280px){ 
  .new_round-tabfor4 {
    margin-left: 132% ! important;
  }
  .new_wizardNotefor4forJ{
    right: -109px ! important;
  }
 }

 @media only screen and (device-width : 375px)and (device-height : 812px){ 
   .webclientbackimg{
    width: 300px ! important;
     border: 1px solid #b5b2b2;
   }
   .new_wizard_journy{
    margin-top: 965px ! important;
   }
   .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 }  
  @media only screen and (device-width : 360px) and (device-height : 640px){ 
   .webclientbackimg{
    width: 300px ! important;
     border: 1px solid #b5b2b2;
   }
     .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

 } 

@media only screen and (device-width : 393px) and (device-height : 851px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .webclientcaro{
      width:284px ! important;

    }
     .webclientbackimg{
      /*width: 310px ! important;*/
      border: 1px solid #b5b2b2;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 411px) and (device-height : 731px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 411px) and (device-height : 823px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}
@media only screen and (device-width : 768px) and (device-height : 1024px) { 

    .scrollwrap{
    top: 169px;
    left: 370px;
    }
    .journyanim {
    opacity: 1;
    transition-duration: 0.5s;
    border: 1px solid #bdbcbc;
  }
  .new_wizardNotefor4forJ{
        right: -105px ! important;
    }
   
}

@media only screen and (device-width : 375px) and (device-height : 667px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .webclientbackimg{
      width: 310px ! important;
      border: 1px solid #b5b2b2;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }


}
@media only screen and (device-width : 375px) and (device-height : 812px) { 

    .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }
    .new_wizardNotefor4forJ{
        right: 4px ! important;
    }

}



 /* @media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 640px){
   .webclientbackimg{
    width: 285px ! important;
   }

 }  */
 @media only screen 
    and (min-device-width : 414px) 
    and (device-height :  736px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
    width: 310px ! important;
    }
     .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }

  } 
   @media only screen 
    and (min-device-width : 411px) 
    and (device-height :  823px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
    width: 310px ! important;
    }

 }  
  @media only screen 
    and (min-device-width : 411px) 
    and (device-height :  731px){
   .webclientbackimg{
      width: 339px ! important;
      margin-left: 40px ! important;
      border: 1px solid #b5b2b2;
   }
   .webclientcaro {
     width: 315px ! important; 
}

 }  
 @media only screen 
    and (min-device-width : 320px) 
   and (device-height : 568px){

       .chevron {
      position: absolute;
      width: 28px;
      height: 8px;
      top: 88px;
      left: 185px;
    }
    .scrollwrap{
      width: 24px;
    height: 24px;
    top: 250px;
    left: 650px;
    z-index: 1000;
    position: initial;
    }
    .text{
      margin-left: 152px ! important;
    }

   .webclientbackimg{
        width: 255px ! important;
        margin-left: 30px ! important;
         border: 1px solid #b5b2b2;
      }
      .webclientcaro{
        width: 213px ! important;
      }
      .journytextblock{
        width: 100% ! important;
      }
      .journytextblock{
        height: 130px ! important;
      }
      .responsivefooter{
        height: 640px ! important;
      }
 } 
 @media only screen and (device-width : 1024px) { 

   .add1{
    left:583px;
    opacity: 1;
   }
   .add2{
    left:6px;
   }
   .roadjourny img{
    left:385px;
   }
   .Journycaro1 {
    left: 521px ! important;
    }
    .Journycaro2 {
    left: 260px ! important;
  }
  .add6{
    left:72px;
  }
  .Journycaro4{
    left:628px;
  }
  .Journycaro5{
    left:628px;
  }
  .Journycaro7{
    left:377px;
  }
  .add7 {
    position: relative;
    left: 811px;
    top: 83px;
    opacity: 0;
  }
  .Journycaro8 {
    z-index: 1000;
    position: absolute;
    top: 1323px;
    left: 566px;
    /* opacity: 0; */
}

.iogimg_4{
  margin-left: 0px ! important;
}


} 

 @media only screen and (device-width : 384px) and (device-height : 640px) { 
      .webclientcaro {
        width: 300px ! important;
    }
    .webclientbackimg{
      margin-left: 20px;
    }
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }

@media only screen and (device-width : 412px) and (device-height : 732px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 600px) and (device-height : 960px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
  @media only screen and (device-width : 320px) and (device-height : 533px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 480px) and (device-height : 854px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 320px) and (device-height : 568px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }
 @media only screen and (device-width : 414px) and (device-height : 736px) { 
    
    .new_wizardNotefor4forJ{
      right: 4px ! important;
    }
 }

 @media only screen and (device-width : 401px) and (device-height : 700px) { 
      .webclientcaro {
        width: 300px ! important;
    }
      .topnav a.icon {
      top: -5px;
      right: -13px;
    }
   
 }
   @media only screen and (device-width : 600px) and (device-height : 960px) { 
    .new_round-tabfor6 {
        margin-left: 24% ! important;
    }
    .new_round-tabfor4 {
        margin-left: 39% ! important;
    }
    .new_wizardNotefor4 {
        left: -5px ! important;
    }
    .webclientbackimg{
      width: 500px;
      margin-left: 40px;
      border:1px solid  #eee;
    }
    .webclientcaro{
      width: 450px ! important;
    }
   }
@media only screen and (device-width : 400px) and (device-height : 700px) { 
   .webclientcaro {
      width: 300px ! important;
  }
  .webclientbackimg{
    border: 1px solid #aea7a7;
  }
} 
@media only screen and (device-width : 393px) and (device-height : 851px) { 
     .new_wizardNotefor4forJ{
        right: 4px ! important;
    }
  }
@media only screen and (device-width : 360px) and (device-height : 640px) { 
     .new_wizardNotefor4forJ{
        right: 4px ! important;
    }
  } 
@media only screen and (device-width : 400px) { 
   .webclientcaro {
      width: 300px ! important;
  }
} 
@media only screen and (device-width : 401px) { 
   .webclientcaro {
      width: 300px ! important;
  }
}
@media only screen and (device-width: 1680px) and (device-height: 1050px) 
 {
.roadjourny img{
    left:-249px ! important;
    z-index: 0! important ;
  }
.add1{
    opacity: 1;
    left: 387px ! important;
    z-index: 0! important ;
  }
.add9{
    display: none;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left: 552px ! important;
    z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 248px !important;
    top: 411px ! important;
    z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    left: 677px !important;
    top: 661px ! important;
    z-index: 0! important ;
  }
.add4{
    opacity: 1;
   left: -476px ! important;
    top: 461px ! important;
    z-index: 0! important ;
  }
  
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: left ! important;
  }
.Journycaro4 h3{
    text-align: left ! important;
  }
.Journycaro4{
    opacity: 1;
    top: 761px ! important;
    left: 200px ! important;
    text-align: right ! important;
    z-index: 0! important ;

  }
.add3 {
    opacity: 1;
    left: 603px ! important;
    top: 280px ! important;
    z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 810px ! important;
    left: 746px ! important;
    z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 696px ! important;
    top: 14px ! important;
    z-index: 0! important ;

  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 934px ! important;
    z-index: 0! important ;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 582px ! important;
    z-index: 0! important ;

  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 939px ! important;
    z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -271px ! important;
    top: 156px ! important;
    z-index: 0! important ;
  }
.add10{
    opacity: 1;
    top: -37px ! important;
    left: 304px ! important;
    z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    left: 279px ! important;
    z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    top: 1125px ! important;
    left: 282px ! important;
    z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
    left: 421px ! important;
    top: 1308px ! important;
    z-index: 0! important ;
  }
.Journycaro9 p{
    text-align: left ! important;
  }
.Journycaro9 h3{
    text-align: left ! important;
  }

.add7 {
    opacity: 1;
    left: 246px ! important;
    top: 348px ! important;
    z-index: 0! important ;
  }
.add12 {
    left: 189px ! important;
    top: -112px  !important;
    opacity: 1;
    z-index: 0! important ;
     
   }
.add2{
    opacity:1;
    right: 431px ! important;
    z-index: 0! important ;
   }
  .roadjourny{
    overflow:inherit;
    z-index: 0! important ;
   }      
  }   
@media only screen and (device-width: 1680px) 
 {
  .roadjourny img{
    left:-122px ! important;
    /*z-index: 0! important ;*/
    top:91px ! important;
  }
  .journybg1{
    display: none;
  }
  .add1{
    opacity: 1;
    left: 465px ! important;
    z-index: 0! important ;
  }
  .add9{
    display: none;
    z-index: 0! important ;
  }
  .Journycaro1{
    opacity: 1;
    top:265px ! important;
    left: 641px ! important;
    z-index: 0! important ;
  }
  .Journycaro2{
    opacity: 1;
    left: 407px !important;
    top: 411px ! important;
    z-index: 0! important ;
  }
  .Journycaro3 {
    opacity: 1;
    left: 840px !important;
    top: 661px ! important;
    z-index: 0! important ;
  }
  .add4{
    opacity: 1;
    left: -352px ! important;
    top: 526px ! important;
    z-index: 0! important ;
  }
  
  .Journycaro3 p{
    text-align: left ! important;
  }
  .Journycaro3 h3{
    text-align: left ! important;
  }
  .Journycaro4 p{
    text-align: left ! important;
  }
  .Journycaro4 h3{
    text-align: left ! important;
  }
  .Journycaro4{
    opacity: 1;
    top: 810px ! important;
    left: 746px ! important;
    text-align: right ! important;
    z-index: 0! important ;

  }
  .add3 {
    opacity: 1;
    left: 757px ! important;
    top: 280px ! important;
    z-index: 0! important ;
  }
  .Journycaro6 {
    opacity: 1;
     top: 866px ! important;
    left: 319px ! important;
    z-index: 0! important ;
  }
  .add6{
    opacity: 1;
    left: 696px ! important;
    top: -182px ! important;
    z-index: 0! important ;

  }
  .Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 934px ! important;
    z-index: 0! important ;
  }
  .add5{
    opacity: 1;
    top: 799px ! important;
    right: 550px ! important;

  }
  .Journycaro7 p{
    text-align: left ! important; 
  }
  .Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1071px ! important;
    z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -400px ! important;
    top: 156px ! important;
    z-index: 0! important ;
  }
.add10{
    opacity: 1;
    top: 69px ! important;
    left: 375px ! important;
    z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    top:1530px ! important;
    left: 426px ! important;
    z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    opacity: 1;
    top: 1193px ! important;
    left: 415px ! important;
    z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
    left: 588px ! important;
    top: 1413px ! important;
    z-index: 0! important ;
  }
.Journycaro9 p{
    text-align: left ! important;
  }
.Journycaro9 h3{
    text-align: left ! important;
  }

.add7 {
    opacity: 1;
    left: 513px ! important;
    top: 348px ! important;
    z-index: 0! important ;
  }
.add12 {
    left: 330px ! important;
    top: -112px  !important;
    opacity: 1;
    z-index: 0! important ;
   }
.add2{
    opacity:1;
    right: -40px ! important;
    top:465px ! important;
    z-index: 0! important ;
   }
.roadjourny{
    overflow:inherit;
    z-index: 0! important ;
   }    
  }   
@media only screen and (device-width: 1536px){
      .roadjourny img{
        display: none;
      }
      .add1{
        display: none;
      } 
      .add2{
        display: none;
      }
      .add3{
        display: none;
      } 
      .add4{
        display: none;
      } 
      .add5{
        display: none;
      } 
      .add6{
        display: none;
      } 
      .add7{
        display: none;
      } 
      .add8{
        display: none;
      } 
      .add9{
        display: none;
      }  
      .add10{
        display: none;
      } 
      .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
    .journybg{
      display: none;
    }
     .webclientcaro h3{
      text-align: center;
     }
     .Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
     .Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
    .Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
    .journybg2{
      display: none;
    }
    
  }   
  @media only screen and (device-width: 3200px){
      .roadjourny img{
        display: none;
      }
      .add1{
        display: none;
      } 
      .add2{
        display: none;
      }
      .add3{
        display: none;
      } 
      .add4{
        display: none;
      } 
      .add5{
        display: none;
      } 
      .add6{
        display: none;
      } 
      .add7{
        display: none;
      } 
      .add8{
        display: none;
      } 
      .add9{
        display: none;
      }  
      .add10{
        display: none;
      } 
      .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 1400px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
    .journybg{
      display: none;
    }
     .webclientcaro h3{
      text-align: center;
     }
     .Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
     .Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
     .Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
     .Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
    .Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
    .Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
    .Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }

    .Journycaro2{
     left: 1562px ! important;
      top: 300px ! important;
       opacity: 1;
    }
    .Journycaro4{
     left: 1562px ! important;
      top: 502px ! important;
       opacity: 1;
    }
    .Journycaro6{
     left: 1562px ! important;
      top: 700px ! important;
       opacity: 1;
    }
    .Journycaro8{
     left:1562px ! important;
      top: 915px ! important;
       opacity: 1;
    }
    .Journycaro10{
     left:1562px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
    .journybg2{
      display: none;
    }
    
  }   
  @media only screen and (device-width: 1536px) and (orientation: portrait){
  .roadjourny img{
        display: none;
      }
  .add1{
        display: none;
      } 
  .add2{
        display: none;
      }
  .add3{
        display: none;
      } 
  .add4{
        display: none;
      } 
  .add5{
        display: none;
      } 
  .add6{
        display: none;
      } 
  .add7{
        display: none;
      } 
  .add8{
        display: none;
      } 
  .add9{
        display: none;
      }  
  .add10{
        display: none;
      } 
  .webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
       text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }

    
  }   
@media only screen and (device-width: 2048px){
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-249px ! important;
    top:92px! important;
  }
.add1{
    opacity: 1;
    left: 567px ! important;
    top:291px ! important;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
   left: 877px ! important;
  }
.Journycaro2{
    opacity: 1;
    left: 669px !important;
    top: 411px ! important;
  }
.Journycaro3 {
    opacity: 1;
    top: 629px ! important;
    left: 1103px ! important;
  }
.add4{
    opacity: 1;
    left: 311px ! important;
    top: 239px ! important;
  }
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 346px !important;
    top: 618px ! important;
    text-align: right ! important;
  }
.add3 {
    opacity: 1;
    left: 747px ! important;
    top: 370px ! important;
  }
.Journycaro6 {
    opacity: 1;
    top: 840px ! important;
   left: 583px ! important;
  }
.add6{
    opacity: 1;
    left: 465px ! important;
    top: -47px ! important;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
   left: 1180px ! important;
  }
.add5{
    opacity: 1;
    top: 810px ! important;
    right: 624px ! important;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1283px ! important;
  }
.add8{
    opacity: 1;
    right: -527px ! important;
    top: 156px ! important;
  }
.add10{
    opacity: 1;
    top: 66px ! important;
    left: 390px ! important;
  }
.Journycaro10{
    opacity: 1;
   left: 682px ! important;
  }
.Journycaro8 {
    opacity: 1;
    top: 1199px ! important;
    left: 650px ! important;
  }
.Journycaro9{
    opacity: 1;
    left: 238px ! important;
    top: 1303px ! important;
  }
.add7 {
    opacity: 1;
    left: 651px ! important;
    top: 393px ! important;
  }
.add12 {
    left: 562px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
    right: 244px ! important;
    top:471px ! important;
   }
.roadjourny{
    overflow:inherit;
   }  
.add9{
    display: none;
   }
}
@media only screen and (device-width : 2560px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-249px ! important;
  }
.add1{
    opacity: 1;
    left:886px ! important;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
  }
.Journycaro2{
    opacity: 1;
    left: 1107px !important;
    top: 411px ! important;
  }
.Journycaro3 {
    opacity: 1;
    left: 608px !important;
    top: 613px ! important;
  }
.add4{
    opacity: 1;
    left:646px ! important;
    top: 239px ! important;
  }
  
.Journycaro3 p{
    text-align: right ! important;
  }
.Journycaro3 h3{
    text-align: right ! important;
  }
.Journycaro4 p{
    text-align: left ! important;
  }
.Journycaro4 h3{
    text-align: left ! important;
  }
.Journycaro4{
    opacity: 1;
    top: 543px ! important;
    left: 1586px ! important;
    text-align: right ! important;
}
.add3 {
    opacity: 1;
   left: 1061px ! important;
    top: 280px ! important;
  }
.Journycaro6 {
    opacity: 1;
    top: 785px ! important;
    left: 1230px ! important;
  }
.add6{
    opacity: 1;
    left: 1169px  ! important;
    top: 12px ! important;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 1799px ! important;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 429px ! important;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
   text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
        top: 1119px ! important;
       left: 1814px ! important;
  }
.add8{
    opacity: 1;
   right: -860px ! important;
    top: 156px ! important;
  }
.add10{
    opacity: 1;
  }
.Journycaro10{
    opacity: 1;
    left: 1180px ! important;
  }
.Journycaro8 {
    opacity: 1;
    top: 1086px ! important;
    left: 1042px ! important;

  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
     top: 1268px ! important;
  }
.add7 {
    opacity: 1;
    left: 1125px ! important;
    top: 348px ! important;
  }
.add12 {
    left: 1067px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
   }
.roadjourny{
    overflow:inherit;
   }     
} 
@media only screen and (device-width : 2880px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny img{
    left:-567px ! important;
     /*z-index: 0! important ;*/
  }
.add1{
    opacity: 1;
    left:765px ! important;
    z-index: 0! important ;
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
     z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 1107px !important;
    top: 411px ! important;
     z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    top: 543px ! important;
    left: 1586px ! important;
    text-align: left ! important;
     z-index: 0! important ;
  }
.add4{
    opacity: 1;
    left:546px ! important;
    top: 239px ! important;
     z-index: 0! important ;
  }
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 608px !important;
    top: 613px ! important;
    text-align: right ! important;
     z-index: 0! important ;
  }
.add3 {
    opacity: 1;
    left: 1067px ! important;
    top: 280px ! important;
     z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 785px ! important;
    left: 1087px ! important;
     z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 978px ! important;
    top: -110px ! important;
     z-index: 0! important ;
  }
.Journycaro5{
    opacity: 1;
    top: 964px ! important;
    left: 1799px ! important;
     z-index: 0! important ;
  }
.add5{
    opacity: 1;
    top: 763px ! important;
    right: 701px ! important;
     z-index: 0! important ;
  }
.Journycaro7 p{
    text-align: left ! important; 
  }
.Journycaro7 h3{
    text-align: left ! important; 
  }
.Journycaro8 p{
    text-align: right ! important; 
  }
.Journycaro8 h3{
    text-align: right ! important; 
  }
.Journycaro7{
    opacity: 1;
    top: 1119px ! important;
    left: 1808px ! important;
     z-index: 0! important ;
  }
.add8{
    opacity: 1;
    right: -760px ! important;
    top: 156px ! important;
     z-index: 0! important ;
  }
.add10{
    opacity: 1;
     z-index: 0! important ;
  }
.Journycaro10{
    opacity: 1;
    left: 1180px ! important;
     z-index: 0! important ;
  }
.Journycaro8 {
    opacity: 1;
    top: 1086px ! important;
    left: 1042px ! important;
     z-index: 0! important ;
  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
     top: 1268px ! important;
      z-index: 0! important ;
  }
.add7 {
    opacity: 1;
    left: 1131px ! important;
    top: 348px ! important;
     z-index: 0! important ;
  }
.add12 {
    left: 1067px ! important;
    top: -112px  !important;
    opacity: 1;
     
   }
.add2{
    opacity:1;
    top:402px! important;
    right: 331px ! important;
     z-index: 0! important ;
   }
.roadjourny{
    overflow:inherit;
     z-index: 0! important ;
   }
.add9{
    display: none;
   }    
} 
 @media only screen and (device-width : 2560px) {
.new_wizardNotefor4forJ{
    right: 63px !important;
  }
.roadjourny{
        overflow: inherit;
        z-index: 0;
        top: 92px;
      }
.roadjourny img{
    left:-249px ! important;
  }
.add1{
    opacity: 1;
    left:886px ! important;
    /*z-index: 0! important ;*/
  }
.Journycaro1{
    opacity: 1;
    top:265px ! important;
    left:1393px ! important;
    z-index: 0! important ;
  }
.Journycaro2{
    opacity: 1;
    left: 1168px !important;
    top: 433px ! important;
    z-index: 0! important ;
  }
.Journycaro3 {
    opacity: 1;
    top: 620px ! important;
    left: 1617px ! important;
    /*z-index: 0! important ;*/
  }
.add4{
    opacity: 1;
    left: 661px ! important;
    top: 272px ! important;
    z-index: 0! important ;
  }  
.Journycaro3 p{
    text-align: left ! important;
  }
.Journycaro3 h3{
    text-align: left ! important;
  }
.Journycaro4 p{
    text-align: right ! important;
  }
.Journycaro4 h3{
    text-align: right ! important;
  }
.Journycaro4{
    opacity: 1;
    left: 680px !important;
    top: 652px ! important; 
    text-align: right ! important;
    /*z-index: 0! important ;*/
  }
.add3 {
    opacity: 1;
   left: 1086px ! important;
    top: 389px ! important;
    z-index: 0! important ;
  }
.Journycaro6 {
    opacity: 1;
    top: 879px ! important;
    left: 1088px ! important;
    z-index: 0! important ;
  }
.add6{
    opacity: 1;
    left: 977px  ! important;
    top: 12px ! important;
    z-index: 0! important ;
  }
.Journycaro5{
    opacity: 1;
    top: 1005px ! important;
    left: 1776px ! important;
    /*z-index: 0! important ;*/
  }
.add5{
    opacity: 1;
    top: 839px ! important;
    right: 470px ! important;
    z-index: 0! important ;
  }
.Journycaro7 p{
    text-align: left ! important; 
    z-index: 0! important ;
  }
.Journycaro7 h3{
    text-align: left ! important; 
    z-index: 0! important ;
  }
.Journycaro8 p{
    text-align: right ! important; 
    z-index: 0! important ;
  }
.Journycaro8 h3{
    text-align: right ! important; 
    z-index: 0! important ;
  }
.Journycaro7{
    opacity: 1;
    top: 1158px ! important;
    left: 1814px ! important;
  }
.add8{
    opacity: 1;
    right: -860px ! important;
    top: 206px ! important;
  }
.add10{
    opacity: 1;
    top: 70px ! important;
    left: 919px ! important;
  }
.Journycaro10{
    opacity: 1;
    top: 1518px;
    left: 1180px ! important;
  }
.Journycaro8 {
    opacity: 1;
     top: 1207px ! important;
    left: 1166px ! important;
  }
.Journycaro9{
    opacity: 1;
     left: 686px ! important;
    top: 1333px ! important;
  }
.add7 {
    opacity: 1;
    left: 1163px ! important;
    top: 423px ! important;
  }
.add12 {
    left: 1074px ! important;
    top: -80px !important;
    opacity: 1;  
   }
.add2{
    opacity:1;
    right: 81px;
    top: 487px;
    z-index: 0! important ;
   }
.add9{
    display: none;
   }
.roadjourny{
    overflow:inherit;
   }   
}  
 @media only screen and (device-width : 1600px) {
.roadjourny img{
        display: none;
      }
.roadjourny{
        overflow: inherit;
        z-index: 0;
      }
.add1{
        display: none;
      } 
.add2{
        display: none;
      }
.add3{
        display: none;
      } 
.add4{
        display: none;
      } 
.add5{
        display: none;
      } 
.add6{
        display: none;
      } 
.add7{
        display: none;
      } 
.add8{
        display: none;
      } 
.add9{
        display: none;
      }  
.add10{
        display: none;
      } 
.webclientcaro {
        height: 150px ! important;
        /*padding: 10px;*/
        width: 630px ! important;
        /*height: 100px;*/
        text-align:center;
        border:1px solid #8b8d8e ! important;
    }
.journybg{
      display: none;
    }
.webclientcaro h3{
      text-align: center;
     }
.Journycaro1 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro2 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro3 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro4 p{
         padding: 10px;
      text-align: center;
     }
.Journycaro5 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro6 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro7 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro8 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro9 p{
      text-align: center;
         padding: 10px;
     }
.Journycaro10 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro11 p{
      text-align: center;
      padding: 10px;
     }
.Journycaro1{
        left: 101px ! important;
         top: 297px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro3{
        left: 101px ! important;
        top: 497px ! important;
        text-align: center;
         opacity: 1;
        /*padding: 10px;*/
    }
.Journycaro5{
        left: 101px ! important;
         top: 697px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro7{
        left: 101px ! important;
         top: 913px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro9{
        left: 101px ! important;
         top: 1119px ! important;
         text-align: center;
          opacity: 1;
    }
.Journycaro2{
     left: 791px ! important;
      top: 300px ! important;
       opacity: 1;
    }
.Journycaro4{
     left: 791px ! important;
      top: 502px ! important;
       opacity: 1;
    }
.Journycaro6{
     left: 791px ! important;
      top: 700px ! important;
       opacity: 1;
    }
.Journycaro8{
     left: 791px ! important;
      top: 915px ! important;
       opacity: 1;
    }
.Journycaro10{
     left: 791px ! important;
      top: 1120px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }
 }

 @media only screen and (device-width: 1024px) {
.Journycaro4{
      display: none;
    }
.add3{
      display: none;
    }
  }
@media only screen and (device-width : 1280px) and (device-height : 800px) {
    
}
@media only screen and (device-width : 1280px) {
.roadjourny{
      overflow: inherit;
      top:92px ! important;
      z-index: 0;
    }
.Journycaro1{
      left:532px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add10{
      opacity: 1;
      top:78px ! important;
    }
.add1{
      left:495px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left:438px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 239px ! important;
       z-index: 0! important ;
    }
.Journycaro4{
         top: 711px! important;
        opacity: 1;
        left: 701px ! important;
      z-index: 0! important ;
    }
.Journycaro5{
      left:846px ! important;
      opacity: 1;
      top: 918px ! important;
      z-index: 0! important ;
    }
.add5{
      opacity: 1;
      top:805px ! important;
       z-index: 0! important ;
    }
.Journycaro7{
      left:423px ! important;
      top:1115px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -412px ! important;
      top:175px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left:760px ! important;
      opacity: 1;
      top:1412px ! important;
       z-index: 0! important ;
    }
.add7{
      left: 712px ! important;
      top:326px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 95px ! important;
      top: -46px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top:1149px ! important;
      top:1220px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
      left: 335px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left:224px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
      left:204px ! important;
      opacity: 1;
      top: 834px;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: -103px ! important;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 239px;
       z-index: 0! important ;
    }
.add3 {
      left: 630px;
      opacity: 1;
      top: 325px;
     z-index: 0! important ;
  }
.downimgtext{
    left:19em ! important;
  }
}
@media only screen and (device-width : 1152px) {
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.roadjourny img{
      left:205px ! important;
    }
.Journycaro1{
      left:425px ! important;
      opacity: 1;
    }
.journybg2{
      display: none;
    }
.add1{
      left:501px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left:170px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      left:92px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro4{
      top:650px! important;
      left:600px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left:746px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro7{
      left:323px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -761px ! important;
      opacity: 1;
      top:170px ! important;
       z-index: 0! important ;
    }
.Journycaro8{
      left:672px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 712px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: -754px ! important;
      top:527px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top:1149px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
      left: 213px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left:133px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
      left:106px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 548px;
       z-index: 0! important ;
    }
.add10{
      opacity: 1;
      top:50px;
       z-index: 0! important ;
    }
.add3 {
    left: 513px;
    opacity: 1;
    top: 271px;
     z-index: 0! important ;
  }
}
@media only screen and (device-width : 1920px) {
.roadjourny img{
    left:-147px ! important;
    top:91px !  important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0! important ;
    }
.Journycaro1{
     left: 850px ! important;
      opacity: 1;
      z-index: 0;
    }
.add1{
      left: 595px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro2{
      left: 758px ! important;   
      opacity: 1;
      top:363px ! important;
      z-index: 0;
    }
.Journycaro3{
      opacity: 1;
      left: 550px;
      z-index: 0;

    }
.Journycaro4{
      top: 697px! important;
      left: 1050px;
      opacity: 1;
      z-index: 0;
    }
.Journycaro5{
      left: 1169px ! important;
      top:950px ! important;
      opacity: 1;
      z-index: 0;
    }
.add5{
      opacity: 1;
      right: 511px;
      top:804px  ! important;
      z-index: 0;

    }
.Journycaro7{
      left: 759px ! important;
      top:1155px ! important;
      opacity: 1;
      z-index: 0;
    }
.add8{
      right: -538px ! important;
      top:196px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
      z-index: 0;
      top:1392px ! important;
    }
.add7{
      left: 930px ! important;
      top:333px ! important;
      opacity: 1;
      z-index: 0;
    }
.add6{
      left: 433px ! important;
      top:-34px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro9{
      top: 1360px ! important;
      left: 158px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro10{
     left: 706px ! important;
      top: 1543px;
      opacity: 1;
      z-index: 0;
    }
.add9{
      left: 532px ! important;
      top:319px ! important;
      opacity: 1;
      z-index: 0;
    }
.Journycaro6{
     left: 529px ! important;
     top:860px ! important;
      opacity: 1;
      z-index: 0;
    }
.add2{
      opacity: 1;
      right: 88px;
      top: 457px;
      z-index: 0;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 361px;
    }
.add3 {
      left: 940px;
      opacity: 1;
      top: 352px;
      z-index: 0;
  }
.add10{
    opacity: 1;
    top: 165px ! important;
    left: 109px ! important;
    z-index: 0;
  }
}
@media only screen and (device-width : 1856px) {
.roadjourny img{
    left:-147px ! important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.Journycaro1{
     left: 837px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add1{
      left: 550px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left: 548px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 476px;
       z-index: 0! important ;

    }
.Journycaro4{
      top: 697px! important;
      left: 967px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left: 1169px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
      right: 511px;
       z-index: 0! important ;

    }
.Journycaro7{
      left: 680px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -495px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 930px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 375px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top: 1266px ! important;
      left: 110px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
     left: 638px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left: 469px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
     left: 529px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: 223px;
      top: 375px;
       z-index: 0! important ;
    }
.add4{
      opacity: 1;
      position: relative;
      left: 291px;
       z-index: 0! important ;
    }
.add3{
      left: 869px;
      opacity: 1;
      top: 271px;
       z-index: 0! important ;
  }
.add10{
      opacity: 1;
      top: 81px ! important;
      left: 60px ! important;
       z-index: 0! important ;
    }
}
@media only screen and (device-width : 1792px) {
.roadjourny img{
    left:-147px ! important;
  }
.roadjourny{
      overflow: inherit;
      z-index: 0;
    }
.Journycaro1{
     left: 760px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add1{
      left: 510px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro2{
      left: 482px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro3{
      opacity: 1;
      left: 412px;
       z-index: 0! important ;
    }
.Journycaro4{
      top: 673px! important;
      left: 901px;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro5{
      left: 1095px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add5{
      opacity: 1;
      right: 511px;
       z-index: 0! important ;

    }
.Journycaro7{
      left: 624px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add8{
      right: -455px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro8{
      left: 967px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add7{
      left: 930px ! important;
      top:241px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add6{
      left: 308px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro9{
      top: 1266px ! important;
      left: 29px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro10{
     left: 538px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add9{
      left: 409px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.Journycaro6{
     left: 415px ! important;
      opacity: 1;
       z-index: 0! important ;
    }
.add2{
      opacity: 1;
      right: 223px;
      top: 375px;
       z-index: 0! important ;
    }
.add4 {
      opacity: 1;
      position: relative;
      left: 247px;
       z-index: 0! important ;
    }
.add3 {
        left: 781px;
        opacity: 1;
        top: 275px;
         z-index: 0! important ;
  }
.add10{
      opacity: 1;
      top: 81px ! important;
      left: 13px ! important;
    }
}






.reparebtn{
  font-size: 13px;
  background-color: #7B943F;
  border-radius: 0px;
  padding: 17px 45px;
  color: #fff;


}

.webclientbackimg{
    color: #333;
    font-size: 15px;
    font-weight: 500;
    /*box-shadow: 0 10px 29px rgba(92,141,255,.22);*/
    border-radius: 8px;
    width: 677px;
}


.webclientcaro h3{
  /*text-align: left;*/
  font-weight: bold;
}
.webclientcaro{
  height: 137px;
  width: 385px;
}

@media only screen and (device-width : 1440px){
  
  
}
@media screen and (min-width: 1600px) {
/*  .Journycaro1{
    left:1138px !important;
  }
  .add1{
    left:936px ! important;
  }

  
  .Journycaro2{
    left:874px !important;
  }
  .add2{
    right: -49px ! important;
  }
  .Journycaro3{
    left:648px !important;
  }
  .add3{
    left:990px !important;
  }
  .Journycaro4{
    left:1337px ! important;
  }
   .add4{
    left:613px ! important;
    top:265px ! important;
  }
  .add6{
    left:715px ! important;
  }
  .Journycaro7{
    left:1028px ! important;
  }
   .add7{
    top:1238px ! important;
    left:243px ! important;
  }
  .Journycaro8{
    top:1339px ! important;
    left:1280px ! important;
  }
  .Journycaro9{
    left:482px ! important;
  }
  .add9{
    left: 817px ! important;
  }
  .add8{
    right:-827px ! important;
  }*/
  
}
@media screen and (min-width: 1900px) {
 .Journycaro1{
    /*left:1138px !important;*/
  }
 /* .add1{
    left:936px ! important;
  }*/
  .Journycaro2{
    /*left:874px !important;*/
  }
 /* .add2{
    right: -49px ! important;
  }*/
  .Journycaro3{
    /*left:648px !important;*/
  }
 /* .add3{
    left:990px !important;
  }*/
  .Journycaro4{
    /*left:1337px ! important;*/
  }
 /* .add4{
    left:613px ! important;
    top:265px ! important;
  }*/
 /* .add6{
    left:715px ! important;
  }*/
  .Journycaro7{
    /*left:1028px ! important;*/
  }
  /*.add7{
    top:1238px ! important;
    left:243px ! important;
  }*/
  .Journycaro8{
    /*top:1339px ! important;
    left:1280px ! important;*/
  }
   .Journycaro9{
    /*left:482px ! important;*/
  }
  
 /* .add9{
    left: 817px ! important;
  }
  .add8{
    right:-827px ! important;
  }*/
  
}


.founderbrdcrm{
	margin-top: 20px;
    margin-left: -24px;
    width: 310px ! important;
}
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

.container6 {
  position: absolute;
  top: 26px;
}


.switch {
  position: relative;
  height: 26px;
  width: 120px;
  /*margin: 20px auto;*/
  /*background: rgba(0, 0, 0, 0.25);*/
  border-radius: 10px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
}

.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  width: 58px;
  line-height: 26px;
  /*font-size: 11px;*/
  color:  #2e2b2b;
  text-align: center;
  /*text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);*/
  cursor: pointer;
}
.switch-label:active {
  font-weight: 100;
}

.switch-label-off {
  padding-left: 2px;
}

.switch-label-on {
  padding-right: 2px;
}


.switch-input {
  display: none;
}
.switch-input:checked + .switch-label {
  font-weight: 100;
  color: #fff;
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  transition: 0.15s ease-out;
  transition-property: color, text-shadow;
}
.switch-input:checked + .switch-label-on ~ .switch-selection {
  left: 60px;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  display: block;
  width: 58px;
  height: 22px;
  border-radius: 10px;
  background-color: #269be5;
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
  transition: left 0.15s ease-out;
}
.switch-blue .switch-selection {
  background-color: #3aa2d0;
  background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
}
.switch-yellow .switch-selection {
  background-color: #c4bb61;
  background-image: linear-gradient(top, #e0dd94, #c4bb61);
}


.flip-card {
  background-color: transparent;
  width: 300px;
  height: 335px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  border-radius: 15px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 15px;
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  transition-duration:1.5s;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 15px;
}

.flip-card-front {
  background-color: #eee;
  color: black;
  border-radius: 15px;
    border-radius: 15px;

}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  border-radius: 15px;
}

.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}


.addform{
	font-size: 25px;
	cursor: pointer;
	padding: 0px;
}
.formcontent-wrap{
	margin-top:60px;
	margin-bottom: 50px;
	color: #fff;
	background-size: 100% 100%;
	height: auto;
}
.jobpageform{
	box-shadow: 01px 1px 15px 1px #ccc;
	height: auto;
	padding: 25px;
    color: #555;
    margin-top: 20px
}
.jobformcontent{
	margin-bottom: 30px !important;
	margin-left: 383px;
}
.dropZindex{
	z-index: 10;
}
.css-1r4vtzz, .css-48ayfv, .css-1pcexqc-container{
	min-width: 100%!important;
	border: 1px solid #ccc !important;
	box-shadow: none !important;

}
 @media (max-width: 480px){

 	.jobpageform{
 		width: 300px ! important;
 	}
 	.jobformcontent{
 		margin-left: 0px ! important;
 	}
 	.formcontent-wrap{
 		margin-top: 150px ! important;
 	}

 }
@media screen and (device-width: 2560px) {
	.jobformcontent{
		margin-left: 900px ! important;
	}
	.jobpageform{
		margin-top: 50px ! important;
	}
}
@media screen and (device-width: 2048px) {
	.jobformcontent{
		margin-left: 700px ! important;
	}
}
@media screen and (min-width: 768px) {

	 /* .jobformcontent{
	  	margin-left: 400px ! important;
	  }*/
  }
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}

.services_img{
  width: 100%;
}
.dwstmTitle{
  font-size: 18px;
  font-weight: 700;
  margin-top: 2px;
}
.leafModalBody{
  height: 415px;
  padding: 0px;
}
.leafModalFooter{
  border: none !important;
}
.skipText{
  color: #555;
  font-size: 15px;    
  text-align: center;
  cursor: pointer;
  font-weight: 900
}
.contentWraper{
	height: auto;
	background-color: transparent;
	padding-top: 0px!important;
	/*background-color: #fff;*/
	/*padding-top: 35px;*/
  overflow: hidden;
}

.serflip-card {
    /*background-color: #fff;*/
    /*box-shadow: 0 10px 29px rgba(92,141,255,.22);*/
    margin-bottom: 70px;
    height: 300px;
    width:300px; 
     -webkit-perspective: 1000px; 
             perspective: 1000px;
    border-radius: 15px;
}
.serflip-cardservices{
   margin-bottom: 70px;
    height: 300px;
    width:300px; 
     -webkit-perspective: 1000px; 
             perspective: 1000px;
    border-radius: 15px;

}

.serflip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 10px 29px rgba(92,141,255,.22);
  border-radius: 15px;
}

.serflip-cardservices:hover .serflip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  transition-duration:1.5s;
}
.serflip-cardservices:hover .serflip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  transition-duration:1.5s;
}

.serflip-card-front, .serflip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 15px;
}

.serflip-card-front {
  background-color: #fff;
  color: black;
  border-radius: 15px;
  border-radius: 15px;
  padding: 60px 22px 47px;


}

.serflip-card-back {
  background-color: #2980b9;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  border-radius: 15px;
  padding: 22px 22px 47px; /*PB*/
}

.serflip-card-back p{
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 600;
}
.downstreamservicesblock{
	  background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 87px 22px 47px;
    height: 300px;
}
.serflip-card img{
  height: 130px;
  margin-bottom: 15px;
}
.serflip-cardservices img{
  height: 130px;
  width: 190px;
  object-fit: contain;
  /*padding-left: 50px;*/
  margin-bottom: 15px;
}

.serflip-cardservices h2{
	/*margin-top: 70px;*/
	font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  text-align: center;
}
.serflip-card h2{
  /*margin-top: 70px;*/
  font-size: 18px;
  line-height: 35px;
  font-weight: 600;
  text-align: center;
}
.block p{
  font-size: 14px;
  color:#000;
}

.contentheader{
	/*margin-bottom:120px;*/
}
.contentheader h1{
	  font-size: 41px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheader h2{
  font-size: 42px;
  line-height: 55px;
  /*letter-spacing: 2px;*/
}
.contentheader hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}
.contentheadernew{
  /*margin-bottom:120px;*/
}
.contentheadernew h1{
    font-size: 30px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -.4px;
}
.contentheadernew h2{
  font-size: 30px;
  line-height: 55px;
  letter-spacing: 2px;
}
.contentheadernew hr{
  height: 2px;
  width: 95px;
  border: 1px solid #f19000;
  background-color: #f19000;
  /*margin-bottom: 39px;*/
}

/*.block1height{
  height: 300px;
}
.block2height{
  height: 300px;
}
.block3height{
  height: 300px;
}
.block4height{
  height: 300px;
}
.block5height{
  height: 300px;
}
.block6height{
  height: 300px;
}
*/
.text-justify{
	text-align: justify;
}
@-webkit-keyframes rotation {
    
    to{
       
        -webkit-transform: perspective(100px) rotateY(10deg);
    }
}
.upstreamBg{
    padding: 150px 0px;
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/6.png");
    height: 785px;
}

.upstream-wrapper:before {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}
.upstream-wrapper:after {
  background-image: url("/images/data_image_svg+xml;….svg");
  background-size: 100% 100%;
      height: 760px;
}

.upstream-wrapper:before {
    -webkit-animation: swell 7s ease -1.25s infinite;
    animation: swell 7s ease -1.25s infinite;
    background-position: calc(50% - 100px) 50%;
    opacity: .2;
}

.upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}

.upstream-wrapper:after {
    -webkit-animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    animation: swell 7s cubic-bezier(.36,.45,.63,.53) infinite;
    background-position: calc(50% + 100px) 50%;
    opacity: .1;
}
.upstream-wrapper:after, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
@-webkit-keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0);
  }
  50% {
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
}
.upstream-wrapperafter, .upstream-wrapper:before {
    position: absolute;
    z-index: 0;
    top: -15px;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-repeat: repeat-x;
    content: "";
    pointer-events: none;
}
.upstreamBg{
    position: relative;
    z-index: 1;
    background-position: 50%;
    background-repeat: repeat-x;
}
.svgAnimation{
    background-size: 100% 100%;
    /*background-color: #367588;*/
    background-image: url("/images/data_image_svgs.svg");
    height: 540px;
}



.Main_Cards__Bubble:first-child {
    top: 270px;
    left: -75px;
}
.Main_Cards__Bubble:first-child {
    width: 493px;
    height: 493px;
    content: "";
}
.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in, -webkit-transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color:#eeeeee4d;
    border-radius: 50%;
}
.Main_Cards__Bubble:nth-child(2) {
    right: 0px;
    bottom: 0px;
    width: 800px;
    height: 800px;
}
/*.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color: rgba(105,92,255,.05);
    border-radius: 50%;
}*/
.havh{
  
}




@-webkit-keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}




@keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}
@-webkit-keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@-webkit-keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
@keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
.bub1{
    -webkit-animation:  bubblea1 30s linear infinite;
            animation:  bubblea1 30s linear infinite;


}

.bub2{
    -webkit-animation: bubblea3 30s linear infinite;
            animation: bubblea3 30s linear infinite;
}
.downstreamtxt{
  padding-top: 10px;
}
@media only screen and (max-width : 600px){
  .commonBlockpadd{
    padding: 0px ! important;
  }
  .B2T_p{
    padding: 0px ! important;
  }
  .responsivePadding{
    padding-left: 0px ! important;
  }
}

@media only screen and (device-width: 1024px) {
  .newcard_landscape{
    width: 220px ! important;

  }
  .serflip-cardservices img{
    padding-left: 0px ! important;
  }
}  
@media only screen and (device-width: 1366px) {
  .newcard_landscape{
    width: 300px ! important;

  }

} 
@media only screen and (device-width: 1024px) 
   {
  .newcard_landscape{
    width: 220px ! important;

  }
  .downimgtext{
    left:15em ! important;
  } 

}  
@media only screen 
  and (min-width: 1440px) 
  and (max-height: 900px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .newcard_landscape{
    width: 295px ! important;

  }
}

@media only screen and (device-width : 393px) and (device-height : 851px) {

   .serflip-cardservices img{
    margin-left: 20px ! important;
  } 
}
@media only screen and (device-width : 393px) and (device-height : 851px) {

  .serflip-card{
    width:260px ! important;
  } 
}
@media only screen and (device-width : 411px) and (device-height : 823px) {

   .serflip-cardservices img{
    margin-left: 15px ! important;
  } 
}
@media only screen and (device-width : 411px) {

   .serflip-cardservices img{
    margin-left: 15px ! important;
  } 
}
@media only screen and (device-width : 375px) and (device-height : 812px) { 
    .newcard_landscape {
      width: 248px ! important;
  }
}
@media only screen and (device-width : 320px) and (device-height : 568px) { 
    .newcard_landscape {
      width: 210px ! important;
  }
}
@media only screen and (device-width : 401px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
 }
  @media only screen and (device-width : 412px) and (device-height : 732px) {
   .serflip-card {
        width: 275px ! important;
    } 
  }  
 @media only screen and (device-width : 400px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
     .serflip-card img{
      width: 220px ! important;
     }
 }
 @media only screen and (device-width : 401px) and (device-height : 700px){ 
  .serflip-card{
        width: 275px ! important;
     }
     .serflip-card img{
      width: 220px ! important;
     }
 }

.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	font-family: 'Raleway', sans-serif;
}

.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	font-family: 'Open Sans', sans-serif
}

.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	/*font-family: 'Open Sans', sans-serif*/
}

.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.breadcrumb4{
	margin-top: 120px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.plan_line_paraaaa{
    line-height: 30px;
    font-size: 19px;
}
.plan_line_subheadd{
    /*font-weight: 600;*/
    font-size: 33px;
    color: #3a3737;
    font-weight: bold;
  }

 .plan_line_subsubheadd{
 	font-weight: 700;
    font-size: 23px;
    color: #3a3737;
 }

.plans_imgg{
  /*width: 751px;*/
  /*margin-left: 200px;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) , 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
.plan_line_paraaaa3{
    line-height: 30px;
    font-size: 19px;
    margin-top: 41px;
}
.mt_plans{
	width: 98%;
}
.p1{
        margin-left: 28px;
}

.p2{
        margin-left: 28px;
}

.p3{
        margin-left: 28px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg1{
	background-image:url("/images/downstreambg1.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg2{
	background-image:url("/images/downstreambg3.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}
.downstreambg3{
	background-image:url("/images/downstreambg2.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 500px;
}

.paraaa-top{
    margin-bottom: -1px;
    /* font-size: 50px; */
    font-size: 40px;

    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 30px;
}
.newparaaa-top{
    
    margin-top: 85px;
}


.line_paraaaa{
    line-height: 30px;
    font-size: 17px;
    text-align: justify;
}
.line_subheadd{
    /*font-weight: 600;*/
    /* font-size: 33px; */
    font-size: 30px;
    color: #3a3737;
    font-weight: bold;
  }

.bluetext {
    color: #1378d4;
}

.line_overview {
    background-color: #f19000;
    width: 105px;
    margin-top: 32px;
    margin-bottom: 41px;
    height: 4px;
    /* margin-left: 500px; */
    text-align: center;
}

 .BT1_line{
    background-color: #f08f00;
    width: 90px;
    height: 4px;
    top: 211px;
    left: 60px;
    position: absolute;
    z-index: 999
  }
  .innerBlocktepmlate{
    height: auto;
  }
  .BT1_img{
    height: 200px;
    width: 100%;
    margin-top: 45px;

  }
  .BT1_bannerTitle{
    position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 500px;*/
    top: 165px;
    letter-spacing: 2px;
  }
  .HistorianTitle{
    color: #fff;
  }
  .dynamicpageimg{
    background-image: url("/images/acc.png");
  }
  .policyMainDiv{
    margin-top: 30px;
    padding: 40px;
    /*width: 1200px;*/
    /*margin-left: 66px;*/
    box-shadow: 0px 0px 39px -7px #eee;
  }
  @media(max-width : 600px){
    
    .BT1_bannerTitle{
      font-size: 37px ! important;
    }
    /*.serflip-card{
      width: 240px ! important;
    }*/

  }
 @media screen and (min-width: 1900px) {
  .policyMainDiv{
    width: 1500px ! important ;
    margin-left: 195px ! important;
  }
}
.Plicy_padding{
  padding: 80px;
}
@media only screen and (device-width : 393px) and (device-height : 851px) {
  .Plicy_padding{
    padding: 25px;
  }
  .policyMainDiv{
    padding: 10px;
  }

}
@media only screen and (device-width : 360px) and (device-height : 640px) {
  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }

}
@media only screen and (device-width : 411px) and (device-height : 731px) {

  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 411px) and (device-height : 823px) {

  .Plicy_padding{
    padding: 25px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 320px) and (device-height : 568px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 375px) and (device-height : 667px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 414px) and (device-height : 736px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 375px) and (device-height : 812px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}
@media only screen and (device-width : 384px) and (device-height : 640px) {

  .Plicy_padding{
    padding: 10px;
  }
   .policyMainDiv{
    padding: 10px;
  }
}



   
.rp_line_paraaaa{
    line-height: 30px;
    font-size: 19px;
    color: #7b6f6f;
}

.rp_line_subheadd{
    /*font-weight: 600;*/
    font-size: 33px;
    color: #3a3737;
    font-weight: bold;
  }

  .rp_line_subsubheadd{
    font-weight: 700;
    font-size: 23px;
    color: #3a3737;
 }

 .grey_line1 hr{
    /*width: 100%;*/
    height: 1px;
    /*margin-left: 74px;*/
    background-color: transparent;
  }

 .refinery_list{
    font-size: 19px;
    color: #7b6f6f;
    background-repeat: no-repeat;
    /*margin-left: 78px;*/
  }

.refinery_sublist{
    /*margin-left: 15px;*/
    font-size: 19px;
    line-height: 28px;
    color: #7b6f6f;
 }

.mb100{
    margin-bottom: 100px;
}
.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	font-family: 'Raleway', sans-serif;
}

.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}
div, a, p, h1, h2, h3, h4, h5, h6, i, span, label{
	/*font-family: 'Open Sans', sans-serif ! important;*/
	color:#000;
}
p{
	/*font-size: 17px;*/
}


.refinerynavigate{
	width:333px ! important;
}



.demadnavigate{
	width: 353px ! important;
}


.retailnavigate{
	width:330px ! important;
}




.iogimplmntbrdCrm{
	z-index: 999;
	margin-top: 150px ! important;
	margin-left: 20px;
	/*position: fixed;*/
}
.breadcrumb2{
	background-color: transparent;
	width: 301px;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
	margin-left: 20px;
}
.implementimage{
	margin-top: 80px;
	border-top: 3px solid #333;
	border-left: 3px solid #333;
	border-bottom: 3px solid #333;
}

.BT3_text1{
	margin-top: 120px;
}


/*.innerBlocktConsult{
	height: 1700px;
	background-image: url("/images/consultdown.png");
	background-size: 100% 100%;
}
.innerBlocktConsult_res{
  height: 1500px;
  
  background-size: 100% 100%;
}
.downimgtext{
	position: absolute;
    top: 5em;
    left: 16em;
    color: #009de2;
    font-weight: 600;
}
.downimgtext_res{
  
    color: #009de2;
    font-weight: 600;
}
.downimg_p_txt{
	  position: absolute;
    top: 12.5em;
    left: 9em;
   
    font-size: 20px;
    padding-right:135px;
}
.downimg_p_txt_res{
    
    text-align: justify; 
    font-size: 18px
}
#consultdown_data0{
   position: absolute;
   top: 29em;
   left: 56em;
   width: 400px;

}#consultdown_data0_res,#consultdown_data1_res,#consultdown_data2_res,#consultdown_data3_res{
  
   width: 400px;
   font-weight: 600;

}
#consultdown_data1{
   position: absolute;
   top: 36em;
   left: 5em;
   width: 400px;
   text-align: right;

}
#consultdown_data2{
    position: absolute;
    top: 47em;
    left: 56em;
    width: 400px;
    text-align: left;

}
#consultdown_data3{
    position: absolute;
    top: 56em;
    left: 5em;
    width: 400px;
    text-align: right;

}
#consultdown_data4{
	 position: absolute;
    top: 66em;
    left: 56em;
    width: 400px;
    text-align: left;

}
#consultdown_data5{
	position: absolute;
    top: 74em;
    left: 5em;
    width: 400px;
    text-align: right;
}
#consultdown_data6{
	position: absolute;
    top: 83em;
    left: 56em;
    width: 400px;
    text-align: left;
}
/*#consultdown_data7{
	position: absolute;
    top: 91em;
    right:71em;
    width: 400px;
    text-align: right;
}*/
/*#downimg_text0,#downimg_text4,#downimg_text6{
	font-weight: 600;

}
#downimg_p_text0,#downimg_p_text4,#downimg_p_text6{
	font-size: 17px;

}
#downimg_text1,#downimg_text5,#downimg_text7{
	font-weight: 600;

}
#downimg_p_text1,#downimg_p_text5,#downimg_p_text7{
	font-size: 17px;

}
#downimg_text2,#downimg_text3{
	font-weight: 600;

}
#downimg_p_text2, #downimg_p_text3{
	font-size: 17px;

}*/
.takelftconsult{
	margin-left: 78px;
}
.consultMainImg{
	width: 100%;
}
.breadcrumbtrain{
	width: 250px;
	background-color: transparent;
	height: 34px;
	background-color: #f5f5f5;
	border-radius: 100px;
	margin-left: 20px;
}
.mainpagetxt{
	position: absolute;
    left: 19%;
    top: 49%;
    font-weight: 600;
}
.breadcrumb3{
	width        : 262px;
	border-radius: 100px;
}
.mainpagetxt{
	position: absolute;
    left: 19%;
    top: 49%;
    font-weight: 600;
}






.services_img{
  width: 100%;
}
























.checkpage{
	height: 500px;
	margin-top: 200px;
}
.B2T1_line{


}
.innerBlocktepmlate1{
	height: auto;
}
.B2T_overviewTitle{
	  /*top: 53px;
    left: 609px;
    position: absolute;*/
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
    margin-top: 25px;
}


.B2T_p{
    padding-right: 45px;
	/*position: absolute;
    top: 154px;*/
    font-size: 17px;
}
.B2T_overviewTxt{
    height: 350px;

	/*height: 260px;*/
	/*margin-top: 150px;*/
}
.B2T_overviewTxt img{
    /*height: 100%;*/
}
.B2T_btn{
 position: absolute;
 top:350px;

}
.readmore{
	position: absolute;
    top: 360px;
    left: 130px;
    color: #ffffff;
}
.mtop25{
	margin-top: 25px;
}
.ocTitle{
	
}
.newocTitle{
    color: #376bff;
    line-height: 1.5;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin:auto auto;
    margin-top: -20px;

}

.ocLine{
	margin-top: 45px;
	height: 2px;
  background-color: #aaa;
	/*margin-top: 5px;*/ 
}
.spimg1{
    background-image: url("/img/1.png") !important;
    height: 426px;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 90px;


    /*background-image: url("/img/bg001.png");*/

}
.roundimg{
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: 50px;
    margin-left: 377px;
    /*position: relative;*/
    display: block;
    /*width: 100%;*/
    height: auto;
}
.sftext1para1{
    padding: 143px 15px 0px 30px;
    height: 550px;

}
.spimg12{
    
    border-radius: 50%;
    position: relative;
    /*height: 426px;
    width: 426px;*/
    right: 296px;
    top:52px;
}
.sfimg1overlay{
  position: absolute;
    top: 0px;
    bottom: 0;
    left: 378px;
    right: 0;
    height: 100%;
    width: 67%;
    border-radius: 50%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008cba70;
}

.spimg12:hover .sfimg1overlay {
  opacity: 1;
  cursor: pointer;
}

.textspf {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
/* img 2*/
.spimg2{
    background-image: url("/img/2.png") !important;
    height: 426px;
    background-repeat: no-repeat;
    position: relative;
    background-position:right;


    /*background-image: url("/img/bg001.png");*/

}
.roundimg2{
    border-radius: 50%;
    height: 426px;
    width: 426px;
    margin-top: 25px;
    /*position: relative;*/
    display: block;
    /*width: 100%;*/
    height: auto;
}
.sftext1para2{
    padding: 75px 15px 0px 30px;
    height: 550px;
    text-align: right;

}
.spimg12a{
    
    border-radius: 50%;
    position: relative;
    width: 200px;
    top: 69px;
    /*right: 37px;*/
    /*height: 426px;*/
    /*width: 426px;*/
}
.sfimg1overlay2{
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008cba70;
}

.spimg12a:hover .sfimg1overlay2 {
  opacity: 1;
  cursor: pointer;
}

.text2 {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.txt2r{
  text-align: right;
}

/*===============*/
.sftext1para1Mobile{
  text-align: left;
}
.SFWrapMobile{
  height: 2180px;
  /*background-color: #ddd;*/
}
.projecttxt{
  margin-bottom: 25px;
  color: #333;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
}
.projecttxt1{
  margin-bottom: 2px;
  color: #333;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
}
.firstpara1{
  color: #333;
  line-height: 1.4;
  font-weight: 100;
  /*text-align: justify;*/
}
.specialLine{
  margin-left: 44px;
}
.txt2c12{
  font-size: 36px;
  color: #333;
  font-weight: 700;

}
.takeup{
  margin-top: -180px;
}
.blockline3{
  margin-left: 85px;
}
.specilfDiv{
  margin-top: -68px;
}
.takedownimg{
  margin-top: 50px;
}
.newline1{
  width: 85px;
}
.featuretitle{
  font-size: 18px;
  color: #003352;
  font-weight: 600;
}
.featuretext{
    line-height : 23px;
    font-size   : 16px;
    color       : #333;
    margin-left : 15px;
  }
  .textboxposition{
    margin-top: 20px;
  }
  .mainFeatureDiv{
    margin-top: 19px;
  }
  .imagedivfestures{
    margin-top: 65px;
  }
  .innerfeaturediv{
    margin-top: 36px;
  }
  .B3T_AppDiv{
    margin-top: 25px;
  }
  .featuretitleleft{
    padding-right: 0px;
  }
  .block4img{
    margin-top: 50px;
  }
  
  .B2T_iconcircle{
    height: 65px;
    width: 65px;
    border-radius: 100px;
    background-color: #376bff;
  }
  .featureicon{
    margin-top: 10px;
    height: 32px;
  }

  
  .B3T_star{
    position: absolute;
    right: 9px; 
    font-size: 48px;
    color: #ffffff;
    padding-top: 7px;
  }
  .B3T1_img2{
    height: 300px;
    width: 100%;
    margin-top: 40px;

  }
  .B3T_circle1{
    height: 90px;
    width: 90px;
    background-color:#ffffff;
    border-radius: 100px;
    border:7px solid #add8e6;
    bottom:100px;
    position: absolute;
  }
   .B3T_circle2{
    background-color:#ffffff;
    border: 7px solid #add8e6;
    position: absolute;
    border-radius: 100px;
    width: 90px;
    height: 90px;
    left: 173px;
    bottom: 97px;
   }
   .B3T_circle3{
    background-color:#ffffff;
    border: 7px solid #add8e6;
    position: absolute;
    border-radius: 100px;
    width: 90px;
    height: 90px;
    left: 314px;
    bottom: 95px;
   }
   .B3T_circle4{
    background-color:#ffffff;
    border: 7px solid #add8e6;
    position: absolute;
    border-radius: 100px;
    width: 90px;
    height: 90px;
    bottom: 101px;
   }

   .B3T_btmtxt{
    position: absolute;
    bottom: 65px;
    color: #ffffff;
    left: -10px;
  }

  .B3T_circletxt{
    /*padding: 27px;*/
    font-weight: 600;
    font-weight: 600;
    padding-top: 24px ;
    padding-left: 4px;
    position: absolute;
    font-size: 18px;

  }
  .txt2{
    bottom: -40px;
    position: absolute;
    font-size: 11px;
  }
  .iconclass{
    /*margin-left: -15px;*/
    width:40px;
    margin-top: 15px;
  }
.innerBlocktepmlate4{
	height: auto;
}
.B3T_challengesDiv {
   
}
.B4T_img{
	margin-left: 77px;
	height: 80px;
}
.B4T_txt{
	margin-top: 25px;
	margin-left: 20px;
}
.B4T_line{
    background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 54px;
    left: 15px;
  	position: absolute;
  	z-index: 999	
}
.B2T_challengesTitle{
	
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
    margin-top: 75px;
}
 .innerBlock5tepmlate{
 	height:auto;
 }
 .B4T_ServicesDiv{
    /*top: 135px;*/
    box-shadow: 0px 0px 3px 0px #eee;
    /*margin-left: 76px;*/
    margin-bottom: 20px;
    min-height: 150px;
  }
  .B5T_img{
  	/*width: 185px;*/
    padding: 11px;
  }
  .B5T_txt{
  	font-weight: 600;
  }
  .innerServiceDiv{
  	/*padding-left: 45px;*/
  }
  .B2T_serviceTitle{
	/*top: 53px;
    left: 563px;
    position: absolute;*/
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
    margin-top: 83px;
}



 .BT1_line{
 	background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 211px;
    left: 60px;
  	position: absolute;
  	z-index: 999
  }
  .innerBlocktepmlate{
  	height: auto;
  }
  .BT1_img{
  	height: 200px;
  	width: 100%;
  	margin-top: 140px;

  }
  .BT1_bannerTitle{
  	position: absolute;
  	color: #ffffff;
  	font-size: 43px;
    font-weight: 600;
    /*margin-top: 120px;*/
    /*left: 462px;*/
    top: 190px;
  }

   

.innerBlocktepmlate4{
	height: auto;
}
.B3T_NewsDiv {
    /*top: 130px;*/
    height: 190px;
    /*width: 303px;*/
    box-shadow: 3px 6px 4px 2px #eee;
    margin-right: 20px;
    margin-bottom: 40px;
}
.news_img{
	/*margin-left: 77px;*/
	height: 80px;
}
.B4T_txt{
	margin-top: 25px;
	margin-left: 20px;
}
.B4T_line{
    background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 54px;
    left: 15px;
  	position: absolute;
  	z-index: 999	
}
.B2T_challengesTitle{
	/*top: 53px;
    left: 563px;
    position: absolute;*/
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
}
.yearbox{
    box-shadow: -3px 1px 6px 5px #f3f3f3;
    width: 70px;
    height: 47px;
    margin-right: 15px;

    color:#009de2;
}
.maindivNews{
  margin-top: 25px;
  min-height: 400px;
}
.newsheading{
  font-weight: 700;
  color:#009de2;
}
.Newsbannerimg{
  height: 180px;
}

@media screen and (min-width: 1900px) {

  .eventdesc{
    padding: 0px ! important;
  }
}  















.siteMapBlock{
	padding-top: 100px;
	padding-bottom: 50px;
}
.siteMapBlock{
	padding-top: 100px;
	padding-bottom: 50px;
}
.iogpdfbtn{
	color: #ffffff;
	background-color: #009de2;
	border-radius: 4px;
	font-weight: 600;
	/*margin-left: -122px;*/
	height: 31px;
    border: none;
    width: 113px;
    margin-right: 70px;
}
  .assetimgtxt{
    position: absolute;
    z-index: 999;
    bottom: 1356px;
    font-weight: 700;
    left: 84px;
}
.assetimg{
	width: 100%;
	height: 100%;
	margin-top: -190px;
}
.criticalitytext{
	margin-top: 50px;
}
.assetTxticon{
	height: 50px;
}
.criticalitytext1{
	font-size: 20px;
}
.criticalitytext123{
	font-size: 18px ! important ;
	text-align: justify;
}
.criticalitytext12{
	font-size: 20px;
	margin-top: -11px;
	text-align: justify;
}
.assetlisticon{
	height: 30px;
	width: 30px;
	background-color: #fff;
	box-shadow: 1px 0px 5px 1px;
	padding-left: 9px;
	border-radius: 4px;
	margin-top: 6px;
}
.iconcircle{
	height: 15px;
	width: 15px;
	border-radius: 100px;
	background-color: #1FBED6;
	margin-top: 8px;
}
.listposition{
	margin-top: 20px;
}
.img2ndasset{
	background-size: 100% 100%
}
.image2ndasset{
	height: 350px;

}









.sbbannerWall{
	/*height: 535px;*/
  margin-top: 127px;
	/*background-color:  #444;*/
	padding: 0px !important;
	position: relative;
  text-align: center;
  color: white;
  	/*background-image: url("/images/bannerback.jpeg");*/
  background-size: 100% 100%;

}
/*.sbbannerWall:hover {
    -webkit-transform: scale(1.1) ! important;
    transform: scale(1.1) ! important;
    cursor: pointer;
    -webkit-transition-duration: .5s ! important;
    transition-duration: .5s ! important;
  }*/
.nopadding{
	padding: 0px;
}
.blogTitle_Single{
  font-size: 35px ! important;
  color: #fff ! important;
  margin-top: -30px ! important;
  margin-bottom: 20px;

}
@media screen and (max-width: 600px) {
  .blogTitle_Single{
    font-size: 24px ! important;
  }
  .fs24{
    font-size: 18px ! important;
  }

}
.sbcentered {
  background-color: #191818ba;
  /*background-image: url("/images/bannerback1.png");*/
  padding: 0px !important;
  /*background-repeat: no-repeat;*/
  height: 490px;
  background-size: 100% 100%;
  padding:0;
  background-color: rgba(25,24,24,.73);


}
.sbbtextcentered{
  top: 25%;
}
.fs40{
  font-size: 40px;
  margin-top: 40px;

}

.fs72{
  font-weight: 600;
  font-size: 72px;
}
.newblogTxt{
  font-size: 40px ! important;
}
.fs24{
  font-size: 20px;
  color:#fff ! important;
}
.ht100{
  height: 100px;
}
.commentBox {
height: 160px;

}
.newcommentbox{
	margin-bottom: 25px;
}

.formcontent {

height: 85px;

}
textarea.form-control {
    height: auto !important;
}
.commentBoxbtn{
	background-color: #ccc;
}
.userImgB{

	height: 75px;
	width: 75px;
	border-radius: 50%;

}
.graycolor{
	color:#777;
}
.newcommentboxArea{
	background-color: #ffffff ! important;
}
.newbtncommentbox{
	background-color: #009de2;
}
.AllBlogsbannerWall{
  height: 435px;
  /*background-color:  #444;*/
  padding: 0px !important;
  position: relative;
    text-align: center;
    color: white;
    background-image: url("https://wealthyvia.s3.ap-south-1.amazonaws.com/website/ceo.png");
    background-size: 100% 100%;
    margin-bottom: 100px;

}
.AllBlogsbannerWall img{
  margin-top: 30px;
}
.nopadding{
  padding: 0px;
}
.AllBlogscentered {
  background-color: #f3e8e8ba;
  padding: 0px !important;
  height: 435px;
  text-align:center !important;

}
.AllBlogstextcentered{
  position: absolute;
  top: 43%;
  text-align: center;
  left: 0%;
  
}

.fs72{
  font-weight: 600;
  font-size: 72px;
  color: #333;
}
.fs24{
  font-size: 22px;
}



.AllBlogsBox{
	/*background-color: #f6f6f6;*/
	/*border: 1px solid #eaeaea;*/
	padding: 20px;
}
.All1blog1{


}
.blogs_newhwading{

 position: absolute;
    top: -343px;
    color: #333;
    font-size: 55px
}
.Bolgs_div{
	margin-top: 120px ! important;
}
.BT1_bannerTitleblog_res{
	 position: relative;
    color: #fff;
    font-size: 43px;
    font-weight: 600;
    /*left: 27px;*/
    top: 130px;
    letter-spacing: 2px;

}
.blogTitle{
	margin: 5px 0px;
	  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
#data {
    width: 258px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#data:hover{
    overflow: visible; 
    white-space: normal; 
    width: auto;
    position: absolute;
    background-color:#FFF;
}
#data:hover+div {
    margin-top:20px;
}
.AllblogImgB{
	height: 173px!important;
	width: 100%;
}
.Allblog a:hover{
	text-decoration: none;
}
.Allblog:hover .blogTitle{
	color: #1db1f1;
}
.blogPara{
	overflow: hidden;
	height: 125px;
	text-align: justify;
	text-overflow: ellipsis;
	padding-top: 10px;
}
.All1blog1{
	position: relative;
	height: 310px;
	margin-left: 40px;
	overflow: hidden;
	margin-bottom: 50px;
	background-color: #fff;
	box-shadow: 0px 11px 15px 1px #ccc;
}
@media only screen 
    and (device-width : 1440px) 
    and (device-height : 900px) { 
    .All1blog1{
    margin-left:55px ! important;
  }
}
.blogSummeryp10{
	padding-left:  20px;
	padding-right: 20px;
}
.imgRoundrb{
	border-radius: 100%;
	height: 50px !important;
	width: 50px !important;
	position: relative;
	padding: 0px;
    top: 4%;
    left: 8%;
}
.r1blog1 hr{
	margin-bottom: 0px!important;
}

.mtop10{
	margin-top: 10px;
}

.mtop20{
	margin-top: 20px;
}
.graycolor{
	color:#777;
}

.z50{
	position: relative;
	top: -55px;
	
}
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 4%;
 right: -2%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.All1blog1:hover .middle {
  opacity: 1;
}

.wclr{
	color: #fff;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}
.rclr{
	color: #f00;
	margin: 5px;
	font-size: 20px !important;
	cursor: pointer;
}

.hoverbk{
	    background-color: #111;
    border-radius: 12px;
}
.noBlogs{
	margin-top: 10%;
	margin-bottom: 10%;
	font-size:30px !important;
}
.premiumBlogIndicate{
	  position: absolute;
	  left: -20px;
	  top:14px;
	  padding-left: 20px;
	  padding-right:  20px;
	  -webkit-transform: rotate(-40deg);
	          transform: rotate(-40deg);
	  background-color:#f5d26e;
	  color: #333;
	  font-size: 16px;

}
.Allblog{
	/*margin-top: 66px ! important;*/

}
.pricehover-float:hover{
	-webkit-transform: translateY(-6px);
	        transform: translateY(-6px);
}
.AllblogImgB {
    height: 173px!important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
}
.blogDateSBP{
	margin-top: 30px;
}
.mt40{
	text-align: justify;
}
.blogtext1{

	/*margin-top: 120px;*/
	left:8em;
}
.bloglist_margin{
	margin-left: 60px;
}

@media (max-width: 480px){
.bloglist_margin{
	margin-left: 0px ! important;
	background-color: #fff ! important;

  }
.blogtext1{
left: 35% ! important;
	} 
.All1blog1{
	margin-left: 20px ! important;
 } 
} 
@media screen and (min-width: 1900px) {
	.BolgsHoghDef{
		/*margin-left: 130px;*/
	}
	.Allblog{
		width: 400px ! important;
		height: 340px ! important;
		margin-left: 50px ! important;
	}

	.blogtext1{
		left:11em;
	}
	.iogimgblock2 img{
		width: 100% ! important;
	}
	.iogimgblock1 img{
		width: 100% ! important;
	}
	.iogimgblock3 img{
		width: 107% ! important;
	}
	.iogimg_4 img{
		width: 95% ! important;
        margin-top: -21px ! important;
	}
	.iogblock4text{
		/*padding-top: 157px ! important;*/
	}
	
	.iogblock2text{
		padding-top:140px ! important;
	}
	.ht_row4{
		margin-top: 8px ! important;
	}
	.iogblock3text{
		height: 510px ! important;
	}
	.career_para-top2{
		margin-top: 150px ! important;
	}
	.iogimg_4{
		width: 1143px ! important;
	}
}	

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

	.blogtext1{
		left:6em ! important;
	}
/* Styles */
}

@media only screen and (device-width: 1024px){
	.All1blog1{
		margin-left: 55px ! important;
	}
	.blogtext1{
		left:5em ! important;
	}
  }	

  @media only screen and (device-width : 411px) and (device-height : 823px) { 
    .All1blog1 {
		    width: 300px ! important;
		}
   
 } 

@media only screen and (device-width : 2560px){ 
 .All1blog1{
	 	margin-left: 80px ! important;
	 }
}
@media only screen and (device-width : 1366px) { 
 .All1blog1{
	 	margin-left: 45px ! important;
	 }
}
@media only screen and (device-width : 1360px) { 
 .All1blog1{
	 	margin-left: 45px ! important;
	 }
}
@media only screen and (device-width : 1280px){ 
  .All1blog1{
    margin-left: 101px ! important;
  }
 }
 @media only screen and (device-width : 1600px) {
   .All1blog1{
    margin-left: 90px ! important;
  }
}
 @media only screen and (device-width : 1680px) {
   .All1blog1{
    margin-left: 100px ! important;
  }
}
@media only screen and (device-width : 2048px) {
   .All1blog1{
       margin-left: 80px ! important;
  }
}
@media only screen and (device-width : 1152px) {
   .All1blog1{
       margin-left: 70px ! important;
  }
}
@media only screen and (device-width : 3200px) {
   .All1blog1{
     	 width: 465px ! important;
	    height: 340px ! important;
	    margin-left: 50px ! important;
  }
}
.takelftconsult{
	margin-left: 78px;
}
.consultMainImg{
	width: 100%;
}
.Service_overview{
	font-size: 33px;
    color: #3a3737;
    font-weight: 700;
    margin-left: 150px;
}
.serviceOverview_txt{
	line-height: 30px;
    font-size: 19px;
}

.BT3_text1{
	margin-top: 40px;
	margin-bottom: 50px;
}

.innerBlocktepmlate3{
 height: auto;
}
.BT3_dataDiv{
	top:25px;
	/*width: 277px;*/
	/*margin-left: 82px;*/
	margin-bottom: 20px;
  min-height: 240px;

}
.BT3_innerBlock{
	height: 460px;
}
.BT3_text{
	/*margin-top: 150px;*/
	font-size: 17px;
  padding-top: 15px;

}

.BT3_subhead{
	font-weight: 600;
	/*margin-left: 18px;*/
}
.BT3_img{
    margin-left: 77px;
    position: absolute;
    top: 9%;
    right: 12%;
}
.BT4_txt {
    margin-top: 17px;
    /*margin-left: 54px;*/
    font-size: 15px;
    height: auto;
    font-size: 15px;
    /* white-space: nowrap; */
    overflow: hidden !important;
    text-overflow: ellipsis;
}
.BT4_imgBack{
    height: 60px;
    width: 60px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px 1px;
    /*margin-left: 112px;*/
}
.Main_Cards__Bubble1:first-child {
    top: 270px;
    left: -75px;
}
.Main_Cards__Bubble1:first-child {
    width: 200px;
    height: 200px;
    content: "";
}
.Main_Cards__Bubble1 {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in, -webkit-transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble1, .Main_Cards__Wrapper1:after {
    position: absolute;
    background-color: #eeeeee4d;
    border-radius: 50%;
}
.Main_Cards__Bubble1:nth-child(2) {
    right: 0px;
    bottom: 0px;
    width: 600px;
    height: 600px;
}
/*.Main_Cards__Bubble {
    pointer-events: none;
    transition: -webkit-transform 20s ease-in;
    transition: transform 20s ease-in;
    transition: transform 20s ease-in,-webkit-transform 20s ease-in;
}
.Main_Cards__Bubble, .Main_Cards__Wrapper:after {
    position: absolute;
    background-color: rgba(105,92,255,.05);
    border-radius: 50%;
}*/
.havh{
  
}




@-webkit-keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}




@keyframes bubblea1 {
  
  0% {
    left:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
   left:150px ;top :100px; -webkit-transform: scaleY(0.99) scaleX(1.10); transform: scaleY(0.99) scaleX(1.10);
  }
  48% {
    
    left:250px ;top :200px; -webkit-transform: scaleY(1.1) scaleX(0.9); transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    left:500px ;top :350px; -webkit-transform-origin: 350px -50px; transform-origin: 350px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
    left:900px; top :700px; -webkit-transform: scale(1); transform: scale(1);
  }
}
@-webkit-keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@keyframes bubblea2 {
  
  0% {
    right:0px ;top :0px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.90) scaleX(1.10);
            transform: scaleY(0.90) scaleX(1.10);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform: scaleY(0.99) scaleX(1.10);
    
            transform: scaleY(0.99) scaleX(1.10);
  }
  80% {
    -webkit-transform: scaleY(1.10) scaleX(0.99);
            transform: scaleY(1.10) scaleX(0.99);
  }
  97%, 100% {
     right:-900px ;top :-500px;  -webkit-transform: scale(1);  transform: scale(1);
  }
}
@-webkit-keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
@keyframes bubblea3 {
  
  0% {
       
   bottom: 0px;  left: 500px; -webkit-transform: scale(1) ; transform: scale(1) ; 
  }
  20% {
    -webkit-transform: scaleY(0.95) scaleX(1.05);
            transform: scaleY(0.95) scaleX(1.05);
  }
  48% {
    
     -webkit-transform: scaleY(1.1) scaleX(0.9);
    
             transform: scaleY(1.1) scaleX(0.9);
  }
  68% {
    
    -webkit-transform-origin: 550px -50px;
    
            transform-origin: 550px -50px; -webkit-transform: scaleY(0.98) scaleX(1.02); transform: scaleY(0.98) scaleX(1.02);
  }
  80% {
    -webkit-transform: scaleY(1.02) scaleX(0.98);
            transform: scaleY(1.02) scaleX(0.98);
  }
  97%, 100% {
   bottom:1050px; left:100px;    -webkit-transform: scale(1);    transform: scale(1);
  }
}
.bub11{
    -webkit-animation:  bubblea1 30s linear infinite;
            animation:  bubblea1 30s linear infinite;


}

.bub21{
    -webkit-animation: bubblea3 30s linear infinite;
            animation: bubblea3 30s linear infinite;
}

.takelftconsult{
	margin-left: 78px;
}
.consultMainImg{
	width: 100%;
}
.takelftconsult{
	margin-left: 78px;
}
.consultMainImg{
	width: 100%;
}
.innerBlocktepmlate1_dynamic{
	height: 90px;

}
.pageHgt{
	/*min-height: 1875px !important;*/
	width: 100%;
	/*background-color: #f1f1f1;*/
}
.heightblock{
	height: 650px;
}
.dp-blockTitle{
	text-align: center;
	font-size: 40px;
	padding-top: 85px;
}
.inputrow{
	padding: 5px 15px;
}
.contactBar:hover .hovertext{
  display: block;
  cursor: pointer;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}
.hovertext{
  cursor: pointer;
  color: #fff;
  width : 100px;
  right: 5px;
  display: none;
}
.NewLoader{
            /*background-image: url("/images/spin-Preloader.gif");*/
            background-repeat: no-repeat;
            background-size: 98% 100%;
            height: 500px;
            width: 100%;
}
.NewLoader1{
            /*background-image: url("/images/spin-Preloader.gif");*/
            background-repeat: no-repeat;
            background-size: 98% 100%;
            height: 1000px;
            width: 100%;
}


@media only screen and (device-width : 393px) and (device-height : 851px) { 
    .NewLoader{
      margin-left: -250px !important;
    }

  } 

@media only screen and (device-width : 414px) and (device-height : 736px) { 
    .NewLoader{
      margin-left: -250px !important;
    }

  }   
@media only screen and (device-width : 375px) and (device-height : 812px) { 
    .NewLoader{
      margin-left: -250px !important;
    }

  } 
@media only screen and (device-width : 375px) and (device-height : 667px) { 
    .NewLoader{
      margin-left: -250px !important;
    }

  }  


@media screen and (max-width: 600px){ 
    .NewLoader{
      margin-left: -250px !important;
    }

  }       
.pageWrapper{
    height: 6000px;

}
.p1{
    height: 1px;
    width: 1px;
    border: 2px solid #000;
    position: absolute;
    left: 592px;
    top: 342px;
    border-radius: 100%;
    background-color: #000;
}
.p2{
    height: 15px;
    width: 15px;
    border: 1px solid #aaa;
    position: absolute;
    left: 587px;
    top: 336px;
    border-radius: 100%;
}
.p3{
    height: 50px;
    width: 50px;
    border: 1px solid #aaa;
    position: absolute;
    left: 569px;
    top: 317px;
    border-radius: 100%;
}
.p4{
    height: 100px;
    width: 100px;
    border: 1px solid #aaa;
    position: absolute;
    left: 572px;
    top: 293px;
    border-radius: 100%;
}
.p5{
    height: 125px;
    width: 125px;
    border: 1px solid #000;
    position: absolute;
    left: 560px;
    top: 279px;
    border-radius: 100%;
}
.p6{
    height: 145px;
    width: 145px;
    border: 1px solid #000;
    position: absolute;
    left: 550px;
    top: 269px;
    border-radius: 100%;
}
.p7{
    height: 200px;
    width: 200px;
    border: 1px solid #000;
    position: absolute;
    left: 522px;
    top: 238px;
    border-radius: 100%;
}
.p8{
     height: 225px;
    width: 225px;
    border: 1px solid #000;
    position: absolute;
    left: 510px;
    top: 225px;
    border-radius: 100%;
}
.p9{height: 95px;
    border: 1px solid #aaa;
    position: absolute;
    left: 621px;
    top: 297px;
    border-radius: 100%;
}
.p10{
     height: 95px;
    border: 1px solid #aaa;
    position: absolute;
    left: 623px;
    top: 296px;
    border-radius: 100%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.p11{
    height: 35px;
    width: 420px;
    border: 4px solid #000;
    position: absolute;
    left: 419px;
    top: 614px;
  }
.p12{
    height: 35px;
    width: 370px;
    border: 4px solid #000;
    position: absolute;
    left: 444px;
    top: 572px;
}
.p13{
    height: 35px;
    width: 362px;
    border: 4px solid #000;
    position: absolute;
    left: 444px;
    top: 150px;
}
.p14{
    height: 21px;
    width: 45px;
    border: 4px solid #000;
    position: absolute;
    left: 470px;
    top: 189px;
}
.p15{
    height: 21px;
    width: 45px;
    border: 4px solid #000;
    position: absolute;
    left: 720px;
    top: 189px;
}
.p16{
    height: 14px;
    width: 71px;
    border: 4px solid #000;
    position: absolute;
    left: 463px;
    top: 554px;
}
.p17{
    height: 14px;
    width: 71px;
    border: 4px solid #000;
    position: absolute;
    left: 709px;
    top: 554px;
}
.p18{
    width: 195px;
    border: 2px solid #000;
    position: absolute;
    left: 523px;
    top: 200px;
}
.p19{
    height: 336px;
    width: 30px;
    border: 4px solid #000;
    position: absolute;
    left: 733px;
    top: 214px;
}
.p20{
    height: 336px;
    width: 30px;
    border: 4px solid #000;
    position: absolute;
    left: 476px;
    top: 214px;
}
.p21{
        height: 35px;
    width: 267px;
    border: 4px solid #000;
    position: absolute;
    left: 484px;
    top: 111px;
}
.p22{
    height: 35px;
    width: 190px;
    border: 4px solid #000;
    position: absolute;
    left: 522px;
    top: 72px;
}
.p23{
    height: 35px;
    width: 124px;
    border: 4px solid #000;
    position: absolute;
    left: 556px;
    top: 32px;

}
.p24{height: 29px;
    border: 1px solid #000;
    position: absolute;
    left: 631px;
    top: 318px;
    border-radius: 100%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}
.p25{height: 37px;
    border: 1px solid #000;
    position: absolute;
    left: 611px;
    top: 310px;
    border-radius: 100%;
    -webkit-transform: rotate(145deg);
            transform: rotate(145deg);
}
.p26{height: 45px;
    border: 1px solid #000;
    position: absolute;
    left: 630px;
    top: 339px;
    border-radius: 100%;
    -webkit-transform: rotate(155deg);
            transform: rotate(155deg);
}



.para a {
  text-decoration: none;
  font-family: Helvetica;
  font-weight: bold;
  color: #222;
  border: 1px solid #ccc9c9;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 1px 0px #e8e7e7;
  font-size: 15px;
  cursor: default;
}

.para a:hover {
  background-color: #eaeaea;
}

.para a:active {
  box-shadow: inset -0px 4px 2px -3px #ccc9c9;
}

.para h1 {
margin-left: 505px;
    margin-top: -841px;
    color: #111;
    font-family: 'Open Sans Condensed', sans-serif;
    line-height: 3900px;
}

.nav a {
  text-decoration: none;
  font-family: Helvetica;
  font-weight: bold;
  color: #222;
  border: 1px solid #ccc9c9;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 3px;
  box-shadow: 0px 1px 0px #e8e7e7;
  font-size: 15px;
  cursor: default;
}

.nav a:hover {
  background-color: #eaeaea;
}

.para a:active {
  box-shadow: inset -0px 4px 2px -3px #ccc9c9;
}

.para h1 {
margin-left: 505px;
    margin-top: -841px;
    color: #111;
    font-family: 'Open Sans Condensed', sans-serif;
    line-height: 3900px;
}

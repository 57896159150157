.NOpadding{
	padding-left: 0px;
	padding-right: 0px;
}
body{
  /*overflow-x: hidden;*/
}

/* for safari */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{
    /*
      Define here the CSS styles applied only to Safari browsers
      (any version and any device)
	 */
	 
	 .new_HoverDiv1 img, .new_HoverDiv2 img, .new_HoverDiv3 img, .new_HoverDiv4 img, .new_HoverDiv5 img, .new_HoverDiv6 img, .new_HoverDiv7 img, .new_HoverDivforboat img, .new_HoverDiv img{
		-webkit-animation : ''
	 }

}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .new_HoverDiv1 img, .new_HoverDiv2 img, .new_HoverDiv3 img, .new_HoverDiv4 img, .new_HoverDiv5 img, .new_HoverDiv6 img, .new_HoverDiv7 img, .new_HoverDivforboat img, .new_HoverDiv img{
		-webkit-animation : ' ' !important;      
	 }

}





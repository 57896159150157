.innerBlocktepmlate4{
	height: auto;
}
.B3T_challengesDiv {
   
}
.B4T_img{
	margin-left: 77px;
	height: 80px;
}
.B4T_txt{
	margin-top: 25px;
	margin-left: 20px;
}
.B4T_line{
    background-color: #f08f00;
  	width: 90px;
  	height: 4px;
  	top: 54px;
    left: 15px;
  	position: absolute;
  	z-index: 999	
}
.B2T_challengesTitle{
	
    color: #009de2;
    font-size: 35px;
    font-weight: 600;
    margin-top: 75px;
}
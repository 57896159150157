.contentWraper{
    height: auto;
    background-color: transparent;
    padding-top: 161px;
 /* padding-top: 282px;*/
    padding-left: 00px;
    padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}

.aboutus_block{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    /*padding: 32px 40px 56px;*/
    margin-top: 58px;
    text-align: center;
   
    
 /*   margin: 10px;
    margin-left: 43px;*/
}
.icn_1{
    margin-top: 60px;
}

.aboutus_block:hover .icn1_hover{
  display: block;
}
.aboutus_block:hover .icn_1{
  display: none;
}
.aboutus_block .icn1_hover{
  display: none;
}

.icn_2{
  margin-top: 60px;
  margin-bottom: -57px;
}

.aboutus_block:hover .icn2_hover{
  display: block;
}
.aboutus_block:hover .icn_2{
  display: none;
}
.aboutus_block .icn2_hover{
  display: none;
}

.icn_3{
    margin-top: 60px;
}
.aboutus_block:hover .icn3_hover{
  display: block;
}
.aboutus_block:hover .icn_3{
  display: none;
}
.aboutus_block .icn3_hover{
  display: none;
}

.icn_4{
   margin-top: 60px;
}

.aboutus_block:hover .icn4_hover{
  display: block;
}
.aboutus_block:hover .icn_4{
  display: none;
}
.aboutus_block .icn4_hover{
  display: none;
}
.aboutus_block:hover{
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    background-color: #009de2;
    color:white;
    background-image:url("/images/11.png") no-repeat;
    height: 200px;
    top:12px;

}
.aboutus_block1height:hover{
    height: 290px;
    margin-bottom: -5px;
    margin-top: 15px;
    transition-duration: 1.2s;

}

.aboutus_block h2{
    font-size: 28px;
    line-height: 34px;
    font-weight: 600;
    margin-top: 13px;
}

/*.contentheader{
    padding-bottom:35px;
}
.contentheader h1{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    letter-spacing: -.4px;
}
*/
.aboutus_block1height{
    top: 15px;
    /*height: 186px;*/
    height: 240px;
    width: 286px;
    /*width: 260px;*/
    /*margin-left: 44px;*/
}
.aboutus_block2height{
  top:15px;
  height: 300px;
}
.aboutus_block3height{
  top:15px;

  height: 300px;
}
.aboutus_block4height{
  top:15px;

  height: 300px;
}
.aboutus_block5height{
  top:15px;

  height: 300px;
}
.aboutus_block6height{
    top:15px;

  height: 300px;
}

.text-justify{
    text-align: justify;
}
.para-top{
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
}
.respara-top{
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 700;
}

.para1-top{
    margin-top: 56px;
    margin-bottom: -1px;
    font-size: 30px;
    font-weight: 700;
}
@media(max-width: 768px) {
  .para1-top{
   font-size: 27px ! important;
  }
  .line2{
    width: 90px ! important;
  }
}

.iog_txt{
    margin-top: 73px;
    /*margin-left: 34px;*/
    font-size: 20px;
    }

/*.iog_txt1{
    margin-top: 57px;
    margin-left: 34px;
    font-size: 20px;
    }*/
/*
.block1{
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    background-color: #003bb3;
    color:white;
    margin: 10px;
}
.iog_txt1{
    margin-top: 203px;
    margin-left: 57px;
    font-size: 20px;
    }*/

  .btm_txt{
     margin-top: 53px;
  } 

  .b1width{
    width: 377px;
    margin-left: 211px;
    padding-top: 0px;
    height: 163px;
}
   .b2width{
    width: 300px;
    margin-left: 211px;
    padding-top: 0px;
    height: 264px ! important;
}
  .vision_align{
    padding-bottom: 31px;
    font-size: 17px;
  } 
  .vision_align1{
/*padding-top: 16px;*/
  }

  .txt-size-director{
    font-size: 52px;
    
  } 
  .txt-size-founder{
    font-weight: 700;
    font-size: 27px;
    
  }
 .head_spacing{
margin-top: 100px;
}

 .para_spacing{
    font-size: 17px;
    padding: 0px 0px 22px 0px;
    
  }

  .line_subhead{
    /*font-weight: 600;*/
    font-size: 28px;
    /*margin-top: 25px;*/
    color: #000;
  }

  .line_para{
    line-height: 25px;
    letter-spacing: 1px;
     /*margin-top: 20px; */
    font-size: 20px;
}
.line_para1{
    line-height: 23px;
    letter-spacing: 0.5px;
    margin-top: 228px;
    /* padding-right: 110px; */
    margin-left: 50px;
    font-size: 18px;
}

.line_para2{
    line-height: 25px;
    letter-spacing: 1px;
    font-size: 19px;
    color: #000;
}
.line_para3{
    line-height: 23px;
    letter-spacing: 0.5px;
    margin-top: 188px;  
    color: #000;
    margin-left: 52px;
    font-size: 18px;
  }
.line_para4{
    line-height: 23px;
    letter-spacing: 1px;
}
.para-top3{
  font-size: 31px;
  /*margin-top: 160px;*/
}

.alg_lft{
      /*margin-left: -22px;*/
      padding-inline-start: 25px!important;
    font-size: 20px;
    padding: 0 125px 10px 10px;
}

.blck{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    padding: 32px 40px 56px;
    margin-top: 58px;
    margin: 10px;
}

.blck1{
    /*background-color: #ffc60b;*/
    border-radius: 4px;
    box-shadow: 0 10px 29px rgba(92,141,255,.22);
    margin-bottom: 50px;
    /*padding: 32px 40px 56px;*/
    margin-top: 58px;
    margin: 10px;
}


.blck1height{
    top: 137px;
    height: 224px;
    width: 427px;
    margin-left: 161px;
    margin-top: 10px;
    border-radius: 10px;
}
.blck1heightnew{
    top: 137px;
    height: 200px;
    width: 600px;
    /*margin-left: 161px;*/
    margin-top: 10px;
    border-radius: 10px;
    float: right;
}
.blck1height1{
    /*top: 137px;*/
    height: 224px;
    width: 300px;
    /*margin-left: 161px;*/
    margin-top: 86px;
    border-radius: 10px;
}
   /*.anim_box{ color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
}*/

.blk_ht{
  height: 325px;
  margin-top: 24px;
}

.line1{
  background-color: #f08f00;
  width: 90px;
  height: 4px;
  margin-top: 40px;
  margin-left: 35px;
}

.line2{
  background-color: #f19000;
  width: 105px;
  margin-top: 35px;
  height: 4px;
  /*margin-left: 500px;*/
  text-align: center;
}
.resline2{
   background-color: #f19000;
  width: 105px;
  margin-top: 35px;
  height: 4px;
  margin-left: 100px;
  text-align: center;

}

.line_ht{
  line-height: 30.8px;
  margin-bottom: 10px;
}

.ln_ht{
  margin-bottom: 10px;
}
.ln_ht2{
  margin-bottom: 107px;
}

.img1{
  height: ;
  width: ;
}

/*****************************************************************************************/
.intro_img{
    /*width: 100%;
    margin-top: 50px;*/
  }
.third_img{
  width: 70%;
  /*margin-top: 46px;*/
}
.line_yellow{
  background-color: #f19000;
  width: 105px;
  margin-top: 145px;
  height: 4px;
  margin-left: 145px;
}

.truck{
    width: 90%;
    /*margin-top: 26px;*/
    /*margin-left: 113px;*/
}

.plane{
    width: 90%;
    /*margin-left: 49px;*/
    margin-top: 35px;
}

.director_img{
  width: 100%;
  margin-top: 65px;
  /* margin-left: 171px; */
  /*margin-top: 235px;*/
  border: 9px solid #fff;
  box-shadow: 0 10px 29px rgba(92,141,255,0.5);
}
.director_imgDiv{
  /*padding-left: 170px;*/
    padding-top: 30px;
}
.DirectorText{
  padding-top: 27px;
}

.curve_img{
    width: 100%;
    margin-top: -135px;
    margin-left: 250px;
}
.icon1{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn1_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
./*block:hover  .icon1{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icon1:hover .icn1_hover{
  background-image: url("/images/11.png");
  margin-top: 37px;
  margin-bottom: -55px;
}*/
.icon2{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn2{
  margin-top: 35px;
}
.icn2_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.icon3{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn3_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.icon4{
  margin-top: 37px;
  margin-bottom: -55px;
}
.icn4_hover{
  margin-top: 37px;
  margin-bottom: -55px;
  margin-left: 71px;

}
.blck2height{
    top: 101px;
    height: 196px;
    width: 427px;
    margin-left: 161px;
    margin-top: 86px;
    border-radius: 10px;
}

.bckgrnd_curve_img {
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  background-size: 100% 100%;
  height: 550px !important;
  margin-top: 50px;
}

.backgrd_curve1{
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  /*height: auto;*/
  height: 640px;
}
.backgrd_curve1_mob{
  background-image: url("/images/Founder-Profiles.png");
  background-size: 100% 100%;
  /*height: auto;*/
  height: 1150px;
}


.servicekbtn{
   color: #fff;
    margin-top: 15px;
    width: 76%;
    font-weight: 600;
    padding: 5px;
    font-size: 15px;
    padding: 8px;
}
.servicekbtnarrow{
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    margin-left: 10px;
}
@media(max-width : 767px){
  .line1{
    /*margin-left:135px ! important;*/

  }
}
@media(max-width : 600px){
  .line1{
    margin-left:135px ! important;

  }
}
@media(max-width : 768px){
  .line1{
    /*margin-left:118px ! important;*/

  }
}
@media (max-width: 480px){
    .line1{
    margin-left:99px ! important;

  }
  .plane{
    width: 65% ! important;
  }
}

.contentWraper1{
   height: auto;
   background-color: transparent;
   padding-top: 161px;
/*    padding-top: 282px;
*/ padding-left: 0px;
   padding-right: 0px;
    /*background-color: #fff;*/
    /*padding-top: 35px;*/
    /*padding: 0;*/
}
.careerAnchorhover:hover, .careerAnchorhover:focus{
  text-decoration: none
}
.career_para-top2{
  font-size: 40px;
   /* font-weight: 100;*/
   color: #1378d4;
    padding-left: 22px;
    
}
.line_subhead2{
  padding-left: 22px;
  font-size: 20px;
  color: #808080;
  margin-bottom: 41px;
  margin-top: 28px;
}
.career2_img{
  width: 95%;
    margin-top: 206px;
    /*padding-left: 66px;*/
    height: 434px;
}

.spacing1{
  margin-top: 37px;
}
.position_title_1{
font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
}
.position_title_1:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_1 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_1 a:visited * { color: #1eb0e9 !important; }


.position_content{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .first-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing2{
  margin-top: 37px;
}
.position_title_2{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}

/*.position_title_2:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7;
  margin-left: 24px;
  padding-bottom: 16px;
}*/

.position_title_2:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_2 a{
  text-decoration: none;
  color: #1eb0e9 !important;


}
.position_title_2 a:visited * { color: #1eb0e9 !important; }



.position_content2{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department2 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location2 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button2 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .second-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing3{
  margin-top: 37px;
}
.position_title_3{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}

/*.position_title_3:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7; 
  margin-left: 24px;
  padding-bottom: 16px;
}*/
.position_title_3:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_3 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_3 a:visited * { color: #1eb0e9 !important; }



.position_content3{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department3 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location3 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button3 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .third-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .spacing4{
  margin-top: 37px;
}
.position_title_4{
  font-size: 24px;
  line-height: 1.1;
  color: #1eb0e9;
  margin-left: 24px;
  padding-bottom: 16px;
}
/*
.position_title_4:hover{
  font-size: 30px;
  line-height: 1.1;
  color: #337ab7;
  margin-left: 24px;
  padding-bottom: 16px;
}
*/
.position_title_4:hover a{
  /*font-size: 30px;
  line-height: 1.1;*/
  color: #337ab7 !important;
/*  margin-left: 24px;*/
}

.position_title_4 a{
  text-decoration: none;
    color: #1eb0e9 !important;


}
.position_title_4 a:visited * { color: #1eb0e9 !important; }

.blockdefault {
    border-color: #77a0d2;
}
.position_content4{
  display: inline-block;
  float: left;
  width: 85%;
}

.position_department4 {
    font-weight: 500;
    font-style: normal;
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_location4 {
    color: #828e96;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.position_button4 {
    display: inline-block;
    float: left;
    width: 15%;
  }
  .fourth-line{
    background-color: #D3D3D3;
    margin-top: 10px;
    width: 594px;
    height: 0px;
  }

  .button_2 {
    background-color: #003bb3;
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 18px;
    padding-right: 22px;
    padding-bottom: 18px;
    padding-left: 14px;
    width: 231px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
    margin-top: 86px;
    height: -24px;
    border-radius: 40px;
}


.button_2 span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button_2 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  left: 185px;
  transition: 0.5s;
}

.button_2:hover span {
  /*padding-right: 25px;*/

}

.button_2:hover span:after {
  opacity: 1;
  right: 0;
}
.lineee1{
  background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 55px;
    margin-left: 69px;
}

/******************/




/* */

.panel-default>.panel-heading {
  color: #333;
  background-color: #fff;
  border-color: #e4e5e7;
  padding: 0;
  user-select: none;
}

.panel-default>.panel-heading a {
  display: block;
  padding: 10px 15px;
}

.panel-default>.panel-heading a:after {
  content: "";
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
  transition: transform .25s linear;
  -webkit-transition: -webkit-transform .25s linear;
}

.panel-default>.panel-heading a[aria-expanded="true"] {
  background-color: #eee;
}

.panel-default>.panel-heading a[aria-expanded="true"]:after {
  content: "\2212";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.panel-default>.panel-heading a[aria-expanded="false"]:after {
  content: "\002b";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.panel-body{
  color: #000;
}

.full_head {
    display: block;
    border-bottom: 2px dotted #EEE;
  }

  .padding_zero {
    padding: 0 !important;
}

.bottom_pad {
    padding-bottom: 44px !important;
}border-radius: 23px;
.simple_list {
    list-style: disc;
}

.buttonn_1{
    width: 161px;
    height: 40px;
 /*   background-color: #0375ef;*/
    color: white;
    border-radius: 23px;
    padding:9px;
}

.lineee2 {
    background-color: #f19000;
    width: 105px;
    height: 4px;
    margin-top: 55px;
    margin-left: 730px;
}

/*.lightbluebg {
    background-color: #009de2;
}
*/
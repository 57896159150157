.megamenuWrapper{
	width: 90%;
	height: 500px;
	background-color: #fff;
	padding: 15px;
	border: 1px solid #999;
	border-top: 3px solid #aaf;
	margin: auto; 
	margin-top: 100px;
}

.menuCol{
	width: 20%;
}
.evencol{
	background-color: #FAFAFA;
}
.evencol{
	background-color: #FAFAFA;
}